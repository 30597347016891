import { notification } from "antd";

notification.config({
  placement: "top",
  duration: 2.5,
});

export function openNotificationWithIcon(
  type,
  message,
  description,
  className
) {
  notification[type]({
    message: message,
    description: description,
    className: className,
    style: {
      whiteSpace: "pre-wrap",
    },
  });
}
