import { authAxios } from "../api/axiosClient";
import {
  listDepartment,
  listProject,
  listUser,
  postIp_Muiti_user,
} from "../constants";

export const GetListUser = (params = {}) => {
  const url = listUser;
  return authAxios().get(url, { params });
};

export const PostListUser = (params = {}) => {
  const url = listUser;
  return authAxios().post(url, params);
};

export const PostIpMuitiuser = (params = {}) => {
  const url = postIp_Muiti_user;
  return authAxios().post(url, params);
};

export const PutListUserApi = (params = {}) => {
  const url = listUser;
  return authAxios().put(url, params);
};

export const GetIdUserEditApi = (params = {}) => {
  const url = listUser;
  return authAxios().get(url, { params });
};

export const GetListDepartment = (params = {}) => {
  const url = listDepartment;
  return authAxios().get(url, { params });
};

export const GetListProject = (params = {}) => {
  const url = listProject;
  return authAxios().get(url, { params });
};

export const PostListProject = (params = {}) => {
  const url = listProject;
  return authAxios().get(url, params);
};
