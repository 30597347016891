import React, { useEffect, useState } from 'react'
import { GetDataEntry, UpdateRevenue } from '../../api/detailProject';
import { CloseCircleOutlined, HistoryOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { Button, DatePicker, Popconfirm, Spin, Table, Tooltip } from 'antd';
import dayjs from "dayjs";
import { useSelector } from 'react-redux';
import CreateBillIcon from "../../images/CreateBill.svg";
import CreateBillMuchIcon from "../../images/CreateBillMuch.svg";
import ModalEntryCreateBill from './ModalEntryCreateBill';
import { openNotificationWithIcon } from '../../Function';
import ModalCreateMultiBillEntry from './ModalCreateMultiBillEntry';


function Entry() {
  const antIcon = <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />;
  const [loadingTable, setLoadingTable] = React.useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [filteredInfo, setFilteredInfo] = useState({});
  const [dataEntry, setDataEntry] = useState([])
  const [valueFilter, setValueFilter] = useState([])
  const [isOpenModalCreateBill, setIsOpenModalCreateBill] = React.useState(false);
  const [lsPermissions, setLsPermissions] = useState([]);
  const [dataPerMonth, setDataPerMonth] = useState();
  const [dataBasicProject, setDataBasicProject] = useState();
  const [dateValueDetail, setDateValueDetail] = useState()
  const [isOpenModalCreateMultipleBill, setIsOpenModalCreateMultipleBill] =
    useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    defaultPageSize: 20,
    pageSize: 20,
    showSizeChanger: true,
  });
  const pageSize = 10;

  const [monthConfirm, setMonthConfirm] = useState()

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const handleChange = (value) => {
    console.log(456)
    setLoadingSubmit(true);
    fetchDataEntry(value, "change_time")
  };

  const fetchDataEntry = (value, type) => {
    if (type === "first_time") {
      if (value.$D <= 10) {
        if (value.$M === 0) {
          GetDataEntry({
            // date: dayjs(value.$d).format("YYYY-MM-DD"),
            date: (value.$y - 1) + "-" + 12 + "-" + value.$D,
          })
            .then((res) => {
              clearFilters();
              setDataEntry(res.data.message);
              // setDateDataCheck(value);
              setDateValueDetail(value)
              getColumnFilters(res.data.message)
              setLoadingSubmit(false);
            })
            .catch((err) => {
              setLoadingSubmit(false);
            });
        } else {
          GetDataEntry({
            // date: dayjs(value.$d).format("YYYY-MM-DD"),
            date: value.$y + "-" + (value.$M) + "-" + value.$D,
          })
            .then((res) => {
              clearFilters();
              setDataEntry(res.data.message);
              // setDateDataCheck(value);
              setDateValueDetail(value)
              getColumnFilters(res.data.message)
              setLoadingSubmit(false);
            })
            .catch((err) => {
              setLoadingSubmit(false);
            });
        }

      } else {
        GetDataEntry({
          // date: dayjs(value.$d).format("YYYY-MM-DD"),
          date: value.$y + "-" + (value.$M + 1) + "-" + value.$D,
        })
          .then((res) => {
            clearFilters();
            setDataEntry(res.data.message);
            // setDateDataCheck(value);
            setDateValueDetail(value)
            getColumnFilters(res.data.message)
            setLoadingSubmit(false);
          })
          .catch((err) => {
            setLoadingSubmit(false);
          });
      }
    } else {
      GetDataEntry({
        // date: dayjs(value.$d).format("YYYY-MM-DD"),
        date: value.$y + "-" + (value.$M + 1) + "-" + value.$D,
      })
        .then((res) => {
          clearFilters();
          setDataEntry(res.data.message);
          // setDateDataCheck(value);
          setDateValueDetail(value)
          getColumnFilters(res.data.message)
          setLoadingSubmit(false);
        })
        .catch((err) => {
          setLoadingSubmit(false);
        });
    }
  }

  const confirmNoRevenue = (value) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", value.id_detailproject);
    UpdateRevenue(data)
      .then((res) => {
        openNotificationWithIcon("success", "Khai báo thành công");
        console.log(dateValueDetail)
        handleChange(dateValueDetail)
        // callDataDetailProject();
      })
      .catch((err) => {
        openNotificationWithIcon("error", "Khai báo thất bại");
      });
  };

  const getColumnFilters = (dataFilter) => {
    if (dataFilter !== undefined) {
      const filters = dataFilter.reduce((acc, item) => {
        if (!acc.find((filter) => filter.value === item.clientname)) {
          acc.push({
            text: item.clientname,
            value: item.clientname,
          });
        }
        return acc;
      }, []);
      setValueFilter(filters)
      // return filters;
    }
  };

  const clearFilters = () => {
    setFilteredInfo([]);
  };

  useEffect(() => {
    if (userInfo) {
      // setIsSuperA(userInfo.is_superuser);
      const arrPermission = userInfo.permission.map((item, index) => {
        return item.name;
      });

      if (arrPermission !== undefined) {
        setLsPermissions([...arrPermission]);
      }
    }
  }, [userInfo]);

  const showModalCreateBill = (value) => {
    setDataPerMonth(value);
    setDataBasicProject(value)
    setIsOpenModalCreateBill(true);
  };

  const columns = [
    {
      title: <p className="text-header-check">STT</p>,
      dataIndex: "stt",
      width: "4%",
      align: "center",
      render: (value, record, index) => (
        <span>
          {dataEntry.length > 0
            ? index + 1 + (pagination.current - 1) * pagination.pageSize
            : null}
        </span>
      ),
    },
    {
      title: <p className="text-header-check">Khách hàng</p>,

      dataIndex: "clientname",
      // filters: getColumnFilters(),
      filters: valueFilter.map((item) => ({ text: item.text, value: item.value })), // Sử dụng giá trị từ filterValues

      // filterMode: "tree",
      filterSearch: true,
      filteredValue: filteredInfo.clientname || [],
      onFilter: (value, record) => record.clientname.startsWith(value),
      render: (value, record, index) => <span>{value}</span>,
      width: "43%",
      align: "center",
    },
    {
      title: <p className="text-header-check">Dự án</p>,
      dataIndex: "projectname",
      width: "43%",
      align: "center",
      render: (value, record, index) => <span>{value}</span>,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      align: "center",
      width: "10%",
      render: (value, item, index) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {lsPermissions.includes("Tạo phiếu tính tiền Operation") ===
              true && (
                <>
                  <Tooltip placement="top" title={"Tạo phiếu tính tiền"}>
                    <img
                      src={CreateBillIcon}
                      style={{ cursor: "pointer" }}
                      onClick={() => showModalCreateBill(value)}
                      alt=""
                    ></img>
                  </Tooltip>
                  <Tooltip placement="topLeft" title={"Không có doanh thu"}>
                    <Popconfirm
                      title="Thông báo"
                      description="Xác nhận tháng này không có doanh thu?"
                      onConfirm={() => confirmNoRevenue(value)}
                      // onCancel={cancelNoRevenue}
                      placement="topLeft"
                      okText="Có"
                      cancelText="Không"
                    >
                      <CloseCircleOutlined
                        style={{
                          color: "red",
                          fontSize: 20,
                          cursor: "pointer",
                          marginLeft: 16,
                        }}
                      />
                    </Popconfirm>
                  </Tooltip>
                </>
              )}
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    console.log(123)
    fetchDataEntry(dayjs(), "first_time")
    setTimeout(() => {
      setLoadingTable(false);
    }, 1000);
  }, []);

  const changePagination = (pagi, filters, sorter, extra) => {
    console.log(pagi)
    setPagination(pagi)
    setFilteredInfo(filters);
  };

  const showCreateMultipleBill = (value, index) => {
    setIsOpenModalCreateMultipleBill(true);
  };

  const checkDate = () => {
    console.log(dayjs().date())
    if (dayjs().date() <= 10) {
      var d = new Date(dayjs().year(), dayjs().month() - 1, dayjs().date())
      setDateValueDetail(dayjs(d))
      return dayjs(d)
    } else {
      return dayjs()
    }
  }

  return (
    <>
      <div style={{ width: "98%", boxSizing: "border-box" }}>
        {/* Khi có dữ liệu sẽ set số lượng row cho table */}

        {loadingTable === true ? (
          <>
            <div className="loading-detail-table">
              <Spin
                indicator={antIcon}
                spinning={loadingTable}
                size="large"
              ></Spin>
            </div>
          </>
        ) : (
          <>
            <div className="bg-params-check">
              <div className="test-check">
                <label
                  className="typo-month-lc"
                  style={{ marginLeft: "2%", marginTop: "0.5%" }}
                >
                  Tháng dự án
                </label>
                <DatePicker
                  placeholder="Chọn tháng"
                  style={{
                    marginLeft: "2%",
                    marginTop: "0.2%",
                    cursor: "pointer",
                    width: "40%",
                  }}
                  onChange={handleChange}
                  picker="month"
                  format={"MM-YYYY"}
                  allowClear={false}
                  defaultValue={checkDate}
                ></DatePicker>

                <label
                  className="typo-month-lc"
                  style={{ marginLeft: "2%", marginTop: "0.5%" }}
                >
                  &nbsp;
                </label>
                <Button
                  className={"btn-revenue"}
                  onClick={(value, index) =>
                    showCreateMultipleBill(value, index)
                  }
                  style={{ color: "#337aee" }}
                >
                  CREATE MULTIPLE BILL
                  <img
                    style={{ marginLeft: 8 }}
                    src={CreateBillMuchIcon}
                    alt=""
                  ></img>
                </Button>

              </div>
            </div>
            <Table
              columns={columns}
              rowClassName="text-body-check"
              className="table-lc"
              dataSource={dataEntry}
              style={{ marginLeft: "1%", marginTop: "1%", width: "100%" }}
              onChange={changePagination}
              pagination={{ defaultPageSize: 20, showSizeChanger: true }}

              scroll={{
                y: window.screen.availHeight > 850 ? "60vh" : "53vh",
              }}
              loading={loadingSubmit}
            // className="custom-table table-striped-rows"


            />
          </>
        )}

        <ModalEntryCreateBill
          isOpenModalCreateBill={isOpenModalCreateBill}
          setIsOpenModalCreateBill={setIsOpenModalCreateBill}
          dataPerMonth={dataPerMonth}
          dataBasicProject={dataBasicProject}
          dateValueDetail={dateValueDetail}
          handleChange={handleChange}
        />
        <ModalCreateMultiBillEntry
          isOpenModalCreateMultipleBill={isOpenModalCreateMultipleBill}
          setIsOpenModalCreateMultipleBill={setIsOpenModalCreateMultipleBill}
          dataBasicProject={dataBasicProject}
          // valuesDetail={valuesDetail}
          listProject={userInfo.listProject}
          listClientOfUser={userInfo.listClient}
        />
      </div>
    </>
  )
}

export default Entry