import { authAxios } from "../api/axiosClient";
import { listBankAC } from "../constants";

export const GetListBankAC = (params = {}) => {
  const url = listBankAC;
  return authAxios().get(url, { params });
};

export const GetIdBankACEditApi = (params = {}) => {
  const url = listBankAC;
  return authAxios().get(url, { params });
};

export const PostListBankAC = (params = {}) => {
  const url = listBankAC;
  return authAxios().post(url, params);
};

export const PutBankACApi = (params = {}) => {
  const url = listBankAC;
  return authAxios().post(url, params);
};
