import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { logout } from "../../app/Actions/auth";
import imgTong from "../../images/background.svg";
import imgLogin from "../../images/imgLogin.svg";
import imgLoginLogo from "../../images/LOGO IMAGE (1) 1.svg";
import { authAxios } from "../../api/axiosClient";
import { logoutURL } from "../../constants";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { PostListUser } from "../../api/listUser";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const cookies = new Cookies();

const ChangePW = () => {
  const [valueOldPass, setValueOldPass] = React.useState("");
  const [valueNewPass, setValueNewPass] = React.useState("");
  const [valueCheckNewPass, setValueCheckNewPass] = React.useState("");
  const [isFocused1, setIsFocused1] = React.useState(false);
  const [isFocused2, setIsFocused2] = React.useState(false);
  const [isFocused3, setIsFocused3] = React.useState(false);
  const svgFillColor1 = isFocused1 ? "#6266EF" : "#9B9B9B";
  const svgFillColor2 = isFocused2 ? "#6266EF" : "#9B9B9B";
  const svgFillColor3 = isFocused3 ? "#6266EF" : "#9B9B9B";
  const [lsPermissions, setLsPermissions] = useState([]);
  const [lsPermissionsType, setLsPermissionsType] = useState([]);
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const screenWindown = window.screen.width <= 1550;
  const [userlogin, setUserlogin] = useState();
  const history = useHistory();

  useEffect(() => {
    if (userInfo) {
      setUserlogin(userInfo.userlogin);
      if (userInfo.role !== "SuperAdmin") {
        const arrPermission = userInfo.permission.map((item, index) => {
          return item.name;
        });

        const arrPermissionType = userInfo.permission.map((item, index) => {
          return item.type;
        });

        if (arrPermission !== undefined) {
          setLsPermissions([...arrPermission]);
          setLsPermissionsType([...arrPermissionType]);
        }
      } else {
        const arrPermission = userInfo.role;
        setLsPermissions([arrPermission]);
      }
    }
  }, [userInfo]);

  const handleChangeOldPass = (e) => {
    setValueOldPass(e.target.value);
  };

  const handleChangeNewPass = (e) => {
    setValueNewPass(e.target.value);
  };

  const handleChangeCheckNewPass = (e) => {
    setValueCheckNewPass(e.target.value);
  };

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlur1 = () => {
    setIsFocused1(false);
  };

  const handleFocus2 = () => {
    setIsFocused2(true);
  };

  const handleBlur2 = () => {
    setIsFocused2(false);
  };

  const handleFocus3 = () => {
    setIsFocused3(true);
  };

  const handleBlur3 = () => {
    setIsFocused3(false);
  };

  const logout_new = (e) => {
    authAxios()
      .get(logoutURL)
      .then((res) => {
        //tắt
        cookies.remove("token_qldt");
        cookies.remove("refresh_qldt");
        window.location = "/dang-nhap";
      });
    logout();
  };

  const handleClickBack = () => {
    if (
      lsPermissions.includes("Xem project detail Operation") === true &&
      lsPermissions.includes("Xem project detail Sales") === false
    ) {
      history.push("/pj-detail");
    } else if (
      lsPermissions.includes("Xem project detail Operation") === true &&
      lsPermissions.includes("Xem project detail Sales") === true
    ) {
      history.push("/pj-detail");
    } else if (
      lsPermissions.includes("Xem project detail Sales") === true &&
      lsPermissions.includes("Xem project detail Operation") === false
    ) {
      history.push("/pj-detail-sale");
    } else if (
      lsPermissions.includes("Admin Operation") === true &&
      lsPermissionsType.length === 1
    ) {
      history.push("/user");
    } else if (
      lsPermissions.includes("Upload file sao kê Operation") === true &&
      lsPermissionsType.length === 1
    ) {
      history.push("/saoke");
    } else if (
      lsPermissions.includes("LC phiếu tính tiền Operation") === true &&
      lsPermissionsType.length === 1
    ) {
      history.push("/lastcheck");
    } else if (
      lsPermissions.includes("Check phiếu tính tiền Operation") === true &&
      lsPermissionsType.length === 1
    ) {
      history.push("/check");
    }
    else if (
      lsPermissions.includes("Xuất Output DTTT Operation") === true &&
      lsPermissionsType.length === 1
    ) {
      history.push("/dttt");
    }
    else if (
      lsPermissions.includes("Xuất Output DT DD&TT Operation") === true &&
      lsPermissionsType.length === 1
    ) {
      history.push("/dtddtt");
    }
    else if (lsPermissions.includes("SuperAdmin") === true) {
      history.push("/user");
    }
  };

  const handleClickSubmitChangePW = () => {
    if (valueOldPass === "" || valueOldPass === undefined) {
      ToastError.fire({
        icon: "error",
        title: "Please enter your old password.",
      });
      return false;
    } else if (valueNewPass === "" || valueNewPass === undefined) {
      ToastError.fire({
        icon: "error",
        title: "Please enter your new password.",
      });
      return false;
    } else if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()])[A-Za-z\d~`!@#$%^&*()]{9,}$/.test(
        valueNewPass
      ) === false
    ) {
      ToastError.fire({
        icon: "error",
        title:
          "Mật khẩu phải dài ít nhất 9 ký tự và bao gồm chữ hoa và chữ thường, số và ký tự đặc biệt(~`!@#$%^&*()).",
      });
    } else if (
      valueCheckNewPass === "" ||
      valueCheckNewPass === undefined ||
      valueNewPass !== valueCheckNewPass
    ) {
      ToastError.fire({
        icon: "error",
        title: "Please confirm your new password.",
      });
      return false;
    }
  };

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("message", "change_pass");
    data.append("password", values.password);
    data.append("old_pass", values.old_password);
    data.append("username", userlogin);

    // currentDuAn
    PostListUser(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Thành công",
        });
        logout_new();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ height: "100vh", background: "white", position: "relative" }}
    >
      <Col
        className="FormLogin"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={imgTong}
          alt=""
          width={screenWindown === true ? "65%" : "60%"}
          style={{ position: "absolute" }}
        />
        <Row
          style={screenWindown === true ? { width: "65%" } : { width: "60%" }}
        >
          <Col
            span={12}
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <p
                style={{
                  color: "#5465EF",
                  fontWeight: 700,
                  fontSize: "25px",
                }}
              >
                Let's build something great
              </p>
              <img src={imgLogin} alt="" style={{ marginTop: 40 }} />
            </div>
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <div
              style={{
                width: "90%",
                background: "#fff",
                padding: "50px 0px",
                borderRadius: "15px",
                boxShadow: "-1px 2px 21px 0px #00000026",
              }}
            >
              <img src={imgLoginLogo} alt="" />
              <p
                style={{
                  fontSize: "32px",
                  fontWeight: 700,
                  color: "#5C6485",
                }}
              >
                Welcome!!
              </p>
              <div>
                <Form
                  name="normal_login"
                  className="login-form"
                  onFinish={onFinish}
                >
                  <Form.Item className="FormItem">
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        float: "left",
                        marginTop: "2%",
                      }}
                      className={valueOldPass && "filled"}
                    >
                      Current password
                    </p>
                  </Form.Item>
                  <Form.Item
                    name="old_password"
                    key="old_password"
                    className="FormItem1"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      className="inputPageLogin"
                      onFocus={handleFocus1}
                      onBlur={handleBlur1}
                      iconRender={(visible) =>
                        visible ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21 19V9C21 7.89543 20.1046 7 19 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19Z"
                              stroke={svgFillColor1}
                              stroke-width="1.5"
                            />
                            <path
                              d="M13.5 13C13.5 12.1716 12.8284 11.5 12 11.5C11.1716 11.5 10.5 12.1716 10.5 13C10.5 13.8284 11.1716 14.5 12 14.5C12.8284 14.5 13.5 13.8284 13.5 13Z"
                              stroke={svgFillColor1}
                              stroke-width="1.5"
                            />
                            <path
                              d="M12 15L12 17"
                              stroke={svgFillColor1}
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.5 6.625C7.5 4.07068 9.44568 2 12 2C13.3984 2 14.6519 2.62066 15.5 3.60154"
                              stroke={svgFillColor1}
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 7L7.5 6.5C7.5 4.01472 9.51472 2 12 2V2C14.4853 2 16.5 4.01472 16.5 6.5L16.5 7"
                              stroke={svgFillColor1}
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M21 19V9C21 7.89543 20.1046 7 19 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19Z"
                              stroke={svgFillColor1}
                              stroke-width="2"
                            />
                            <path
                              d="M13.5 13C13.5 12.1716 12.8284 11.5 12 11.5C11.1716 11.5 10.5 12.1716 10.5 13C10.5 13.8284 11.1716 14.5 12 14.5C12.8284 14.5 13.5 13.8284 13.5 13Z"
                              stroke={svgFillColor1}
                              stroke-width="2"
                            />
                            <path
                              d="M12 15L12 17"
                              stroke={svgFillColor1}
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )
                      }
                      onChange={handleChangeOldPass}
                    />
                  </Form.Item>
                  <Form.Item className="FormItem">
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        float: "left",
                        marginTop: "2%",
                      }}
                      className={valueNewPass && "filled"}
                    >
                      New Password
                    </p>
                  </Form.Item>
                  <Form.Item
                    name="new_password"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        pattern:
                          /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{9,}/,
                        message: "",
                      },
                    ]}
                    key="new_password"
                    className="FormItem1"
                  >
                    <Input.Password
                      size="large"
                      className="inputPageLogin"
                      placeholder="Password"
                      onFocus={handleFocus2}
                      onBlur={handleBlur2}
                      iconRender={(visible) =>
                        visible ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21 19V9C21 7.89543 20.1046 7 19 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19Z"
                              stroke={svgFillColor2}
                              stroke-width="1.5"
                            />
                            <path
                              d="M13.5 13C13.5 12.1716 12.8284 11.5 12 11.5C11.1716 11.5 10.5 12.1716 10.5 13C10.5 13.8284 11.1716 14.5 12 14.5C12.8284 14.5 13.5 13.8284 13.5 13Z"
                              stroke={svgFillColor2}
                              stroke-width="1.5"
                            />
                            <path
                              d="M12 15L12 17"
                              stroke={svgFillColor2}
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.5 6.625C7.5 4.07068 9.44568 2 12 2C13.3984 2 14.6519 2.62066 15.5 3.60154"
                              stroke={svgFillColor2}
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 7L7.5 6.5C7.5 4.01472 9.51472 2 12 2V2C14.4853 2 16.5 4.01472 16.5 6.5L16.5 7"
                              stroke={svgFillColor2}
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M21 19V9C21 7.89543 20.1046 7 19 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19Z"
                              stroke={svgFillColor2}
                              stroke-width="2"
                            />
                            <path
                              d="M13.5 13C13.5 12.1716 12.8284 11.5 12 11.5C11.1716 11.5 10.5 12.1716 10.5 13C10.5 13.8284 11.1716 14.5 12 14.5C12.8284 14.5 13.5 13.8284 13.5 13Z"
                              stroke={svgFillColor2}
                              stroke-width="2"
                            />
                            <path
                              d="M12 15L12 17"
                              stroke={svgFillColor2}
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )
                      }
                      onChange={handleChangeNewPass}
                    />
                  </Form.Item>
                  <Form.Item className="FormItem">
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        float: "left",
                        marginTop: "2%",
                      }}
                      className={valueCheckNewPass && "filled"}
                    >
                      Confirm new password
                    </p>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    key="password"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("new_password") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(""));
                        },
                      }),
                    ]}
                    hasFeedback
                    className="FormItem1"
                  >
                    <Input.Password
                      size="large"
                      className="inputPageLogin"
                      placeholder="Password"
                      onFocus={handleFocus3}
                      onBlur={handleBlur3}
                      iconRender={(visible) =>
                        visible ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21 19V9C21 7.89543 20.1046 7 19 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19Z"
                              stroke={svgFillColor3}
                              stroke-width="1.5"
                            />
                            <path
                              d="M13.5 13C13.5 12.1716 12.8284 11.5 12 11.5C11.1716 11.5 10.5 12.1716 10.5 13C10.5 13.8284 11.1716 14.5 12 14.5C12.8284 14.5 13.5 13.8284 13.5 13Z"
                              stroke={svgFillColor3}
                              stroke-width="1.5"
                            />
                            <path
                              d="M12 15L12 17"
                              stroke={svgFillColor3}
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.5 6.625C7.5 4.07068 9.44568 2 12 2C13.3984 2 14.6519 2.62066 15.5 3.60154"
                              stroke={svgFillColor3}
                              stroke-width="1.5"
                              stroke-linecap="round"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.5 7L7.5 6.5C7.5 4.01472 9.51472 2 12 2V2C14.4853 2 16.5 4.01472 16.5 6.5L16.5 7"
                              stroke={svgFillColor3}
                              stroke-width="2"
                              stroke-linecap="round"
                            />
                            <path
                              d="M21 19V9C21 7.89543 20.1046 7 19 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19Z"
                              stroke={svgFillColor3}
                              stroke-width="2"
                            />
                            <path
                              d="M13.5 13C13.5 12.1716 12.8284 11.5 12 11.5C11.1716 11.5 10.5 12.1716 10.5 13C10.5 13.8284 11.1716 14.5 12 14.5C12.8284 14.5 13.5 13.8284 13.5 13Z"
                              stroke={svgFillColor3}
                              stroke-width="2"
                            />
                            <path
                              d="M12 15L12 17"
                              stroke={svgFillColor3}
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        )
                      }
                      onChange={handleChangeCheckNewPass}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      // loading={loading}
                      size="large"
                      style={{
                        width: "100%",
                        background: "#5465EF",
                        color: "#fff",
                        fontWeight: 700,
                        display: "flex",
                        textAlign: "center",
                        margin: "auto",
                        fontSize: "16px",
                      }}
                      className="button-with-icon"
                      onClick={handleClickSubmitChangePW}
                    >
                      Submit
                    </Button>
                    <Button
                      htmlType="submit"
                      // loading={loading}
                      onClick={handleClickBack}
                      size="large"
                      style={{
                        width: "100%",
                        background: "#fff",
                        color: "#9199B1",
                        fontWeight: 700,
                        display: "flex",
                        textAlign: "center",
                        margin: "auto",
                        fontSize: "16px",
                        border: "none",
                      }}
                      className="button-with-icon"
                    >
                      Back
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ChangePW;
