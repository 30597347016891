import { authAxios } from "../api/axiosClient";
import {
  importFileClient,
  listClient,
  postIp_Muiti_client,
} from "../constants";

export const GetListClient = (params = {}) => {
  const url = listClient;
  return authAxios().get(url, { params });
};

export const PostListClient = (params = {}) => {
  const url = listClient;
  return authAxios().post(url, params);
};

export const GetIdClientEditApi = (params = {}) => {
  const url = listClient;
  return authAxios().get(url, { params });
};

export const PutClientApi = (params = {}) => {
  const url = listClient;
  return authAxios().post(url, params);
};

export const PostFileClient = (params = {}) => {
  const url = importFileClient;
  return authAxios().post(url, params);
};

export const PostIpMuiticlient = (params = {}) => {
  const url = postIp_Muiti_client;
  return authAxios().post(url, params);
};
