import { Col, Form, Input, Modal, Row, Button, Tree } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { PostListRole } from "../../api/listRole";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const ModalEditRole = ({
  fetchListDataRole,
  isModalOpenEditRole,
  handleOkEditRole,
  setIsModalOpenEditRole,
  treeData,
  dataInforRole,
  idEditRole,
}) => {
  const [form] = Form.useForm();
  const handleCancelEditRole = () => {
    setIsModalOpenEditRole(false);
    form.resetFields();
    setValueRole("");
    setDefaultCheckedValue([]);
  };
  const [defaultCheckedValue, setDefaultCheckedValue] = useState([]);
  const [idValueRole, setValueRole] = useState("");
  const [loadings, setLoadings] = useState(false);

  const onSelect = (selectedKeysValue, info) => {
    // console.log("selected", selectedKeysValue, info);
  };

  const onCheck = (checkedKeys, info) => {
    setDefaultCheckedValue(checkedKeys);

    const uniqueKeys = Object.values(checkedKeys).reduce((acc, key) => {
      const uniqueKey = key.split("-")[1];
      if (uniqueKey && !acc.includes(uniqueKey)) {
        acc.push(uniqueKey);
      }
      return acc;
    }, []);

    const uniqueKeysString = uniqueKeys.join(",");
    setValueRole(uniqueKeysString);
  };

  useEffect(() => {
    form.resetFields();
    if (isModalOpenEditRole) {
      if (dataInforRole.list_permission.length > 0) {
        const treeData = [];

        // Create an object to store the keys for each type
        const typeKeys = {};

        dataInforRole.list_permission.forEach((item) => {
          // Get the type of the current item
          const { type } = item;

          // If the type key doesn't exist in the typeKeys object, create a new key with an empty array
          if (!typeKeys[type]) {
            typeKeys[type] = [];
          }

          // Push the item to the corresponding type key
          typeKeys[type].push(item);
        });
        for (const type in typeKeys) {
          treeData.push({
            title: type === "1" ? "Operation" : "Sale",
            key: `${type}`,
            children: typeKeys[type].map((item) => ({
              title: item.name,
              key: `${type}-${item.id}`,
            })),
          });
        }

        const defaultCheckedKeys1 = treeData
          .map((item) => item.children.map((child) => child.key))
          .flat();
        setDefaultCheckedValue(defaultCheckedKeys1);
      }

      form.setFieldsValue({
        name: dataInforRole.name,
      });
    }
  }, [isModalOpenEditRole, dataInforRole, form]);

  const onFinish = (values) => {
    const uniqueKeys = Object.values(defaultCheckedValue).reduce((acc, key) => {
      const uniqueKey = key.split("-")[1];
      if (uniqueKey && !acc.includes(uniqueKey)) {
        acc.push(uniqueKey);
      }
      return acc;
    }, []);
    const uniqueKeysString = uniqueKeys.join(",");

    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);

    data.append("id", idEditRole);
    data.append("message", "update");
    data.append("name", values.name);
    data.append(
      "manager_per",
      idValueRole === "" ? uniqueKeysString : idValueRole
    );

    // currentDuAn
    PostListRole(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Cập nhật thành công",
        });
        setLoadings(false);
        fetchListDataRole();
        handleCancelEditRole();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="EDIT ROLE"
      open={isModalOpenEditRole}
      onOk={handleOkEditRole}
      onCancel={handleCancelEditRole}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Role Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Market Name" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: "flex" }}>
            <p style={{ marginRight: 10, fontSize: "16px", fontWeight: 500 }}>
              Chọn quyền:
            </p>
            <Tree
              checkable
              onSelect={onSelect}
              onCheck={onCheck}
              treeData={treeData}
              checkedKeys={defaultCheckedValue}
            />
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelEditRole}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalEditRole;
