import { Col, Form, Input, Modal, Row, Button, Select } from "antd";
import React from "react";
import { PostListClient } from "../../api/listClient";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const { Option } = Select;

const ModalAddClient = ({
  isModalOpenAddClient,
  handleOkAddClient,
  setIsModalOpenAddClient,
  fetchListDataClient,
  isListValueMarket,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);
  const handleCancelAddClient = () => {
    setIsModalOpenAddClient(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);

    data.append("message", "create");
    data.append("clientname", values.clientname);
    data.append("clientcode", values.clientcode);
    data.append("marketid", parseInt(values.marketid));
    // currentDuAn
    PostListClient(data)
      .then((res) => {
        if (res.data.message === "ok") {
          ToastSuccess.fire({
            icon: "success",
            title: "Thêm mới Client thành công",
          });
          setLoadings(false);
          fetchListDataClient();
          handleCancelAddClient();
        } else {
          ToastSuccess.fire({
            icon: "error",
            title: res.data.message,
          });
          setLoadings(false);
        }
      })
      .catch((error) => {
        // ToastError.fire({
        //   icon: "error",
        //   title: error.data.message,
        // });
      });
  };

  const handleChangeMarket = () => { };

  return (
    <Modal
      title="ADD CLIENT"
      open={isModalOpenAddClient}
      onOk={handleOkAddClient}
      onCancel={handleCancelAddClient}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Client Name"
              name="clientname"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Project Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Project Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Client Code"
              name="clientcode"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Project Code!",
                },
              ]}
            >
              <Input size="large" placeholder="Project Code" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Market"
              name="marketid"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Market"
                onChange={handleChangeMarket}
              >
                {isListValueMarket.map((market) => (
                  <Option key={market.id} value={market.id}>
                    {market.codemarket}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              // type="success"
              htmlType="submit"
              variant="contained"
              // onClick={() => form.submit()}
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelAddClient}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalAddClient;
