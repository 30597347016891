import { authAxios } from "../api/axiosClient";
import {
  createBill,
  createBillSale,
  detailEditExpRevenue,
  detailProject,
  detailProjectSale,
  exportFileBill,
  getDataProject,
  getProjectRevenue,
  logDetail,
  logDetailSale,
  multipleBill,
  multipleRevenue,
  returnRevenue,
  updateRevenue,
  updateRevenueSale,
} from "../constants";

// Operation
export const GetDataDetailProject = (params = {}) => {
  const url = detailProject;
  return authAxios().get(url, { params });
};

export const GetDataProject = (params = {}) => {
  const url = getDataProject;
  return authAxios().get(url, { params });
};

export const GetDataProjectRevenue = (params = {}) => {
  const url = getProjectRevenue;
  return authAxios().get(url, { params });
};

export const EditDataProjectRevenue = (params = {}) => {
  const url = getProjectRevenue;
  return authAxios().post(url,  params);
};

// Sale
export const GetDataDetailProjectSale = (params = {}) => {
  const url = detailProjectSale;
  return authAxios().get(url, { params });
};

// operation
export const GetLogDetail = (params = {}) => {
  const url = logDetail;
  return authAxios().get(url, { params });
};

// Sale
export const GetLogDetailSale = (params = {}) => {
  const url = logDetailSale;
  return authAxios().get(url, { params });
};

// operation

export const CreateRevenue = (params = {}) => {
  const url = detailProject;
  return authAxios().post(url, params);
};

// sale
export const CreateRevenueSale = (params = {}) => {
  const url = detailProjectSale;
  return authAxios().post(url, params);
};

// operation

export const CreateBill = (params = {}) => {
  const url = createBill;
  return authAxios().post(url, params);
};

export const GetDataEntry = (params = {}) => {
  const url = createBill;
  return authAxios().get(url, {params});
};

// sale
export const CreateBillSale = (params = {}) => {
  const url = createBillSale;
  return authAxios().post(url, params);
};

// operation
export const UpdateRevenue = (params = {}) => {
  const url = updateRevenue;
  return authAxios().post(url, params);
};

// sale
export const UpdateRevenueSale = (params = {}) => {
  const url = updateRevenueSale;
  return authAxios().post(url, params);
};

export const CreateMultipleBill = (params = {}) => {
  const url = multipleBill;
  return authAxios().post(url, params);
};

export const CreateMultipleRevenue = (params = {}) => {
  const url = multipleRevenue;
  return authAxios().post(url, params);
};

export const DetailEditExpRevenue = (params = {}) => {
  const url = detailEditExpRevenue;
  return authAxios().post(url, params);
};

export const GetFileExport = (params = {}) => {
  const url = exportFileBill;
  return authAxios().get(url, { params });
};

export const ReturnRevenueApi = (params = {}) => {
  const url = returnRevenue;
  return authAxios().get(url, { params} );
};
