/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import '../../App.css';
import 'react-pivottable/pivottable.css';
import './pivotSale.css';
import PivotGrid, { FieldChooser } from 'devextreme-react/pivot-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.dark.compact.css';
import { Button, DatePicker } from 'antd';
import { Box, Fade } from '@mui/material';
import { GetListPivot } from '../../api/listPivot';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { GridLoader } from 'react-spinners';

const { RangePicker } = DatePicker;

const PivotSale = () => {
  const [isListValuePivot, setIsListValuePivot] = useState([]);
  const startOfMonth = dayjs().startOf('year');
  const endOfMonth = dayjs().endOf('year');
  const [loading, setLoading] = useState(true);

  const fetchListDataPivot = () => {
    GetListPivot({
      date_from: startOfMonth.format('DD-MM-YYYY'),
      date_to: endOfMonth.format('DD-MM-YYYY'),
      type: '2'
    })
      .then(res => {
        setIsListValuePivot(res.data.message);
        setLoading(false);
      })
      .catch(err => {});
  };

  useEffect(() => {
    setLoading(true);
    fetchListDataPivot();
  }, []);

  const convertToMonth = dateString => {
    const dateParts = dateString !== null && dateString.split('/');
    const month = parseInt(dateParts[1], 10);
    return month;
  };

  const convertToYear = dateString => {
    const dateParts = dateString !== null && dateString.split('/');
    const year = parseInt(dateParts[2], 10);
    return year;
  };

  const convertedData = isListValuePivot
    .filter(item => item['Close Date'] !== null)
    .map(item => ({
      ...item,
      'Close Date': convertToMonth(item['Close Date']),
      Year: convertToYear(item['Close Date'])
    }));

  const dataSource = {
    fields: [
      { dataField: 'Project Name', area: 'row', width: 200 },
      { dataField: 'Year', area: 'column' },
      { dataField: 'Close Date', area: 'column' },
      {
        dataField: 'Exchange Rate',
        summaryType: 'sum',
        customizeText: cellInfo => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined ? cellInfo.value.toLocaleString() : '0';
        }
      },
      {
        dataField: 'Discount',
        summaryType: 'sum',
        customizeText: cellInfo => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined ? cellInfo.value.toLocaleString() : '0';
        }
      },
      {
        dataField: 'Expected Revenue',
        summaryType: 'sum',
        customizeText: cellInfo => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined ? cellInfo.value.toLocaleString() : '0';
        }
      },
      {
        dataField: 'Price Exchange',
        summaryType: 'sum',
        customizeText: cellInfo => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined ? cellInfo.value.toLocaleString() : '0';
        }
      },
      {
        dataField: 'Project Amount',
        area: 'data',
        summaryType: 'sum',
        customizeText: cellInfo => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined ? cellInfo.value.toLocaleString() : '0';
        }
      },
      {
        dataField: 'Quantity',
        summaryType: 'sum',
        customizeText: cellInfo => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined ? cellInfo.value.toLocaleString() : '0';
        }
      },
      {
        dataField: 'Unit Price',
        summaryType: 'sum',
        customizeText: cellInfo => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined ? cellInfo.value.toLocaleString() : '0';
        }
      }
    ],
    store: convertedData // Thay data bằng dữ liệu thực tế của bạn
  };

  const onChangeRangePicker = (month, dateString) => {
    setLoading(true);
    GetListPivot({
      date_from: dateString[0],
      date_to: dateString[1],
      type: '2'
    })
      .then(res => {
        setIsListValuePivot(res.data.message);
        setLoading(false);
      })
      .catch(err => {});
  };
  const screenWindown1540px = window.screen.width <= 1540;
  const DownloadExcel = () => {};

  return (
    <>
      <Box
        sx={{
          width: '96%',
          margin: '2% auto'
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <RangePicker
            picker="month"
            onChange={onChangeRangePicker}
            format={'DD-MM-YYYY'}
            allowClear={false}
            defaultValue={[startOfMonth, endOfMonth]}
            disabled={loading === true ? true : false}
          />
          {isListValuePivot.length > 0 && (
            <Button
              onClick={DownloadExcel}
              style={{
                // fontSize: "14px",
                color: '#fff',
                background: 'rgba(84, 101, 239, 1)',
                borderColor: 'rgba(84, 101, 239, 1)',
                marginLeft: 30
              }}
            >
              Download
            </Button>
          )}
        </div>
        {loading === false ? (
          <PivotGrid
            dataSource={dataSource}
            id="sales"
            allowFiltering={true}
            allowSorting={true}
            allowSortingBySummary={true}
            height={'80vh'}
            showBorders={true}
            style={screenWindown1540px === true ? { maxWidth: 1200 } : { maxWidth: 1600 }}
            onCellClick={e => {
              // const cellData = e.cellElement.textContent;
            }}
          >
            <FieldChooser dataSource={dataSource} enabled={true} height={400} />
          </PivotGrid>
        ) : null}
        {loading === true ? (
          <Fade in={loading} unmountOnExit>
            <div
              style={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                background: '#f5f5f5'
              }}
            >
              <div style={{ marginBottom: '10px' }}>
                <GridLoader color="#020f59" loading={true} size={10} />
              </div>
              <p style={{ fontSize: 20 }}>Loading...</p>
            </div>
          </Fade>
        ) : null}
      </Box>
    </>
  );
};

export default PivotSale;
