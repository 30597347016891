import { authAxios } from "../api/axiosClient";
import { export_Pivot, listPivot } from "../constants";

export const GetListPivot = (params = {}) => {
  const url = listPivot;
  return authAxios().get(url, { params });
};

export const GetExport_Pivot = (params = {}) => {
  const url = export_Pivot;
  return authAxios().get(url, { params });
};
