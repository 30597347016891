import { Button, Col, Form, Input, Modal, Row, Tree } from "antd";
import React, { useState } from "react";
import { PostListRole } from "../../api/listRole";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const ModalAddRole = ({
  isModalOpenAddRole,
  setIsModalOpenAddRole,
  handleOkAddRole,
  fetchListDataRole,
  treeData,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);
  const [idValueRole, setValueRole] = useState(null);

  const handleCancelAddRole = () => {
    setIsModalOpenAddRole(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);

    data.append("message", "create");
    data.append("name", values.name);
    data.append("manager_per", idValueRole);
    // currentDuAn
    PostListRole(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Thành công",
        });
        setLoadings(false);
        fetchListDataRole();
        handleCancelAddRole();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  // const onSelect = (selectedKeys, info) => {
  //   console.log("selected", selectedKeys, info);
  // };

  const onCheck = (checkedKeys, info) => {
    const uniqueKeys = Object.values(checkedKeys).reduce((acc, key) => {
      const uniqueKey = key.split("-")[1];
      if (uniqueKey && !acc.includes(uniqueKey)) {
        acc.push(uniqueKey);
      }
      return acc;
    }, []);
    const uniqueKeysString = uniqueKeys.join(",");
    setValueRole(uniqueKeysString);
  };

  return (
    <Modal
      title="ADD ROLE"
      open={isModalOpenAddRole}
      onOk={handleOkAddRole}
      onCancel={handleCancelAddRole}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Role Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Market Name" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ display: "flex" }}>
            <p style={{ marginRight: 10, fontSize: "16px", fontWeight: 500 }}>
              Chọn quyền:
            </p>
            <Tree
              checkable
              // onSelect={onSelect}
              onCheck={onCheck}
              treeData={treeData}
            />
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelAddRole}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalAddRole;
