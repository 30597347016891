import { Box } from "@mui/material";
import { Button, Col, DatePicker, Row, Select } from "antd";
import React, { useEffect } from "react";
import { GetListDtddtt, GetListDtddttTeam, GetListDtddttTeamDownload, GetVerApi } from "../../api/listDT_DD_TT";
import { localhost } from "../../server";
import { useState } from "react";
import dayjs from "dayjs";
import DT_DD_TT from "./dt_dd_tt";
import DT_DD_TT_TEAM from "./team_dt_dd_tt";
import { ToastError } from "../../components/ToastSwal";
import { openNotificationWithIcon } from "../../Function";
import { useSelector } from "react-redux";
import { saveAs } from 'file-saver';

const { Option } = Select;

const DTDDTT = () => {
  const listDTDDTT = [
    { id: 1, value: "KHPLAN", label: "ALL" },
    { id: 2, value: "Team", label: "DEP" },
  ];

  const screenWindown = window.screen.width <= 1550;
  const dateFormat = "YYYY";

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isListValueDTDDTTTeam1, setIsListValueDTDDTTTeam1] = useState([]);
  const [isListValueDTDDTTTeam2, setIsListValueDTDDTTTeam2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueSelectLoaiReport, setValueSelectLoaiReport] = useState("KHPLAN");
  const [listDataDepCheckId, setListDataDepCheckId] = useState(userInfo.listDepOfProject[0].depart_id);
  const [loadings, setLoadings] = useState(false);
  const [isListValueDTDDTT, setIsListValueDTDDTT] = useState([]);
  const [isListValueSum, setIsListValueSum] = useState([]);
  const [isListValueSumDT, setIsListValueSumDT] = useState([]);
  const [onOpenColumns, setOnOpenColumns] = useState(false)
  const [quantityColumns, setQuantityColumns] = useState(3)
  const [titleBtn, setTitleBtn] = useState("View All")
  const [lsPermissions, setLsPermissions] = useState([]);
  const [verReport, setVerReport] = useState(0)
  const [listCurrency, setListCurrency] = useState([])


  const handleDownload = () => {
    setLoadings(!loadings);
    const fnFileDownload = require("js-file-download");
    console.log(valueSelectLoaiReport)
    if (valueSelectLoaiReport === "KHPLAN") {
      GetListDtddttTeamDownload({
        year: currentYear,
        message: "download",
        ver: verReport,
      })
        .then((res) => {
          console.log(res)
          if (res.data.message === undefined) {
            fnFileDownload(res.data, "all.xlsx");
            setLoadings(false);
          } else {
            openNotificationWithIcon("warning", res.data.message);
            setLoadings(false);
          }
        })
        .catch((err) => {
          setLoadings(false);
        });

    } else if (valueSelectLoaiReport === "Team") {
      GetListDtddtt({
        year: currentYear,
        message: "download",
        ver: verReport,
        dep:
          listDataDepCheckId === undefined
            ? listDataDepCheckId
            : listDataDepCheckId,
      })
        .then((res) => {
          setLoadings(false);
          if (res.data.message === undefined) {
            const blob = new Blob([res.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = `${localhost}/api/dtddtt?year=${currentYear}&dep=${listDataDepCheckId}&message=download&ver=${verReport}`;
            link.setAttribute("download", "team.xlsx"); // Set the desired file name and extension
            document.body.appendChild(link);

            // Trigger the download
            link.click();

            // Clean up the temporary URL and link
            URL.revokeObjectURL(url);
            document.body.removeChild(link);
          } else {
            openNotificationWithIcon(
              "warning",
              `Không có dữ liệu ${currentYear === undefined ? currentYear : currentYear
              }!`
            );
          }
        })
        .catch((err) => {
          setLoadings(false);
          openNotificationWithIcon(
            "warning",
            `Không có dữ liệu ${currentYear === undefined ? currentYear : currentYear
            }!`
          );
        });
    }
  };

  const handleYear = (date, dateString) => {
    console.log(valueSelectLoaiReport)
    GetVerApi({
      year: dateString
    }).then(res => {
      setVerReport(res.data.ver)
      if (valueSelectLoaiReport === "KHPLAN") {
        const selectedYear = dayjs(dateString).year();

        setCurrentYear(selectedYear);
        GetListDtddttTeam({
          year: dateString,
          ver: res.data.ver,
        })
          .then((res) => {
            setIsListValueDTDDTTTeam1(res.data.data["Table 1"]);
            setIsListValueDTDDTTTeam2(res.data.data["Table 2"]);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err)
            ToastError.fire({
              icon: "error",
              title: "Lỗi!",
            });
          });
      } else if (valueSelectLoaiReport === "Team") {
        const selectedYear = dayjs(dateString).year();
        setCurrentYear(selectedYear);
        setLoading(true);
        GetListDtddtt({
          year: dateString,
          dep:
            listDataDepCheckId === undefined
              ? listDataDepCheckId
              : listDataDepCheckId,
          ver: res.data.ver,
        })
          .then((res) => {
            setIsListValueDTDDTT(res.data.message.data);
            setIsListValueSum(res.data.message["data 1"]);
            setIsListValueSumDT(res.data.message["data 2"]);
            setLoading(false);
          })
          .catch((err) => {
            ToastError.fire({
              icon: "error",
              title: "Không có dữ liệu !",
            });
            setLoading(false);
            setIsListValueDTDDTT([]);
            setIsListValueSum([]);
            setIsListValueSumDT([]);
          });
      }
    }).catch(err => {
      console.log(err)
    })

  };

  const handleChangeLoaiReport = (e) => {
    setValueSelectLoaiReport(e);
    console.log(e)
    if (e === "KHPLAN") {
      // setVerReport(event);
      GetListDtddttTeam({
        year: currentYear,
        ver: verReport,
      })
        .then((res) => {
          setIsListValueDTDDTTTeam1(res.data.data["Table 1"]);
          setIsListValueDTDDTTTeam2(res.data.data["Table 2"]);
          setLoading(false);
        })
        .catch((err) => {
          ToastError.fire({
            icon: "error",
            title: "Lỗi!",
          });
        });
    } else if (e === "Team") {
      setLoading(true);
      GetListDtddtt({
        year: currentYear,
        dep:
          listDataDepCheckId === undefined
            ? listDataDepCheckId
            : listDataDepCheckId,
        ver: verReport,
      })
        .then((res) => {
          setIsListValueDTDDTT(res.data.message.data);
          setIsListValueSum(res.data.message["data 1"]);
          setIsListValueSumDT(res.data.message["data 2"]);
          let data = []
          for (let i = 0; i < res.data.message["data 1"].length; i++) {
            data.push(res.data.message["data 1"][i].Currency)
          }
          setListCurrency(data)
          setLoading(false);
        })
        .catch((err) => {
          ToastError.fire({
            icon: "error",
            title: "Không có dữ liệu !",
          });
          setLoading(false);
          setIsListValueDTDDTT([]);
          setIsListValueSum([]);
          setIsListValueSumDT([]);
        });
    }
  };

  const showColumns = () => {
    setOnOpenColumns(!onOpenColumns)
    if (onOpenColumns === true) {
      setTitleBtn("View All")
      setQuantityColumns(3)
    } else {
      setTitleBtn("Hidden")
      setQuantityColumns(6)
    }
  }

  useEffect(() => {
    if (userInfo) {
      // setIsSuperA(userInfo.is_superuser);
      const arrPermission = userInfo.permission.map((item, index) => {
        return item.name;
      });
      console.log(arrPermission)
      if (arrPermission !== undefined) {
        setLsPermissions([...arrPermission]);
      }
    }

  }, [userInfo]);

  const quarters = [
    { name: "1.0", value: 0 },
    { name: "1.1", value: 1 },
    { name: "1.2", value: 2 },
    { name: "1.3", value: 3 },
  ]



  const chooseVer = (event) => {
    console.log(valueSelectLoaiReport)
    console.log(event)
    if (valueSelectLoaiReport === "KHPLAN") {
      setVerReport(event);
      GetListDtddttTeam({
        year: currentYear,
        ver: event,
      })
        .then((res) => {
          setIsListValueDTDDTTTeam1(res.data.data["Table 1"]);
          setIsListValueDTDDTTTeam2(res.data.data["Table 2"]);
          setLoading(false);
        })
        .catch((err) => {
          ToastError.fire({
            icon: "error",
            title: "Lỗi!",
          });
        });
    } else if (valueSelectLoaiReport === "Team") {
      setVerReport(event);
      setLoading(true);
      GetListDtddtt({
        year: currentYear,
        dep:
          listDataDepCheckId === undefined
            ? listDataDepCheckId
            : listDataDepCheckId,
        ver: event,
      })
        .then((res) => {
          setIsListValueDTDDTT(res.data.message.data);
          setIsListValueSum(res.data.message["data 1"]);
          setIsListValueSumDT(res.data.message["data 2"]);
          setLoading(false);
        })
        .catch((err) => {
          ToastError.fire({
            icon: "error",
            title: "Không có dữ liệu !",
          });
          setLoading(false);
          setIsListValueDTDDTT([]);
          setIsListValueSum([]);
          setIsListValueSumDT([]);
        });
    }
  }

  const fetchVerData = () => {
    // console.log(value)
    GetVerApi({
      year: currentYear
    }).then(res => {
      chooseVer(res.data.ver)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    fetchVerData()
  }, []);

  return (
    <>
      <Box
        sx={
          screenWindown === true
            ? {
              width: "96%",
              margin: "0% auto",
            }
            : {
              width: "96%",
              margin: "1% auto",
            }
        }
      >
        <Box
          sx={
            screenWindown === true
              ? { marginBottom: "1%" }
              : { marginBottom: "2%" }
          }
        >
          <Row>
            <Col span={3}>
              <p className="pSaoKeRightBottom">Chọn loại</p>
              <Select
                placeholder="Product/Service"
                onChange={handleChangeLoaiReport}
                style={{
                  width: "80%",
                }}
                defaultValue={valueSelectLoaiReport}
              >
                {listDTDDTT.map((e) => (
                  <Option key={e.id} value={e.value}>
                    {e.label}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col span={3}>
              <p className="pSaoKeRightBottom">Chọn năm</p>
              <DatePicker
                allowClear={false}
                picker="year"
                defaultValue={dayjs(`${currentYear}`, dateFormat)}
                format={dateFormat}
                onChange={handleYear}
                value={dayjs(`${currentYear}`, dateFormat)}
              />
            </Col>
            <Col span={3}>
              <p className="pSaoKeRightBottom">Chọn Ver</p>
              <Select
                placeholder="Chọn Ver"
                key="project"
                maxTagCount="responsive"
                optionFilterProp="children"
                // className="custom-select"
                onChange={chooseVer}
                // showSearch
                value={verReport}
                style={{ width: "80%" }}
              // optionLabelProp="data-label"
              // getPopupContainer={getPopupContainer}
              >
                {quarters.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Col>

            {valueSelectLoaiReport === "Team" && lsPermissions.includes(
              "View full Operation"
            ) ? <Col span={3}>
              <p className="pSaoKeRightBottom">&nbsp;</p>
              <Button onClick={showColumns} style={{ width: 150 }}>{titleBtn}</Button>
            </Col> : null}

            <Col span={3}>
              <p style={{ height: "27px" }}></p>
              <Button
                onClick={handleDownload}
                style={{
                  // fontSize: "14px",
                  color: "#fff",
                  background: "rgba(84, 101, 239, 1)",
                  borderColor: "rgba(84, 101, 239, 1)",
                }}
                loading={loadings}
              >
                Download
              </Button>
            </Col>
          </Row>
        </Box>
      </Box>
      {valueSelectLoaiReport === "KHPLAN" && (
        <DT_DD_TT
          currentYear={currentYear}
          isListValueDTDDTTTeam1={isListValueDTDDTTTeam1}
          setIsListValueDTDDTTTeam1={setIsListValueDTDDTTTeam1}
          isListValueDTDDTTTeam2={isListValueDTDDTTTeam2}
          setIsListValueDTDDTTTeam2={setIsListValueDTDDTTTeam2}
          loading={loading}
          setLoading={setLoading}
          verReport={verReport}
          setVerReport={setVerReport}
        />
      )}
      {valueSelectLoaiReport === "Team" && isListValueDTDDTT.length > 0 ? (
        <DT_DD_TT_TEAM
          currentYear={currentYear}
          listDataDepCheckId={listDataDepCheckId}
          setListDataDepCheckId={setListDataDepCheckId}
          setIsListValueDTDDTT={setIsListValueDTDDTT}
          setIsListValueSum={setIsListValueSum}
          setIsListValueSumDT={setIsListValueSumDT}
          isListValueDTDDTT={isListValueDTDDTT}
          isListValueSum={isListValueSum}
          isListValueSumDT={isListValueSumDT}
          setLoading={setLoading}
          loading={loading}
          onOpenColumns={onOpenColumns}
          quantityColumns={quantityColumns}
          verReport={verReport}
          setVerReport={setVerReport}
          listCurrency={listCurrency}
          setListCurrency={setListCurrency}
        />
      ) : null}
    </>
  );
};

export default DTDDTT;
