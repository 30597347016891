import axios from "axios";
import * as actionTypes from "./actionTypes";
import Cookies from "universal-cookie";
import { loginLockURL, loginURL } from "../../constants";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const cookies = new Cookies();

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const authLogin = (username, password) => {
  var FormData = require("form-data");
  var data = new FormData();
  data.append("username", username);
  data.append("password", password);

  return (dispatch) => {
    axios
      .post(loginLockURL, data)
      .then((res) => {
        if (res.data.message !== "ok") {
          ToastSuccess.fire({
            icon: "success",
            title: res.data.message,
          });
        }
        dispatch(authStart());
        axios
          .post(loginURL, {
            username: username,
            password: password,
          })
          .then((res) => {
            const token = res.data.access;
            const refresh = res.data.refresh;

            //tắt
            cookies.set("token_qldt", token);
            cookies.set("refresh_qldt", refresh);
            dispatch(authSuccess(token));
            dispatch(checkAuthTimeout(60 * 60 * 24 * 15)); // 15d
          })
          .catch((err) => {
            dispatch(authFail(err));
          });
      })
      .catch((error) => {
        if (error.request.status === 0 || error.request.status === 502) {
          ToastError.fire({
            icon: "error",
            title: "Lỗi kết nối, kiểm tra kết nối tới server !!!",
          });
        } else if (error.request.status === 400) {
          ToastError.fire({
            icon: "error",
            title: "Vui lòng kiểm tra lại tài khoản hoặc mật khẩu",
          });
        } else {
          ToastError.fire({
            icon: "error",
            title: "Lỗi chưa xác định !!!",
          });
        }
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = cookies.get("token_qldt");
    if (token === undefined) {
      dispatch(logout());
    } else {
      dispatch(authSuccess(token));
    }
  };
};
