import React, { useEffect } from "react";
import { Box, Fade } from "@mui/material";
import { GridLoader } from "react-spinners";
import { GetListDtddttTeam, GetVerApi } from "../../../api/listDT_DD_TT";
import { Table } from "antd";

const DT_DD_TT = ({
  currentYear,
  isListValueDTDDTTTeam1,
  setIsListValueDTDDTTTeam1,
  isListValueDTDDTTTeam2,
  setIsListValueDTDDTTTeam2,
  loading,
  setLoading,
  verReport,
  setVerReport
}) => {
  const columnsPlan = [
    {
      title: "Plan Revenue",
      dataIndex: "Department",
      key: "Plan Revenue",
      align: "center",
      width: 200,
      render: (text) => {
        return {
          props: {
            style: {
              fontWeight: text === "Grand Total" ? "bold" : null, // Đổi màu chữ thành đỏ nếu là "Grand Total"
            },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "Jan",
      dataIndex: "Jan",
      key: "Jan",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Feb",
      dataIndex: "Feb",
      key: "Feb",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Mar",
      dataIndex: "Mar",
      key: "Mar",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Apr",
      dataIndex: "Apr",
      key: "Apr",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "May",
      dataIndex: "May",
      key: "May",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Jun",
      dataIndex: "Jun",
      key: "Jun",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Jul",
      dataIndex: "Jul",
      key: "Jul",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Aug",
      dataIndex: "Aug",
      key: "Aug",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Sep",
      dataIndex: "Sep",
      key: "Sep",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Oct",
      dataIndex: "Oct",
      key: "Oct",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Nov",
      dataIndex: "Nov",
      key: "Nov",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Dec",
      dataIndex: "Dec",
      key: "Dec",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "SUM (VND)",
      dataIndex: ["SUM (VND)"],
      key: "SUM (VND)",
      align: "right",
      render: (text) => (
        <span style={{ fontWeight: "bold" }}>
          {text !== undefined ? numberWithCommas(text) : ""}
        </span>
      ),
      width: 100,
      className: "custom-header",
    },
  ];

  const columnsActual = [
    {
      title: "Actual Revenue",
      dataIndex: "Department",
      key: "Actual Revenue",
      align: "center",
      width: 200,
      render: (text) => {
        return {
          props: {
            style: {
              fontWeight: text === "Grand Total" ? "bold" : null, // Đổi màu chữ thành đỏ nếu là "Grand Total"
            },
          },
          children: <span>{text}</span>,
        };
      },
    },
    {
      title: "Jan",
      dataIndex: "Jan",
      key: "Jan",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Feb",
      dataIndex: "Feb",
      key: "Feb",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Mar",
      dataIndex: "Mar",
      key: "Mar",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Apr",
      dataIndex: "Apr",
      key: "Apr",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "May",
      dataIndex: "May",
      key: "May",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Jun",
      dataIndex: "Jun",
      key: "Jun",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Jul",
      dataIndex: "Jul",
      key: "Jul",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Aug",
      dataIndex: "Aug",
      key: "Aug",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Sep",
      dataIndex: "Sep",
      key: "Sep",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Oct",
      dataIndex: "Oct",
      key: "Oct",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Nov",
      dataIndex: "Nov",
      key: "Nov",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "Dec",
      dataIndex: "Dec",
      key: "Dec",
      align: "right",
      render: (text, record, index) => {
        const isLastRow = index === isListValueDTDDTTTeam1.length - 1; // Kiểm tra dòng cuối cùng
        return {
          props: {
            style: {
              fontWeight: isLastRow ? "bold" : "normal", // Đặt font-weight thành "bold" nếu là dòng cuối cùng
            },
          },
          children: (
            <span>{text !== undefined ? numberWithCommas(text) : ""}</span>
          ),
        };
      },
      width: 100,
    },
    {
      title: "SUM (VND)",
      dataIndex: ["SUM (VND)"],
      key: "SUM (VND)",
      align: "right",
      render: (text) => (
        <span style={{ fontWeight: "bold" }}>
          {text !== undefined ? numberWithCommas(text) : ""}
        </span>
      ),
      width: 100,
      className: "custom-header",
    },
  ];

  const fetchListDataKH_PLAN = () => {
    GetListDtddttTeam({
      year: currentYear,
      ver: verReport,
    })
      .then((res) => {
        setIsListValueDTDDTTTeam1(res.data.data["Table 1"]);
        setIsListValueDTDDTTTeam2(res.data.data["Table 2"]);
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setLoading(true);
    // fetchListDataKH_PLAN();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numberWithCommas = (number) => {
    if (number !== null) {
      return number.toLocaleString();
    }
  };

  const screenWindown = window.screen.width <= 1550;

  return (
    <>
      <Box
        sx={
          screenWindown === true
            ? {
                width: "96%",
                margin: "0% auto",
              }
            : {
                width: "96%",
                margin: "2% auto",
              }
        }
      >
        {loading === false ? (
          <>
            <Box sx={{ width: "100%" }}>
              <Table
                columns={columnsPlan}
                dataSource={isListValueDTDDTTTeam1}
                pagination={false}
                size="small"
                className="tableDTDDTT"
                rowClassName={(record, index) =>
                  index === isListValueDTDDTTTeam1.length - 1
                    ? "last-row-DTDDTT"
                    : ""
                }
              />
            </Box>
            <Box sx={{ width: "100%", marginTop: 10 }}>
              <Table
                columns={columnsActual}
                dataSource={isListValueDTDDTTTeam2}
                pagination={false}
                className="tableDTDDTT"
                size="small"
                rowClassName={(record, index) =>
                  index === isListValueDTDDTTTeam1.length - 1
                    ? "last-row-DTDDTT"
                    : ""
                }
              />
            </Box>
          </>
        ) : null}
        {loading === true ? (
          <Fade in={loading} unmountOnExit>
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                background: "#f5f5f5",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <GridLoader color="#020f59" loading={true} size={10} />
              </div>
              <p style={{ fontSize: 20 }}>Loading...</p>
            </div>
          </Fade>
        ) : null}
      </Box>
    </>
  );
};

export default DT_DD_TT;
