/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */

import React, { useState, useEffect } from "react";
import { localhost } from "../../server"
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import EditIcon from "../../images/Pen.svg";

import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DownOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Table,
  Tooltip,

} from "antd";

import dayjs from "dayjs";
import { GetLogDetailCenter } from "../../api/apiLog";
const { Option } = Select;

export default function Notifications() {
  const options = [
    { name: "Expected Revenue", value: 1 },
    { name: "Entry", value: 2 },
    { name: "Check", value: 3 },
    { name: "Last check", value: 4 },
    { name: "Bank Statement", value: 5 },
  ]
    
  const [selectedInput, setSelectedInput] = useState(options[0].value);
  const [isLoading, setIsLoading] = useState(false);
  const [monthNotification, setMonthNotification] = useState(dayjs());
  const [checkExpectedRevenue, setCheckExpectedRevenue] = React.useState(false);
  const [dataDetailProject, setDataDetailProject] = useState([]);
  const [valueNotification, setValueNotification] = useState();
  const [formGetDataDetail] = Form.useForm();
  const [lsPermissions, setLsPermissions] = useState([]);
  const [dataNotification, setdataNotification] = useState([])
  const [formNotification] = Form.useForm();
  const [valueFilter, setValueFilter] = useState([])
  const [indexPagi, setIndexPagi] = useState({
    current: 1,
    pageSize: 10,
  })

  const dateValue = new Date();
  const [selectedOption, setSelectedOption] = useState("");

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  useEffect(() => {
    if (userInfo) {
      // setIsSuperA(userInfo.is_superuser);
      const arrPermission = userInfo.permission.map((item, index) => {
        return item.name;
      });

      if (arrPermission !== undefined) {
        setLsPermissions([...arrPermission]);
      }
    }
  }, [userInfo]);

  const getColumnFilters = (dataFilter) => {
    // console.log('isFilter: ',isFilter);
    if (dataFilter !== undefined) {
      // setFilter(true);
      const filters = dataFilter.reduce((acc, item) => {
        if (!acc.find((filter) => filter.value === item.project)) {
          acc.push({
            text: item.project,
            value: item.project,
          });
        }
        return acc;
      }, []);
      setValueFilter(filters);
    }
  };

  const onChangeMonth = (dateobj, dateString) => {
    console.log('dateobj: ', dateobj);
    setMonthNotification(dateobj);
    // setSelectedInput(dateString);
  };

  const handleInput = (valueInput) => {
    setSelectedInput(valueInput);
  }
  const columns = [
    {
      title: <p className="text-header-check">Thời gian</p>,
      dataIndex: "time",
      width: "10%",
      align: "center",
      render: (value, record, index) => <span>{value}</span>,
    },
    {
      title: <p className="text-header-check">Khách hàng</p>,
      dataIndex: "client",
      width: "10%",
      align: "center",
      render: (value, record, index) => <span>{value}</span>,
    },
    {
      title: <p className="text-header-check">Tên dự án</p>,
      dataIndex: "project",
      width: "10%",
      align: "center",
      fixed: "left",
      filters: valueFilter.map((item) => ({ text: item.text, value: item.value })), // Sử dụng giá trị từ filterValues
      filterSearch: true,
      onFilter: (value, record) => record.project.startsWith(value),

      render: (value, item, index) => (
          <p>{value && value !== 0 ? value.toLocaleString() : value}</p>

      ),
    },
    {
      title: <p className="text-header-check">User</p>,
      dataIndex: "user",
      width: "10%",
      align: "center",
      render: (value, record, index) => <span>{value}</span>,
    },
    {
      title: <p className="text-header-check">Chi tiết</p>,
      dataIndex: "detail",
      width: "15%",
      align: "center",
      render: (value, record, index) => <span>{value}</span>,
    },
    {
      title: <p className="text-header-check">Tháng dự án</p>,
      dataIndex: "project_month_year",
      width: "10%",
      align: "center",
      render: (value, record, index) => <span>{value}</span>,
    },
  ];

  const handleFormNotification = (values) => {
    // setValueFilter([]);
    console.log('Selected Month:', monthNotification);
    console.log('Selected Input:', selectedInput);

    var dateObj = new Date(monthNotification); // Đây là ví dụ, bạn cần thay thế bằng đối tượng Date thực tế của bạn

    const day = '01'; // Luôn lấy ngày là 01
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Lấy tháng và thêm số 0 phía trước nếu cần
    const year = dateObj.getFullYear(); // Lấy năm

    const yearMonthDay = day + '-' + month + '-' + year;

    console.log(yearMonthDay); // Kết quả: '01-05-2024'
    
    GetLogDetailCenter({
      type: selectedInput,
      date: yearMonthDay,
    })
      .then((res) => {
        // res.data.message.listProject.map(item => {})
        setValueNotification(values);
        setdataNotification(res?.data?.log)
        setIsLoading(false);        
        getColumnFilters(res?.data?.log);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (dataDetailProject.length === 1) {
      setCheckExpectedRevenue(true);
    } else {
      setCheckExpectedRevenue(false);
    }
  }, [dataDetailProject]);


  useEffect(() => {
    // formExpectedRevenue.resetFields();
    // formGetDataDetail.setFieldValue("ver", Math.floor(dateValue.getMonth() / 3 ) + 1);
    formGetDataDetail.setFieldValue("date", dayjs());
  }, []);


  const changePagination = (pagi, filters, sorter) => {
    setIndexPagi(pagi)
  }

  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "12vh",
          }}
        >
          <div className="get-detail-pj">
            <Form
              form={formGetDataDetail}
              layout="vertical"
              onFinish={handleFormNotification}
              className="custom-form"
              key="formImport"
            >
              <Row className="params-pj" style={{ paddingLeft: "2%", justifyContent: "flex-start" }}>
                
                <Col span={6}>
                    <p className="pSaoKeRightBottom">Tháng nhận log</p>
                    <DatePicker
                      onChange={onChangeMonth}
                      allowClear={false}
                      picker="month"
                      format={"MM-YYYY"}
                      defaultValue={dayjs()}
                      style={{ width: "80%" }}
                      size="large"
                    />
                </Col>

                <Col span={6}>
                    <p className="pSaoKeRightBottom">Input</p>
                    {
                      <Select
                        size="large"
                        placeholder="Bank Account"
                        style={{
                          width: "80%",
                        }}
                        defaultValue={options[0]}
                        onChange={handleInput}
                      >
                        {options.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      ))}
                      </Select>
                    }
                </Col>

                <Col span={2}>
                <p className="pSaoKeRightBottom">
                      {" "}
                      <br />
                    </p>
                  <Button
                    htmlType="submit"
                    id="btn-confirm"
                    loading={isLoading}                    
                    style={{
                      color: "#fff",
                      backgroundColor: "#5465EF",
                      height: "4vh",
                      width: "100%",
                      // marginLeft: 50,
                    }}
                    className="btn-revenue"
                  >
                    CONFIRM
                  </Button>
                </Col>
                
              </Row>
            </Form>

          </div>
        </div>

        <div className="table-sale-ope" style={{ marginTop: "2%" }}>
          <Form
            form={formNotification}
            layout="vertical"
            autoComplete="off"
            // onFinish={onFinishEditRevenue}
            // onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
            }}
            className="custom-form"
            key="formImport"
            style={{ width: "99%", marginLeft: "1%" }}
          >
            <Table
              columns={columns}
              dataSource={dataNotification}
              style={{ width: "98%" }}
              onChange={changePagination}

              scroll={{
                x: 1000,
                y: window.screen.availHeight > 850 ? "56vh" : "53vh",
              }}
              // pagination={false}
              className="custom-table table-striped-rows"
              bordered
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              pagination={{ defaultPageSize: 20 }}
            />
          </Form>
        </div>
      </div>
    </>
  );
}