import { DatePicker, Form, Input, Modal, Table } from "antd";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import { IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { GetIdCurrencyEditApi, PutCurrencyApi } from "../../api/listCurrency";
import { createRef } from "react";
import { EditOutlined } from "@mui/icons-material";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const ModalMoneyDate = ({
  isModalOpenMoneyDate,
  handleOkMoneyDate,
  setIsModalOpenMoneyDate,
  currentYear,
  setCurrentYear,
  dataMoneyDate,
  idEditCurrency,
  fetchListDataCurrency,
  setDataMoneyDate,
}) => {
  const [form] = Form.useForm();

  const [inputRefValue1, setInputRefValue1] = useState();
  const [inputRefValue2, setInputRefValue2] = useState();
  const [inputRefValue3, setInputRefValue3] = useState();
  const [inputRefValue4, setInputRefValue4] = useState();
  const [inputRefValue5, setInputRefValue5] = useState();
  const [inputRefValue6, setInputRefValue6] = useState();
  const [inputRefValue7, setInputRefValue7] = useState();
  const [inputRefValue8, setInputRefValue8] = useState();
  const [inputRefValue9, setInputRefValue9] = useState();
  const [inputRefValue10, setInputRefValue10] = useState();
  const [inputRefValue11, setInputRefValue11] = useState();
  const [inputRefValue12, setInputRefValue12] = useState();
  const [showInput1, setShowInput1] = useState(false);
  const [showInput2, setShowInput2] = useState(false);
  const [showInput3, setShowInput3] = useState(false);
  const [showInput4, setShowInput4] = useState(false);
  const [showInput5, setShowInput5] = useState(false);
  const [showInput6, setShowInput6] = useState(false);
  const [showInput7, setShowInput7] = useState(false);
  const [showInput8, setShowInput8] = useState(false);
  const [showInput9, setShowInput9] = useState(false);
  const [showInput10, setShowInput10] = useState(false);
  const [showInput11, setShowInput11] = useState(false);
  const [showInput12, setShowInput12] = useState(false);
  const inputRef1 = createRef();
  const inputRef2 = createRef();
  const inputRef3 = createRef();
  const inputRef4 = createRef();
  const inputRef5 = createRef();
  const inputRef6 = createRef();
  const inputRef7 = createRef();
  const inputRef8 = createRef();
  const inputRef9 = createRef();
  const inputRef10 = createRef();
  const inputRef11 = createRef();
  const inputRef12 = createRef();
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);
  const [isHovered8, setIsHovered8] = useState(false);
  const [isHovered9, setIsHovered9] = useState(false);
  const [isHovered10, setIsHovered10] = useState(false);
  const [isHovered11, setIsHovered11] = useState(false);
  const [isHovered12, setIsHovered12] = useState(false);

  const ClickAddMoney = (e) => {
    if (e === "1") {
      setShowInput1(true);
    } else if (e === "2") {
      setShowInput2(true);
    } else if (e === "3") {
      setShowInput3(true);
    } else if (e === "4") {
      setShowInput4(true);
    } else if (e === "5") {
      setShowInput5(true);
    } else if (e === "6") {
      setShowInput6(true);
    } else if (e === "7") {
      setShowInput7(true);
    } else if (e === "8") {
      setShowInput8(true);
    } else if (e === "9") {
      setShowInput9(true);
    } else if (e === "10") {
      setShowInput10(true);
    } else if (e === "11") {
      setShowInput11(true);
    } else if (e === "12") {
      setShowInput12(true);
    }
  };

  useEffect(() => {
    if (showInput1 && inputRef1.current) {
      inputRef1.current.focus();
    }
  }, [showInput1, inputRef1]);

  useEffect(() => {
    if (showInput2 && inputRef2.current) {
      inputRef2.current.focus();
    }
  }, [showInput2, inputRef2]);

  useEffect(() => {
    if (showInput3 && inputRef3.current) {
      inputRef3.current.focus();
    }
  }, [showInput3, inputRef3]);

  useEffect(() => {
    if (showInput4 && inputRef4.current) {
      inputRef4.current.focus();
    }
  }, [showInput4, inputRef4]);

  useEffect(() => {
    if (showInput5 && inputRef5.current) {
      inputRef5.current.focus();
    }
  }, [showInput5, inputRef5]);

  useEffect(() => {
    if (showInput6 && inputRef6.current) {
      inputRef6.current.focus();
    }
  }, [showInput6, inputRef6]);

  useEffect(() => {
    if (showInput7 && inputRef7.current) {
      inputRef7.current.focus();
    }
  }, [showInput7, inputRef7]);

  useEffect(() => {
    if (showInput8 && inputRef8.current) {
      inputRef8.current.focus();
    }
  }, [showInput8, inputRef8]);

  useEffect(() => {
    if (showInput9 && inputRef9.current) {
      inputRef9.current.focus();
    }
  }, [showInput9, inputRef9]);

  useEffect(() => {
    if (showInput10 && inputRef10.current) {
      inputRef10.current.focus();
    }
  }, [showInput10, inputRef10]);

  useEffect(() => {
    if (showInput11 && inputRef11.current) {
      inputRef11.current.focus();
    }
  }, [showInput11, inputRef11]);

  useEffect(() => {
    if (showInput12 && inputRef12.current) {
      inputRef12.current.focus();
    }
  }, [showInput12, inputRef12]);

  const handleCancelMoneyDate = () => {
    setIsModalOpenMoneyDate(false);
    form.resetFields();
    setShowInput1(false);
    setShowInput2(false);
    setShowInput3(false);
    setShowInput4(false);
    setShowInput5(false);
    setShowInput6(false);
    setShowInput7(false);
    setShowInput8(false);
    setShowInput9(false);
    setShowInput10(false);
    setShowInput11(false);
    setShowInput12(false);
  };

  const dictRow = {};
  dataMoneyDate.map((value, index) => (dictRow[value.month] = value.money));

  const onClickMoney = (e) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", idEditCurrency);
    data.append("message", "detail");
    data.append("month", e);
    data.append("year", currentYear);
    if (e === "1") {
      data.append("money", inputRefValue1);
      setShowInput1();
      setInputRefValue1();
      setIsHovered1();
    } else if (e === "2") {
      data.append("money", inputRefValue2);
      setShowInput2();
      setInputRefValue2();
      setIsHovered2();
    } else if (e === "3") {
      data.append("money", inputRefValue3);
      setShowInput3();
      setInputRefValue3();
      setIsHovered3();
    } else if (e === "4") {
      data.append("money", inputRefValue4);
      setShowInput4();
      setInputRefValue4();
      setIsHovered4();
    } else if (e === "5") {
      data.append("money", inputRefValue5);
      setShowInput5();
      setInputRefValue5();
      setIsHovered5();
    } else if (e === "6") {
      data.append("money", inputRefValue6);
      setShowInput6();
      setInputRefValue6();
      setIsHovered6();
    } else if (e === "7") {
      data.append("money", inputRefValue7);
      setShowInput7();
      setInputRefValue7();
      setIsHovered7();
    } else if (e === "8") {
      data.append("money", inputRefValue8);
      setShowInput8();
      setInputRefValue8();
      setIsHovered8();
    } else if (e === "9") {
      data.append("money", inputRefValue9);
      setShowInput9();
      setInputRefValue9();
      setIsHovered9();
    } else if (e === "10") {
      data.append("money", inputRefValue10);
      setShowInput10();
      setInputRefValue10();
      setIsHovered10();
    } else if (e === "11") {
      data.append("money", inputRefValue11);
      setShowInput11();
      setInputRefValue11();
      setIsHovered11();
    } else if (e === "12") {
      data.append("money", inputRefValue12);
      setShowInput12();
      setInputRefValue12();
      setIsHovered12();
    }

    // currentDuAn
    PutCurrencyApi(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Cập nhật Money thành công",
        });
        fetchListDataCurrency();

        GetIdCurrencyEditApi({ id: idEditCurrency, year: currentYear })
          .then((re) => {
            setDataMoneyDate(re.data.message);
          })
          .catch((err) => {
            console.log(err);
          });
        // handleCancelMoneyDate();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  const handleYear = (date, dateString) => {
    const selectedYear = dayjs(dateString).year();
    setCurrentYear(selectedYear);
    GetIdCurrencyEditApi({ id: idEditCurrency, year: selectedYear })
      .then((re) => {
        setDataMoneyDate(re.data.message);
        setIsModalOpenMoneyDate(true);
        setShowInput1(false);
        setShowInput2(false);
        setShowInput3(false);
        setShowInput4(false);
        setShowInput5(false);
        setShowInput6(false);
        setShowInput7(false);
        setShowInput8(false);
        setShowInput9(false);
        setShowInput10(false);
        setShowInput11(false);
        setShowInput12(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnchange1 = (e) => {
    const value = e.target.value;
    setInputRefValue1(value);
  };

  const handleOnchange2 = (e) => {
    const value = e.target.value;
    setInputRefValue2(value);
  };

  const handleOnchange3 = (e) => {
    const value = e.target.value;
    setInputRefValue3(value);
  };

  const handleOnchange4 = (e) => {
    const value = e.target.value;
    setInputRefValue4(value);
  };

  const handleOnchange5 = (e) => {
    const value = e.target.value;
    setInputRefValue5(value);
  };

  const handleOnchange6 = (e) => {
    const value = e.target.value;
    setInputRefValue6(value);
  };

  const handleOnchange7 = (e) => {
    const value = e.target.value;
    setInputRefValue7(value);
  };

  const handleOnchange8 = (e) => {
    const value = e.target.value;
    setInputRefValue8(value);
  };

  const handleOnchange9 = (e) => {
    const value = e.target.value;
    setInputRefValue9(value);
  };

  const handleOnchange10 = (e) => {
    const value = e.target.value;
    setInputRefValue10(value);
  };

  const handleOnchange11 = (e) => {
    const value = e.target.value;
    setInputRefValue11(value);
  };

  const handleOnchange12 = (e) => {
    const value = e.target.value;
    setInputRefValue12(value);
  };
  const currentHienTai = dayjs().year();
  const currentMonth = dayjs().month() + 1;

  const columns = [
    {
      title: "Tháng 1",
      dataIndex: "1",
      key: "1",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput1 ? (
              <Input
                ref={inputRef1}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange1}
                suffix={
                  inputRefValue1 && inputRefValue1.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("1")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("1")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput1 ? (
              <Input
                defaultValue={text}
                ref={inputRef1}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange1}
                suffix={
                  inputRefValue1 && inputRefValue1.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("1")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered1(true)}
                onMouseLeave={() => setIsHovered1(false)}
              >
                {text}
                {isHovered1 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("1")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered1 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 1 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("1")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 2",
      dataIndex: "2",
      key: "2",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput2 ? (
              <Input
                ref={inputRef2}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange2}
                suffix={
                  inputRefValue2 && inputRefValue2.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("2")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("2")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput2 ? (
              <Input
                defaultValue={text}
                ref={inputRef2}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange2}
                suffix={
                  inputRefValue2 && inputRefValue2.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("2")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered2(true)}
                onMouseLeave={() => setIsHovered2(false)}
              >
                {text}
                {isHovered2 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("2")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered2 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 2 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("2")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 3",
      dataIndex: "3",
      key: "3",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput3 ? (
              <Input
                ref={inputRef3}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange3}
                suffix={
                  inputRefValue3 && inputRefValue3.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("3")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("3")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput3 ? (
              <Input
                defaultValue={text}
                ref={inputRef3}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange3}
                suffix={
                  inputRefValue3 && inputRefValue3.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("3")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered3(true)}
                onMouseLeave={() => setIsHovered3(false)}
              >
                {text}
                {isHovered3 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("3")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered3 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 3 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("3")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 4",
      dataIndex: "4",
      key: "4",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput4 ? (
              <Input
                ref={inputRef4}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange4}
                suffix={
                  inputRefValue4 && inputRefValue4.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("4")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("4")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput4 ? (
              <Input
                defaultValue={text}
                ref={inputRef4}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange4}
                suffix={
                  inputRefValue4 && inputRefValue4.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("4")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered4(true)}
                onMouseLeave={() => setIsHovered4(false)}
              >
                {text}
                {isHovered4 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("4")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered4 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 4 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("4")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 5",
      dataIndex: "5",
      key: "5",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput5 ? (
              <Input
                ref={inputRef5}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange5}
                suffix={
                  inputRefValue5 && inputRefValue5.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("5")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("5")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput5 ? (
              <Input
                defaultValue={text}
                ref={inputRef5}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange5}
                suffix={
                  inputRefValue5 && inputRefValue5.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("5")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered5(true)}
                onMouseLeave={() => setIsHovered5(false)}
              >
                {text}
                {isHovered5 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("5")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered5 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 5 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("5")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 6",
      dataIndex: "6",
      key: "6",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput6 ? (
              <Input
                ref={inputRef6}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange6}
                suffix={
                  inputRefValue6 && inputRefValue6.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("6")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("6")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput6 ? (
              <Input
                defaultValue={text}
                ref={inputRef6}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange6}
                suffix={
                  inputRefValue6 && inputRefValue6.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("6")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered6(true)}
                onMouseLeave={() => setIsHovered6(false)}
              >
                {text}
                {isHovered6 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("6")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered6 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 6 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("6")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 7",
      dataIndex: "7",
      key: "7",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput7 ? (
              <Input
                ref={inputRef7}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange7}
                suffix={
                  inputRefValue7 && inputRefValue7.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("7")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("7")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput7 ? (
              <Input
                defaultValue={text}
                ref={inputRef7}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange7}
                suffix={
                  inputRefValue7 && inputRefValue7.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("7")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered7(true)}
                onMouseLeave={() => setIsHovered7(false)}
              >
                {text}
                {isHovered7 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("7")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered7 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 7 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("7")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 8",
      dataIndex: "8",
      key: "8",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput8 ? (
              <Input
                ref={inputRef8}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange8}
                suffix={
                  inputRefValue8 && inputRefValue8.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("8")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("8")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput8 ? (
              <Input
                defaultValue={text}
                ref={inputRef8}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange8}
                suffix={
                  inputRefValue8 && inputRefValue8.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("8")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered8(true)}
                onMouseLeave={() => setIsHovered8(false)}
              >
                {text}
                {isHovered8 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("8")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered8 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 8 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("8")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 9",
      dataIndex: "9",
      key: "9",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput9 ? (
              <Input
                ref={inputRef9}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange9}
                suffix={
                  inputRefValue9 && inputRefValue9.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("9")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("9")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput9 ? (
              <Input
                defaultValue={text}
                ref={inputRef9}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange9}
                suffix={
                  inputRefValue9 && inputRefValue9.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("9")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered9(true)}
                onMouseLeave={() => setIsHovered9(false)}
              >
                {text}
                {isHovered9 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("9")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered9 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 9 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("9")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 10",
      dataIndex: "10",
      key: "10",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput10 ? (
              <Input
                ref={inputRef10}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange10}
                suffix={
                  inputRefValue10 && inputRefValue10.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("10")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("10")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput10 ? (
              <Input
                defaultValue={text}
                ref={inputRef10}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange10}
                suffix={
                  inputRefValue10 && inputRefValue10.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("10")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered10(true)}
                onMouseLeave={() => setIsHovered10(false)}
              >
                {text}
                {isHovered10 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("10")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered10 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 10 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("10")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 11",
      dataIndex: "11",
      key: "11",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput11 ? (
              <Input
                ref={inputRef11}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange11}
                suffix={
                  inputRefValue11 && inputRefValue11.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("11")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("11")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput11 ? (
              <Input
                defaultValue={text}
                ref={inputRef11}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange11}
                suffix={
                  inputRefValue11 && inputRefValue11.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("11")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered11(true)}
                onMouseLeave={() => setIsHovered11(false)}
              >
                {text}
                {isHovered11 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("11")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered11 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 11 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("11")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
    {
      title: "Tháng 12",
      dataIndex: "12",
      key: "12",
      align: "center",
      render: (text, record) =>
        text === undefined ? (
          <>
            {showInput12 ? (
              <Input
                ref={inputRef12}
                id="myInput12"
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange12}
                suffix={
                  inputRefValue12 && inputRefValue12.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("12")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <IconButton onClick={() => ClickAddMoney("12")}>
                <AddCircleIcon />
              </IconButton>
            )}
          </>
        ) : (
          <>
            {showInput12 ? (
              <Input
                defaultValue={text}
                ref={inputRef12}
                style={{ width: "100%" }}
                className="InputMoney"
                onChange={handleOnchange12}
                suffix={
                  inputRefValue12 && inputRefValue12.length >= 1 ? (
                    <CheckCircleRoundedIcon
                      className="iconSaveMoney"
                      onClick={(e) => onClickMoney("12")}
                    />
                  ) : (
                    (e) => e
                  )
                }
              ></Input>
            ) : (
              <p
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered12(true)}
                onMouseLeave={() => setIsHovered12(false)}
              >
                {text}
                {isHovered12 && currentYear > currentHienTai && (
                  <IconButton
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "50%",
                      transform: "translate(50%, -50%)",
                      zIndex: 1,
                    }}
                    onClick={() => ClickAddMoney("12")}
                  >
                    <EditOutlined />
                  </IconButton>
                )}
                {isHovered12 &&
                  currentYear === currentHienTai &&
                  currentMonth <= 12 && (
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "50%",
                        transform: "translate(50%, -50%)",
                        zIndex: 1,
                      }}
                      onClick={() => ClickAddMoney("12")}
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
              </p>
            )}
          </>
        ),
    },
  ];

  const dateFormat = "YYYY";

  return (
    <Modal
      title="DATE MONEY"
      open={isModalOpenMoneyDate}
      onOk={handleOkMoneyDate}
      onCancel={handleCancelMoneyDate}
      footer={false}
      width={1350}
      forceRender={true}
      className="headerTitleModal"
    >
      <DatePicker
        allowClear={false}
        picker="year"
        defaultValue={dayjs(`${currentYear}`, dateFormat)}
        format={dateFormat}
        onChange={handleYear}
        value={dayjs(`${currentYear}`, dateFormat)}
      />
      <Table columns={columns} dataSource={[dictRow]} pagination={false} />
    </Modal>
  );
};

export default ModalMoneyDate;
