import { Col, Form, Input, Modal, Row, Button, Select } from "antd";
import React from "react";
import { useEffect } from "react";
import { PutClientApi } from "../../api/listClient";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const { Option } = Select;

const ModalEditClient = ({
  isModalOpenEditClient,
  handleOkEditClient,
  setIsModalOpenEditClient,
  isListValueMarket,
  fetchListDataClient,
  idEditClient,
  dataInforClient,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  const handleCancelEditClient = () => {
    setIsModalOpenEditClient(false);
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
    if (isModalOpenEditClient) {
      form.setFieldsValue({
        name_client: dataInforClient.name_client,
        client_code: dataInforClient.client_code,
        market_code: dataInforClient.market_code,
      });
    }
  }, [isModalOpenEditClient, dataInforClient, form]);

  const handleChangeMarket = () => {};

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);
    data.append("id", idEditClient);
    data.append("message", "update");
    data.append("clientname", values.name_client);
    data.append("clientcode", values.client_code);
    data.append("marketid", parseInt(values.market_code));
    // currentDuAn
    PutClientApi(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Cập nhật Client thành công",
        });
        setLoadings(false);
        fetchListDataClient();
        handleCancelEditClient();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="EDIT CLIENT"
      open={isModalOpenEditClient}
      onOk={handleOkEditClient}
      onCancel={handleCancelEditClient}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Client Name"
              name="name_client"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Project Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Project Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Client Code"
              name="client_code"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Project Code!",
                },
              ]}
            >
              <Input size="large" placeholder="Project Code" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Market"
              name="market_code"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Market"
                onChange={handleChangeMarket}
              >
                {isListValueMarket.map((market) => (
                  <Option key={market.id} value={market.id.toString()}>
                    {market.codemarket}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              variant="contained"
              onClick={() => form.submit()}
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelEditClient}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalEditClient;
