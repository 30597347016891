import { Empty, Modal, Table } from 'antd';
import React, { useState } from 'react'

function ModalShowCheck({ onModalShowCheck, setOnModalShowCheck, dataProjectNotEntry }) {
  const handleCancel = () => {
    setOnModalShowCheck(false)
  }

  const columns = [
    {
      title: "Tên khách hàng",
      width: 50,
      dataIndex: "clientname",
      key: "clientname",
      align: "center",
    },
    {
      title: "Tên dự án",
      dataIndex: "projectname",
      key: "projectname",
      width: 50,
      align: "center",
    },
  ];

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="title-bill-log">
              <span className="title-field-bill" style={{ fontSize: 22 }}>
                Dự án chưa tạo phiếu tính tiền
              </span>
            </div>
          </div>
        }
        open={onModalShowCheck}
        onCancel={handleCancel}
        width={1000}
        closable={false}
        footer={false}
        style={{ marginTop: 40 }}
      >
        {dataProjectNotEntry && dataProjectNotEntry.length > 0 ? (
          <Table
            columns={columns}
            dataSource={dataProjectNotEntry}
            style={{
              width: "100%",
              marginTop: 20,

            }}
            scroll={dataProjectNotEntry.length > 0 ? {
              y: 500,
            } : null}
            pagination={false}
            className="custom-table table-striped-rows"
            rowClassName="text-body-log"
            bordered
          />
        ) : (
          <div>
            <Empty style={{ display: "initial" }} />
          </div>
        )}
      </Modal>
    </>
  );
}
export default ModalShowCheck