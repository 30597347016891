import { authAxios } from "../api/axiosClient";
import { dtddttProduct, dtddttTeam, getVer } from "../constants";

export const GetListDtddtt = (params = {}) => {
  const url = dtddttProduct;
  return authAxios().get(url, { params });
};

export const GetListDtddttTeamDownload = (params = {}) => {
  const url = dtddttTeam;
  return authAxios().get(url, { params, responseType: "blob" });
};

export const GetListDtddttTeam = (params = {}) => {
  const url = dtddttTeam;
  return authAxios().get(url, {params});
};

export const GetVerApi = (params = {}) => {
  const url = getVer;
  return authAxios().get(url, { params });
};

