/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Fade } from "@mui/material";
import { GridLoader } from "react-spinners";
import { Select, Table } from "antd";
import { GetListDtddtt } from "../../../api/listDT_DD_TT";
import { GetListDepartment } from "../../../api/lsDepartment";
import { ToastError } from "../../../components/ToastSwal";
import { useSelector } from "react-redux";
const { Option } = Select;

const DT_DD_TT = ({
  listDataDepCheckId,
  setListDataDepCheckId,
  currentYear,
  setIsListValueDTDDTT,
  setIsListValueSum,
  setIsListValueSumDT,
  isListValueDTDDTT,
  isListValueSum,
  isListValueSumDT,
  setLoading,
  loading,
  onOpenColumns,
  quantityColumns,
  verReport,
  listCurrency,
  setListCurrency
}) => {
  const [listDataDep, setListDataDep] = useState([]);
  // const [quantityColumns, setQuantityColumns] = useState(3)
  const [valueFilter, setValueFilter] = useState([])
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const [checkId, setCheckId] = useState(userInfo.listDepOfProject[0].depart_id)

  // const [listCurrency, setListCurrency] = useState([])
  const getColumnFilters = (dataFilter) => {
    console.log(dataFilter)
    if (dataFilter !== undefined) {
      const filters = dataFilter.reduce((acc, item) => {
        if (!acc.find((filter) => filter.value === item.Client)) {
          acc.push({
            text: item.Client,
            value: item.Client,
          });
        }
        return acc;
      }, []);
      setValueFilter(filters)
      // return filters;
    }
  };

  const screenWindown = window.screen.width <= 1550;

  // const fetchListDep = () => {
  //   GetListDepartment()
  //     .then((res) => {
  //       console.log(res)
  //       setListDataDep(res.data.message);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const fetchListDataDTDDTT = (idCurrenDep) => {
  //   console.log(idCurrenDep)
  //   GetListDtddtt({
  //     year: currentYear,
  //     dep: idCurrenDep,
  //     ver: verReport,
  //   })
  //     .then((res) => {
  //       debugger
  //       setIsListValueDTDDTT(res.data.message.data);
  //       getColumnFilters(res.data.message.data)
  //       setIsListValueSum(res.data.message["data 1"]);
  //       let data = []
  //       for (let i = 0; i < res.data.message["data 1"].length; i++) {
  //         data.push(res.data.message["data 1"][i].Currency)
  //       }
  //       setListCurrency(data)
  //       setIsListValueSumDT(res.data.message["data 2"]);
  //       setLoading(false);
  //     })
  //     .catch((err) => { });
  // };

  useEffect(() => {
    if (isListValueDTDDTT !== undefined) {
      const filters = isListValueDTDDTT.reduce((acc, item) => {
        if (!acc.find((filter) => filter.value === item.Client)) {
          acc.push({
            text: item.Client,
            value: item.Client,
          });
        }
        return acc;
      }, []);
      setValueFilter(filters)
      // return filters;
    }
  }, []);

  useEffect(() => {
    if (checkId.length > 0) {
      setListDataDepCheckId(listDataDep[0].iddepart);
      // fetchListDataDTDDTT(checkId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkId]);

  const onChangeDep = (e) => {
    setCheckId(e);
    setLoading(true);
    GetListDtddtt({
      year: currentYear,
      dep: e,
      ver: verReport,
    })
      .then((res) => {
        setIsListValueDTDDTT(res.data.message.data);
        getColumnFilters(res.data.message.data)

        setIsListValueSum(res.data.message["data 1"]);
        setIsListValueSumDT(res.data.message["data 2"]);
        let data = []
        for (let i = 0; i < res.data.message["data 1"].length; i++) {
          data.push(res.data.message["data 1"][i].Currency)
        }
        setListCurrency(data)
        setLoading(false);
        setListDataDepCheckId(e)
      })
      .catch((err) => {
        ToastError.fire({
          icon: "error",
          title: "Không có dữ liệu !",
        });
        setLoading(false);
        setIsListValueDTDDTT([]);
        setIsListValueSum([]);
        setIsListValueSumDT([]);
      });
  };

  const numberWithCommas = (number) => {
    if (number !== null) {
      return number.toLocaleString();
    }
  };

  const data = isListValueDTDDTT.map((entry, index) => {
    return {
      key: index + 1,
      Category: entry.Category,
      Client: entry.Client,
      Productservice: entry.Productservice,
      Project: entry.Project,
      BankAccount: entry["Bank account"],
      Currency: entry.Currency,
      PlanJan: entry.Plan.Jan,
      PlanFeb: entry.Plan.Feb,
      PlanMar: entry.Plan.Mar,
      PlanApr: entry.Plan.Apr,
      PlanMay: entry.Plan.May,
      PlanJun: entry.Plan.Jun,
      PlanJul: entry.Plan.Jul,
      PlanAug: entry.Plan.Aug,
      PlanSep: entry.Plan.Sep,
      PlanOct: entry.Plan.Oct,
      PlanNov: entry.Plan.Nov,
      PlanDec: entry.Plan.Dec,
      PlanSumPlan: entry["Sum Plan"],
      PlanSumPlanVND: entry["Sum Plan VND"],

      // ... other month columns for Plan
      ACTUALJan: entry.ACTUAL.Jan,
      ACTUALFeb: entry.ACTUAL.Feb,
      ACTUALMar: entry.ACTUAL.Mar,
      ACTUALApr: entry.ACTUAL.Apr,
      ACTUALMay: entry.ACTUAL.May,
      ACTUALJun: entry.ACTUAL.Jun,
      ACTUALJul: entry.ACTUAL.Jul,
      ACTUALAug: entry.ACTUAL.Aug,
      ACTUALSep: entry.ACTUAL.Sep,
      ACTUALOct: entry.ACTUAL.Oct,
      ACTUALNov: entry.ACTUAL.Nov,
      ACTUALDec: entry.ACTUAL.Dec,
      ACTUALSumPlan: entry["Sum ACTUAL"],
      ACTUALSumPlanVND: entry["Sum ACTUAL VND"],
      // ... other month columns for ACTUAL
    };
  });

  const columns = [
    {
      title: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="pSaoKeRightBottom">Chọn DEP:</p>

          {userInfo.listDepOfProject && (
            <Select
              defaultValue={checkId}
              style={{
                width: 120,
              }}
              className="selectKHPlan"
              onChange={onChangeDep}
            >
              {userInfo.listDepOfProject.map((dep, index) => (
                <Option key={index} value={dep.depart_id}>
                  {dep.depart_name}
                </Option>
              ))}
            </Select>
          )}
        </div>
      ),
      fixed: "left",
      children: onOpenColumns === true ? [
        {
          title: "No",
          dataIndex: "key",
          key: "No",
          align: "center",
          width: 80,
          fixed: true,
        },
        {
          title: "Client Code",
          key: "Client Code",
          filters: valueFilter.map((item) => ({ text: item.text, value: item.value })), // Sử dụng giá trị từ filterValues
          filterSearch: true,
          onFilter: (value, record) => record.Client.startsWith(value),
          dataIndex: "Client",
          align: "center",
          width: 200,
          fixed: true,
        },

        // ẩn cột này
        {
          title: "Category",
          key: "Category",
          dataIndex: "Category",
          align: "center",
          width: 100,
          fixed: true,
        }
      ]

        :

        [
          {
            title: "No",
            dataIndex: "key",
            key: "No",
            align: "center",
            width: 80,
            fixed: true,
          },
          {
            title: "Client Code",
            key: "Client Code",
            filters: valueFilter.map((item) => ({ text: item.text, value: item.value })), // Sử dụng giá trị từ filterValues
            filterSearch: true,
            onFilter: (value, record) => record.Client.startsWith(value),
            dataIndex: "Client",
            align: "center",
            width: 200,
            fixed: true,
          },

          // ẩn cột này

          // onOpenColumns === true ?
          //   {
          //     title: "Category",
          //     key: "Category",
          //     dataIndex: "Category",
          //     align: "center",
          //     width: 100,
          //     fixed: true,
          //   }
          //   : null,
        ],
    },
    {
      title: <></>,
      children: onOpenColumns === true ? [

        // cột ẩn
        onOpenColumns === true ? {
          title: "Sub-Category",
          dataIndex: "Productservice",
          key: "Sub-category",
          width: 150, //cong 50
          fixed: true,
          align: "center",
        } : null,



        {
          title: "Project",
          key: "Project",
          dataIndex: "Project",
          width: 150,
          fixed: true,
          align: "center",
        },


        // 2 cột ẩn

        onOpenColumns === true ? {
          title: "Bank Account",
          key: "BankAccount",
          dataIndex: "BankAccount",
          width: 120,
          fixed: true,
          align: "center",
        } : null,

        onOpenColumns === true ?
          {
            title: "Currency",
            dataIndex: "Currency",
            key: "Currency",
            align: "center",
            fixed: true,
            width: 80,
          } : null,
      ] : [
        // cột ẩn
        // onOpenColumns === true ? {
        //   title: "Sub-Category",
        //   dataIndex: "Productservice",
        //   key: "Sub-category",
        //   width: 150, //cong 50
        //   fixed: true,
        //   align: "center",
        // } : null,



        {
          title: "Project",
          key: "Project",
          dataIndex: "Project",
          width: 150,
          fixed: true,
          align: "center",
        },


        // 2 cột ẩn

        // onOpenColumns === true ? {
        //   title: "Bank Account",
        //   key: "BankAccount",
        //   dataIndex: "BankAccount",
        //   width: 120,
        //   fixed: true,
        //   align: "center",
        // } : null,

        // onOpenColumns === true ?
        //   {
        //     title: "Currency",
        //     dataIndex: "Currency",
        //     key: "Currency",
        //     align: "center",
        //     fixed: true,
        //     width: 80,
        //   } : null,
      ],
    },
    {
      title: "PLAN",
      children: [
        {
          title: "Jan",
          dataIndex: "PlanJan",
          key: "PlanJan",
          width: 130,
          className: "canhTrai backgroundPlan",
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Feb",
          dataIndex: "PlanFeb",
          className: "canhTrai backgroundPlan",
          key: "PlanFeb",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Mar",
          dataIndex: "PlanMar",
          className: "canhTrai backgroundPlan",
          key: "PlanMar",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Apr",
          dataIndex: "PlanApr",
          className: "canhTrai backgroundPlan",
          key: "PlanApr",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "May",
          dataIndex: "PlanMay",
          className: "canhTrai backgroundPlan",
          key: "PlanMay",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Jun",
          dataIndex: "PlanJun",
          className: "canhTrai backgroundPlan",
          key: "PlanJun",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Jul",
          dataIndex: "PlanJul",
          className: "canhTrai backgroundPlan",
          key: "PlanJul",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Aug",
          dataIndex: "PlanAug",
          className: "canhTrai backgroundPlan",
          key: "PlanAug",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Sep",
          dataIndex: "PlanSep",
          className: "canhTrai backgroundPlan",
          key: "PlanSep",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Oct",
          dataIndex: "PlanOct",
          className: "canhTrai backgroundPlan",
          key: "PlanOct",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Nov",
          dataIndex: "PlanNov",
          className: "canhTrai backgroundPlan",
          key: "PlanNov",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Dec",
          dataIndex: "PlanDec",
          className: "canhTrai backgroundPlan",
          key: "PlanDec",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "SUM (By Currency)",
          dataIndex: "PlanSumPlan",
          key: "sum by Currency",
          whiteSpace: "nowrap",
          className: "colorRed canhTrai backgroundPlan",
          width: 150,
          render: (text) => {
            return (
              <span style={{ fontWeight: "bold" }}>
                {text !== undefined ? numberWithCommas(text) : 0}
              </span>
            );
          },
        },
        {
          title: "SUM (VND)",
          dataIndex: "PlanSumPlanVND",
          key: "SUM (VND)",
          whiteSpace: "nowrap",
          className: "colorRed borderRightTable canhTrai backgroundPlan",
          width: 130,
          render: (text) => {
            return (
              <span style={{ fontWeight: "bold" }}>
                {text !== undefined ? numberWithCommas(text) : 0}
              </span>
            );
          },
        },
      ],
      className: "borderRightTable",
    },
    {
      title: "ACTUAL",
      children: [
        {
          title: "Jan",
          dataIndex: "ACTUALJan",
          key: "ACTUALJan",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Feb",
          dataIndex: "ACTUALFeb",
          key: "ACTUALFeb",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Mar",
          dataIndex: "ACTUALMar",
          key: "ACTUALMar",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Apr",
          dataIndex: "ACTUALApr",
          key: "ACTUALApr",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "May",
          dataIndex: "ACTUALMay",
          key: "ACTUALMay",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Jun",
          dataIndex: "ACTUALJun",
          key: "ACTUALJun",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Jul",
          dataIndex: "ACTUALJul",
          key: "ACTUALJul",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Aug",
          dataIndex: "ACTUALAug",
          key: "ACTUALAug",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Sep",
          dataIndex: "ACTUALSep",
          key: "ACTUALSep",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Oct",
          dataIndex: "ACTUALOct",
          key: "ACTUALOct",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Nov",
          dataIndex: "ACTUALNov",
          key: "ACTUALNov",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "Dec",
          dataIndex: "ACTUALDec",
          key: "ACTUALDec",
          className: "canhTrai backgroundACTUAL",
          width: 130,
          render: (text) => {
            return (
              <span>{text !== undefined ? numberWithCommas(text) : 0}</span>
            );
          },
        },
        {
          title: "SUM (By Currency)",
          dataIndex: "ACTUALSumPlan",
          key: "ACTUALSumPlan",
          whiteSpace: "nowrap",
          width: 150,
          className: "colorRed canhTrai backgroundACTUAL",
          render: (text) => {
            return (
              <span style={{ fontWeight: "bold" }}>
                {text !== undefined ? numberWithCommas(text) : 0}
              </span>
            );
          },
        },
        {
          title: "SUM (VND)",
          dataIndex: "ACTUALSumPlanVND",
          key: "ACTUALSumPlanVND",
          whiteSpace: "nowrap",
          width: 130,
          className: "colorRed canhTrai backgroundACTUAL",
          render: (text) => {
            return (
              <span style={{ fontWeight: "bold" }}>
                {text !== undefined ? numberWithCommas(text) : 0}
              </span>
            );
          },
        },
      ],
    },
  ];

  const [tableHeight, setTableHeight] = useState(window.innerHeight - 300); // Đặt chiều cao ban đầu

  useEffect(() => {
    const handleResize = () => {
      setTableHeight(window.innerHeight - 300); // Cập nhật chiều cao khi kích thước màn hình thay đổi
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const listCurrentBPS = [
  //   { name: "JPY" },
  //   { name: "VND" },
  //   { name: "USD" },
  // ]

  // const listCurrentDLS = [
  //   { name: "JPY" },
  //   { name: "USD" },
  //   { name: "VND" },
  // ]
  // const listCurrentOTH = [
  //   { name: "USD" },
  //   { name: "VND" },
  // ]

  return (
    <>
      <div style={{ position: "relative", width: "98%", padding: "0% 1%" }}>
        {/* <Box
          sx={
            screenWindown === true
              ? {
                width: "96%",
                display: "grid",
                margin: "1% auto",
              }
              : {
                width: "96%",
                margin: "2% auto",
                display: "grid",
              }
          }
        > */}
        {loading === false ? (
          <Table
            columns={columns}
            dataSource={data}
            bordered
            className="canhTraiTable"
            size="small"
            style={{
              overflow: "auto",
              fontSize: "15px",
              position: "absolute"
            }}
            scroll={{
              x: 1500,
              y: tableHeight,
            }}
            pagination={false}
            summary={() => (
              <>
                {isListValueSum.map((e, index) => (
                  <Table.Summary>
                    <Table.Summary.Row
                      style={{
                        bottom: (isListValueSum.length - index) * 39,
                        zIndex: 2,
                        position: "sticky",
                      }}
                    >
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt FixedLeft"
                        index={index}
                        // colSpan={3}
                        colSpan={quantityColumns}
                      >
                        {/* Total {checkId === 1 ? `( ${listCurrentBPS[index].name} )` : checkId === 2 ? `( ${listCurrentDLS[index].name} )` : `( ${listCurrentOTH[index].name} )`} */}
                        Total {quantityColumns === 3 ? `( ${listCurrency[index]} )` : null}
                      </Table.Summary.Cell>

                      {/* cột ẩn */}

                      {onOpenColumns === true ?
                        <Table.Summary.Cell
                          className="TableSummaryTeamDtddtt FixedLeft1"
                          index={index}
                        >
                          {e.Currency}
                        </Table.Summary.Cell>
                        : null}

                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Jan !== undefined
                          ? numberWithCommas(e.Plan.Jan)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Feb !== undefined
                          ? numberWithCommas(e.Plan.Feb)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Mar !== undefined
                          ? numberWithCommas(e.Plan.Mar)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Apr !== undefined
                          ? numberWithCommas(e.Plan.Apr)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.May !== undefined
                          ? numberWithCommas(e.Plan.May)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Jun !== undefined
                          ? numberWithCommas(e.Plan.Jun)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Jul !== undefined
                          ? numberWithCommas(e.Plan.Jul)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Aug !== undefined
                          ? numberWithCommas(e.Plan.Aug)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Sep !== undefined
                          ? numberWithCommas(e.Plan.Sep)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Oct !== undefined
                          ? numberWithCommas(e.Plan.Oct)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Nov !== undefined
                          ? numberWithCommas(e.Plan.Nov)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.Plan.Dec !== undefined
                          ? numberWithCommas(e.Plan.Dec)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai boldDtddtt"
                        index={index}
                      >
                        {e["Sum Plan"] !== undefined
                          ? numberWithCommas(e["Sum Plan"])
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai boldDtddtt"
                        index={index}
                      >
                        {e["Sum Plan VND"] !== undefined
                          ? numberWithCommas(e["Sum Plan VND"])
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Jan !== undefined
                          ? numberWithCommas(e.ACTUAL.Jan)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Feb !== undefined
                          ? numberWithCommas(e.ACTUAL.Feb)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Mar !== undefined
                          ? numberWithCommas(e.ACTUAL.Mar)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Apr !== undefined
                          ? numberWithCommas(e.ACTUAL.Apr)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.May !== undefined
                          ? numberWithCommas(e.ACTUAL.May)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Jun !== undefined
                          ? numberWithCommas(e.ACTUAL.Jun)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Jul !== undefined
                          ? numberWithCommas(e.ACTUAL.Jul)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Aug !== undefined
                          ? numberWithCommas(e.ACTUAL.Aug)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Sep !== undefined
                          ? numberWithCommas(e.ACTUAL.Sep)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Oct !== undefined
                          ? numberWithCommas(e.ACTUAL.Oct)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Nov !== undefined
                          ? numberWithCommas(e.ACTUAL.Nov)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai"
                        index={index}
                      >
                        {e.ACTUAL.Dec !== undefined
                          ? numberWithCommas(e.ACTUAL.Dec)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai boldDtddtt"
                        index={index}
                      >
                        {e["Sum ACTUAL"] !== undefined
                          ? numberWithCommas(e["Sum ACTUAL"])
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt canhTrai boldDtddtt"
                        index={index}
                      >
                        {e["Sum ACTUAL VND"] !== undefined
                          ? numberWithCommas(e["Sum ACTUAL VND"])
                          : 0}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                ))}
                {isListValueSumDT.map((e, index) => (
                  <Table.Summary>
                    <Table.Summary.Row
                      style={{
                        bottom: 0,
                        zIndex: 2,
                        position: "sticky",
                      }}
                    >
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1 FixedLeft"
                        index={index}
                        // colSpan={3}
                        colSpan={quantityColumns}
                      >
                        {e.Client} {quantityColumns === 3 ? "( VND )" : null}
                      </Table.Summary.Cell>

                      {/* cột ẩn */}

                      {onOpenColumns === true ? <Table.Summary.Cell
                        index={index}
                        className="TableSummaryTeamDtddtt1 FixedLeft1"
                      >
                        {e.Currency}
                      </Table.Summary.Cell>
                        : null}

                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Jan !== undefined
                          ? numberWithCommas(e.Plan.Jan)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Feb !== undefined
                          ? numberWithCommas(e.Plan.Feb)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Mar !== undefined
                          ? numberWithCommas(e.Plan.Mar)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Apr !== undefined
                          ? numberWithCommas(e.Plan.Apr)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.May !== undefined
                          ? numberWithCommas(e.Plan.May)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Jun !== undefined
                          ? numberWithCommas(e.Plan.Jun)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Jul !== undefined
                          ? numberWithCommas(e.Plan.Jul)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Aug !== undefined
                          ? numberWithCommas(e.Plan.Aug)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Sep !== undefined
                          ? numberWithCommas(e.Plan.Sep)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Oct !== undefined
                          ? numberWithCommas(e.Plan.Oct)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Nov !== undefined
                          ? numberWithCommas(e.Plan.Nov)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.Plan.Dec !== undefined
                          ? numberWithCommas(e.Plan.Dec)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e["Sum Plan"] !== undefined
                          ? numberWithCommas(e["Sum Plan"])
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e["Sum Plan VND"] !== undefined
                          ? numberWithCommas(e["Sum Plan VND"])
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Jan !== undefined
                          ? numberWithCommas(e.ACTUAL.Jan)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Feb !== undefined
                          ? numberWithCommas(e.ACTUAL.Feb)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Mar !== undefined
                          ? numberWithCommas(e.ACTUAL.Mar)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Apr !== undefined
                          ? numberWithCommas(e.ACTUAL.Apr)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.May !== undefined
                          ? numberWithCommas(e.ACTUAL.May)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Jun !== undefined
                          ? numberWithCommas(e.ACTUAL.Jun)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Jul !== undefined
                          ? numberWithCommas(e.ACTUAL.Jul)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Aug !== undefined
                          ? numberWithCommas(e.ACTUAL.Aug)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Sep !== undefined
                          ? numberWithCommas(e.ACTUAL.Sep)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Oct !== undefined
                          ? numberWithCommas(e.ACTUAL.Oct)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Nov !== undefined
                          ? numberWithCommas(e.ACTUAL.Nov)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e.ACTUAL.Dec !== undefined
                          ? numberWithCommas(e.ACTUAL.Dec)
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e["Sum ACTUAL"] !== undefined
                          ? numberWithCommas(e["Sum ACTUAL"])
                          : 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        className="TableSummaryTeamDtddtt1_1"
                        index={index}
                      >
                        {e["Sum ACTUAL VND"] !== undefined
                          ? numberWithCommas(e["Sum ACTUAL VND"])
                          : 0}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                ))}
              </>
            )}
          />
        ) : null}
        {loading === true ? (
          <Fade in={loading} unmountOnExit>
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                background: "#f5f5f5",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <GridLoader color="#020f59" loading={true} size={10} />
              </div>
              <p style={{ fontSize: 20 }}>Loading...</p>
            </div>
          </Fade>
        ) : null}
        {/* </Box> */}
      </div>
    </>
  );
};

export default DT_DD_TT;
