import React, { useEffect, useState, memo } from "react";
import { Col, Form, Input, Modal, Row, Button, TreeSelect, Select } from "antd";
import { PostListUser } from "../../api/listUser";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const { Option } = Select;

const ModalAddUser = ({
  isModalOpenAddUser,
  handleOkAddUser,
  setIsModalOpenAddUser,
  fetchListDataUser,
  listDataDep,
  listDataProject,
  listDataRole,
}) => {
  const [form] = Form.useForm();
  const [currentDuAn, setCurrentDuAn] = useState([]);
  const [treeDataDuAn, setTreeDataDuAn] = useState([]);
  const [loadings, setLoadings] = useState(false);

  const handleCancelAddUser = () => {
    setIsModalOpenAddUser(false);
    form.resetFields();
    setCurrentDuAn([]);
  };

  const handleChangeDep = (e) => {
    setCurrentDuAn(
      listDataProject
        .filter((item) => item.departmentid === e)
        .map((itemCheck) => itemCheck.projectname)
    );
    if (currentDuAn) {
    }
  };

  const onChangeDuAn = (value) => {
    if (value.length > 0) {
      setCurrentDuAn(value);
    } else {
      setCurrentDuAn([]);
    }
  };

  const treeData = [
    {
      title:
        currentDuAn.length > 0 ? (
          <span
            onClick={() => {
              onChangeDuAn([]);
            }}
            className="style_SelectTeam"
          >
            Bỏ chọn tất cả
          </span>
        ) : (
          <span
            onClick={() => {
              onChangeDuAn(listDataProject.map((item) => item.projectname));
            }}
            className="style_SelectTeam"
          >
            Chọn tất cả
          </span>
        ),
      value: "xxx",
      disableCheckbox: true,
      disabled: true,
      className: "style_treeTeam",
    },
    ...treeDataDuAn,
  ];

  useEffect(() => {
    const treeDataDA = listDataProject.map((item) => {
      return {
        title: item.projectname,
        value: item.projectname,
        key: item.projectname,
      };
    });
    setTreeDataDuAn(treeDataDA);
  }, [listDataProject]);

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);

    let newArr = [];

    for (let i = 0; i < currentDuAn.length; i++) {
      for (let j = 0; j < listDataProject.length; j++) {        
        if (currentDuAn[i] === listDataProject[j].projectname) {
          newArr.push(listDataProject[j].id)
          break;
        }
      }
    }
    data.append("message", "create");
    data.append("username", values.username);
    data.append("name", values.name);
    data.append("dep", values.dep);
    data.append("role", values.role);
    data.append("msnv", values.msnv);
    data.append("email", values.email);
    if (currentDuAn.length >= 1) {
      data.append("manager_project", newArr);
    }

    // currentDuAn
    PostListUser(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Thêm mới user thành công",
        });
        setLoadings(false);
        fetchListDataUser();
        handleCancelAddUser();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="ADD USER"
      open={isModalOpenAddUser}
      onOk={handleOkAddUser}
      onCancel={handleCancelAddUser}
      footer={false}
      width={800}
      className="headerTitleModal"
      forceRender={true}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Mã số nhân viên"
              name="msnv"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập mã số nhân viên!",
                },
              ]}
            >
              <Input size="large" placeholder="Mã số nhân viên" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên!",
                },
              ]}
            >
              <Input size="large" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Dep"
              name="dep"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Dep!",
                },
              ]}
            >
              <Select size="large" placeholder="Dep" onChange={handleChangeDep}>
                {listDataDep.map((dep) => (
                  <Option key={dep.iddepart} value={dep.iddepart}>
                    {dep.codedepart}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <p style={{ padding: "0 0 8px", fontWeight: 600, fontSize: 15 }}>
              Dự án
            </p>
            <TreeSelect
              treeData={treeData}
              value={treeDataDuAn.length > 0 ? currentDuAn : undefined}
              onChange={onChangeDuAn}
              treeCheckable={true}
              showCheckedStrategy={"SHOW_PARENT"}
              maxTagCount={2}
              placeholder={"Chọn dự án"}
              style={{ width: "100%" }}
              size="large"
              clearIcon
            />
          </Col>
          <Col span={11}>
            <Form.Item
              label="User"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập User!",
                },
              ]}
            >
              <Input size="large" placeholder="User" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>

          <Col span={11}>
            <Form.Item
              label="Role"
              name="role"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Role!",
                },
              ]}
            >
              <Select size="large" placeholder="Dep">
                {listDataRole.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập đúng định dạng!",
                  type: "email",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Email"
                onBlur={(e) => {
                  form.setFieldsValue({ email: e.target.value.trim() });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelAddUser}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(ModalAddUser);
