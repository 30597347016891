/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Button, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import "./ProductOutput.css";
import {
  GetDataBankAC,
  GetDataClientCode,
  GetDataCurrency,
  GetDataDepartment,
  GetDataMarket,
  GetDataProduct,
} from "../../api/dttt";
import { localhost } from "../../server";
import TableOnSaleProduct from "./Product/TableOnSaleProduct";
import TableRealtimeProduct from "./Product/TableRealtimeProduct";
import TableReportProduct from "./Product/TableReportProduct";
import TableProjectServiceProduct from "./Product/TableProjectServiceProduct";
import TableForecastProduct from "./Product/TableForecastproduct1";
import TableForecastMarket from "./Market/TableForecastMarket";
import TableOnSaleMarket from "./Market/TableOnSaleMarket";
import TableRealtimeMarket from "./Market/TableRealtimeMarket";
import TableProjectServiceMarket from "./Market/TableProjectServiceMarket";
import TableReportMarket from "./Market/TableReportMarket";
import TableForecastClient from "./Client Code/TableForecastClient";
import TableOnSaleClient from "./Client Code/TableOnSaleClient";
import TableRealtimeClient from "./Client Code/TableRealtimeClient";
import TableProjectServiceClient from "./Client Code/TableProjectServiceClient";
import TableReportClient from "./Client Code/TableReportClient";
import TableForecastDepartment from "./Department/TableForecastDepartment";
import TableOnSaleDepartment from "./Department/TableOnSaleDepartment";
import TableRealtimeDepartment from "./Department/TableRealtimeDepartment";
import TableProjectServiceDepartment from "./Department/TableProjectServiceDepartment";
import TableReportDepartment from "./Department/TableReportDepartment";
import TableForecastCurrency from "./Currency/TableForecastCurrency";
import TableOnSaleCurrency from "./Currency/TableOnSaleCurrency";
import TableRealtimeCurrency from "./Currency/TableRealtimeCurrency";
import TableProjectServiceCurrency from "./Currency/TableProjectServiceCurrency";
import TableReportCurrency from "./Currency/TableReportCurrency";
import TableForecastBank from "./BankAC/TableForecastBank";
import TableOnSaleBank from "./BankAC/TableOnSaleBank";
import TableRealtimeBank from "./BankAC/TableRealtimeBank";
import TableProjectServiceBank from "./BankAC/TableProjectServiceBank";
import TableReportBank from "./BankAC/TableReportBank";
import { Fade } from "@mui/material";
import { GridLoader } from "react-spinners";
import { GetVerApi } from "../../api/listDT_DD_TT";

const { Option } = Select;

function DTTT() {
  const selectRef = useRef();

  const listDTTT = [
    { id: 1, value: "Product/Service", label: "Product/Service" },
    { id: 2, value: "Client", label: "Client Code" },
    { id: 3, value: "Department", label: "Department" },
    { id: 4, value: "Currency", label: "Currency" },
    { id: 5, value: "Bank", label: "Bank AC" },
    { id: 6, value: "Market", label: "Market" },
  ];

  const [valueSelect, setValueSelect] = useState([]);
  const [checkAllOption, setCheckAllOption] = useState(false);
  const [openTableNo1, setOpenTableNo1] = useState(false);
  const [openTableNo4, setOpenTableNo4] = useState(false);
  const [openTableNo5, setOpenTableNo5] = useState(false);
  const [openTableNo6, setOpenTableNo6] = useState(false);
  const [openTableNo7, setOpenTableNo7] = useState(false);
  const [dataTable1, setDataTable1] = useState([]);
  const [dataTable4, setDataTable4] = useState([]);
  const [dataTable5, setDataTable5] = useState([]);
  const [dataTable6, setDataTable6] = useState([]);
  const [dataTable7, setDataTable7] = useState([]);
  const [dataTime, setDataTime] = useState(dayjs().$y);
  const [loading, setLoading] = useState(true);
  const [valueVer, setValueVer] = useState(0)
  const [valueSelectLoaiReport, setValueSelectLoaiReport] =
    useState("Product/Service");

  const [runFirst, setRunFirst] = useState(true)
  let lengthOptionTable = 5;

  const listTable = [
    "CHỌN TẤT CẢ",
    "DOANH THU DỰ ĐOÁN",
    "DOANH THU DEAL ĐANG SALE",
    "DOANH THU DỰ ĐOÁN REALTIME",
    `TỈ LỆ HOÀN THÀNH THEO ${valueSelectLoaiReport.toLocaleUpperCase()}`,
    "TỈ LỆ HOÀN THÀNH THEO BÁO CÁO",

  ];

  const quarters = [
    { name: "1.0", value: 0 },
    { name: "1.1", value: 1 },
    { name: "1.2", value: 2 },
    { name: "1.3", value: 3 },
  ]


  useEffect(() => {
    setValueSelect(listTable);
    setCheckAllOption(true);
  }, [valueSelectLoaiReport]);

  const handleChange = (value, event) => {
    if (value.length === lengthOptionTable && !value.includes("CHỌN TẤT CẢ")) {
      if (checkAllOption === false) {
        setValueSelect(listTable);
        setCheckAllOption(true);
        selectRef.current.blur();
      } else {
        setValueSelect([]);
        selectRef.current.blur();
        setCheckAllOption(false);
      }
    } else if (
      value.length === lengthOptionTable &&
      value.includes("CHỌN TẤT CẢ")
    ) {
      if (checkAllOption === true) {
        value.pop();
        setCheckAllOption(false);
        setValueSelect(value);
      } else {
        setCheckAllOption(true);
        setValueSelect(listTable);
        selectRef.current.blur();
      }
    } else if (
      value.length < lengthOptionTable &&
      value.includes("CHỌN TẤT CẢ")
    ) {
      setCheckAllOption(true);
      setValueSelect(listTable);
      selectRef.current.blur();
    } else {
      setCheckAllOption(false);
      setValueSelect(value);
    }
  };

  const fetchVerData = (value) => {
    GetVerApi({
      year: value || dataTime
    }).then(res => {
      getListDataProduct(value || dataTime, res.data.ver, valueSelectLoaiReport || "Product/Service");
      setValueVer(res.data.ver)
    }).catch(err => {
      console.log(err)
    })
  }

  const onChangeYear = (value) => {
    setDataTime(value.$y);
    fetchVerData(value.$y)
  };

  const handleChangeLoaiReport = (e) => {
    setValueSelectLoaiReport(e);
    setDataTable1();
    setDataTable4();
    setDataTable5();
    setDataTable6();
    setDataTable7();
    console.log(e)
    getListDataProduct(dataTime, valueVer, e || "Product/Service")
  };

  // useEffect(() => {
  //   getListDataProduct(dataTime, valueVer)
  // }, [valueSelectLoaiReport]);

  const getListDataProduct = (time, dataVer, typeReport) => {
    setLoading(true)
    console.log(123)
    if (typeReport === "Product/Service") {
      GetDataProduct({
        year: time,
        ver: dataVer,
      })
        .then((res) => {
          setDataTable1(res.data.data["Table 1"]);
          setDataTable4(res.data.data["Table 4"]);
          setDataTable5(res.data.data["Table 5"]);
          setDataTable6(res.data.data["Table 6"]);
          setDataTable7(res.data.data["Table 7"]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (typeReport === "Market") {
      GetDataMarket({
        year: time,
        ver: dataVer,
      })
        .then((res) => {
          setDataTable1(res.data.data["Table 1"]);
          setDataTable4(res.data.data["Table 4"]);
          setDataTable5(res.data.data["Table 5"]);
          setDataTable6(res.data.data["Table 6"]);
          setDataTable7(res.data.data["Table 7"]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (typeReport === "Client") {
      GetDataClientCode({
        year: time,
        ver: dataVer,
      })
        .then((res) => {
          setDataTable1(res.data.data["Table 1"]);
          setDataTable4(res.data.data["Table 4"]);
          setDataTable5(res.data.data["Table 5"]);
          setDataTable6(res.data.data["Table 6"]);
          setDataTable7(res.data.data["Table 7"]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (typeReport === "Department") {
      GetDataDepartment({
        year: time,
        ver: dataVer,

      })
        .then((res) => {
          setDataTable1(res.data.data["Table 1"]);
          setDataTable4(res.data.data["Table 4"]);
          setDataTable5(res.data.data["Table 5"]);
          setDataTable6(res.data.data["Table 6"]);
          setDataTable7(res.data.data["Table 7"]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (typeReport === "Currency") {
      GetDataCurrency({
        year: time,
        ver: dataVer,

      })
        .then((res) => {
          setDataTable1(res.data.data["Table 1"]);
          setDataTable4(res.data.data["Table 4"]);
          setDataTable5(res.data.data["Table 5"]);
          setDataTable6(res.data.data["Table 6"]);
          setDataTable7(res.data.data["Table 7"]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (typeReport === "Bank") {
      GetDataBankAC({
        year: time,
        ver: dataVer,
      })
        .then((res) => {
          setDataTable1(res.data.data["Table 1"]);
          setDataTable4(res.data.data["Table 4"]);
          setDataTable5(res.data.data["Table 5"]);
          setDataTable6(res.data.data["Table 6"]);
          setDataTable7(res.data.data["Table 7"]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchVerData()
  }, []);

  // useEffect(() => {
  //   if (valueSelect.length > 0) {
  //     setLoading(true);
  //   }
  //   // getListDataProduct();
  //   fetchVerData()
  // }, [dataTime, valueSelectLoaiReport, valueVer]);

  useEffect(() => {
    if (valueSelect.includes("DOANH THU DỰ ĐOÁN")) {
      setOpenTableNo1(true);
    } else {
      setOpenTableNo1(false);
    }
    if (valueSelect.includes("DOANH THU DEAL ĐANG SALE")) {
      setOpenTableNo4(true);
    } else {
      setOpenTableNo4(false);
    }
    if (valueSelect.includes("DOANH THU DỰ ĐOÁN REALTIME")) {
      setOpenTableNo5(true);
    } else {
      setOpenTableNo5(false);
    }
    if (
      valueSelect.includes(
        `TỈ LỆ HOÀN THÀNH THEO ${valueSelectLoaiReport.toLocaleUpperCase()}`
      )
    ) {
      setOpenTableNo6(true);
    } else {
      setOpenTableNo6(false);
    }
    if (valueSelect.includes("TỈ LỆ HOÀN THÀNH THEO BÁO CÁO")) {
      setOpenTableNo7(true);
    } else {
      setOpenTableNo7(false);
    }
  }, [valueSelect]);

  const DownloadExcel = () => {
    if (valueSelectLoaiReport === "Product/Service") {
      GetDataProduct({
        year: dataTime,
        message: "download",
        ver: valueVer,
      })
        .then((res) => {
          // fnFileDownload(res.data, filename)
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = `${localhost}/api/dttt?year=${dataTime}&message=download&ver=${valueVer}`;
          link.setAttribute("download", "dttt.xlsx"); // Set the desired file name and extension
          document.body.appendChild(link);
          // Trigger the download
          link.click();
          // Clean up the temporary URL and link
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (valueSelectLoaiReport === "Market") {
      GetDataMarket({
        year: dataTime,
        message: "download",
        ver: valueVer,

      })
        .then((res) => {
          // fnFileDownload(res.data, filename)
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = `${localhost}/api/dttt_market?year=${dataTime}&message=download&ver=${valueVer}`;
          link.setAttribute("download", "dttt.xlsx"); // Set the desired file name and extension
          document.body.appendChild(link);
          // Trigger the download
          link.click();
          // Clean up the temporary URL and link
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (valueSelectLoaiReport === "Client") {
      GetDataClientCode({
        year: dataTime,
        message: "download",
        ver: valueVer,

      })
        .then((res) => {
          // fnFileDownload(res.data, filename)
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = `${localhost}/api/dttt_client?year=${dataTime}&message=download&ver=${valueVer}`;
          link.setAttribute("download", "dttt.xlsx"); // Set the desired file name and extension
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Clean up the temporary URL and link
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (valueSelectLoaiReport === "Department") {
      GetDataDepartment({
        year: dataTime,
        message: "download",
        ver: valueVer,

      })
        .then((res) => {
          // fnFileDownload(res.data, filename)
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = `${localhost}/api/dttt_department?year=${dataTime}&message=download&ver=${valueVer}`;
          link.setAttribute("download", "dttt.xlsx"); // Set the desired file name and extension
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Clean up the temporary URL and link
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (valueSelectLoaiReport === "Currency") {
      GetDataCurrency({
        year: dataTime,
        message: "download",
        ver: valueVer,
      })
        .then((res) => {
          // fnFileDownload(res.data, filename)
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = `${localhost}/api/dttt_currency?year=${dataTime}&message=download&ver=${valueVer}`;
          link.setAttribute("download", "dttt.xlsx"); // Set the desired file name and extension
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Clean up the temporary URL and link
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (valueSelectLoaiReport === "Bank") {
      GetDataBankAC({
        year: dataTime,
        message: "download",
        ver: valueVer,

      })
        .then((res) => {
          // fnFileDownload(res.data, filename)
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = `${localhost}/api/dttt_bankac?year=${dataTime}&message=download&ver=${valueVer}`;
          link.setAttribute("download", "dttt.xlsx"); // Set the desired file name and extension
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Clean up the temporary URL and link
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const chooseVer = (value) => {
    // clearData()
    console.log(value)
    setValueVer(value)
    getListDataProduct(dataTime, value, valueSelectLoaiReport)
  };


  return (
    <>
      <div style={{ backgroundColor: "#fff" }}>
        <div className="bg-params-dttt">
          <div style={{ width: "14%" }}>
            <div className="test">
              <label
                className="typo-month-lc"
                style={{ marginTop: "0.5%", width: "100%" }}
              >
                Chọn loại
              </label>
              <Select
                style={{
                  width: "80%",
                }}
                defaultValue={valueSelectLoaiReport}
                onChange={handleChangeLoaiReport}
                disabled={loading === true ? true : false}
              >
                {listDTTT.map((e) => (
                  <Option key={e.id} value={e.value}>
                    {e.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ width: "14%" }}>
            <div className="params-output">
              <label
                className="typo-month-lc"
                style={{ marginTop: "0.5%", width: "100%" }}
              >
                Năm
              </label>
              <DatePicker
                style={{
                  width: "100%",
                  border: "1px solid var(--neutral-sub-text-4, #9199B1)",
                }}
                allowClear={false}
                picker="year"
                defaultValue={dayjs()}
                onChange={onChangeYear}
                disabled={loading === true ? true : false}
              />
            </div>
          </div>
          <div style={{ width: "10%", marginLeft: "3%" }}>
            <div className="params-output">
              <label
                className="typo-month-lc"
                style={{ marginTop: "0.5%", width: "100%" }}
              >
                Ver
              </label>
              <Select
                placeholder="Chọn Ver"
                key="project"
                maxTagCount="responsive"
                optionFilterProp="children"
                // className="custom-select"
                onChange={chooseVer}
                // showSearch
                value={valueVer}
              // optionLabelProp="data-label"
              // getPopupContainer={getPopupContainer}

              >
                {quarters.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ width: "30%" }}>
            <div className="test">
              <label
                className="typo-month-lc"
                style={{ marginLeft: "1%", marginTop: "0.5%", width: "100%" }}
              >
                Bảng doanh thu
              </label>
              <Select
                mode="multiple"
                style={{
                  width: "80%",
                }}
                placeholder="Chọn bảng"
                onChange={handleChange}
                optionLabelProp="label"
                maxTagCount="responsive"
                value={valueSelect}
                ref={selectRef} //add ref here
              >
                {listTable.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ width: "5%" }}>
            <div className="test">
              <label
                className="typo-month-lc"
                style={{ marginLeft: "1%", marginTop: "2%", width: "100%" }}
              >
                &nbsp;
              </label>
              <Button onClick={DownloadExcel} type="primary">
                Download
              </Button>
            </div>
          </div>
        </div>
        <div
          style={{
            overflowY: "scroll",
            height: "75vh",
            width: "99%",
            display: "grid",
            alignContent: "baseline",
          }}
        >
          {valueSelectLoaiReport === "Product/Service" && (
            <>
              {loading === false ? (
                <>
                  {openTableNo1 === true ? (
                    <TableForecastProduct dataTable1={dataTable1} />
                  ) : null}

                  {openTableNo4 === true ? (
                    <TableOnSaleProduct dataTable4={dataTable4} />
                  ) : null}

                  {openTableNo5 === true ? (
                    <TableRealtimeProduct dataTable5={dataTable5} />
                  ) : null}

                  {openTableNo6 === true ? (
                    <TableProjectServiceProduct dataTable6={dataTable6} />
                  ) : null}

                  {openTableNo7 === true ? (
                    <TableReportProduct dataTable7={dataTable7} />
                  ) : null}
                </>
              ) : null}
              {loading === true ? (
                <Fade in={loading} unmountOnExit>
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#f5f5f5",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <GridLoader color="#020f59" loading={true} size={10} />
                    </div>
                    <p style={{ fontSize: 20 }}>Loading...</p>
                  </div>
                </Fade>
              ) : null}
            </>
          )}
          {valueSelectLoaiReport === "Client" && (
            <>
              {loading === false ? (
                <>
                  {openTableNo1 === true ? (
                    <TableForecastClient dataTable1={dataTable1} />
                  ) : null}
                  {/* <TableActual />
                <TableExpectedOperating /> */}

                  {openTableNo4 === true ? (
                    <TableOnSaleClient dataTable4={dataTable4} />
                  ) : null}

                  {openTableNo5 === true ? (
                    <TableRealtimeClient dataTable5={dataTable5} />
                  ) : null}

                  {openTableNo6 === true ? (
                    <TableProjectServiceClient dataTable6={dataTable6} />
                  ) : null}

                  {openTableNo7 === true ? (
                    <TableReportClient dataTable7={dataTable7} />
                  ) : null}
                </>
              ) : null}
              {loading === true ? (
                <Fade in={loading} unmountOnExit>
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#f5f5f5",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <GridLoader color="#020f59" loading={true} size={10} />
                    </div>
                    <p style={{ fontSize: 20 }}>Loading...</p>
                  </div>
                </Fade>
              ) : null}
            </>
          )}
          {valueSelectLoaiReport === "Department" && (
            <>
              {loading === false ? (
                <>
                  {openTableNo1 === true ? (
                    <TableForecastDepartment dataTable1={dataTable1} />
                  ) : null}

                  {openTableNo4 === true ? (
                    <TableOnSaleDepartment dataTable4={dataTable4} />
                  ) : null}

                  {openTableNo5 === true ? (
                    <TableRealtimeDepartment dataTable5={dataTable5} />
                  ) : null}

                  {openTableNo6 === true ? (
                    <TableProjectServiceDepartment dataTable6={dataTable6} />
                  ) : null}

                  {openTableNo7 === true ? (
                    <TableReportDepartment dataTable7={dataTable7} />
                  ) : null}
                </>
              ) : null}
              {loading === true ? (
                <Fade in={loading} unmountOnExit>
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#f5f5f5",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <GridLoader color="#020f59" loading={true} size={10} />
                    </div>
                    <p style={{ fontSize: 20 }}>Loading...</p>
                  </div>
                </Fade>
              ) : null}
            </>
          )}
          {valueSelectLoaiReport === "Market" && (
            <>
              {loading === false ? (
                <>
                  {openTableNo1 === true ? (
                    <TableForecastMarket dataTable1={dataTable1} />
                  ) : null}

                  {openTableNo4 === true ? (
                    <TableOnSaleMarket dataTable4={dataTable4} />
                  ) : null}

                  {openTableNo5 === true ? (
                    <TableRealtimeMarket dataTable5={dataTable5} />
                  ) : null}

                  {openTableNo6 === true ? (
                    <TableProjectServiceMarket dataTable6={dataTable6} />
                  ) : null}

                  {openTableNo7 === true ? (
                    <TableReportMarket dataTable7={dataTable7} />
                  ) : null}
                </>
              ) : null}
              {loading === true ? (
                <Fade in={loading} unmountOnExit>
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#f5f5f5",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <GridLoader color="#020f59" loading={true} size={10} />
                    </div>
                    <p style={{ fontSize: 20 }}>Loading...</p>
                  </div>
                </Fade>
              ) : null}
            </>
          )}
          {valueSelectLoaiReport === "Currency" && (
            <>
              {loading === false ? (
                <>
                  {openTableNo1 === true ? (
                    <TableForecastCurrency dataTable1={dataTable1} />
                  ) : null}

                  {openTableNo4 === true ? (
                    <TableOnSaleCurrency dataTable4={dataTable4} />
                  ) : null}

                  {openTableNo5 === true ? (
                    <TableRealtimeCurrency dataTable5={dataTable5} />
                  ) : null}

                  {openTableNo6 === true ? (
                    <TableProjectServiceCurrency dataTable6={dataTable6} />
                  ) : null}

                  {openTableNo7 === true ? (
                    <TableReportCurrency dataTable7={dataTable7} />
                  ) : null}
                </>
              ) : null}
              {loading === true ? (
                <Fade in={loading} unmountOnExit>
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#f5f5f5",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <GridLoader color="#020f59" loading={true} size={10} />
                    </div>
                    <p style={{ fontSize: 20 }}>Loading...</p>
                  </div>
                </Fade>
              ) : null}
            </>
          )}
          {valueSelectLoaiReport === "Bank" && (
            <>
              {loading === false ? (
                <>
                  {openTableNo1 === true ? (
                    <TableForecastBank dataTable1={dataTable1} />
                  ) : null}

                  {openTableNo4 === true ? (
                    <TableOnSaleBank dataTable4={dataTable4} />
                  ) : null}

                  {openTableNo5 === true ? (
                    <TableRealtimeBank dataTable5={dataTable5} />
                  ) : null}

                  {openTableNo6 === true ? (
                    <TableProjectServiceBank dataTable6={dataTable6} />
                  ) : null}

                  {openTableNo7 === true ? (
                    <TableReportBank dataTable7={dataTable7} />
                  ) : null}
                </>
              ) : null}
              {loading === true ? (
                <Fade in={loading} unmountOnExit>
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#f5f5f5",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <GridLoader color="#020f59" loading={true} size={10} />
                    </div>
                    <p style={{ fontSize: 20 }}>Loading...</p>
                  </div>
                </Fade>
              ) : null}
            </>
          )}
        </div>
        &nbsp;
      </div>
    </>
  );
}

export default DTTT;
