import axios from "axios";
import { endpoint, token_refresh_URL } from "../constants";
import Cookies from "universal-cookie";
import { ToastError } from "../components/ToastSwal";

const cookies = new Cookies();

const authAxiosTest = () => {
  const token = cookies.get("token_qldt");

  const axiosTest = axios.create({
    baseURL: endpoint,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  axiosTest.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;

      if (error.response) {
        const { status } = error.response;

        if (status === 400 || status === 404 || status === 500) {
          return Promise.reject(error.response);
        }

        if (status === 403) {
          ToastError.fire({
            icon: "error",
            title: "Bạn không có quyền xem chức năng này",
          });
          return Promise.reject(error.response);
        }

        if (status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const refresh = cookies.get("refresh_qldt");

          if (refresh) {
            try {
              const res = fetch(token_refresh_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ refresh }),
              });
              const data = res.json();

              if (data.access) {
                cookies.set("token_qldt", data.access);
                originalRequest.headers.Authorization = `Bearer ${data.access}`;
                return axios(originalRequest);
              } else {
                cookies.remove("token_qldt");
                cookies.remove("refresh_qldt");
                window.location = "/dang-nhap";
              }
            } catch (err) {
              cookies.remove("token_qldt");
              cookies.remove("refresh_qldt");
              window.location = "/dang-nhap";
            }
          } else {
            window.location = "/dang-nhap";
          }
        }
      }
      return Promise.reject(error);
    }
  );
  return axiosTest;
};

export const authAxios = () => authAxiosTest();
