import { Col, Form, Input, Modal, Row, Button } from "antd";
import React from "react";
import { PostListCurrency } from "../../api/listCurrency";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const ModalAddCurrency = ({
  isModalOpenAddCurrency,
  handleOkAddCurrency,
  setIsModalOpenAddCurrency,
  fetchListDataCurrency,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  const handleCancelAddCurrency = () => {
    setIsModalOpenAddCurrency(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);
    data.append("message", "create");
    data.append("name", values.name);
    data.append("code", values.code);

    // currentDuAn
    PostListCurrency(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Thêm mới Currency thành công",
        });
        setLoadings(false);
        fetchListDataCurrency();
        handleCancelAddCurrency();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="ADD CURRENCY"
      open={isModalOpenAddCurrency}
      onOk={handleOkAddCurrency}
      onCancel={handleCancelAddCurrency}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Currency Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Currency Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Currency Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Currency Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Currency Code!",
                },
              ]}
            >
              <Input size="large" placeholder="Currency Code" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelAddCurrency}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalAddCurrency;
