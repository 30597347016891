import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import dayjs from 'dayjs';
import React from 'react'
import "./ProjectDetailSale.css"

const { Option } = Select;

function FormConFirmSale({
    formGetDataDetail,
    onFinishImport,
    validateMessages,
    userInfo,
    userRole,
    ChooseDepartment,
    listDataDep,
    ChooseClient,
    listClientOfUser,
    listDataProjectOfDep,
    loadingConfirm
}) {
    return (
        <>
            <Form
                form={formGetDataDetail}
                layout="vertical"
                onFinish={onFinishImport}
                // onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                className="custom-form"
                key="formImport"
            >
                <Row className="params-pj" style={{ paddingLeft: "2%" }}>
                    <Col span={4}>
                        <Form.Item
                            name="department"
                            label={<p className="title-detail-pj">Department</p>}
                            key="department"
                        >
                            {userInfo ? (
                                userRole === "Admin" ? (
                                    <Select
                                        placeholder="Chọn Bộ phận"
                                        key="department"
                                        allowClear
                                        maxTagCount="responsive"
                                        optionFilterProp="children"
                                        onChange={ChooseDepartment}
                                        className="custom-select"
                                        showSearch
                                    >
                                        {userInfo
                                            ? listDataDep.map((item, index) => (
                                                <Option key={index} value={item.depart_id}>
                                                    {item.depart_name}
                                                </Option>
                                            ))
                                            : null}
                                    </Select>
                                ) : (
                                    <Input
                                        readOnly
                                        style={{
                                            height: "4vh",
                                            border:
                                                "1px solid var(--neutral-sub-text-4, #9199B1)",
                                        }}
                                    />
                                )
                            ) : null}
                        </Form.Item>
                    </Col>
                    <Col span={4} offset={1}>
                        <Form.Item
                            name="client_code"
                            label={<p className="title-detail-pj">Client</p>}
                            key="client_code"
                        >
                            <Select
                                placeholder="Chọn khách hàng"
                                key="project"
                                allowClear
                                maxTagCount="responsive"
                                optionFilterProp="children"
                                className="custom-select"
                                showSearch
                                onChange={ChooseClient}
                            >
                                {userInfo
                                    ? listClientOfUser.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.client_code}
                                        </Option>
                                    ))
                                    : null}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4} offset={1}>
                        <Form.Item
                            name="project"
                            label={<p className="title-detail-pj">Project</p>}
                            key="project"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Chọn Dự án"
                                key="project"
                                allowClear
                                maxTagCount="responsive"
                                optionFilterProp="children"
                                className="custom-select"
                                showSearch

                            // optionLabelProp="data-label"
                            // getPopupContainer={getPopupContainer}
                            >
                                {userInfo
                                    ? listDataProjectOfDep.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.project}
                                        </Option>
                                    ))
                                    : null}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4} offset={1}>
                        <Form.Item
                            name="date"
                            label={<p className="title-detail-pj">Project Date</p>}
                            key="date"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker
                                style={{
                                    height: "4vh",
                                    width: "100%",
                                    border:
                                        "1px solid var(--neutral-sub-text-4, #9199B1)",
                                }}
                                allowClear={false}
                                picker="year"
                                defaultValue={dayjs()}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} offset={1}>
                        <Button
                            htmlType="submit"
                            id="btn-view"
                            loading={loadingConfirm}
                            style={{
                                color: "#fff",
                                backgroundColor: "#5465EF",
                                height: "4vh",
                                width: "50%",
                                marginLeft: 50,
                            }}
                            className="btn-revenue"
                        >
                            CONFIRM
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default FormConFirmSale