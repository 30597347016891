import React, { useState } from "react";
import { Empty, Modal, Table } from "antd";
import { GetLogRevenue } from "../../api/apiRevenue";

const ModalLogDetailRevenue = ({
    isOpenModalLogDetail,
    setIsOpenModalLogDetail,
    dataLogDetail,
}) => {
    const [dataLog, setDataLog] = useState([]);

    const columns = [
        {
            title: "Thời gian",
            width: 50,
            dataIndex: "time",
            key: "time",
            align: "center",
        },
        {
            title: "Người thao tác",
            dataIndex: "user",
            key: "user",
            width: 50,
            align: "center",
        },
        {
            title: "Chi tiết",
            dataIndex: "detail",
            key: "detail",
            width: 200,
            align: "center",
        },
    ];
    const handleCancel = () => {
        setIsOpenModalLogDetail(false);
        if (dataLog.length > 0) {
            var elem = document.getElementById("elem_id");
            elem.scrollIntoView(true);
        }
    };

    const getDataLogDetail = () => {
        GetLogRevenue({
            id: dataLogDetail.id,
            year: dataLogDetail.year,
            ver: dataLogDetail.ver,
        })
            .then((res) => {
                setDataLog(res.data.log);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        if (isOpenModalLogDetail === true) {
            getDataLogDetail();
        }
    }, [isOpenModalLogDetail]);

    return (
        <>
            <Modal
                title={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div className="title-bill-log">
                            <span className="title-field-bill" style={{ fontSize: 22 }}>
                                View Log
                            </span>
                        </div>
                    </div>
                }
                open={isOpenModalLogDetail}
                onCancel={handleCancel}
                width={1000}
                closable={false}
                footer={false}
                style={{ marginTop: 40 }}
            >
                {dataLog && dataLog.length > 0 ? (
                    <Table
                        columns={columns}
                        dataSource={dataLog}
                        style={{
                            width: "100%",
                            marginTop: 20,
                            overflow: "auto",
                            height: 500,
                        }}
                        pagination={false}
                        className="custom-table table-striped-rows"
                        rowClassName="text-body-log"
                        bordered
                        id="elem_id"
                    />
                ) : (
                    <div>
                        <Empty style={{ display: "initial" }} />
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ModalLogDetailRevenue