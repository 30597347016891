import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, IconButton, Fade, TablePagination } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useState } from "react";
import ModalAddDepartment from "./ModalAddDepartment";
import ModalEditDepartment from "./ModalEditDepartment";
import {
  GetIdDepartmentEditApi,
  GetListDepartment,
} from "../../api/lsDepartment";
import { GridLoader } from "react-spinners";
import { Input } from "antd";
import { SearchOutlined } from "@mui/icons-material";
import { ToastError } from "../../components/ToastSwal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(246, 246, 252, 1)",
    color: "rgba(59, 66, 95, 1)",
    fontSize: 16,
    fontWeight: 700,
    height: 60,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

//CSS ToolTip
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const columns = [
  { id: "no", label: "No.", minWidth: 170, align: "center" },
  {
    id: "DepartmentName",
    label: "Department Name",
    minWidth: 100,
    align: "center",
  },
  {
    id: "DepartmentCode",
    label: "Department Code",
    minWidth: 170,
    align: "center",
  },
  {
    id: "actions",
    label: "Action",
    minWidth: 170,
    align: "center",
  },
];

function createData(no, DepartmentName, DepartmentCode, ThaoTac) {
  return { no, DepartmentName, DepartmentCode, ThaoTac };
}
const rows = [
  createData("1", "Business processing solution Department", "BPS"),
  createData("2", "Digital labor solution Department", "DLS"),
];

const Department = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isListValueDepartment, setIsListValueDepartment] = useState([]);
  const [isModalOpenAddDepartment, setIsModalOpenAddDepartment] =
    useState(false);
  const [isModalOpenEditDepartment, setIsModalOpenEditDepartment] =
    useState(false);
  const [dataInforDepartment, setDataInforDepartment] = useState([]);
  const [idEditDepartment, setIdEditDepartment] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchListDataDepartment = () => {
    GetListDepartment()
      .then((res) => {
        setIsListValueDepartment(res.data.message);
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setLoading(true);
    fetchListDataDepartment();
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const showModalAddDepartment = () => {
    setIsModalOpenAddDepartment(true);
  };

  const handleOkAddDepartment = () => {
    setIsModalOpenAddDepartment(false);
  };

  const showModalEditDepartment = (e) => {
    setIdEditDepartment(e.iddepart);
    GetIdDepartmentEditApi({ id: e.iddepart })
      .then((re) => {
        setDataInforDepartment(re.data.message);
        setIsModalOpenEditDepartment(true);
      })
      .catch((err) => {
        ToastError.fire({
          icon: "error",
          title: `Lỗi status ${err.status}`,
        });
      });
  };

  const handleOkEditDepartment = () => {
    setIsModalOpenEditDepartment(false);
  };

  const screenWindown = window.screen.width <= 1550;
  const [valueSearch, setValueSearch] = useState([]);
  const [checkValueSearch, setCheckValueSearch] = useState();

  const handleSearchPS = (e) => {
    const keyword = e.target.value.toUpperCase();
    setCheckValueSearch(e.target.value);
    const valueSS = isListValueDepartment
      .filter(
        (value) =>
          value.codedepart.toUpperCase().includes(keyword) ||
          value.namedepart.toUpperCase().includes(keyword)
      )
      .map((item) => {
        return item;
      });

    setPage(0);
    setValueSearch(valueSS);
  };
  return (
    <>
      <Box
        sx={
          screenWindown === true
            ? {
                width: "96%",
                margin: "1% auto",
              }
            : {
                width: "96%",
                margin: "2% auto",
              }
        }
      >
        <Box
          sx={
            screenWindown === true
              ? { float: "left", marginBottom: "1%" }
              : { float: "left", marginBottom: "2%" }
          }
        >
          <Input
            placeholder="Search..."
            suffix={<SearchOutlined />}
            size="large"
            onChange={handleSearchPS}
          />
        </Box>
        <Box
          sx={
            screenWindown === true
              ? { float: "right", marginBottom: "1%" }
              : { float: "right", marginBottom: "2%" }
          }
        >
          <Button
            className="setSizeButton"
            variant="outlined"
            style={{
              fontSize: "14px",
              color: "#fff",
              background: "rgba(51, 122, 238, 1)",
              borderColor: "rgba(51, 122, 238, 1)",
            }}
            // onClick={showModalAddClient}
          >
            Export
            <IconButton>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.24316 11.2891L9.0315 8.5L6.24316 5.71094"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.59375 8.5H9.02929"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.03125 2.65625H12.75C12.8909 2.65625 13.026 2.71222 13.1257 2.81185C13.2253 2.91148 13.2812 3.0466 13.2812 3.1875V13.8125C13.2812 13.9534 13.2253 14.0885 13.1257 14.1882C13.026 14.2878 12.8909 14.3438 12.75 14.3438H9.03125"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </Button>
          {/* <Button
            className="setSizeButton"
            variant="outlined"
            style={{
              fontSize: "14px",
              color: "#fff",
              background: "rgba(84, 101, 239, 1)",
              borderColor: "rgba(84, 101, 239, 1)",
              marginLeft: "10px",
            }}
            // onClick={showModalAddUser}
          >
            Import
            <IconButton>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.83984 11.5586L10.0937 13.8125L12.3476 11.5586"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0938 8.5V13.8125"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.37431 13.8125H4.78056C4.25319 13.812 3.73196 13.6993 3.25146 13.4819C2.77096 13.2646 2.34219 12.9475 1.99361 12.5518C1.64503 12.156 1.38461 11.6907 1.22963 11.1866C1.07465 10.6825 1.02866 10.1512 1.09471 9.62798C1.16077 9.10476 1.33735 8.60157 1.61275 8.15181C1.88814 7.70206 2.25605 7.31602 2.69205 7.01932C3.12805 6.72263 3.62217 6.52206 4.14161 6.43094C4.66105 6.33981 5.19393 6.36021 5.70489 6.49078"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.3125 8.49997C5.31258 7.65823 5.51268 6.82855 5.8963 6.07931C6.27993 5.33007 6.8361 4.68271 7.51899 4.19058C8.20187 3.69844 8.99193 3.37562 9.82405 3.24871C10.6562 3.12181 11.5065 3.19445 12.3051 3.46065C13.1036 3.72685 13.8275 4.17899 14.417 4.77982C15.0065 5.38064 15.4448 6.11295 15.6958 6.91641C15.9468 7.71986 16.0033 8.57145 15.8606 9.40101C15.7179 10.2306 15.3802 11.0144 14.8752 11.6878"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </Button> */}
          <Button
            className="setSizeButton"
            variant="outlined"
            style={{
              fontSize: "14px",
              color: "rgba(84, 101, 239, 1)",
              marginLeft: "10px",
              borderColor: "rgba(84, 101, 239, 1)",
            }}
            onClick={showModalAddDepartment}
          >
            Create New
            <IconButton>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.54199 8.5H13.4587"
                  stroke="#5465EF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 3.5415V13.4582"
                  stroke="#5465EF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </Button>
        </Box>
        <Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer
              component={Paper}
              sx={
                screenWindown === true ? { maxHeight: 540 } : { maxHeight: 650 }
              }
            >
              <Table aria-label="sticky table" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loading === false ? (
                  <TableBody>
                    {checkValueSearch && checkValueSearch.length > 0
                      ? valueSearch
                          .slice(startIndex, endIndex)
                          .map((e, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="center">
                                {page * rowsPerPage + index + 1}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {e.namedepart}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {e.codedepart}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <LightTooltip title="Chỉnh sửa">
                                  <IconButton
                                    onClick={() => showModalEditDepartment(e)}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        opacity="0.2"
                                        d="M10.0625 4.49991L14 8.43742L15.8523 6.58516C15.9577 6.47967 16.017 6.3366 16.017 6.18741C16.017 6.03823 15.9577 5.89516 15.8523 5.78967L12.7102 2.64766C12.6048 2.54217 12.4617 2.48291 12.3125 2.48291C12.1633 2.48291 12.0202 2.54217 11.9148 2.64766L10.0625 4.49991Z"
                                        fill="#9199B1"
                                      />
                                      <path
                                        d="M10.0625 4.5L14 8.4375"
                                        stroke="#9199B1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.25 15.1874H3.875C3.72582 15.1874 3.58274 15.1282 3.47725 15.0227C3.37176 14.9172 3.3125 14.7741 3.3125 14.6249V11.4829C3.3125 11.409 3.32705 11.3359 3.35532 11.2677C3.38359 11.1994 3.42502 11.1374 3.47725 11.0852L11.9148 2.64766C12.0202 2.54217 12.1633 2.48291 12.3125 2.48291C12.4617 2.48291 12.6048 2.54217 12.7102 2.64766L15.8523 5.78967C15.9577 5.89516 16.017 6.03823 16.017 6.18741C16.017 6.3366 15.9577 6.47967 15.8523 6.58516L7.25 15.1874Z"
                                        stroke="#9199B1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </IconButton>
                                </LightTooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                      : isListValueDepartment
                          .slice(startIndex, endIndex)
                          .map((e, index) => (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="center">
                                {page * rowsPerPage + index + 1}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {e.namedepart}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {e.codedepart}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <LightTooltip title="Chỉnh sửa">
                                  <IconButton
                                    onClick={() => showModalEditDepartment(e)}
                                  >
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        opacity="0.2"
                                        d="M10.0625 4.49991L14 8.43742L15.8523 6.58516C15.9577 6.47967 16.017 6.3366 16.017 6.18741C16.017 6.03823 15.9577 5.89516 15.8523 5.78967L12.7102 2.64766C12.6048 2.54217 12.4617 2.48291 12.3125 2.48291C12.1633 2.48291 12.0202 2.54217 11.9148 2.64766L10.0625 4.49991Z"
                                        fill="#9199B1"
                                      />
                                      <path
                                        d="M10.0625 4.5L14 8.4375"
                                        stroke="#9199B1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.25 15.1874H3.875C3.72582 15.1874 3.58274 15.1282 3.47725 15.0227C3.37176 14.9172 3.3125 14.7741 3.3125 14.6249V11.4829C3.3125 11.409 3.32705 11.3359 3.35532 11.2677C3.38359 11.1994 3.42502 11.1374 3.47725 11.0852L11.9148 2.64766C12.0202 2.54217 12.1633 2.48291 12.3125 2.48291C12.4617 2.48291 12.6048 2.54217 12.7102 2.64766L15.8523 5.78967C15.9577 5.89516 16.017 6.03823 16.017 6.18741C16.017 6.3366 15.9577 6.47967 15.8523 6.58516L7.25 15.1874Z"
                                        stroke="#9199B1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </IconButton>
                                </LightTooltip>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                  </TableBody>
                ) : null}
              </Table>
              {loading === true ? (
                <Fade in={loading} unmountOnExit>
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      background: "#f5f5f5",
                    }}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <GridLoader color="#020f59" loading={true} size={10} />
                    </div>
                    <p style={{ fontSize: 20 }}>Loading...</p>
                  </div>
                </Fade>
              ) : null}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={
                checkValueSearch && checkValueSearch.length > 0
                  ? valueSearch.length
                  : rows.length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
      <ModalAddDepartment
        isModalOpenAddDepartment={isModalOpenAddDepartment}
        handleOkAddDepartment={handleOkAddDepartment}
        setIsModalOpenAddDepartment={setIsModalOpenAddDepartment}
        fetchListDataDepartment={fetchListDataDepartment}
      />
      <ModalEditDepartment
        isModalOpenEditDepartment={isModalOpenEditDepartment}
        handleOkEditDepartment={handleOkEditDepartment}
        setIsModalOpenEditDepartment={setIsModalOpenEditDepartment}
        fetchListDataDepartment={fetchListDataDepartment}
        dataInforDepartment={dataInforDepartment}
        idEditDepartment={idEditDepartment}
      />
    </>
  );
};

export default Department;
