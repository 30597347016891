import { authAxios } from "../api/axiosClient";
import { currencyofmonth, listCurrency } from "../constants";

export const GetListCurrency = (params = {}) => {
  const url = listCurrency;
  return authAxios().get(url, { params });
};

export const PostListCurrency = (params = {}) => {
  const url = listCurrency;
  return authAxios().post(url, params);
};

export const GetIdCurrencyEditApi = (params = {}) => {
  const url = listCurrency;
  return authAxios().get(url, { params });
};

export const PutCurrencyApi = (params = {}) => {
  const url = listCurrency;
  return authAxios().post(url, params);
};

export const PutCurrencyApi1 = (params = {}) => {
  const url = listCurrency;
  return authAxios().put(url, params);
};

export const GetCurrencyOfMonth = (params = {}) => {
  const url = currencyofmonth;
  return authAxios().get(url, { params });
};
