import { Col, Form, Input, Modal, Row, Button } from "antd";
import React from "react";
import { useEffect } from "react";
import { PutDepartmentApi } from "../../api/lsDepartment";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const ModalEditDepartment = ({
  isModalOpenEditDepartment,
  handleOkEditDepartment,
  setIsModalOpenEditDepartment,
  fetchListDataDepartment,
  dataInforDepartment,
  idEditDepartment,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  const handleCancelEditDepartment = () => {
    setIsModalOpenEditDepartment(false);
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
    if (isModalOpenEditDepartment) {
      form.setFieldsValue({
        codedepart: dataInforDepartment.codedepart,
        namedepart: dataInforDepartment.namedepart,
      });
    }
  }, [isModalOpenEditDepartment, dataInforDepartment, form]);

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);
    data.append("id", idEditDepartment);
    data.append("message", "update");
    data.append("codedepart", values.codedepart);
    data.append("namedepart", values.namedepart);

    // currentDuAn
    PutDepartmentApi(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Cập nhật Department thành công",
        });
        setLoadings(false);
        fetchListDataDepartment();
        handleCancelEditDepartment();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="EDIT DEPARTMENT"
      open={isModalOpenEditDepartment}
      onOk={handleOkEditDepartment}
      onCancel={handleCancelEditDepartment}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Department Name"
              name="namedepart"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Department Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Department Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Department Code"
              name="codedepart"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Department Code!",
                },
              ]}
            >
              <Input size="large" placeholder="Department Code" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelEditDepartment}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalEditDepartment;
