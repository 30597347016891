import { Col, Form, Input, Modal, Row, Button } from "antd";
import React from "react";
import { useEffect } from "react";
import { PutMarketApi } from "../../api/listMarket";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const ModalEditMarket = ({
  isModalOpenEditMarket,
  handleOkEditMarket,
  setIsModalOpenEditMarket,
  dataInforMarket,
  idEditMarket,
  fetchListDataMarket,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  const handleCancelEditMarket = () => {
    setIsModalOpenEditMarket(false);
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
    if (isModalOpenEditMarket) {
      form.setFieldsValue({
        namemarket: dataInforMarket.namemarket,
        codemarket: dataInforMarket.codemarket,
      });
    }
  }, [isModalOpenEditMarket, dataInforMarket, form]);

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);
    data.append("id", idEditMarket);
    data.append("message", "update");
    data.append("namemarket", values.namemarket);
    data.append("codemarket", values.codemarket);

    // currentDuAn
    PutMarketApi(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Cập nhật Market thành công",
        });
        setLoadings(false);
        fetchListDataMarket();
        handleCancelEditMarket();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="EDIT MARKET"
      open={isModalOpenEditMarket}
      onOk={handleOkEditMarket}
      onCancel={handleCancelEditMarket}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Market Name"
              name="namemarket"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Market Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Market Code"
              name="codemarket"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market Code!",
                },
              ]}
            >
              <Input size="large" placeholder="Market Code" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelEditMarket}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalEditMarket;
