import { Table } from "antd";
import React from "react";

const TableReportMarket = ({ dataTable7 }) => {
  const columns = [
    {
      title: <p className="text-header-output">Market</p>,
      dataIndex: "Market",
      width: "25%",
      align: "left",
      sorter: (a, b) => {
        if (a.Market.toLowerCase() !== "grand total") {
          return b.Market.localeCompare(a.Market);
        } else {
          return null;
        }
      },
      sortOrder: "descend",
      showSorterTooltip: false,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background: index === dataTable7.length - 1 ? "#E6E6E6" : null,
              fontWeight: index === dataTable7.length - 1 ? "bold" : null,
            },
          },
          children: (
            <p
              style={
                index === dataTable7.length - 1
                  ? { textAlign: "center" }
                  : { textAlign: "left" }
              }
            >
              {value}
            </p>
          ),
        };
      },
    },

    {
      title: <p className="text-header-output">Doanh thu dự đoán</p>,
      dataIndex: "expected_revenue",
      width: 200,
      align: "right",
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background: index === dataTable7.length - 1 ? "#E6E6E6" : null,
              fontWeight: index === dataTable7.length - 1 ? "bold" : null,

            },
          },
          children: (
            <div>
              {value && value !== null ? Math.round(value).toLocaleString() : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Doanh thu thực tế</p>,
      dataIndex: "revenue_excluding_tax_changed",
      align: "right",
      width: 200,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background: index === dataTable7.length - 1 ? "#E6E6E6" : null,
              fontWeight: index === dataTable7.length - 1 ? "bold" : null,

            },
          },
          children: (
            <div>
              {value && value !== null ? Math.round(value).toLocaleString() : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">% hoàn thành</p>,
      dataIndex: "ti le",
      align: "right",
      width: 200,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background: index === dataTable7.length - 1 ? "#E6E6E6" : null,
              fontWeight: index === dataTable7.length - 1 ? "bold" : null,

            },
          },
          children: (
            <div>
              {value && value !== null
                ? Math.round(parseInt(value)).toLocaleString()
                : 0}
              %
            </div>
          ),
        };
      },
    },
  ];

  return (
    <>
      <p className="title-table" style={{ marginLeft: "1%", marginTop: 10 }}>
        TỈ LỆ HOÀN THÀNH THEO BÁO CÁO
      </p>
      <Table
        columns={columns}
        pagination={false}
        bordered
        rowClassName="text-body-check"
        className="custom-table table-dttt content-table"
        dataSource={dataTable7}
        style={{
          marginLeft: "1%",
          width: "55%",
          overflowX: "scroll",
        }}
      />
    </>
  );
};

export default TableReportMarket;
