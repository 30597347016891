import { authAxios } from "../api/axiosClient";
import {
  detailCheck,
  detailLastCheck,
  detailLastCheckSuccess,
  getProjectCheckNotEntry,
} from "../constants";

export const GetDataCheck = (params = {}) => {
  const url = detailCheck;
  return authAxios().get(url, { params });
};

export const PostDataCheck = (params = {}) => {
  const url = detailCheck;
  return authAxios().post(url, params);
};
export const GetDataLastCheck = (params = {}) => {
  const url = detailLastCheck;
  return authAxios().get(url, { params });
};
export const GetDataLastCheckSuccess = (params = {}) => {
  const url = detailLastCheckSuccess;
  return authAxios().get(url, { params });
};

export const GetProjectCheckNotEntry = (params = {}) => {
  const url = getProjectCheckNotEntry;
  return authAxios().get(url, { params });
};

export const PostDataLastCheck = (params = {}) => {
  const url = detailLastCheck;
  return authAxios().post(url, params);
};
