/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import {
  Button,
  Col,
  DatePicker,
  Empty,
  Form,
  InputNumber,
  Modal,
  Result,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  CreateMultipleRevenue,
  GetDataProjectRevenue,
} from "../../api/detailProject";
// import "./Detail.css";
import { openNotificationWithIcon } from "../../Function";
import { LoadingOutlined } from "@ant-design/icons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ShowStatusCreateRevenue from "./ShowStatusCreateRevenue";

const { Option } = Select;

const validateMessages = {
  required: "Không được để trống!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  pattern: {
    mismatch: "Chỉ được nhập số nguyên",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const ModalAllRevenue = ({
  isModalOpenAllRevenue,
  setIsModalOpenAllRevenue,
}) => {
  const [formCreateMultiRevenue] = Form.useForm();
  const [listProject, setListProject] = useState([]);
  const [listClient, setListClient] = useState([]);
  const [projectChose, setProjectChose] = useState([]);
  const [listProjectFilter, setListProjectFilter] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [dataOfRemove, setDataOfRemove] = useState([]);
  const [grandTotal, setGrandTotal] = useState([]);
  const [chooseAllClient, setChooseAllClient] = useState(false)
  const [chooseAllProject, setChooseAllProject] = useState(false)

  const [selectedItems, setSelectedItems] = useState([]);

  const [valueDate, setValueDate] = useState();
  const [valueVer, setValueVer] = useState()

  const [loadingSelectClient, setLoadingSelectClient] = useState(false)

  const [openModalStatus, setOpenModalStatus] = useState(false)

  const [detailStatus, setDetailStatus] = useState()

  const [disableMonth123, setDisableMonth123] = useState(false)
  const [disableMonth456, setDisableMonth456] = useState(false)
  const [disableMonth789, setDisableMonth789] = useState(false)

  const resetAll = () => {
    setIsModalOpenAllRevenue(false);
  };

  const handleCancel = () => {
    resetAll();
  };

  const columns = [
    {
      title: "",
      width: 0,
      dataIndex: "project",
      key: "project",
      fixed: "left",
      render: (value, item, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "project_name"]}
            label={""}
            key="project_id"
            className="custom-form-item"

          >
            {/* <div className="typo-month">
              <p>{projectChose[index].project}</p>
            </div> */}
          </Form.Item>
        </>
      ),
    },
    {
      title: "Dự án",
      width: 150,
      dataIndex: "project",
      key: "project",
      fixed: "left",
      render: (value, item, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "project_id"]}
            label={""}
            key="project_id"
            className="custom-form-item"
          >
            <div className="typo-month">
              <p>{projectChose[(indexPagi.pageSize * (indexPagi.current - 1) + index)].project}</p>
            </div>
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 1</p>,
      dataIndex: "Jan",
      key: "Jan",
      width: "150px",
      align: "right",
      render: (value, item, index) => (
        <Form.Item
          name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jan"]}
          label={""}
          key="jan"
          rules={[
            {
              required: true,
            },
          ]}
          className="custom-form-item"
        >
          <InputNumber
            // type="number"
            // value={valueRevenueExcluding}
            onChange={(e) =>
              sumData(
                e,
                formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jan"]),
                index
              )
            }
            disabled={disableMonth123}
            placeholder="Nhập tiền"
            className="right-to-left-input"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            style={{ width: "100%" }}
            onKeyPress={(e) => {
              const charCode = e.which ? e.which : e.keyCode;
              if (charCode < 48 || charCode > 57) {
                e.preventDefault();
              }
              if (
                charCode === 32 &&
                formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jan"]) !==
                undefined
              ) {
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jan"]),
                  index
                );
              }
            }}
            min={0}
            controls={false}
          />
        </Form.Item>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 2</p>,
      dataIndex: "Feb",
      key: "Feb",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Feb"]}
            label={""}
            key="feb"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              disabled={disableMonth123}
              min={0}
              controls={false}
              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Feb"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Feb",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Feb",
                    ]),
                    index
                  );
                }
              }}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 3</p>,
      dataIndex: "Mar",
      key: "Mar",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Mar"]}
            label={""}
            key="mar"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              disabled={disableMonth123}
              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Mar"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Mar",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Mar",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 4</p>,
      dataIndex: "Apr",
      key: "Apr",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Apr"]}
            label={""}
            key="apr"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              disabled={disableMonth456}

              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Apr"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Apr",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Apr",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 5</p>,
      dataIndex: "May",
      key: "May",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "May"]}
            label={""}
            key="may"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              disabled={disableMonth456}
              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "May"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "May",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "May",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 6</p>,
      dataIndex: "Jun",
      key: "Jun",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jun"]}
            label={""}
            key="jun"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              disabled={disableMonth456}
              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jun"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Jun",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Jun",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 7</p>,
      dataIndex: "Jul",
      key: "Jul",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jul"]}
            label={""}
            key="jul"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              disabled={disableMonth789}

              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jul"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Jul",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Jul",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 8</p>,
      dataIndex: "Aug",
      key: "Aug",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Aug"]}
            label={""}
            key="aug"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              disabled={disableMonth789}
              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Aug"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Aug",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Aug",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 9</p>,
      dataIndex: "Sep",
      key: "Sep",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Sep"]}
            label={""}
            key="sep"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Sep"]),
                  index
                )
              }
              disabled={disableMonth789}
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Sep",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Sep",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 10</p>,
      dataIndex: "Oct",
      key: "Oct",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Oct"]}
            label={""}
            key="oct"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Oct"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Oct",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Oct",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 11</p>,
      dataIndex: "Nov",
      key: "Nov",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Nov"]}
            label={""}
            key="nov"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Nov"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Nov",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Nov",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 12</p>,
      dataIndex: "Dec",
      key: "Dec",
      width: "150px",
      align: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Dec"]}
            label={""}
            key="dec"
            rules={[
              {
                required: true,
              },
            ]}
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              onChange={(e) =>
                sumData(
                  e,
                  formCreateMultiRevenue.getFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Dec"]),
                  index
                )
              }
              placeholder="Nhập tiền"
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
                if (
                  charCode === 32 &&
                  formCreateMultiRevenue.getFieldValue([
                    "data",
                    (indexPagi.pageSize * (indexPagi.current - 1) + index),
                    "Dec",
                  ]) !== undefined
                ) {
                  sumData(
                    e,
                    formCreateMultiRevenue.getFieldValue([
                      "data",
                      (indexPagi.pageSize * (indexPagi.current - 1) + index),
                      "Dec",
                    ]),
                    index
                  );
                }
              }}
              min={0}
              controls={false}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Grand Total</p>,
      dataIndex: "",
      key: "",
      width: "150px",
      align: "right",
      fixed: "right",
      render: (value, record, index) => (
        <>
          <Form.Item
            name={["Grand_total", (indexPagi.pageSize * (indexPagi.current - 1) + index), "total"]}
            label={""}
            key="grand_total"
            className="custom-form-item"
          >
            <InputNumber
              // type="number"
              // value={valueRevenueExcluding}
              min={0}
              controls={false}
              className="right-to-left-input"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              style={{ width: "100%", border: "unset" }}
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if (charCode < 48 || charCode > 57) {
                  e.preventDefault();
                }
              }}
              readOnly
            />
          </Form.Item>
        </>
      ),
    },
  ];

  const sumData = (event, value, index) => {
    let month = "";
    if (typeof event !== "number" && event !== null) {
      month = event.target.id.slice(-3);
    }

    switch (month) {
      case "Jan":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jan"],
          value * 1000
        );
        break;
      case "Feb":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Feb"],
          value * 1000
        );
        break;
      case "Mar":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Mar"],
          value * 1000
        );
        break;
      case "Apr":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Apr"],
          value * 1000
        );
        break;
      case "May":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "May"],
          value * 1000
        );
        break;
      case "Jun":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jun"],
          value * 1000
        );
        break;
      case "Jul":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jul"],
          value * 1000
        );
        break;
      case "Aug":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Aug"],
          value * 1000
        );
        break;
      case "Sep":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Sep"],
          value * 1000
        );
        break;
      case "Oct":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Oct"],
          value * 1000
        );
        break;
      case "Nov":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Nov"],
          value * 1000
        );
        break;
      case "Dec":
        formCreateMultiRevenue.setFieldValue(
          ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Dec"],
          value * 1000
        );
        break;
      default:
    }

    const dataForm = formCreateMultiRevenue.getFieldValue("data");
    let arrGrandTotal = grandTotal;

    let values = Object.values(dataForm[(indexPagi.pageSize * (indexPagi.current - 1) + index)]);

    //remove the first element
    // Vì phần tử đầu tiên là tên project. Nó vẫn có thế convert từ string về 1 số


    values.splice(0, 2);


    // used to add all element in an object
    // can handle calculations other than sums
    let sum = values.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
    arrGrandTotal[(indexPagi.pageSize * (indexPagi.current - 1) + index)] = sum;
    setGrandTotal(arrGrandTotal);
    formCreateMultiRevenue.setFieldValue(
      ["Grand_total", (indexPagi.pageSize * (indexPagi.current - 1) + index), "total"],
      arrGrandTotal[(indexPagi.pageSize * (indexPagi.current - 1) + index)]
    );
  };

  const clearData = () => {
    formCreateMultiRevenue.setFieldValue("client", undefined);
    formCreateMultiRevenue.setFieldValue("project", undefined);
    setSelectedItems([])
    // setProjectChose([]);
    // setListProject([]);
    setListProjectFilter([])
    setProjectChose([])
  }

  const chooseMonth = (value) => {
    clearData()

    setValueDate(value)
    GetClient(value, valueVer);
  };

  const chooseVer = (value) => {
    clearData()
    console.log(value)
    if (value === 1) {
      setDisableMonth123(true)
      setDisableMonth456(false)
      setDisableMonth789(false)
    } else if (value === 2) {
      setDisableMonth123(true)
      setDisableMonth456(true)
      setDisableMonth789(false)
    } else if (value === 3) {
      setDisableMonth123(true)
      setDisableMonth456(true)
      setDisableMonth789(true)
    } else {
      setDisableMonth123(false)
      setDisableMonth456(false)
      setDisableMonth789(false)
    }
    setValueVer(value)
    GetClient(valueDate, value);
  };

  const onFinish = (values) => {
    setLoadingModal(true);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("year", dayjs(values.year).year());
    // data.append("data", JSON.stringify(values.data));
    data.append("data", JSON.stringify(formCreateMultiRevenue.getFieldValue("data")));
    data.append("ver", values.ver);

    CreateMultipleRevenue(data)
      .then((res) => {
        setDetailStatus(res.data)
        setLoadingModal(false);
        // resetAll();
        // openNotificationWithIcon("success", "Đã tạo phiếu thành công");
        setOpenModalStatus(true)
      })
      .catch((err) => {
        setLoadingModal(false);
        openNotificationWithIcon("error", "Tạo phiếu thất bại");
      });
  };

  const GetClient = (value, dataVer) => {
    setLoadingSelectClient(true)
    GetDataProjectRevenue({
      year: dayjs(value).year(),
      ver: dataVer,
    })
      .then((res) => {
        setLoadingSelectClient(false)
        setListProject(res.data.message.listProject);
        setListClient(res.data.message.listClient.sort((a, b) => {
          const nameA = a.client_code.toUpperCase(); // ignore upper and lowercase
          const nameB = b.client_code.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        }));
      })
      .catch((err) => {
        setLoadingSelectClient(false)
        console.log(err);
      });
  };

  useEffect(() => {

    if (isModalOpenAllRevenue === true) {
      setValueDate(dayjs())
      setValueVer(quarters[0].value)
      GetClient(dayjs(), quarters[0].value);
      setChooseAllClient(false)
      setChooseAllProject(false)

      formCreateMultiRevenue.resetFields();
      setProjectChose([]);
      setListProjectFilter([]);
      // setGrandTotal([])
      setSelectedItems([]);

      formCreateMultiRevenue.setFieldValue("year", dayjs());
      formCreateMultiRevenue.setFieldValue("ver", quarters[0].value);

    }
  }, [isModalOpenAllRevenue]);

  const ChooseClient = (value) => {
    formCreateMultiRevenue.setFieldsValue({
      project: undefined,
      Grand_total: undefined,
      currency: undefined,
      file_entry: undefined,
      data: undefined,
    });
    setSelectedItems([])
    const newListClient = listProject.filter(
      (item) => value.includes(item.client_id)
    );

    setProjectChose([]);
    setListProjectFilter(newListClient);
  };

  // const ChooseProject = (value, index) => {

  //   const hasAll = value.includes("all");

  //   if (!hasAll) {
  //     const parsedData = value.map((jsonString) => JSON.parse(jsonString));

  //     // get List id of parsedData
  //     let newArrId = parsedData.map((item) => {
  //       return item.id;
  //     });


  //     let newListProject = parsedData.filter((item) =>
  //       newArrId.includes(item.id)
  //     );


  //     const deselectedIndexes = selectedItems
  //       .map((item, index) => (value.indexOf(item) === -1 ? index : -1))
  //       .filter((index) => index !== -1);

  //     console.log(deselectedIndexes)
  //     // Xử lý các mục bị bỏ chọn
  //     if (deselectedIndexes.length > 0) {
  //       // Thực hiện hành động bạn muốn với các mục bị bỏ chọn ở đây
  //       setDataOfRemove(value);
  //       setProjectChose(newListProject);
  //       if (newListProject.length === 0) {
  //         formCreateMultiRevenue.setFieldValue("data", undefined);
  //       }
  //     } else {
  //       newListProject.forEach((item, index) => {
  //         formCreateMultiRevenue.setFieldValue(
  //           ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "project_id"],
  //           item.id
  //         );

  //         formCreateMultiRevenue.setFieldValue(
  //           ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "project_name"],
  //           item.project
  //         );

  //         console.log(item)
  //         if (item.expected_revenue !== undefined) {
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jan"],
  //             item.expected_revenue[1] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Feb"],
  //             item.expected_revenue[2] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Mar"],
  //             item.expected_revenue[3] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Apr"],
  //             item.expected_revenue[4] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "May"],
  //             item.expected_revenue[5] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jun"],
  //             item.expected_revenue[6] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Jul"],
  //             item.expected_revenue[7] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Aug"],
  //             item.expected_revenue[8] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Sep"],
  //             item.expected_revenue[9] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Oct"],
  //             item.expected_revenue[10] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Nov"],
  //             item.expected_revenue[11] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );
  //           formCreateMultiRevenue.setFieldValue(
  //             ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "Dec"],
  //             item.expected_revenue[12] // [(indexPagi.pageSize * (indexPagi.current - 1) + index) + 1] là key
  //           );

  //           formCreateMultiRevenue.setFieldValue(
  //             ["Grand_total", (indexPagi.pageSize * (indexPagi.current - 1) + index), "total"],
  //             (item.expected_revenue[1] + item.expected_revenue[2] + item.expected_revenue[3] + item.expected_revenue[4] + item.expected_revenue[5] +
  //               item.expected_revenue[6] + item.expected_revenue[7] + item.expected_revenue[8] + item.expected_revenue[9] + item.expected_revenue[10] +
  //               item.expected_revenue[11] + item.expected_revenue[12])
  //           );
  //         }
  //       });

  //       setDataOfRemove(value);
  //       setProjectChose(newListProject);
  //       if (newListProject.length === 0) {
  //         formCreateMultiRevenue.setFieldValue("data", undefined);
  //       }
  //     }
  //     setSelectedItems(value);
  //   }
  // };

  const handleDeselect = (value) => {
    const newList = formCreateMultiRevenue.getFieldValue("data");
    const newListTotal = formCreateMultiRevenue.getFieldValue("Grand_total");

    // Get index of value want remove
    const index = dataOfRemove.indexOf(value);
    if (selectedItems.length > 1) {
      newList.splice(index, 1);
      formCreateMultiRevenue.setFieldValue(["data"], newList);

      if (newListTotal !== undefined) {
        newListTotal.splice(index, 1);
        formCreateMultiRevenue.setFieldValue(["Grand_total"], newListTotal);
      }
    } else {
      formCreateMultiRevenue.setFieldValue(["data"], undefined);
      formCreateMultiRevenue.setFieldValue(["Grand_total"], undefined);
    }
  };

  const quarters = [
    { name: "1.0", value: 0 },
    { name: "1.1", value: 1 },
    { name: "1.2", value: 2 },
    { name: "1.3", value: 3 },
  ]

  const selectAllClient = (value) => {

    if (value === "all") {
      if (formCreateMultiRevenue.getFieldValue("client").length - 1 < listClient.length) {
        const listIdClient = listClient.map(item => { return item.id })
        const newListClient = listProject.filter(
          (item) => listIdClient.includes(item.client_id)
        );
        formCreateMultiRevenue.setFieldValue("client", listIdClient)
        setListProjectFilter(newListClient)
        setChooseAllClient(true)
      } else {
        formCreateMultiRevenue.setFieldValue("client", [])
        setListProjectFilter([])
        setChooseAllClient(false)
      }

      // if (chooseAllClient === false) {
      //   const listIdClient = listClient.map(item => { return item.id })
      //   const newListClient = listProject.filter(
      //     (item) => listIdClient.includes(item.client_id)
      //   );
      //   formCreateMultiRevenue.setFieldValue("client", listIdClient)
      //   setListProjectFilter(newListClient)
      //   setChooseAllClient(true)
      // } else {
      //   formCreateMultiRevenue.setFieldValue("client", [])
      //   setListProjectFilter([])
      //   setChooseAllClient(false)
      // }
    }
  }

  // const selectAllProject = (value) => {
  //   if (value === "all") {

  //     if (formCreateMultiRevenue.getFieldValue("project").length - 1 < listProjectFilter.length) {
  //       const listIdAllProject = listProjectFilter.map((item) => JSON.stringify(item))
  //       // const listIdProject = listProjectFilter.map(item => { return item.id })
  //       // // const newListClient = listProject.filter(
  //       // //   (item) => listIdClient.includes(item.client_id)
  //       // // );
  //       formCreateMultiRevenue.setFieldValue("project", listIdAllProject)
  //       ChooseProject(listIdAllProject)
  //       // // setListProjectFilter(newListClient)
  //       setChooseAllProject(true)
  //     } else {
  //       formCreateMultiRevenue.setFieldValue(["data"], undefined)
  //       formCreateMultiRevenue.setFieldValue("project", [])
  //       formCreateMultiRevenue.setFieldValue(["Grand_total"], undefined);
  //       setProjectChose([])
  //       // setListProjectFilter([])
  //       setSelectedItems([])
  //       setChooseAllProject(false)
  //     }
  //   }
  // }

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
      }}
      spin
    />
  );

  const [indexPagi, setIndexPagi] = useState({
    current: 1,
    pageSize: 10,
  })

  const changePagination = (pagi, filters, sorter) => {
    // setIsEditRevenue(false)
    setIndexPagi(pagi)
  }

  const setMonthlyValues = (formData, item, index) => {
    const monthLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    for (let i = 1; i <= 12; i++) {
      formData.setFieldValue(
        ["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), monthLabels[i - 1]],
        item.expected_revenue[i] || 0
      );
    }
  };

  const setTotalValue = (formData, item, index) => {
    let total = 0;
    for (let i = 1; i <= 12; i++) {
      total += item.expected_revenue[i] || 0;
    }
    formData.setFieldValue(["Grand_total", (indexPagi.pageSize * (indexPagi.current - 1) + index), "total"], total);
  };

  const ChooseProject = (value, index) => {

    const hasAll = value.includes("all");

    if (!hasAll) {
      const parsedData = value.map((jsonString) => JSON.parse(jsonString));

      // get List id of parsedData
      let newArrId = parsedData.map((item) => {
        return item.id;
      });


      let newListProject = parsedData.filter((item) =>
        newArrId.includes(item.id)
      );

      // Check for deselected indexes
      const deselectedIndexes = selectedItems
        .map((item, index) => (value.indexOf(item) === -1 ? index : -1))
        .filter((index) => index !== -1);

      if (deselectedIndexes.length > 0) {
        setDataOfRemove(value);
        setProjectChose(newListProject);
        if (newListProject.length === 0) {
          formCreateMultiRevenue.setFieldValue("data", undefined);
        }
      } else {
        newListProject.forEach((item, index) => {
          formCreateMultiRevenue.setFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "project_id"], item.id);
          formCreateMultiRevenue.setFieldValue(["data", (indexPagi.pageSize * (indexPagi.current - 1) + index), "project_name"], item.project);
          setMonthlyValues(formCreateMultiRevenue, item, index);
          setTotalValue(formCreateMultiRevenue, item, index);
        });

        setDataOfRemove(value);
        setProjectChose(newListProject);
        if (newListProject.length === 0) {
          formCreateMultiRevenue.setFieldValue("data", undefined);
        }
      }
      setSelectedItems(value);
    }
  };

  const selectAllProject = (value) => {
    if (value === "all") {
      if (formCreateMultiRevenue.getFieldValue("project").length - 1 < listProjectFilter.length) {
        const listIdAllProject = listProjectFilter.map((item) => JSON.stringify(item));
        formCreateMultiRevenue.setFieldValue("project", listIdAllProject);
        ChooseProject(listIdAllProject);
        setChooseAllProject(true);
      } else {
        formCreateMultiRevenue.setFieldValue(["data"], undefined);
        formCreateMultiRevenue.setFieldValue("project", []);
        formCreateMultiRevenue.setFieldValue(["Grand_total"], undefined);
        setProjectChose([]);
        setSelectedItems([]);
        setChooseAllProject(false);
      }
    }
  };


  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="title-bill" style={{ width: "100%" }}>
              <span className="title-field-bill" style={{ fontSize: 22 }}>
                Tạo nhiều doanh thu
              </span>
            </div>
          </div>
        }
        open={isModalOpenAllRevenue}
        // onCancel={handleCancel}
        width={"100%"}
        closable={false}
        footer={true}
      >
        <Form
          form={formCreateMultiRevenue}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          validateMessages={validateMessages}
          initialValues={{
            remember: true,
          }}
          className="custom-form"
          key="formImport"
        >
          <Row>
            <Col span={4} className="ColModalAllRevenue">
              <Form.Item
                name="year"
                label={<span className="title-field-bill">Năm tạo dự kiến</span>}
                key="year"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  onChange={chooseMonth}
                  allowClear={false}
                  picker="year"
                  style={{ width: "100%" }}
                  size="middle"
                  placeholder="Chọn năm"
                />
              </Form.Item>
            </Col>
            <Col span={4} className="ColModalAllRevenue" offset={1}>
              <Form.Item
                name="ver"
                label={<span className="title-field-bill">Ver</span>}
                key="client"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Chọn Ver"
                  key="project"
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  // className="custom-select"
                  onChange={chooseVer}
                  // showSearch
                  defaultValue={quarters[0]}
                // optionLabelProp="data-label"
                // getPopupContainer={getPopupContainer}

                >
                  {quarters.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6} className="ColModalAllRevenue" offset={1}>
              <Form.Item
                name="client"
                label={<span className="title-field-bill">Khách hàng</span>}
                key="client"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Chọn Khách hàng"
                  key="client"
                  allowClear
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  showSearch
                  onChange={ChooseClient}
                  mode="multiple"
                  onSelect={selectAllClient}
                // loading={loadingSelectClient}
                >

                  {loadingSelectClient === false ?
                    (<>
                      {listClient.length > 0 ?
                        <Option key="all" value="all" style={{ background: "#F0F0F0" }}>---SELECT ALL---</Option>
                        : null}


                      {listClient.length > 0
                        ? listClient.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.client_code}
                          </Option>
                        ))
                        : null}
                    </>)
                    : <Option disabled style={{ background: "#fff", textAlign: "center" }}><Spin indicator={antIcon} /></Option>}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6} className="ColModalAllRevenue" offset={1}>
              <Form.Item
                name="project"
                label={<p className="title-field-bill">Dự án</p>}
                key="project"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Chọn Dự án"
                  key="project"
                  allowClear
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  // className="custom-select"
                  showSearch
                  // optionLabelProp="data-label"
                  mode="multiple"
                  onDeselect={handleDeselect}
                  onChange={ChooseProject}
                  onSelect={selectAllProject}
                // getPopupContainer={getPopupContainer}
                >

                  {listProjectFilter.length > 0 ?
                    <Option key="all" value="all" style={{ background: "#F0F0F0" }}>---SELECT ALL---</Option>
                    : null}

                  {listProject.length > 0
                    ? listProjectFilter.map((item, index) => (
                      <Option
                        key={index}
                        value={JSON.stringify(item)}
                      // value={item.id}
                      >
                        {item.project}
                      </Option>
                    ))
                    : null}
                </Select>
              </Form.Item>
            </Col>

            <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <span style={{ color: "red", fontSize: 18, marginBottom: "0.5%", fontWeight: 500 }}>* Nhập tiền ngoại tệ theo dự án</span>
            </Row>

            <Col span={24}>
              <Table
                columns={columns}
                dataSource={projectChose}
                style={{ width: "100%" }}
                scroll={{
                  x: window.screen.availWidth < 1800 ? 1500 : null,
                  y: "45vh"
                }}
                pagination={{ defaultPageSize: 20 }}
                className="custom-table table-striped-rows table-all-revenue"
                bordered
                onChange={changePagination}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="Chưa có dữ liệu"
                    />
                  ),
                }}
              />
            </Col>
          </Row>
          <Row
            style={{
              justifyContent: "center",
              height: "62px",
              marginTop: "1.5%",
            }}
          >
            <Button
              loading={loadingModal}
              htmlType="submit"
              className="btn-submit-dtdk"
              style={{ height: "46px", width: 180 }}
            >
              SUBMIT
            </Button>
            <Button
              className="btn-cancel-dtdk"
              onClick={handleCancel}
              style={{ marginLeft: "30px", height: "46px", width: 180 }}
            >
              CANCEL
            </Button>
          </Row>
        </Form>
      </Modal>

      {openModalStatus === true ?
        <ShowStatusCreateRevenue openModalStatus={openModalStatus} setOpenModalStatus={setOpenModalStatus} detailStatus={detailStatus} resetAll={resetAll} />
        : null}
    </>
  );
};

export default ModalAllRevenue;
