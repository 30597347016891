import { Col, Form, Input, Modal, Row, Button } from "antd";
import React from "react";
import { PostListBankAC } from "../../api/listBankAC";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const ModalAddBankAccount = ({
  isModalOpenAddBank,
  handleOkAddBank,
  setIsModalOpenAddBank,
  fetchListDataBankAC,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  const handleCancelAddBank = () => {
    setIsModalOpenAddBank(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);
    data.append("message", "create");
    data.append("codebank", values.codebank);
    data.append("namebank", values.namebank);
    data.append("accountbank", values.accountbank);
    // currentDuAn
    PostListBankAC(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Thêm mới Bank Account thành công",
        });
        setLoadings(false);
        fetchListDataBankAC();
        handleCancelAddBank();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="ADD BANK ACCOUNT"
      open={isModalOpenAddBank}
      onOk={handleOkAddBank}
      onCancel={handleCancelAddBank}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Bank Account Name"
              name="namebank"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Bank Account Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Bank Account Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Bank Account Code"
              name="codebank"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Bank Account Code!",
                },
              ]}
            >
              <Input size="large" placeholder="Bank Account Code" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Bank Account Number"
              name="accountbank"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Bank Account Number!",
                },
              ]}
            >
              <Input size="large" placeholder="Bank Account Number" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelAddBank}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalAddBankAccount;
