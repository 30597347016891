import { authAxios } from "../api/axiosClient";
import { listDepartment } from "../constants";

export const GetListDepartment = (params = {}) => {
  const url = listDepartment;
  return authAxios().get(url, { params });
};

export const PostListDepartment = (params = {}) => {
  const url = listDepartment;
  return authAxios().post(url, params);
};

export const GetIdDepartmentEditApi = (params = {}) => {
  const url = listDepartment;
  return authAxios().get(url, { params });
};

export const PutDepartmentApi = (params = {}) => {
  const url = listDepartment;
  return authAxios().post(url, params);
};
