/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */

import React, { useState, useEffect } from "react";
import { localhost } from "../../server"
import { CreateRevenue, EditDataProjectRevenue, GetDataDetailProject, GetFileExport, UpdateRevenue } from "../../api/detailProject";
import { openNotificationWithIcon } from "../../Function";
import { useSelector } from "react-redux";

import DownloadIcon from "../../images/IconDownload.svg"
import NoData from "../../images/noData.svg";
import CreateBillMuchIcon from "../../images/CreateBillMuch.svg";
import EditIcon from "../../images/Pen.svg";


import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";

import CreateBillIcon from "../../images/CreateBill.svg";

import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DownOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";
import { exportFileBill } from "../../constants";
import FormConfirm from "../home/ProjectDetail/FormConfirm";
import ModalAllRevenue from "./ModalAllRevenue";
import { DownloadRevenueVer, EditRevenueVer, GetRevenueVer } from "../../api/apiRevenue";
import { IconButton } from "@mui/material";
import ModalLogDetailRevenue from "./ModalLogDetailRevenue";
import axios from "axios";
import { GetVerApi } from "../../api/listDT_DD_TT"

const { Option } = Select;

const validateMessages = {
  required: "Không được để trống!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  pattern: {
    mismatch: "Chỉ được nhập số nguyên",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

export default function Revenue() {
  const [loading, setLoading] = React.useState(true);
  const dateValue = new Date();
  const defaultQuarter = Math.floor(dateValue.getMonth() / 3);
  // console.log("defaultQuarter: ", defaultQuarter);
  const [loadingBtnDtdk, setLoadingBtnDtdk] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  // const [isEditRevenue, setIsOpenModalCreateBill] =
  //   React.useState(false);
  const [isOpenModalLogDetail, setIsOpenModalLogDetail] = React.useState(false);
  const [isOpenExpectedRevenue, setIsOpenExpectedRevenue] =
    React.useState(false);
  const [isOpenEditExpectedRevenue, setIsOpenEditExpectedRevenue] =
    React.useState(false);
  const [isOpenBtnEditExpectRevenue, setIsOpenBtnEditExpectRevenue] =
    useState(false);

  const [checkExpectedRevenue, setCheckExpectedRevenue] = React.useState(false);

  const [listClientOfUser, setListClientOfUser] = useState([]);
  const [listDataDep, setListDataDep] = React.useState([]);
  const [listDataProjectOfDep, setListDataProjectOfDep] = React.useState([]);

  const [dataPerMonth, setDataPerMonth] = useState();
  const [dataLogDetail, setDataLogDetail] = useState();
  const [userRole, setUserRole] = React.useState("");

  const [dataDetailProject, setDataDetailProject] = useState([]);
  const [dataBasicProject, setDataBasicProject] = useState();
  const [valuesDetail, setValuesDetail] = useState();

  const [formGetDataDetail] = Form.useForm();
  const [formExpectedRevenue] = Form.useForm();
  const [formEditExpectedRevenue] = Form.useForm();
  const [lsPermissions, setLsPermissions] = useState([]);
  const [idProjectDownload, setIdProjectDownload] = useState();

  const [listDisable, setListDisable] = useState([]);

  const [loadingBtnDownloadFile, setLoadingBtnDownloadFile] = useState(false);
  const [isOpenModalCreateMultipleBill, setIsOpenModalCreateMultipleBill] =
    useState(false);

  const [isModalOpenAllRevenue, setIsModalOpenAllRevenue] = useState(false);

  const [onOpenColumns, setOnOpenColumns] = useState(false)

  const [dataRevenue, setDataRevenue] = useState([])

  const [titleBtn, setTitleBtn] = useState("VIEW ALL")

  const [indexRow, setIndexRow] = useState(0);
  const [isEditRevenue, setIsEditRevenue] = useState(false)

  const [formEditRevenue] = Form.useForm();

  const [dateConfirm, setDateConfirm] = useState()
  const [verConfirm, setVerConfirm] = useState()

  const [valueFilter, setValueFilter] = useState([])
  const [oldDataDetail, setOldDataDetail] = useState({})
  const [indexPagi, setIndexPagi] = useState({
    current: 1,
    pageSize: 10,
  })

  const quarters = [
    { name: "1.0", value: 0 },
    { name: "1.1", value: 1 },
    { name: "1.2", value: 2 },
    { name: "1.3", value: 3 },
  ]
  
  const [loadingDownload, setLoadingDownload] = useState(false)
  
  const [disableMonth123, setDisableMonth123] = useState(false)
  const [disableMonth456, setDisableMonth456] = useState(false)
  const [disableMonth789, setDisableMonth789] = useState(false)
  
  // function getQuarter(dateValue) {
  //   console.log('getQuarter: ', parseInt(dateValue.getMonth() / 3 ) + 1);
  // }
  
  const [selectedOption, setSelectedOption] = useState(defaultQuarter);

  const handleOptionChange = (value) => {
    // console.log('value: ', value);
    setSelectedOption(parseInt(value));
    if (value === 1) {
      setDisableMonth123(true);
      setDisableMonth456(false);
      setDisableMonth789(false);
    } else if (value === 2) {
      setDisableMonth123(true);
      setDisableMonth456(true);
      setDisableMonth789(false);
    } else if (value === 3) {
      setDisableMonth123(true);
      setDisableMonth456(true);
      setDisableMonth789(true);
    } else {
      setDisableMonth123(false);
      setDisableMonth456(false);
      setDisableMonth789(false);
    }
    
  };

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  useEffect(() => {
    if (userInfo) {
      // setIsSuperA(userInfo.is_superuser);
      const arrPermission = userInfo.permission.map((item, index) => {
        return item.name;
      });

      if (arrPermission !== undefined) {
        setLsPermissions([...arrPermission]);
      }
    }
  }, [userInfo]);

  const getColumnFilters = (dataFilter) => {
    if (dataFilter !== undefined) {
      const filters = dataFilter.reduce((acc, item) => {
        if (!acc.find((filter) => filter.value === item.client_code)) {
          acc.push({
            text: item.client_code,
            value: item.client_code,
          });
        }
        return acc;
      }, []);
      setValueFilter(filters)
      // return filters;
    }
  };

  const sumData = (event, value, index) => {
    let month = "";
    if (typeof event !== "number" && event !== null) {
      month = event.target.id;
    }
    // formEditRevenue.setFieldValue(
    //   "grand_total", 123
    // );

    switch (month) {
      case "thang_1":
        formEditRevenue.setFieldValue("thang_1", value * 1000);
        break;
      case "thang_2":
        formEditRevenue.setFieldValue("thang_2", value * 1000);
        break;
      case "thang_3":
        formEditRevenue.setFieldValue("thang_3", value * 1000);
        break;
      case "thang_4":
        formEditRevenue.setFieldValue("thang_4", value * 1000);
        break;
      case "thang_5":
        formEditRevenue.setFieldValue("thang_5", value * 1000);
        break;
      case "thang_6":
        formEditRevenue.setFieldValue("thang_6", value * 1000);
        break;
      case "thang_7":
        formEditRevenue.setFieldValue("thang_7", value * 1000);
        break;
      case "thang_8":
        formEditRevenue.setFieldValue("thang_8", value * 1000);
        break;
      case "thang_9":
        formEditRevenue.setFieldValue("thang_9", value * 1000);
        break;
      case "thang_10":
        formEditRevenue.setFieldValue("thang_10", value * 1000);
        break;
      case "thang_11":
        formEditRevenue.setFieldValue("thang_11", value * 1000);
        break;
      case "thang_12":
        formEditRevenue.setFieldValue("thang_12", value * 1000);
        break;
      default:
    }

    let sum = 0;

    for (let i = 1; i < 13; i++) {
      sum += formEditRevenue.getFieldValue(`thang_${i}`, index)
    }

    formEditRevenue.setFieldValue("Grand_Total", sum);

    // const dataForm = formEditRevenue.getFieldValue("data");
    // // let arrGrandTotal = grandTotal;

    // let values = Object.values(dataForm[index]);
    // console.log(dataForm[index])

    // //remove the first element
    // // Vì phần tử đầu tiên là tên project. Nó vẫn có thế convert từ string về 1 số
    // values.shift();

    // // used to add all element in an object
    // // can handle calculations other than sums
    // let sum = values.reduce((accumulator, value) => {
    //   return accumulator + value;
    // }, 0);
    // arrGrandTotal[index] = sum;
    // setGrandTotal(arrGrandTotal);
    // formCreateMultiRevenue.setFieldValue(
    //   ["Grand_total", index, "total"],
    //   arrGrandTotal[index]
    // );
  };

  const valueVer = selectedOption === null ? defaultQuarter : selectedOption;

  const columns = [
    {
      title: <p style={{ textAlign: "center" }}>Khách hàng</p>,
      width: 100,
      dataIndex: "client_code",
      key: "month",
      fixed: "left",
      filters: valueFilter.map((item) => ({ text: item.text, value: item.value })), // Sử dụng giá trị từ filterValues
      filterSearch: true,
      onFilter: (value, record) => record.client_code.startsWith(value),

      render: (value, item, index) => (
        <div className="typo-month">
          <span>{value}</span>
        </div>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Dự án</p>,
      width: 80,
      dataIndex: "project",
      key: "expected_revenue",
      align: "center",
      fixed: "left",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Ngoại tệ</p>,
      width: 80,
      dataIndex: "currency_code",
      // key: "currency_code",
      align: "center",
      fixed: "left",
      // render: (value, item, index) => (
      //   <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      // ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 1</p>,
      dataIndex: "1",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_1"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_1", index),
                    index
                  )
                }
                // onChange={changeMoneyVND}
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_1", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_1", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
                disabled={disableMonth123}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 2</p>,
      dataIndex: "2",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_2"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_2"),
                    index,
                    "thang_2"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_2", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_2", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
                disabled={disableMonth123}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 3</p>,
      dataIndex: "3",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_3"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_3"),
                    index,
                    "thang_3"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_3", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_3", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
                disabled={disableMonth123}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 4</p>,
      dataIndex: "4",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_4"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_4"),
                    index,
                    "thang_4"
                  )
                }
                // onChange={changeMoneyVND}
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_4", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_4", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
                disabled={disableMonth456}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 5</p>,
      dataIndex: "5",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_5"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_5"),
                    index,
                    "thang_5"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_5", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_5", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
                disabled={disableMonth456}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 6</p>,
      dataIndex: "6",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_6"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_6"),
                    index,
                    "thang_6"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_6", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_6", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
                disabled={disableMonth456}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 7</p>,
      dataIndex: "7",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_7"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_7"),
                    index,
                    "thang_7"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_7", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_7", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
                disabled={disableMonth789}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 8</p>,
      dataIndex: "8",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_8"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_8"),
                    index,
                    "thang_8"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_8", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_8", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
                disabled={disableMonth789}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 9</p>,
      dataIndex: "9",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_9"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_9"),
                    index,
                    "thang_9"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_9", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_9", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
                disabled={disableMonth789}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 10</p>,
      dataIndex: "10",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_10"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_10"),
                    index,
                    "thang_10"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_10", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_10", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 11</p>,
      dataIndex: "11",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_11"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_11"),
                    index,
                    "thang_11"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_11", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_11", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tháng 12</p>,
      dataIndex: "12",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="thang_12"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                onChange={(e) =>
                  sumData(
                    e,
                    formEditRevenue.getFieldValue("thang_12"),
                    index,
                    "thang_12"
                  )
                }
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  } if (
                    charCode === 32 &&
                    formEditRevenue.getFieldValue("thang_12", index) !== undefined
                  ) {
                    sumData(
                      e,
                      formEditRevenue.getFieldValue("thang_12", index),
                      index
                    )
                  }
                }}
                min={0}
                controls={false}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Grand Total</p>,
      dataIndex: "Grand Total",
      key: "2",
      width: 100,
      align: "right",
      fixed: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="Grand_Total"
              style={{ height: 7 }}
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  }
                }}
                min={0}
                controls={false}
                disabled
              />
            </Form.Item>
          ) : (
            <>
              <p>{value.toLocaleString()}</p>
            </>
          )}
        </>
      ),
    },

    {
      title: <p style={{ textAlign: "center" }}>Grand Total (VND)</p>,
      dataIndex: "Grand Total (VND)",
      key: "2",
      width: 120,
      align: "right",
      fixed: "right",
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ? (
            <Form.Item
              name="Grand_Total_VND"
              style={{ height: 7 }}
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  }
                }}
                min={0}
                controls={false}
                disabled
              />
            </Form.Item>
          ) : (
            <>
              <p>{value.toLocaleString()}</p>
            </>
          )}
        </>
      ),
    },

    {
      title: "Action",
      key: "operation",
      align: "center",
      fixed: "right",
      width: 80,
      render: (value, item, index) => (
        <>
          {isEditRevenue === true && index === indexRow ?
            <Form.Item style={{ height: 7, width: "100%" }}>
              <IconButton type="submit">
                <CheckOutlined style={{ color: "green" }} />
              </IconButton>
              <IconButton type="button" onClick={handleCancelCreateBill}>
                <CloseOutlined style={{ color: "red" }} />
              </IconButton>
            </Form.Item> :

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "15%" }}>
              {lsPermissions.includes('Chỉnh sửa doanh thu dự kiến Operation') === true ?
                <Tooltip placement="topLeft" title={"Chỉnh sửa doanh thu"}>
                  <img
                    src={EditIcon}
                    alt=""
                    onClick={() => showEditRevenue(valueVer, value, index)}
                    value={selectedOption}
                    onChange={ (value) => handleOptionChange(value)}
                    style={{ cursor: "pointer" }}
                  ></img>
                </Tooltip>
                : null}
              <Tooltip placement="top" title={"Lịch sử thao tác"}>
                <HistoryOutlined
                  onClick={() => showModalLogDetail(value)}
                  style={{ color: "green", fontSize: 20, cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          }
        </>
      ),
    },
  ];

  const handleCancelCreateBill = () => {
    setIsEditRevenue(false);
    formEditRevenue.resetFields();
  };

  const showModalLogDetail = (value) => {
    setDataLogDetail({
      id: value.id,
      year: valuesDetail.date.$y,
      ver: valuesDetail.ver,
    })
    setIsOpenModalLogDetail(true)
  }

  const [listGrandTotal, setListGrandTotal] = useState([])
  
  const showEditRevenue = (valueVer, value, index) => {
    // console.log("valueVer: ", valueVer);
    setSelectedOption(parseInt(valueVer));
    if (valueVer === 1) {
      setDisableMonth123(true);
      setDisableMonth456(false);
      setDisableMonth789(false);
    } else if (valueVer === 2) {
      setDisableMonth123(true);
      setDisableMonth456(true);
      setDisableMonth789(false);
    } else if (valueVer === 3) {
      setDisableMonth123(true);
      setDisableMonth456(true);
      setDisableMonth789(true);
    } else {
      setDisableMonth123(false);
      setDisableMonth456(false);
      setDisableMonth789(false);
    }
    
    formEditRevenue.setFieldsValue({
      thang_1: value["1"],
      thang_2: value["2"],
      thang_3: value["3"],
      thang_4: value["4"],
      thang_5: value["5"],
      thang_6: value["6"],
      thang_7: value["7"],
      thang_8: value["8"],
      thang_9: value["9"],
      thang_10: value["10"],
      thang_11: value["11"],
      thang_12: value["12"],
      id: value["id"],
      Grand_Total: value["Grand Total"],
      Grand_Total_VND: value["Grand Total (VND)"],
    })

    setOldDataDetail({
      thang_1_old: value["1"],
      thang_2_old: value["2"],
      thang_3_old: value["3"],
      thang_4_old: value["4"],
      thang_5_old: value["5"],
      thang_6_old: value["6"],
      thang_7_old: value["7"],
      thang_8_old: value["8"],
      thang_9_old: value["9"],
      thang_10_old: value["10"],
      thang_11_old: value["11"],
      thang_12_old: value["12"],
    })
    setIndexRow(index);
    setIsEditRevenue(true)
  }

  const onFinishImport = (values) => {
    setLoadingConfirm(true);
    setIsOpenExpectedRevenue(false);
    const yearDetail = values.date.$y;
    GetRevenueVer({
      ver: values.ver,
      year: yearDetail,
    })
      .then((res) => {
        // res.data.message.listProject.map(item => {})
        setValuesDetail(values);
        setDataRevenue(res.data.message.listProject)
        setIsOpenEditExpectedRevenue(false);
        setLoadingConfirm(false);
        getColumnFilters(res.data.message.listProject)
        // let arrGrandTotal = []

        // for (let i = 0; i < res.data.message.listProject.length; i++) {
        //   let valuesGrandTotal = Object.values(res.data.message.listProject[i]);

        //   valuesGrandTotal.splice(-3)

        //   let sum = valuesGrandTotal.reduce((accumulator, value) => {
        //     return accumulator + value;
        //   }, 0);
        //   arrGrandTotal.push(sum)
        //   console.log(sum)
        // }

        // console.log(arrGrandTotal)

        // formEditRevenue.setFieldValue("grand_total", arrGrandTotal)

        // setListGrandTotal(arrGrandTotal)

        setDateConfirm(yearDetail)
        setVerConfirm(values.ver)

        setIsEditRevenue(false)
      })
      .catch((err) => {
        setLoadingConfirm(false);
      });
  };

  useEffect(() => {
    if (dataDetailProject.length === 1) {
      setCheckExpectedRevenue(true);
    } else {
      setCheckExpectedRevenue(false);
    }
  }, [dataDetailProject]);

  const fetchVer = (value) => {
    console.log(dayjs().$y)
    GetVerApi({
      year: value
    }).then(res => {
      formGetDataDetail.setFieldValue("ver", res.data.ver);
    }).catch(err => {
      console.log(err)
    })
  }


  useEffect(() => {
    // formExpectedRevenue.resetFields();
    // fetchVer(dayjs().$y)
    formGetDataDetail.setFieldValue("ver", Math.floor(dateValue.getMonth() / 3 ));
    formGetDataDetail.setFieldValue("date", dayjs());
  }, []);

  useEffect(() => {
    const listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const monthNow = dayjs().format("M");
    const listInputDisable = [];
    if (isOpenEditExpectedRevenue === true) {
      for (let i = 0; i < listMonth.length; i++) {
        if (listMonth[i] < monthNow) {

          listInputDisable.push(listMonth[i]);
        }
      }
    }
    setListDisable(listInputDisable);
  }, [isOpenEditExpectedRevenue]);

  const showCreateMultipleBill = (value, index) => {
    setIsOpenModalCreateMultipleBill(true);
    setIsModalOpenAllRevenue(true)
  };

  const onFinishEditRevenue = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("thang_1", values.thang_1);
    data.append("thang_2", values.thang_2);
    data.append("thang_3", values.thang_3);
    data.append("thang_4", values.thang_4);
    data.append("thang_5", values.thang_5);
    data.append("thang_6", values.thang_6);
    data.append("thang_7", values.thang_7);
    data.append("thang_8", values.thang_8);
    data.append("thang_9", values.thang_9);
    data.append("thang_10", values.thang_10);
    data.append("thang_11", values.thang_11);
    data.append("thang_12", values.thang_12);

    data.append("thang_1_old", oldDataDetail.thang_1_old);
    data.append("thang_2_old", oldDataDetail.thang_2_old);
    data.append("thang_3_old", oldDataDetail.thang_3_old);
    data.append("thang_4_old", oldDataDetail.thang_4_old);
    data.append("thang_5_old", oldDataDetail.thang_5_old);
    data.append("thang_6_old", oldDataDetail.thang_6_old);
    data.append("thang_7_old", oldDataDetail.thang_7_old);
    data.append("thang_8_old", oldDataDetail.thang_8_old);
    data.append("thang_9_old", oldDataDetail.thang_9_old);
    data.append("thang_10_old", oldDataDetail.thang_10_old);
    data.append("thang_11_old", oldDataDetail.thang_11_old);
    data.append("thang_12_old", oldDataDetail.thang_12_old);

    data.append("id_project", formEditRevenue.getFieldValue("id"));
    data.append("ver", valuesDetail.ver);
    data.append("year", valuesDetail.date.$y);

    let call = document.getElementById("btn-confirm");

    EditRevenueVer(data)
      .then((res) => {
        call.addEventListener("click", onFinishImport(valuesDetail));
        formEditRevenue.resetFields();
        setIsEditRevenue(false)
        // setLoadingBtnDtdk(false);

        if (res.data.message === "no") {
          openNotificationWithIcon(
            "error",
            "Tổng doanh thu không được <= 0"
          );
        } else {
          openNotificationWithIcon(
            "success",
            "Chỉnh sửa doanh thu dự kiến thành công"
          );
        }


      })
      .catch((err) => {
        // setLoadingBtnDtdk(false);
        openNotificationWithIcon(
          "error",
          "Chỉnh sửa doanh thu dự kiến thất bại"
        );
      });
  }

  const changePagination = (pagi, filters, sorter) => {
    setIsEditRevenue(false)
    setIndexPagi(pagi)
  }

  const DownloadExcel = () => {
    setLoadingDownload(true)
    DownloadRevenueVer({
      ver: valuesDetail.ver,
      year: valuesDetail.date.$y,
      message: "download",
    }).then((res) => {
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = `${localhost}/api/get_revenue_ver?ver=${valuesDetail.ver}&year=${valuesDetail.date.$y}&message=download`;
      // link.setAttribute("download", "dttt.xlsx"); // Set the desired file name and extension
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      // Clean up the temporary URL and link
      URL.revokeObjectURL(url);
      document.body.removeChild(link);

      setLoadingDownload(false)
    }).catch(err => {
      setLoadingDownload(false)
      openNotificationWithIcon("error", "Download thất bại")
    })
  }

  const downloadFileWithToken = async (token, filename) => {
    try {
      // setLoadings(!loadings);
      const response = await axios.get(
        `${localhost}/api/get_revenue_ver?ver=${valuesDetail.ver}&year=${valuesDetail.date.$y}&message=download`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
      // setLoadings(false);
    } catch (error) {
      // setLoadings(false);
      // ToastError.fire({
      //   icon: "error",
      //   title: "Download không thành công!",
      // });
    }
  };

  const handleDownload = () => {
    function get_cookie(cookie_name) {
      const value = "; " + document.cookie;
      const parts = value.split("; " + cookie_name + "=");
      if (parts.length > 1) return parts.pop().split(";").shift();
    }

    var uuid_value = get_cookie("token_qldt");
    const authToken = uuid_value;
    const filename = `expected_revenue_${valuesDetail.date.$y}_ver1.${valuesDetail.ver}.xlsx`;

    downloadFileWithToken(authToken, filename);
  };

  const loadVer = (value) => {
    console.log(value)
    // fetchVer(value.$y)
    formGetDataDetail.setFieldValue("ver", Math.floor(dateValue.getMonth() / 3 ));
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "12vh",
          }}
        >
          <div className="get-detail-pj">
            <Form
              form={formGetDataDetail}
              layout="vertical"
              onFinish={onFinishImport}
              // onFinishFailed={onFinishFailed}
              validateMessages={validateMessages}
              className="custom-form"
              key="formImport"
            >
              <Row className="params-pj" style={{ paddingLeft: "2%", justifyContent: "flex-start" }}>
                <Col span={4}>
                  <Form.Item
                    name="date"
                    label={<p className="title-detail-pj">Năm dự án</p>}
                    key="date"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      style={{
                        height: "4vh",
                        width: "100%",
                        // border:
                        //   "1px solid var(--neutral-sub-text-4, #9199B1)",
                      }}
                      allowClear={false}
                      picker="year"
                      defaultValue={dayjs()}
                      onChange={loadVer}
                    />
                  </Form.Item>

                </Col>
                <Col span={4} offset={1}>
                  <Form.Item
                    name="ver"
                    label={<p className="title-detail-pj">Ver</p>}
                    key="ver"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Chọn Ver"
                      key="ver"
                      style={{ height: "4vh" }}
                      // allowClear
                      maxTagCount="responsive"
                      optionFilterProp="children"
                      className="custom-select"
                      // showSearch
                      // optionLabelProp="data-label"
                      defaultValue={defaultQuarter}
                    // getPopupContainer={getPopupContainer}
                    value={selectedOption}
                    onChange={ (value) => handleOptionChange(value)}
                    >
                      {quarters.map((item, index) => (
                        <Option key={index} value={item.value}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={2} offset={1}>
                  <Button
                    htmlType="submit"
                    id="btn-confirm"
                    loading={loadingConfirm}
                    style={{
                      color: "#fff",
                      backgroundColor: "#5465EF",
                      height: "4vh",
                      width: "100%",
                      // marginLeft: 50,
                    }}
                    className="btn-revenue"
                  >
                    CONFIRM
                  </Button>
                </Col>

                {dataRevenue.length > 0 ?
                  <Col span={2} offset={1}>
                    <Button onClick={handleDownload}
                      htmlType="button"
                      loading={loadingDownload}
                      style={{
                        color: "#fff",
                        height: "4vh",
                        width: "100%",
                        // marginLeft: 50,
                      }}
                      className="btn-revenue"
                      type="primary">
                      Download
                    </Button>
                  </Col>
                  : null}
              </Row>
            </Form>

            {lsPermissions.includes('Tạo doanh thu hàng loạt Operation') === true ?
              <Row className="btn-revenue-row-op">
                <Col span={8} style={{ display: "flex" }}>
                  <Button
                    className="btn-downloadFile"
                    onClick={(value, index) =>
                      showCreateMultipleBill(value, index)
                    }
                    style={{ color: "#337aee" }}
                  >
                    CREATE MULTIPLE REVENUE
                    <img
                      style={{ marginLeft: 8 }}
                      src={CreateBillMuchIcon}
                      alt=""
                    ></img>
                  </Button>
                </Col>
                <Col span={15}></Col>
              </Row>
              : null}
          </div>
        </div>



        <div className="table-sale-ope" style={{ marginTop: "2%" }}>
          <Form
            form={formEditRevenue}
            layout="vertical"
            autoComplete="off"
            onFinish={onFinishEditRevenue}
            // onFinishFailed={onFinishFailed}
            validateMessages={validateMessages}
            initialValues={{
              remember: true,
            }}
            className="custom-form"
            key="formImport"
            style={{ width: "99%", marginLeft: "1%" }}
          >
            <Table
              columns={columns}
              dataSource={dataRevenue}
              style={{ width: "98%" }}
              onChange={changePagination}

              scroll={{
                x: 2000,
                y: window.screen.availHeight > 850 ? "56vh" : "53vh",
              }}
              // pagination={false}
              className="custom-table table-striped-rows"
              bordered
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              pagination={{ defaultPageSize: 20 }}
            />
          </Form>
        </div>
      </div>

      <ModalAllRevenue
        isModalOpenAllRevenue={isModalOpenAllRevenue}
        setIsModalOpenAllRevenue={setIsModalOpenAllRevenue}
      />

      <ModalLogDetailRevenue
        isOpenModalLogDetail={isOpenModalLogDetail}
        setIsOpenModalLogDetail={setIsOpenModalLogDetail}
        dataLogDetail={dataLogDetail}
      />
    </>
  );
}