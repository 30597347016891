import { authAxios } from "../api/axiosClient";
import { listStatement } from "../constants";

export const GetListStatement = (params = {}) => {
  const url = listStatement;
  return authAxios().get(url, { params });
};

export const PostListStatement = (params = {}) => {
  const url = listStatement;
  return authAxios().post(url, params);
};
