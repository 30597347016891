import { useState } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Box, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../theme';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import LogoPage from '../images/LOGO IMAGE TRẮNG.svg';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: '#fff'
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ valueTabs, isSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [lsPermissionsType, setLsPermissionsType] = useState([]);
  const [selected, setSelected] = useState(
    (window.location.pathname === '/' && 'Pivot Management') ||
      (window.location.pathname === '/pivot-sales' && 'Pivot Sale') ||
      (window.location.pathname === '/pj-detail' && 'Project Detail') ||
      (window.location.pathname === '/pj-detail-sale' && 'Project Detail Sale') ||
      (window.location.pathname === '/saoke' && 'Bank Statement') ||
      (window.location.pathname === '/check' && 'Check') ||
      (window.location.pathname === '/lastcheck' && 'Last check') ||
      (window.location.pathname === '/entry' && 'Entry') ||
      (window.location.pathname === '/revenue' && 'Revenue') ||
      (window.location.pathname === '/dttt' && 'DTTT') ||
      (window.location.pathname === '/kh_plan' && 'KH PLAN') ||
      (window.location.pathname === '/dtddtt' && 'DT DD&TT')
  );

  const userInfo = useSelector(state => state.getUserInfo.userInfo);

  useEffect(() => {
    if (valueTabs === 0) {
      setSelected(
        (window.location.pathname === '/pj-detail' && 'Project Detail') ||
          (window.location.pathname === '/' && 'Pivot Management') ||
          (window.location.pathname === '/saoke' && 'Bank Statement') ||
          (window.location.pathname === '/check' && 'Check') ||
          (window.location.pathname === '/lastcheck' && 'Last check') ||
          (window.location.pathname === '/entry' && 'Entry') ||
          (window.location.pathname === '/revenue' && 'Revenue') ||
          (window.location.pathname === '/dttt' && 'DTTT') ||
          (window.location.pathname === '/dtddtt' && 'DT DD&TT')
      );
    } else if (valueTabs === 1) {
      setSelected(
        (window.location.pathname === '/pj-detail-sale' && 'Project Detail Sale') ||
          (window.location.pathname === '/pivot-sales' && 'Pivot Sale')
      );
    }
  }, [valueTabs]);

  useEffect(() => {
    if (userInfo) {
      // setIsSuperA(userInfo.is_superuser);
      const arrPermission = userInfo.permission.map((item, index) => {
        return item.name;
      });

      if (arrPermission !== undefined) {
        setLsPermissionsType([...arrPermission]);
      }
    }
  }, [userInfo]);

  return (
    <Box
      sx={{
        '& .pro-sidebar-inner': {
          background: `rgba(2, 15, 89, 1) !important`
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important'
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important'
        },
        '& .pro-inner-item:hover': {
          color: '#868dfb !important'
        },
        '& .pro-menu-item.active': {
          color: 'rgba(2, 15, 89, 1) !important'
        }
      }}
    >
      <ProSidebar collapsed={isCollapsed} className="ProSidebar">
        <Menu iconShape="square" className="MenuUl">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              color: colors.grey[100]
            }}
            className="MenuItemLogo"
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography variant="h3" color={colors.grey[100]} sx={{ margin: 'auto' }}>
                  <img src={LogoPage} alt="" />
                </Typography>
              </Box>
            )}
          </MenuItem>

          <Box className="BoxMenuItem">
            <Box className="BoxMenuItemTop">
              {valueTabs === 0 && (
                <>
                  {lsPermissionsType.includes('Xem Pivot Operation') === true && (
                    <Item
                      title="Pivot Management"
                      to="/"
                      icon={
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.2"
                            d="M19.2474 6.66376C18.9808 6.75841 18.6929 6.77557 18.4169 6.71324C18.141 6.65092 17.8884 6.51167 17.6883 6.31163C17.4883 6.1116 17.3491 5.85896 17.2867 5.58302C17.2244 5.30707 17.2416 5.01912 17.3362 4.75253C15.4754 3.38251 13.159 2.78068 10.8667 3.07169C8.57433 3.36269 6.48174 4.52424 5.02231 6.31576C3.56288 8.10727 2.84842 10.3915 3.02691 12.6953C3.2054 14.9991 4.26315 17.1461 5.98111 18.6914V18.6913C6.5458 17.5804 7.40704 16.6474 8.46939 15.9959C9.53175 15.3444 10.7537 14.9997 12 15C11.2583 15 10.5333 14.7801 9.91658 14.368C9.2999 13.956 8.81925 13.3703 8.53542 12.6851C8.25159 11.9998 8.17733 11.2458 8.32202 10.5184C8.46672 9.79098 8.82387 9.1228 9.34832 8.59835C9.87277 8.0739 10.541 7.71675 11.2684 7.57206C11.9958 7.42736 12.7498 7.50163 13.435 7.78545C14.1203 8.06928 14.7059 8.54993 15.118 9.16661C15.53 9.7833 15.75 10.5083 15.75 11.25C15.75 12.2446 15.3549 13.1984 14.6516 13.9016C13.9484 14.6049 12.9945 15 12 15C13.2462 14.9997 14.4682 15.3444 15.5305 15.9959C16.5929 16.6474 17.4541 17.5804 18.0188 18.6913C19.6803 17.1968 20.7263 15.1377 20.9534 12.9145C21.1805 10.6914 20.5724 8.46331 19.2474 6.66376Z"
                            fill="white"
                          />
                          <path
                            d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M5.98145 18.6913C6.54639 17.5806 7.40768 16.6478 8.46997 15.9963C9.53226 15.3448 10.7541 15 12.0003 15C13.2464 15 14.4683 15.3448 15.5306 15.9963C16.5929 16.6478 17.4542 17.5806 18.0191 18.6913"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 6.75C19.5784 6.75 20.25 6.07843 20.25 5.25C20.25 4.42157 19.5784 3.75 18.75 3.75C17.9216 3.75 17.25 4.42157 17.25 5.25C17.25 6.07843 17.9216 6.75 18.75 6.75Z"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 3.75V2.625"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17.4508 4.5L16.4766 3.9375"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17.4508 6L16.4766 6.5625"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 6.75V7.875"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.0488 6L21.0231 6.5625"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.0488 4.5L21.0231 3.9375"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.9361 10.9219C20.9787 11.2797 21.0001 11.6397 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91131 5.51983 5.50571 6.99987 4.51677C8.47991 3.52784 10.22 3 12 3C12.2816 3 12.56 3.01275 12.8352 3.03824"
                            stroke={selected === 'Pivot Management' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {lsPermissionsType.includes('Xem project detail Operation') === true && (
                    <Item
                      title="Project Detail"
                      to="/pj-detail"
                      icon={
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.2"
                            d="M15 3.75C15.488 4.39855 15.7512 5.18842 15.75 6V6.75H8.25V6C8.24877 5.18842 8.51205 4.39855 8.99995 3.75H5.25C5.15151 3.75 5.05398 3.76939 4.96298 3.80708C4.87199 3.84477 4.78931 3.90002 4.71966 3.96966C4.65002 4.03931 4.59477 4.12199 4.55708 4.21298C4.51939 4.30398 4.5 4.40151 4.5 4.5V20.25C4.5 20.3485 4.51939 20.446 4.55708 20.537C4.59477 20.628 4.65002 20.7107 4.71966 20.7803C4.78931 20.85 4.87199 20.9052 4.96298 20.9429C5.05398 20.9806 5.15151 21 5.25 21H18.75C18.8485 21 18.946 20.9806 19.037 20.9429C19.128 20.9052 19.2107 20.85 19.2803 20.7803C19.35 20.7107 19.4052 20.628 19.4429 20.537C19.4806 20.446 19.5 20.3485 19.5 20.25V4.5C19.5 4.40151 19.4806 4.30398 19.4429 4.21298C19.4052 4.12199 19.35 4.03931 19.2803 3.96966C19.2107 3.90002 19.128 3.84477 19.037 3.80708C18.946 3.76939 18.8485 3.75 18.75 3.75H15Z"
                            fill="white"
                          />
                          <path
                            d="M9 14.25H15"
                            stroke={selected === 'Project Detail' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 11.25H15"
                            stroke={selected === 'Project Detail' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.0002 3.75H18.75C18.9489 3.75 19.1397 3.82902 19.2803 3.96967C19.421 4.11032 19.5 4.30109 19.5 4.5V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V4.5C4.5 4.30109 4.57902 4.11032 4.71967 3.96967C4.86032 3.82902 5.05109 3.75 5.25 3.75H8.9998"
                            stroke={selected === 'Project Detail' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.25 6.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V6.75H8.25Z"
                            stroke={selected === 'Project Detail' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {(lsPermissionsType.includes('Upload file sao kê Operation') === true ||
                    lsPermissionsType.includes('LC phiếu tính tiền Operation') === true ||
                    lsPermissionsType.includes('Tạo doanh thu hàng loạt Operation') === true ||
                    lsPermissionsType.includes('Tạo phiếu tính tiền Operation') === true ||
                    lsPermissionsType.includes('Check phiếu tính tiền Operation') === true || lsPermissionsType.includes('Xem doanh thu dự kiến Operation') === true) === true && (
                    <SubMenu
                      title="Input"
                      icon={
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 22H15C21 22 22 22 22 15V9C22 2.5 22 2 15 2H12H9C2.00003 2 1.99998 3 2 9V15C2 21.5 1.99999 22 9 22Z"
                            fill="#454475"
                            stroke={selected === 'Input' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M6 16.5098C9.89 17.8098 14.11 17.8098 18 16.5098H6Z" fill="#454475" />
                          <path
                            d="M6 16.5098C9.89 17.8098 14.11 17.8098 18 16.5098"
                            stroke={selected === 'Input' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.4199 13.6599H14.6599V9.41992"
                            stroke={selected === 'Input' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M14.66 13.66L9 8"
                            stroke={selected === 'Input' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      style={{
                        color: 'rgb(255, 255, 255)'
                      }}
                      defaultOpen={
                        (window.location.pathname === '/revenue') === true ||
                        (window.location.pathname === '/entry') === true ||
                        (window.location.pathname === '/saoke') === true ||
                        (window.location.pathname === '/check') === true ||
                        (window.location.pathname === '/lastcheck') === true
                          ? true
                          : false
                      }
                    >
                      {(lsPermissionsType.includes('Tạo doanh thu hàng loạt Operation') === true  || lsPermissionsType.includes('Xem doanh thu dự kiến Operation') === true  )&& (
                        <Item
                          title="Expected Revenue"
                          to="/revenue"
                          icon={<ReceiptOutlinedIcon />}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      )}
                      {(lsPermissionsType.includes('Tạo phiếu tính tiền Operation') === true)&& (
                        <Item
                          title="Entry"
                          to="/entry"
                          icon={<ReceiptOutlinedIcon />}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      )}
                      {lsPermissionsType.includes('Check phiếu tính tiền Operation') === true && (
                        <Item
                          title="Check"
                          to="/check"
                          icon={<ReceiptOutlinedIcon />}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      )}
                      {lsPermissionsType.includes('LC phiếu tính tiền Operation') === true && (
                        <Item
                          title="Last check"
                          to="/lastcheck"
                          icon={<ReceiptOutlinedIcon />}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      )}
                      {lsPermissionsType.includes('Upload file sao kê Operation') === true && (
                        <Item
                          title="Bank Statement"
                          to="/saoke"
                          icon={<ReceiptOutlinedIcon />}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      )}
                    </SubMenu>
                  )}
                  {(lsPermissionsType.includes('Xuất Output DTTT Operation') === true || lsPermissionsType.includes('Xuất Output DT DD&TT Operation') === true )&& (
                    <SubMenu
                      title="Report"
                      icon={
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.2305 20.77L20.7705 17.23"
                            stroke={selected === 'Report' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.7705 20.77L17.2305 17.23"
                            stroke={selected === 'Report' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 10.5V13.5"
                            stroke={selected === 'Report' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 10.5V13.5"
                            stroke={selected === 'Report' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17 10.5V13.5"
                            stroke={selected === 'Report' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22 13V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H13"
                            stroke={selected === 'Report' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      style={{
                        color: 'rgb(255, 255, 255)'
                      }}
                      defaultOpen={
                        (window.location.pathname === '/dttt') === true ||
                        (window.location.pathname === '/dtddtt') === true
                          ? true
                          : false
                      }
                    >
                      {(lsPermissionsType.includes('Xuất Output DTTT Operation')) &&
                      <Item
                        title="DTTT"
                        to="/dttt"
                        icon={
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                              stroke={selected === 'DTTT' ? 'rgba(2, 15, 89, 1)' : 'white'}
                              strokeWidth="1.5"
                            />
                          </svg>
                        }
                        selected={selected}
                        setSelected={setSelected}
                      />
                      }
                      {(lsPermissionsType.includes('Xuất Output DT DD&TT Operation')) &&
                      <Item
                        title="DT DD&TT"
                        to="/dtddtt"
                        icon={
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                              stroke={selected === 'DT DD&TT' ? 'rgba(2, 15, 89, 1)' : 'white'}
                              strokeWidth="1.5"
                            />
                          </svg>
                        }
                        selected={selected}
                        setSelected={setSelected}
                      />
                      }
                    </SubMenu>
                  )}

                  {lsPermissionsType.includes('View log Operation') === true && (
                    <Item
                      title="Logs"
                      to="/logs"
                      icon={
                        
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.2"
                            d="M15 3.75C15.488 4.39855 15.7512 5.18842 15.75 6V6.75H8.25V6C8.24877 5.18842 8.51205 4.39855 8.99995 3.75H5.25C5.15151 3.75 5.05398 3.76939 4.96298 3.80708C4.87199 3.84477 4.78931 3.90002 4.71966 3.96966C4.65002 4.03931 4.59477 4.12199 4.55708 4.21298C4.51939 4.30398 4.5 4.40151 4.5 4.5V20.25C4.5 20.3485 4.51939 20.446 4.55708 20.537C4.59477 20.628 4.65002 20.7107 4.71966 20.7803C4.78931 20.85 4.87199 20.9052 4.96298 20.9429C5.05398 20.9806 5.15151 21 5.25 21H18.75C18.8485 21 18.946 20.9806 19.037 20.9429C19.128 20.9052 19.2107 20.85 19.2803 20.7803C19.35 20.7107 19.4052 20.628 19.4429 20.537C19.4806 20.446 19.5 20.3485 19.5 20.25V4.5C19.5 4.40151 19.4806 4.30398 19.4429 4.21298C19.4052 4.12199 19.35 4.03931 19.2803 3.96966C19.2107 3.90002 19.128 3.84477 19.037 3.80708C18.946 3.76939 18.8485 3.75 18.75 3.75H15Z"
                            fill="white"
                          />
                          <path
                            d="M9 14.25H15"
                            stroke={selected === 'Logs' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 11.25H15"
                            stroke={selected === 'Logs' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.0002 3.75H18.75C18.9489 3.75 19.1397 3.82902 19.2803 3.96967C19.421 4.11032 19.5 4.30109 19.5 4.5V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V4.5C4.5 4.30109 4.57902 4.11032 4.71967 3.96967C4.86032 3.82902 5.05109 3.75 5.25 3.75H8.9998"
                            stroke={selected === 'Logs' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.25 6.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V6.75H8.25Z"
                            stroke={selected === 'Logs' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {/* {lsPermissionsType.includes("Xuất Output Operation") ===
                    true && (
                    <>
                      <SubMenu
                        title="DTTT"
                        icon={
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22 13V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H13"
                              stroke={
                                selected === "DTTT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22 13V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H13"
                              stroke={
                                selected === "DTTT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22 13V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H13"
                              stroke={
                                selected === "DTTT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16 19.43L17.58 21L21 17"
                              stroke={
                                selected === "DTTT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 10.5V13.5"
                              stroke={
                                selected === "DTTT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 10.5V13.5"
                              stroke={
                                selected === "DTTT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M17 10.5V13.5"
                              stroke={
                                selected === "DTTT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        style={{
                          color: "rgb(255, 255, 255)",
                        }}
                        defaultOpen={
                          (window.location.pathname === "/dttt/product") ===
                            true ||
                          (window.location.pathname === "/dttt/client-code") ===
                            true ||
                          (window.location.pathname === "/dttt/department") ===
                            true ||
                          (window.location.pathname === "/dttt/currency") ===
                            true ||
                          (window.location.pathname === "/dttt/market") ===
                            true ||
                          (window.location.pathname === "/dttt/bankAC") === true
                            ? true
                            : false
                        }
                      >
                        <Item
                          title="Product/Service"
                          to="/dttt/product"
                          icon={
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                                stroke="white"
                                strokeWidth="1.5"
                              />
                            </svg>
                          }
                          selected={selected}
                          setSelected={setSelected}
                        />
                        <Item
                          title="Client Code"
                          to="/dttt/client-code"
                          icon={
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                                stroke="white"
                                strokeWidth="1.5"
                              />
                            </svg>
                          }
                          selected={selected}
                          setSelected={setSelected}
                        />
                        <Item
                          title="Department"
                          to="/dttt/department"
                          icon={
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                                stroke="white"
                                strokeWidth="1.5"
                              />
                            </svg>
                          }
                          selected={selected}
                          setSelected={setSelected}
                        />
                        <Item
                          title="Currency"
                          to="/dttt/currency"
                          icon={
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                                stroke="white"
                                strokeWidth="1.5"
                              />
                            </svg>
                          }
                          selected={selected}
                          setSelected={setSelected}
                        />
                        <Item
                          title="Bank AC"
                          to="/dttt/bankAC"
                          icon={
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                                stroke="white"
                                strokeWidth="1.5"
                              />
                            </svg>
                          }
                          selected={selected}
                          setSelected={setSelected}
                        />
                        <Item
                          title="Market"
                          to="/dttt/market"
                          icon={
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                                stroke="white"
                                strokeWidth="1.5"
                              />
                            </svg>
                          }
                          selected={selected}
                          setSelected={setSelected}
                        />
                      </SubMenu>

                      <SubMenu
                        title="DT DD&TT"
                        icon={
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.2305 20.77L20.7705 17.23"
                              stroke={
                                selected === "DT DD&TT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M20.7705 20.77L17.2305 17.23"
                              stroke={
                                selected === "DT DD&TT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 10.5V13.5"
                              stroke={
                                selected === "DT DD&TT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 10.5V13.5"
                              stroke={
                                selected === "DT DD&TT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M17 10.5V13.5"
                              stroke={
                                selected === "DT DD&TT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M22 13V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H13"
                              stroke={
                                selected === "DT DD&TT"
                                  ? "rgba(2, 15, 89, 1)"
                                  : "white"
                              }
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                        style={{
                          color: "rgb(255, 255, 255)",
                        }}
                        defaultOpen={
                          (window.location.pathname === "/kh_plan") === true ||
                          (window.location.pathname === "/team_dt_dd_tt") ===
                            true
                            ? true
                            : false
                        }
                      >
                        <Item
                          title="KH PLAN"
                          to="/kh_plan"
                          icon={
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                                stroke={
                                  selected === "KH PLAN"
                                    ? "rgba(2, 15, 89, 1)"
                                    : "white"
                                }
                                strokeWidth="1.5"
                              />
                            </svg>
                          }
                          selected={selected}
                          setSelected={setSelected}
                        />
                        <Item
                          title="TEAM"
                          to="/team_dt_dd_tt"
                          icon={
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.99996 7.99996C9.99996 6.8953 9.10446 5.9998 7.9998 5.9998C6.89514 5.9998 5.99963 6.8953 5.99963 7.99996C5.99963 9.10462 6.89514 10.0001 7.9998 10.0001C9.10446 10.0001 9.99996 9.10462 9.99996 7.99996Z"
                                stroke={
                                  selected === "TEAM"
                                    ? "rgba(2, 15, 89, 1)"
                                    : "white"
                                }
                                strokeWidth="1.5"
                              />
                            </svg>
                          }
                          selected={selected}
                          setSelected={setSelected}
                        />
                      </SubMenu>
                    </>
                  )} */}
                </>
              )}
              {valueTabs === 1 && (
                <>
                  {lsPermissionsType.includes('Xem Pivot Sales') === true && (
                    <Item
                      title="Pivot Sale"
                      to="/pivot-sales"
                      icon={
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.2"
                            d="M19.2474 6.66376C18.9808 6.75841 18.6929 6.77557 18.4169 6.71324C18.141 6.65092 17.8884 6.51167 17.6883 6.31163C17.4883 6.1116 17.3491 5.85896 17.2867 5.58302C17.2244 5.30707 17.2416 5.01912 17.3362 4.75253C15.4754 3.38251 13.159 2.78068 10.8667 3.07169C8.57433 3.36269 6.48174 4.52424 5.02231 6.31576C3.56288 8.10727 2.84842 10.3915 3.02691 12.6953C3.2054 14.9991 4.26315 17.1461 5.98111 18.6914V18.6913C6.5458 17.5804 7.40704 16.6474 8.46939 15.9959C9.53175 15.3444 10.7537 14.9997 12 15C11.2583 15 10.5333 14.7801 9.91658 14.368C9.2999 13.956 8.81925 13.3703 8.53542 12.6851C8.25159 11.9998 8.17733 11.2458 8.32202 10.5184C8.46672 9.79098 8.82387 9.1228 9.34832 8.59835C9.87277 8.0739 10.541 7.71675 11.2684 7.57206C11.9958 7.42736 12.7498 7.50163 13.435 7.78545C14.1203 8.06928 14.7059 8.54993 15.118 9.16661C15.53 9.7833 15.75 10.5083 15.75 11.25C15.75 12.2446 15.3549 13.1984 14.6516 13.9016C13.9484 14.6049 12.9945 15 12 15C13.2462 14.9997 14.4682 15.3444 15.5305 15.9959C16.5929 16.6474 17.4541 17.5804 18.0188 18.6913C19.6803 17.1968 20.7263 15.1377 20.9534 12.9145C21.1805 10.6914 20.5724 8.46331 19.2474 6.66376Z"
                            fill="white"
                          />
                          <path
                            d="M12 15C14.0711 15 15.75 13.3211 15.75 11.25C15.75 9.17893 14.0711 7.5 12 7.5C9.92893 7.5 8.25 9.17893 8.25 11.25C8.25 13.3211 9.92893 15 12 15Z"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                          />
                          <path
                            d="M5.98145 18.6913C6.54639 17.5806 7.40768 16.6478 8.46997 15.9963C9.53226 15.3448 10.7541 15 12.0003 15C13.2464 15 14.4683 15.3448 15.5306 15.9963C16.5929 16.6478 17.4542 17.5806 18.0191 18.6913"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 6.75C19.5784 6.75 20.25 6.07843 20.25 5.25C20.25 4.42157 19.5784 3.75 18.75 3.75C17.9216 3.75 17.25 4.42157 17.25 5.25C17.25 6.07843 17.9216 6.75 18.75 6.75Z"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 3.75V2.625"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17.4508 4.5L16.4766 3.9375"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M17.4508 6L16.4766 6.5625"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.75 6.75V7.875"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.0488 6L21.0231 6.5625"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.0488 4.5L21.0231 3.9375"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.9361 10.9219C20.9787 11.2797 21.0001 11.6397 21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91131 5.51983 5.50571 6.99987 4.51677C8.47991 3.52784 10.22 3 12 3C12.2816 3 12.56 3.01275 12.8352 3.03824"
                            stroke={selected === 'Pivot Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {lsPermissionsType.includes('Xem project detail Sales') === true && (
                    <Item
                      title="Project Detail Sale"
                      to="/pj-detail-sale"
                      icon={
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            opacity="0.2"
                            d="M15 3.75C15.488 4.39855 15.7512 5.18842 15.75 6V6.75H8.25V6C8.24877 5.18842 8.51205 4.39855 8.99995 3.75H5.25C5.15151 3.75 5.05398 3.76939 4.96298 3.80708C4.87199 3.84477 4.78931 3.90002 4.71966 3.96966C4.65002 4.03931 4.59477 4.12199 4.55708 4.21298C4.51939 4.30398 4.5 4.40151 4.5 4.5V20.25C4.5 20.3485 4.51939 20.446 4.55708 20.537C4.59477 20.628 4.65002 20.7107 4.71966 20.7803C4.78931 20.85 4.87199 20.9052 4.96298 20.9429C5.05398 20.9806 5.15151 21 5.25 21H18.75C18.8485 21 18.946 20.9806 19.037 20.9429C19.128 20.9052 19.2107 20.85 19.2803 20.7803C19.35 20.7107 19.4052 20.628 19.4429 20.537C19.4806 20.446 19.5 20.3485 19.5 20.25V4.5C19.5 4.40151 19.4806 4.30398 19.4429 4.21298C19.4052 4.12199 19.35 4.03931 19.2803 3.96966C19.2107 3.90002 19.128 3.84477 19.037 3.80708C18.946 3.76939 18.8485 3.75 18.75 3.75H15Z"
                            fill="white"
                          />
                          <path
                            d="M9 14.25H15"
                            stroke={selected === 'Project Detail Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 11.25H15"
                            stroke={selected === 'Project Detail Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15.0002 3.75H18.75C18.9489 3.75 19.1397 3.82902 19.2803 3.96967C19.421 4.11032 19.5 4.30109 19.5 4.5V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V4.5C4.5 4.30109 4.57902 4.11032 4.71967 3.96967C4.86032 3.82902 5.05109 3.75 5.25 3.75H8.9998"
                            stroke={selected === 'Project Detail Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.25 6.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V6.75H8.25Z"
                            stroke={selected === 'Project Detail Sale' ? 'rgba(2, 15, 89, 1)' : 'white'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                </>
              )}
              {/* {valueTabs === 2 && (
                
              )} */}

              {/* <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                Charts
              </Typography>
              <Item
                title="Bar Chart"
                to="/bar"
                icon={<BarChartOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Pie Chart"
                to="/pie"
                icon={<PieChartOutlineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}
            </Box>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
