import { authAxios } from "../api/axiosClient";
import { listService } from "../constants";

export const GetListService = (params = {}) => {
  const url = listService;
  return authAxios().get(url, { params });
};

export const PostListService = (params = {}) => {
  const url = listService;
  return authAxios().post(url, params);
};

export const GetIdServiceEditApi = (params = {}) => {
  const url = listService;
  return authAxios().get(url, { params });
};

export const PutServiceApi = (params = {}) => {
  const url = listService;
  return authAxios().post(url, params);
};
