import { Col, Form, Input, Modal, Row, Button, Select } from "antd";
import React, { memo, useState } from "react";
import { PostListProject } from "../../api/listProject";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const { Option } = Select;
const { TextArea } = Input;

const dataOptionTypeProject = [
  {
    id: 1,
    value: "Operation",
  },
  {
    id: 2,
    value: "Sales",
  },
];

const ModalAddProject = ({
  isModalOpenAddProject,
  handleOkAddProject,
  setIsModalOpenAddProject,
  fetchListDataMarket,
  isListClientCode,
  isListMarketCode,
  isListProductServiceCode,
  isListCurrencyCode,
  isListDealOwerCode,
  isListBankACCode,
  isListDepartmentCode,
}) => {
  const [form] = Form.useForm();
  const [searchKeywordClientCode, setSearchKeywordClientCode] = useState("");
  const [searchKeywordPS, setSearchKeywordPS] = useState("");
  const [loadings, setLoadings] = useState(false);

  const handleCancelAddProject = () => {
    setIsModalOpenAddProject(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);

    data.append("message", "create");
    data.append("project_name", values.project_name);
    data.append("client_id", values.client_id);
    data.append("market_id", values.market_id);
    data.append("productservice_id", values.productservice_id);
    data.append("typemoney_id", values.typemoney_id);
    data.append("dealower", values.dealower);
    data.append("bankac_id", values.bankac_id);
    data.append("detail_project", values.detail_project);
    data.append("type_project", values.type_project);
    data.append("department", values.department);

    // currentDuAn
    PostListProject(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Thêm mới Project thành công",
        });
        setLoadings(false);
        fetchListDataMarket();
        handleCancelAddProject();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  const handleSearchClientCode = (value) => {
    setSearchKeywordClientCode(value);
  };

  const handleSearchPS = (value) => {
    setSearchKeywordPS(value);
  };

  return (
    <Modal
      title="ADD PROJECT"
      open={isModalOpenAddProject}
      onOk={handleOkAddProject}
      onCancel={handleCancelAddProject}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Project Name"
              name="project_name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Project Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Project Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Client Code"
              name="client_id"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Client Code!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Client"
                showSearch
                onSearch={handleSearchClientCode}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={searchKeywordClientCode}
              >
                {isListClientCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.client_code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Type Project"
              name="type_project"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Type Project!",
                },
              ]}
            >
              <Select size="large" placeholder="Type Project">
                {dataOptionTypeProject.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Product/ Service"
              name="productservice_id"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Product/ Service!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Product/ Service"
                showSearch
                onSearch={handleSearchPS}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={searchKeywordPS}
              >
                {isListProductServiceCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.servicename}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Department"
              name="department"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Department!",
                },
              ]}
            >
              <Select size="large" placeholder="Department">
                {isListDepartmentCode.map((e) => (
                  <Option key={e.iddepart} value={e.iddepart}>
                    {e.codedepart}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Currency"
              name="typemoney_id"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Currency!",
                },
              ]}
            >
              <Select size="large" placeholder="Currency">
                {isListCurrencyCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Bank Account"
              name="bankac_id"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Bank Account!",
                },
              ]}
            >
              <Select size="large" placeholder="Bank Account">
                {isListBankACCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.codebank}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Market"
              name="market_id"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market!",
                },
              ]}
            >
              <Select size="large" placeholder="Market">
                {isListMarketCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.codemarket}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Deal Ower"
              name="dealower"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Deal Ower!",
                },
              ]}
            >
              <Select size="large" placeholder="Deal Ower">
                {isListDealOwerCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Project Business Description"
              name="detail_project"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Project Business Description!",
                },
              ]}
            >
              <TextArea
                // value={value}
                // onChange={(e) => setValue(e.target.value)}
                placeholder="Controlled autosize"
                autoSize={{
                  minRows: 4,
                  maxRows: 4,
                }}
                size="large"
              />
              {/* <Input size="large" placeholder="Project Business Description" /> */}
            </Form.Item>
          </Col>

          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelAddProject}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(ModalAddProject);
