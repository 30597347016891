/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import * as React from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  message,
} from "antd";
import {
  CloseCircleOutlined,
  DownOutlined,
  HistoryOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import "./Detail.css";
import dayjs from "dayjs";
import NoData from "../../../images/noData.svg";
import CreateBillIcon from "../../../images/CreateBill.svg";
import CreateBillMuchIcon from "../../../images/CreateBillMuch.svg";
import DownloadIcon from "../../../images/IconDownload.svg";
import RevenueIcon from "../../../images/IconRevenue.svg";

import { useState, useEffect } from "react";
import {
  CreateRevenue,
  GetDataDetailProject,
  GetFileExport,
  ReturnRevenueApi,
  UpdateRevenue,
} from "../../../api/detailProject";
import { openNotificationWithIcon } from "../../../Function";
import { useSelector } from "react-redux";
import { localhost } from "../../../server";
import { exportFileBill } from "../../../constants";
// import ModalAllRevenue from "../ProjectDetail/ModalAllRevenue";
import ModalCreateBill from "./ModalCreateBill";
import ModalLogDetail from "./ModalLogDetail";
import FormCreateRevenue from "./form_normal/FormCreateRevenue";
import FormEditRevenue from "./form_normal/FormEditRevenue";
import FormCreateRevenueResponsive from "./form_responsive/FormCreateRevenueResponsive";
import FormEditRevenueResponsive from "./form_responsive/FormEditRevenueResponsive";
import FormConfirm from "./FormConfirm";
import ModalCreateMultipleBill from "./ModalCreateMultipleBill";

const { Option } = Select;

const validateMessages = {
  required: "Không được để trống!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  pattern: {
    mismatch: "Chỉ được nhập số nguyên",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

export default function ProjectDetail() {
  const [loading, setLoading] = React.useState(true);
  const [loadingBtnDtdk, setLoadingBtnDtdk] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const [isOpenModalCreateBill, setIsOpenModalCreateBill] =
    React.useState(false);
  const [isOpenModalLogDetail, setIsOpenModalLogDetail] = React.useState(false);
  const [isOpenExpectedRevenue, setIsOpenExpectedRevenue] =
    React.useState(false);
  const [isOpenEditExpectedRevenue, setIsOpenEditExpectedRevenue] =
    React.useState(false);
  const [isOpenBtnEditExpectRevenue, setIsOpenBtnEditExpectRevenue] =
    useState(false);

  const [checkExpectedRevenue, setCheckExpectedRevenue] = React.useState(false);

  const [listClientOfUser, setListClientOfUser] = useState([]);
  const [listDataDep, setListDataDep] = React.useState([]);
  const [listDataProjectOfDep, setListDataProjectOfDep] = React.useState([]);

  const [dataPerMonth, setDataPerMonth] = useState();
  const [dataLogDetail, setDataLogDetail] = useState();
  const [userRole, setUserRole] = React.useState("");

  const [dataDetailProject, setDataDetailProject] = useState([]);
  const [dataBasicProject, setDataBasicProject] = useState();
  const [valuesDetail, setValuesDetail] = useState();

  const [formGetDataDetail] = Form.useForm();
  const [formExpectedRevenue] = Form.useForm();
  const [formEditExpectedRevenue] = Form.useForm();
  const [lsPermissions, setLsPermissions] = useState([]);
  const [idProjectDownload, setIdProjectDownload] = useState();

  const [listDisable, setListDisable] = useState([]);

  const [loadingBtnDownloadFile, setLoadingBtnDownloadFile] = useState(false);
  const [isOpenModalCreateMultipleBill, setIsOpenModalCreateMultipleBill] =
    useState(false);

  const [onOpenColumns, setOnOpenColumns] = useState(false)

  const [titleBtn, setTitleBtn] = useState("VIEW ALL")

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  useEffect(() => {
    if (userInfo) {
      // setIsSuperA(userInfo.is_superuser);
      const arrPermission = userInfo.permission.map((item, index) => {
        return item.name;
      });

      if (arrPermission !== undefined) {
        setLsPermissions([...arrPermission]);
      }
    }

  }, [userInfo]);

  const columns = [
    {
      title: "",
      width: "7%",
      // width: 100,
      dataIndex: "month",
      key: "month",
      // fixed: "left",
      render: (value, item, index) => (
        <div className="typo-month">
          <span>{value}</span>
        </div>
      ),
    },
    {
      title: "Link File tính tiền",
      dataIndex: "src_file_tinhtien",
      key: "3",
      width: "15%",
      // width: 80,
      // fixed: "left",
      render: (value, item, index) => (
        <a href={localhost + "/" + value} target="_blank">
          {value ? "Link" : null}
        </a>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }}>
          {"Revenue Excluding Tax " +
            (dataBasicProject ? dataBasicProject.currency.code : null)}
        </p>
      ),
      dataIndex: "revenue_excluding_tax",
      key: "6",
      width: "20%",
      // width: 150,
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }}>
          {"Tax " + (dataBasicProject ? dataBasicProject.currency.code : null)}
        </p>
      ),
      dataIndex: "tax",
      key: "7",
      width: "20%",
      // width: 100,
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }}>
          {"Total Revenue " +
            (dataBasicProject ? dataBasicProject.currency.code : null)}
        </p>
      ),
      dataIndex: "total_revenue",
      key: "8",
      width: "20%",
      // width: 120,
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: "Comment Tính tiền",
      dataIndex: "comment_tinhtien",
      key: "15",
      width: "20%",
      // width: 120,
      render: (value, item, index) => (
        <Tooltip placement="top" title={value}>
          <div className="link-file">{value}</div>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "operation",
      // fixed: "right",k
      align: "center",
      width: "10%",
      render: (value, item, index) => (
        <>
          {item.comment_tinhtien !== null ?
            <Tooltip placement="topLeft" title={"Tạo lại phiếu tính tiền"}>
              <Popconfirm
                title="Thông báo"
                description="Xác nhận tạo lại phiếu tính tiền?"
                onConfirm={() => confirmNoRevenue(value)}
                // onCancel={cancelNoRevenue}
                placement="topLeft"
                okText="Có"
                cancelText="Không"
              >
                <RollbackOutlined
                  style={{
                    color: "gray",
                    fontSize: 20,
                    cursor: "pointer",
                    marginRight: 16,
                  }}
                />
              </Popconfirm>
            </Tooltip>
            : null}
          {index < 12 ?
            <span>
              <Tooltip placement="top" title={"Lịch sử thay đổi"}>
                <HistoryOutlined
                  onClick={() => showModalLogDetail(value)}
                  style={{ color: "green", fontSize: 20, cursor: "pointer" }}
                />
              </Tooltip>
            </span>
            : null}
        </>
      ),
    },
  ];

  const columnsAll = [
    {
      title: "",
      // width: "7%",
      width: 100,
      dataIndex: "month",
      key: "month",
      fixed: "left",
      render: (value, item, index) => (
        <div className="typo-month">
          <span>{value}</span>
        </div>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Expected Revenue</p>,
      width: 150,
      dataIndex: "expected_revenue",
      key: "expected_revenue",
      fixed: "left",
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Money</p>,
      dataIndex: "money",
      key: "1",
      width: 120,
      fixed: "left",
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Chênh lệch</p>,
      dataIndex: "chenhlech",
      key: "2",
      width: 120,
      fixed: "left",
      align: "right",

      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: "Link File tính tiền",
      dataIndex: "src_file_tinhtien",
      key: "3",
      // width: "15%",
      width: 80,
      fixed: "left",
      render: (value, item, index) => (
        <a href={localhost + "/" + value} target="_blank">
          {value ? "Link" : null}
        </a>
      ),
    },
    {
      title: "Link File sao kê",
      dataIndex: "src_saoke",
      key: "4",
      width: 80,
      fixed: "left",
      render: (value, item, index) => (
        <a href={localhost + "/" + value} target="_blank">
          {value ? "Link" : null}
        </a>
      ),
    },
    {
      title: "Ngày chuyển tiền",
      dataIndex: "ngaychuyentien",
      key: "5",
      width: 110,
    },
    {
      title: (
        <p style={{ textAlign: "center" }}>
          {"Revenue Excluding Tax " +
            (dataBasicProject ? dataBasicProject.currency.code : null)}
        </p>
      ),
      dataIndex: "revenue_excluding_tax",
      key: "6",
      // width: "20%",
      width: 150,
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }}>
          {"Tax " + (dataBasicProject ? dataBasicProject.currency.code : null)}
        </p>
      ),
      dataIndex: "tax",
      key: "7",
      // width: "20%",
      width: 100,
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }}>
          {"Total Revenue " +
            (dataBasicProject ? dataBasicProject.currency.code : null)}
        </p>
      ),
      dataIndex: "total_revenue",
      key: "8",
      // width: "20%",
      width: 120,
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Exchange Rate</p>,
      dataIndex: "exchange_rate",
      key: "9",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Revenue Excluding Tax VND</p>,
      dataIndex: "revenue_excluding_tax_vnd",
      key: "11",
      width: 160,
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Tax VND</p>,

      dataIndex: "tax_vnd",
      key: "12",
      width: 100,
      align: "right",

      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Total Revenue VND</p>,
      dataIndex: "total_revenue_vnd",
      key: "13",
      width: 120,
      align: "right",

      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: "Comment Tính tiền",
      dataIndex: "comment_tinhtien",
      key: "15",
      // width: "20%",
      width: 120,
      render: (value, item, index) => (
        <Tooltip placement="top" title={value}>
          <div className="link-file">{value}</div>
        </Tooltip>
      ),
    },

    {
      title: "Comment Sao kê",
      dataIndex: "comment_saoke",
      key: "14",
      width: 140,
      render: (value, item, index) => (
        <Tooltip placement="top" title={value}>
          <div className="link-file">{value}</div>
        </Tooltip>
      ),
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      align: "center",
      width: "10%",
      render: (value, item, index) => (
        <>
          {item.comment_tinhtien !== null ?
            <Tooltip placement="topLeft" title={"Tạo lại phiếu tính tiền"}>
              <Popconfirm
                title="Thông báo"
                description="Xác nhận tạo lại phiếu tính tiền?"
                onConfirm={() => confirmNoRevenue(value)}
                // onCancel={cancelNoRevenue}
                placement="topLeft"
                okText="Có"
                cancelText="Không"
              >
                <RollbackOutlined
                  style={{
                    color: "gray",
                    fontSize: 20,
                    cursor: "pointer",
                    marginRight: 16,
                  }}
                />
              </Popconfirm>
            </Tooltip>
            : null}
          {index < 12 ?
            <span>
              <Tooltip placement="top" title={"Lịch sử thay đổi"}>
                <HistoryOutlined
                  onClick={() => showModalLogDetail(value)}
                  style={{ color: "green", fontSize: 20, cursor: "pointer" }}
                />
              </Tooltip>
            </span>
            : null}
        </>
      ),
    },
  ];

  const showExpectedRevenue = () => {
    setIsOpenExpectedRevenue(true);
  };

  const showEditExpectedRevenue = () => {
    setIsOpenEditExpectedRevenue(true);

    const listExpectRevenue = dataDetailProject.map(
      (item) => item.expected_revenue
    );

    formEditExpectedRevenue.setFieldsValue({
      thang_1: listExpectRevenue[0],
      thang_2: listExpectRevenue[1],
      thang_3: listExpectRevenue[2],
      thang_4: listExpectRevenue[3],
      thang_5: listExpectRevenue[4],
      thang_6: listExpectRevenue[5],
      thang_7: listExpectRevenue[6],
      thang_8: listExpectRevenue[7],
      thang_9: listExpectRevenue[8],
      thang_10: listExpectRevenue[9],
      thang_11: listExpectRevenue[10],
      thang_12: listExpectRevenue[11],
    })
  };

  const confirmNoRevenue = (value) => {
    console.log(value);
    var messageParam;
    if (value?.comment_tinhtien.toLowerCase() === "không có doanh thu") {
      messageParam = "kcdt";
    } else {
      messageParam = value?.comment_tinhtien ?? '';
    }
    ReturnRevenueApi({
      id: value.detailid,
      message: messageParam
    })
      .then((res) => {
        console.log(res)
        callDataDetailProject()
        // openNotificationWithIcon("success", "Khai báo thành công");
        // callDataDetailProject();
      })
      .catch((err) => {
        // openNotificationWithIcon("error", "Khai báo thất bại");
      });
  };

  const showModalCreateBill = (value) => {
    setDataPerMonth(value);
    setIsOpenModalCreateBill(true);
  };

  const showModalLogDetail = (value) => {
    setDataLogDetail(value);
    setIsOpenModalLogDetail(true);
  };

  const onFinishImport = (values) => {
    setLoadingConfirm(true);
    setIsOpenExpectedRevenue(false);
    const yearDetail = values.date.$y;
    GetDataDetailProject({
      id_project: values.project,
      year: yearDetail,
    })
      .then((res) => {
        setValuesDetail(values);
        setDataBasicProject(res.data.project);
        setIdProjectDownload(values.project);
        if (res.data.detail_project.length > 1) {
          setDataDetailProject(res.data.detail_project);
          const checkDealStage = res.data.detail_project.filter(
            (item) => item.dealstage === 2
          );
          if (checkDealStage.length < 1) {
            setIsOpenBtnEditExpectRevenue(false);
          } else {
            setIsOpenBtnEditExpectRevenue(true);
          }
        } else {
          openNotificationWithIcon(
            "warning",
            "Dự án chưa có doanh thu dự kiến"
          );
          setDataDetailProject(res.data.detail_project);
        }
        setIsOpenEditExpectedRevenue(false);
        setLoadingConfirm(false);
      })
      .catch((err) => {
        setLoadingConfirm(false);
      });
  };

  useEffect(() => {
    if (dataDetailProject.length === 1) {
      setCheckExpectedRevenue(true);
    } else {
      setCheckExpectedRevenue(false);
    }
  }, [dataDetailProject]);

  const GetFileBill = () => {
    setLoadingBtnDownloadFile(true);
    GetFileExport({
      id: idProjectDownload,
    }).then((res) => {
      let file_path = `${exportFileBill}?id=${idProjectDownload}`;
      let a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoadingBtnDownloadFile(false);
    }).catch((err) => {
      setLoadingBtnDownloadFile(false);
      openNotificationWithIcon("error", err.data.message);
    });
  };

  const onFinishExpectedRevenue = (values) => {
    setLoadingBtnDtdk(true);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("thang_1", values.thang_1);
    data.append("thang_2", values.thang_2);
    data.append("thang_3", values.thang_3);
    data.append("thang_4", values.thang_4);
    data.append("thang_5", values.thang_5);
    data.append("thang_6", values.thang_6);
    data.append("thang_7", values.thang_7);
    data.append("thang_8", values.thang_8);
    data.append("thang_9", values.thang_9);
    data.append("thang_10", values.thang_10);
    data.append("thang_11", values.thang_11);
    data.append("thang_12", values.thang_12);
    data.append("id_project", valuesDetail.project);
    data.append("message", "create");
    data.append("year", valuesDetail.date.$y);

    let call = document.getElementById("btn-view");

    CreateRevenue(data)
      .then((res) => {
        call.addEventListener("click", onFinishImport(valuesDetail));
        formExpectedRevenue.resetFields();
        setIsOpenExpectedRevenue(false);
        setLoadingBtnDtdk(false);
        openNotificationWithIcon("success", "Tạo doanh thu dự kiến thành công");
      })
      .catch((err) => {
        setLoadingBtnDtdk(false);
        openNotificationWithIcon("error", "Tạo doanh thu dự kiến thất bại");
      });
  };

  const onFinishEditExpectedRevenue = (values) => {
    setLoadingBtnDtdk(true);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("thang_1", values.thang_1);
    data.append("thang_2", values.thang_2);
    data.append("thang_3", values.thang_3);
    data.append("thang_4", values.thang_4);
    data.append("thang_5", values.thang_5);
    data.append("thang_6", values.thang_6);
    data.append("thang_7", values.thang_7);
    data.append("thang_8", values.thang_8);
    data.append("thang_9", values.thang_9);
    data.append("thang_10", values.thang_10);
    data.append("thang_11", values.thang_11);
    data.append("thang_12", values.thang_12);
    data.append("id_project", valuesDetail.project);
    data.append("message", "update");
    data.append("year", valuesDetail.date.$y);

    let call = document.getElementById("btn-view");

    CreateRevenue(data)
      .then((res) => {
        call.addEventListener("click", onFinishImport(valuesDetail));
        formEditExpectedRevenue.resetFields();
        setIsOpenEditExpectedRevenue(false);
        setLoadingBtnDtdk(false);
        openNotificationWithIcon(
          "success",
          "Chỉnh sửa doanh thu dự kiến thành công"
        );
      })
      .catch((err) => {
        setLoadingBtnDtdk(false);
        openNotificationWithIcon(
          "error",
          "Chỉnh sửa doanh thu dự kiến thất bại"
        );
      });
  };

  const callDataDetailProject = () => {
    let call = document.getElementById("btn-view");
    call.addEventListener("click", onFinishImport(valuesDetail));
  };

  React.useEffect(() => {
    if (userInfo !== undefined && userInfo.listDepOfProject.length > 0) {
      setLoading(false);
      // Array of department id
      let arrDepart = [];
      userInfo.listProject.forEach((item) => arrDepart.push(item.depart_id));

      // Array of department unique id
      var unique = arrDepart.filter(
        (value, index, array) => array.indexOf(value) === index
      );

      // Department list have project
      const listDepart = userInfo.listDepOfProject.filter((item) =>
        unique.includes(item.depart_id)
      );

      setListDataDep(listDepart);
      setListClientOfUser(userInfo.listClient);
      // setListDataProjectOfDep(userInfo.listProject);
      ChooseDepartment(listDepart[0].depart_id);
      if (listDepart.length > 1) {
        formGetDataDetail.setFieldValue("department", listDepart[0].depart_id);
        setUserRole("Admin");
      } else {
        formGetDataDetail.setFieldValue("department", listDepart[0].depart_id);
        setUserRole("Member");
      }
    } else {
      setLoading(false);
    }
  }, [userInfo]);

  const ChooseDepartment = (e) => {
    if (e !== undefined) {
      formGetDataDetail.setFieldValue("client_code", undefined);
      formGetDataDetail.setFieldValue("department", e);
      const listFilter = userInfo.listClient.filter(
        (item) => item.depart_id === e
      );
      ChooseClient(listFilter[0].id);
      if (listFilter.length > 0) {
        formGetDataDetail.setFieldValue("client_code", listFilter[0].id);
        setListClientOfUser(listFilter);
      } else {
        setListClientOfUser(listClientOfUser);
      }
    } else {
      setListClientOfUser([]);
      setListDataProjectOfDep([]);
      formGetDataDetail.setFieldValue("project", undefined);
      formGetDataDetail.setFieldValue("client_code", undefined);
    }
  };

  const ChooseClient = (e) => {
    formGetDataDetail.setFieldValue("project", undefined);
    const idDepart = formGetDataDetail.getFieldValue("department");
    const listFilter = userInfo.listProject.filter(
      (item) => item.client_id === e
    );
    const newListFilter = listFilter.filter(
      (item) => item.depart_id === idDepart
    );
    if (newListFilter.length > 0) {
      formGetDataDetail.setFieldValue("project", newListFilter[0].id);
      setListDataProjectOfDep(newListFilter);
    } else {
      setListDataProjectOfDep(listDataProjectOfDep);
    }
  };

  useEffect(() => {
    formExpectedRevenue.resetFields();
    formGetDataDetail.setFieldValue("date", dayjs());
  }, []);

  const closeEditExpectedRevenue = () => {
    setIsOpenEditExpectedRevenue(false);
  };

  useEffect(() => {
    const listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const monthNow = dayjs().format("M");
    const listInputDisable = [];
    if (isOpenEditExpectedRevenue === true) {
      for (let i = 0; i < listMonth.length; i++) {
        if (listMonth[i] < monthNow) {

          listInputDisable.push(listMonth[i]);
        }
      }
    }
    setListDisable(listInputDisable);
  }, [isOpenEditExpectedRevenue]);

  const closeCreateRevenue = () => {
    setIsOpenExpectedRevenue(false);
    formExpectedRevenue.resetFields();
  };

  const showCreateMultipleBill = (value, index) => {
    setIsOpenModalCreateMultipleBill(true);
  };

  const [isModalOpenAllRevenue, setIsModalOpenAllRevenue] = useState(false);

  const handleClickMultipleRevenue = () => {
    setIsModalOpenAllRevenue(true);
  };

  const showColumns = () => {
    setOnOpenColumns(!onOpenColumns)
    if (onOpenColumns === true) {
      setTitleBtn("VIEW ALL")
    } else {
      setTitleBtn("HIDDEN")
    }
  }

  return (
    <>
      {loading === true ? (
        <div className="loading-detail-pj">
          <Spin spinning={loading} size="large"></Spin>
        </div>
      ) : (
        <div style={{ position: "relative", width: "100%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "12vh",
            }}
          >
            <div className="get-detail-pj">
              <FormConfirm
                formGetDataDetail={formGetDataDetail}
                onFinishImport={onFinishImport}
                validateMessages={validateMessages}
                ChooseDepartment={ChooseDepartment}
                userRole={userRole}
                userInfo={userInfo}
                listDataDep={listDataDep}
                ChooseClient={ChooseClient}
                listClientOfUser={listClientOfUser}
                listDataProjectOfDep={listDataProjectOfDep}
                loadingConfirm={loadingConfirm}
              />
              <Row className="btn-revenue-row-op">
                <Col span={8} style={{ display: "flex" }}>
                  {/* {dataBasicProject &&
                    dataDetailProject.length === 1 &&
                    lsPermissions.includes(
                      "Tạo doanh thu dự kiến Operation"
                    ) === true ? (
                    <Button
                      className="btn-revenue"
                      onClick={showExpectedRevenue}
                    >
                      CREATE REVENUE
                      <img
                        style={{ marginLeft: 8 }}
                        src={RevenueIcon}
                        alt=""
                      ></img>
                    </Button>
                  ) : null} */}

                  {/* {dataDetailProject &&
                    isOpenBtnEditExpectRevenue === true &&
                    lsPermissions.includes(
                      "Chỉnh sửa doanh thu dự kiến Operation"
                    ) === true &&
                    dataDetailProject.length > 1 ? (
                    <Button
                      className="btn-revenue"
                      onClick={showEditExpectedRevenue}
                    >
                      EDIT REVENUE
                      <img
                        style={{ marginLeft: 8 }}
                        src={RevenueIcon}
                        alt=""
                      ></img>
                    </Button>
                  ) : null} */}

                  {/* <Button
                    className="btn-downloadFile"

                    onClick={(value, index) =>
                      showCreateMultipleBill(value, index)
                    }
                    style={{ color: "#337aee" }}
                  >
                    CREATE MULTIPLE BILL
                    <img
                      style={{ marginLeft: 8 }}
                      src={CreateBillMuchIcon}
                      alt=""
                    ></img>
                  </Button> */}


                  {dataBasicProject ? (
                    <Button
                      htmlType="button"
                      loading={loadingBtnDownloadFile}
                      className="btn-downloadFile"
                      onClick={GetFileBill}
                    >
                      DOWNLOAD FILE{" "}
                      <img
                        style={{ marginLeft: 8 }}
                        src={DownloadIcon}
                        alt=""
                      ></img>
                    </Button>
                  ) : null}

                  {/* {lsPermissions.includes(
                    "Tạo doanh thu hàng loạt Operation"
                  ) === true ? (
                    <Button
                      className={"btn-revenue"}
                      onClick={handleClickMultipleRevenue}
                    >
                      CREATE MULTIPLE REVENUE
                      <img
                        style={{ marginLeft: 8 }}
                        src={RevenueIcon}
                        alt=""
                      ></img>
                    </Button>
                  ) : null} */}


                  {lsPermissions.includes(
                    "View full Operation"
                  ) === true && dataDetailProject.length > 0 ?
                    <Button
                      className={
                        dataDetailProject.length > 0
                          ? "btn-revenue"
                          : "btn-downloadFile"
                      }
                      onClick={showColumns}
                      style={{ color: "#337aee", width: "200px" }}
                    >
                      {titleBtn}
                    </Button> : null}

                  {/* <ModalAllRevenue
                    isModalOpenAllRevenue={isModalOpenAllRevenue}
                    setIsModalOpenAllRevenue={setIsModalOpenAllRevenue}
                  /> */}
                </Col>
                <Col span={15}></Col>
              </Row>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "12vh",
              marginTop: "20px",
            }}
          >
            <div className="information">
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.3%",
                }}
              >
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Currency:{" "}
                      {dataBasicProject ? dataBasicProject.currency.code : null}
                    </p>
                  </div>
                </Col>
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Market:{" "}
                      {dataBasicProject ? dataBasicProject.market.name : null}
                    </p>
                  </div>
                </Col>
                <Col span={8} className="center-col">
                  <div className="center-div">
                    {dataBasicProject ? (
                      <p className="text-basic">
                        Client Code: {dataBasicProject.client.name}
                      </p>
                    ) : (
                      <p className="text-basic">Client Code:</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.5%",
                }}
              >
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Product/Service:{" "}
                      {dataBasicProject
                        ? dataBasicProject.pdservice.code
                        : null}
                    </p>
                  </div>
                </Col>
                <Col span={8} className="center-col">
                  <div className="center-div">
                    {dataBasicProject ? (
                      <p
                        // className="text-basic link-file"
                        className="text-basic"
                      >
                        Bank Account: {dataBasicProject.bank.code}
                      </p>
                    ) : (
                      <p className="text-basic">Bank Account:</p>
                    )}
                  </div>
                </Col>
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Deal owner:{" "}
                      {dataBasicProject ? dataBasicProject.dealown : null}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="table-sale-ope">
            {dataDetailProject.length > 1 &&
              isOpenEditExpectedRevenue === false ? (
              <Table
                columns={onOpenColumns === true ? columnsAll : columns}
                dataSource={dataDetailProject}
                style={{ width: "98%" }}
                scroll={{
                  x: onOpenColumns === true ? 2000 : null,
                  y: window.screen.availHeight > 850 ? "56vh" : "53vh",
                }}
                pagination={false}
                className="custom-table table-striped-rows"
                bordered
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-light" : "table-row-dark"
                }
              />
            ) : null}

            {isOpenExpectedRevenue === false &&
              checkExpectedRevenue === true ? (
              <img src={NoData} alt="" style={{ marginTop: "10%" }}></img>
            ) : null}
          </div>

          {/* screen width > 1600px */}

          {window.screen.width > 1600 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {isOpenExpectedRevenue === true &&
                dataDetailProject.length === 1 ? (

                <FormCreateRevenue
                  formExpectedRevenue={formExpectedRevenue}
                  onFinishExpectedRevenue={onFinishExpectedRevenue}
                  validateMessages={validateMessages}
                  loadingBtnDtdk={loadingBtnDtdk}
                  closeCreateRevenue={closeCreateRevenue}
                  valuesDetail={valuesDetail}
                />

              ) : null}

              {isOpenEditExpectedRevenue === true ? (
                <FormEditRevenue
                  formEditExpectedRevenue={formEditExpectedRevenue}
                  onFinishEditExpectedRevenue={onFinishEditExpectedRevenue}
                  validateMessages={validateMessages}
                  listDisable={listDisable}
                  loadingBtnDtdk={loadingBtnDtdk}
                  closeEditExpectedRevenue={closeEditExpectedRevenue}
                  valuesDetail={valuesDetail}
                />

              ) : null}
            </div>
          ) : (
            <>
              {/* screen width < 1600 */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "14px",
                }}
              >
                {isOpenExpectedRevenue === true &&
                  dataDetailProject.length === 1 ? (
                  <FormCreateRevenueResponsive
                    formExpectedRevenue={formExpectedRevenue}
                    onFinishExpectedRevenue={onFinishExpectedRevenue}
                    validateMessages={validateMessages}
                    loadingBtnDtdk={loadingBtnDtdk}
                    closeCreateRevenue={closeCreateRevenue}
                    valuesDetail={valuesDetail}
                  />
                ) : null}
                {isOpenEditExpectedRevenue === true ? (
                  <FormEditRevenueResponsive
                    formEditExpectedRevenue={formEditExpectedRevenue}
                    onFinishEditExpectedRevenue={onFinishEditExpectedRevenue}
                    validateMessages={validateMessages}
                    listDisable={listDisable}
                    loadingBtnDtdk={loadingBtnDtdk}
                    closeEditExpectedRevenue={closeEditExpectedRevenue}
                    valuesDetail={valuesDetail}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      )}
      <ModalCreateBill
        isOpenModalCreateBill={isOpenModalCreateBill}
        setIsOpenModalCreateBill={setIsOpenModalCreateBill}
        dataPerMonth={dataPerMonth}
        dataBasicProject={dataBasicProject}
        valuesDetail={valuesDetail}
        callDataDetailProject={callDataDetailProject}
      />
      <ModalLogDetail
        isOpenModalLogDetail={isOpenModalLogDetail}
        setIsOpenModalLogDetail={setIsOpenModalLogDetail}
        dataLogDetail={dataLogDetail}
      />

      <ModalCreateMultipleBill
        isOpenModalCreateMultipleBill={isOpenModalCreateMultipleBill}
        setIsOpenModalCreateMultipleBill={setIsOpenModalCreateMultipleBill}
        dataBasicProject={dataBasicProject}
        valuesDetail={valuesDetail}
        listProject={userInfo.listProject}
        listClientOfUser={userInfo.listClient}
      />
    </>
  );
}