import { Col, Form, Input, Modal, Row, Button, Select } from "antd";
import React, { memo } from "react";
import { useEffect } from "react";
import { PutProjectApi } from "../../api/listProject";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const { Option } = Select;
const { TextArea } = Input;

const dataOptionTypeProject = [
  {
    id: 1,
    value: "Operation",
  },
  {
    id: 2,
    value: "Sales",
  },
];

const ModalEditProject = ({
  isModalOpenEditProject,
  handleOkEditProject,
  setIsModalOpenEditProject,
  fetchListDataMarket,
  isListClientCode,
  isListMarketCode,
  isListProductServiceCode,
  isListCurrencyCode,
  isListDealOwerCode,
  isListBankACCode,
  isListDepartmentCode,
  idEditProject,
  dataInforProject,
}) => {
  const [form] = Form.useForm();
  const [currentClientCode, setCurrentClientCode] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [currentBankAC, setCurrentBankAC] = useState(null);
  const [currentMarket, setCurrentMarket] = useState(null);
  const [currentDealOwer, setCurrentDealOwer] = useState(null);
  const [searchKeywordClientCode, setSearchKeywordClientCode] = useState("");
  const [searchKeywordPS, setSearchKeywordPS] = useState("");
  const [loadings, setLoadings] = useState(false);

  const handleCancelEditProject = () => {
    setIsModalOpenEditProject(false);
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
    if (isModalOpenEditProject) {
      form.setFieldsValue({
        projectname: dataInforProject.projectname,
        client_code: dataInforProject.client.name,
        type_project: dataInforProject.type_project,
        service: dataInforProject.service.name,
        department_id: dataInforProject.department_id,
        currency: dataInforProject.currency.name,
        market: dataInforProject.market.name,
        dealowner: dataInforProject.dealowner.name,
        bank: dataInforProject.bank.code,
        project_detail: dataInforProject.project_detail,
      });
    }
  }, [isModalOpenEditProject, dataInforProject, form]);

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);
    data.append("id", idEditProject);
    data.append("message", "update");
    data.append("project_name", values.projectname);
    data.append(
      "client_id",
      currentClientCode === null
        ? dataInforProject.client.id
        : currentClientCode
    );
    data.append("type_project", values.type_project);
    data.append(
      "productservice_id",
      currentService === null ? dataInforProject.service.id : currentService
    );
    data.append("department", values.department_id);
    data.append(
      "typemoney_id",
      currentCurrency === null ? dataInforProject.currency.id : currentCurrency
    );
    data.append(
      "market_id",
      currentMarket === null ? dataInforProject.market.id : currentMarket
    );
    data.append(
      "dealower",
      currentDealOwer === null ? dataInforProject.dealowner.id : currentDealOwer
    );
    data.append(
      "bankac_id",
      currentBankAC === null ? dataInforProject.bank.id : currentBankAC
    );
    data.append("detail_project", values.project_detail);

    // currentDuAn
    PutProjectApi(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Cập nhật Project thành công",
        });
        setLoadings(false);
        fetchListDataMarket();
        handleCancelEditProject();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  const onChangeClientCode = (e) => {
    setCurrentClientCode(e);
  };

  const onChangeService = (e) => {
    setCurrentService(e);
  };

  const onChangeCurrency = (e) => {
    setCurrentCurrency(e);
  };

  const onChangeBankAC = (e) => {
    setCurrentBankAC(e);
  };

  const onChangeMarket = (e) => {
    setCurrentMarket(e);
  };

  const onChangeDealOwer = (e) => {
    setCurrentDealOwer(e);
  };

  const handleSearchClientCode = (value) => {
    setSearchKeywordClientCode(value);
  };

  const handleSearchPS = (value) => {
    setSearchKeywordPS(value);
  };

  return (
    <Modal
      title="EDIT PROJECT"
      open={isModalOpenEditProject}
      onOk={handleOkEditProject}
      onCancel={handleCancelEditProject}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Project Name"
              name="projectname"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Project Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Project Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Client Code"
              name="client_code"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Client Code!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Client"
                onChange={onChangeClientCode}
                showSearch
                onSearch={handleSearchClientCode}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={searchKeywordClientCode}
              >
                {isListClientCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.client_code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Type Project"
              name="type_project"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Type Project!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Type Project"
                disabled={dataInforProject.type_project === 1 ? true : false}
              >
                {dataOptionTypeProject.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Product/ Service"
              name="service"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Product/ Service!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Product/ Service"
                onChange={onChangeService}
                showSearch
                onSearch={handleSearchPS}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={searchKeywordPS}
              >
                {isListProductServiceCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.servicename}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Department"
              name="department_id"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Department!",
                },
              ]}
            >
              <Select size="large" placeholder="Department">
                {isListDepartmentCode.map((e) => (
                  <Option key={e.iddepart} value={e.iddepart}>
                    {e.codedepart}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Currency"
              name="currency"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Currency!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Currency"
                onChange={onChangeCurrency}
              >
                {isListCurrencyCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Bank Account"
              name="bank"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Bank Account!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Bank Account"
                onChange={onChangeBankAC}
              >
                {isListBankACCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.codebank}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Market"
              name="market"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Market"
                onChange={onChangeMarket}
              >
                {isListMarketCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.codemarket}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Deal Ower"
              name="dealowner"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Deal Ower!",
                },
              ]}
            >
              <Select
                size="large"
                placeholder="Deal Ower"
                onChange={onChangeDealOwer}
              >
                {isListDealOwerCode.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Project Business Description"
              name="project_detail"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Project Business Description!",
                },
              ]}
            >
              <TextArea
                // value={value}
                // onChange={(e) => setValue(e.target.value)}
                placeholder="Controlled autosize"
                autoSize={{
                  minRows: 4,
                  maxRows: 4,
                }}
                size="large"
              />
              {/* <Input size="large" placeholder="Project Business Description" /> */}
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelEditProject}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default memo(ModalEditProject);
