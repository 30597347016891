import { exportClient, exportProject, exportUser } from "../constants";
import { authAxios } from "./axiosClient";

export const ExportUser = (params = {}) => {
  const url = exportUser;
  return authAxios().get(url, { params });
};
export const ExportClient = (params = {}) => {
  const url = exportClient;
  return authAxios().get(url, { params });
};
export const ExportProject = (params = {}) => {
  const url = exportProject;
  return authAxios().get(url, { params });
};
