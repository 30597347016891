import {
  Col,
  Form,
  Modal,
  Row,
  Button,
  Select,
  TreeSelect,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { PostFileClient } from "../../api/listClient";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const { Option } = Select;

const ModalUploadFile = ({
  isModalOpenUploadFile,
  handleOkEditProject,
  setIsModalOpenUploadFile,
  fetchListDataMarket,
  isListClientCode,
  isListValueProject,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);
  const [currentDuAn, setCurrentDuAn] = useState([]);
  const [treeDataDuAn, setTreeDataDuAn] = useState([]);

  const handleCancelUploadFile = () => {
    setIsModalOpenUploadFile(false);
    setCurrentDuAn([]);
    setLoadings(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(true);
    data.append("project", currentDuAn.length > 0 ? currentDuAn : []);
    data.append("file", values.file.fileList[0].originFileObj);
    // currentDuAn
    PostFileClient(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Upload file successful",
        });
        setLoadings(false);
        fetchListDataMarket();
        handleCancelUploadFile();
      })
      .catch((error) => {
        setLoadings(false);
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  useEffect(() => {
    const treeDataDA = isListValueProject.map((item) => {
      return {
        title: item.projectname,
        value: item.id,
        key: item.id,
      };
    });
    setTreeDataDuAn(treeDataDA);
  }, [isListValueProject]);

  const handleChangeClient = (e) => {
    const newList = isListValueProject
      .filter((item) => item.clientid === parseInt(e))
      .map((itemCheck) => itemCheck.id);
    setCurrentDuAn(newList);
    form.setFieldValue("project", newList);
  };

  const onChangeDuAn = (value) => {
    if (value.length > 0) {
      setCurrentDuAn(value);
      form.setFieldValue("project", value);
    } else {
      setCurrentDuAn([]);
      form.setFieldValue("project", []);
    }
  };

  const treeData = [
    {
      title:
        currentDuAn.length > 0 ? (
          <span
            onClick={() => {
              onChangeDuAn([]);
            }}
            className="style_SelectTeam"
          >
            Bỏ chọn tất cả
          </span>
        ) : (
          <span
            onClick={() => {
              onChangeDuAn(isListValueProject.map((item) => item.id));
            }}
            className="style_SelectTeam"
          >
            Chọn tất cả
          </span>
        ),
      value: "xxx",
      disableCheckbox: true,
      disabled: true,
      className: "style_treeTeam",
    },
    ...treeDataDuAn,
  ];

  return (
    <>
      <Modal
        title="UPLOAD FILE"
        open={isModalOpenUploadFile}
        onOk={handleOkEditProject}
        onCancel={handleCancelUploadFile}
        footer={false}
        width={800}
        forceRender={true}
        className="headerTitleModal"
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          // validateMessages={validateMessages}
          // loading={loading}
          initialValues={{
            remember: true,
          }}
        >
          <Row>
            <Col span={11}>
              <Form.Item
                label=<p style={{ fontWeight: 600, fontSize: 15 }}>Client</p>
                name="client"
              >
                <Select
                  size="large"
                  placeholder="Choose Client"
                  showSearch
                  onChange={(event) => handleChangeClient(event)}
                  allowClear
                  optionFilterProp="children"
                >
                  {isListClientCode.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.client_code}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} offset={1}>
              <Form.Item
                label="Project"
                name="project"
                rules={[
                  {
                    required: true,
                    message: "Please choose Project!",
                  },
                ]}
              >
                <TreeSelect
                  treeData={treeData}
                  value={treeDataDuAn.length > 0 ? currentDuAn : undefined}
                  onChange={onChangeDuAn}
                  treeCheckable={true}
                  showCheckedStrategy={"SHOW_PARENT"}
                  maxTagCount={2}
                  placeholder={"Choose Project"}
                  style={{ width: "100%" }}
                  size="large"
                  clearIcon
                  optionFilterProp="children"
                  filterTreeNode={(search, item) => {
                    return (
                      item.title
                        .toString()
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) >= 0
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} className="col11">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Item
                  name="file"
                  label={<span>File attached</span>}
                  rules={[
                    {
                      required: true,
                      message: "Please choose File!",
                    },
                  ]}
                  key="file"
                >
                  <Upload
                    maxCount={1}
                    beforeUpload={() => false}
                    accept=".xlsx, .xls"
                  >
                    <Button htmlType="button" icon={<UploadOutlined />}>
                      Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
              <Button
                className="btnSaveModel"
                type="success"
                htmlType="submit"
                variant="contained"
                loading={loadings}
              >
                Save
              </Button>
              <Button
                className="btnCancelModel"
                variant="outlined"
                onClick={handleCancelUploadFile}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalUploadFile;
