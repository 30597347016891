import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import NotFoundLayout from "../notfound";
import NotPermission from "../notPermission";
import { getUserInfo } from "../app/Reducers/getUserInfo";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { authSuccess } from "../app/Actions/auth";
import UserLayout from "../components/UserLayout";
import { CustomLayout } from "../components/Layout";
import { CustomLayoutAdmin } from "../components/LayoutAdmin";
import Login from "../pages/login/Login";
import HomePage from "../pages/home";
import Input from "../pages/input";
import Bar from "../pages/bar";
import Pie from "../pages/pie";
import User from "../pages/user";
import Product_Service from "../pages/product_service";
import Project from "../pages/project";
import Client from "../pages/client";
import Market from "../pages/market";
import Currency from "../pages/currency";
import Bank_Account from "../pages/bank_account";
import Department from "../pages/department";
import SaoKe from "../pages/saoKe";
import LastCheck from "../pages/lastCheck";
import ProjectDetail from "../pages/home/ProjectDetail/ProjectDetail";
import Roles from "../pages/roles";
import ChangePW from "../pages/changePW";
import Check from "../pages/check/check";
import ProjectDetailSale from "../pages/project_detail_sale/ProjectDetailSale";
import PivotSale from "../pages/pivotSale";
import DTTT from "../pages/dttt";
import DTDDTT from "../pages/output";
import Entry from "../pages/entry/Entry";
import Revenue from "../pages/revenue/Revenue";
import Logs from "../pages/log/Logs";
const cookies = new Cookies();

function PageTitleUpdater() {
  const location = useLocation();

  React.useEffect(() => {
    const path = location.pathname;
    let pageTitle = "Quản lý doanh thu"; // Đặt tiêu đề mặc định

    // Cập nhật tiêu đề dựa trên URL
    if (path === "/") {
      pageTitle = "Pivot Management";
    } else if (path === "/pivot-sales") {
      pageTitle = "Pivot Sale";
    } else if (path === "/pj-detail") {
      pageTitle = "Project Detail";
    } else if (path === "/pj-detail-sale") {
      pageTitle = "Project Detail";
    } else if (path === "/saoke") {
      pageTitle = "Bank Statement";
    } else if (path === "/check") {
      pageTitle = "Check";
    } else if (path === "/lastcheck") {
      pageTitle = "Last check";
    } else if (path === "/entry") {
      pageTitle = "Entry";
    } else if (path === "/dttt") {
      pageTitle = "DTTT";
    } else if (path === "/kh_plan") {
      pageTitle = "KH PLAN";
    } else if (path === "/team_dt_dd_tt") {
      pageTitle = "TEAM";
    } else if (path === "/user") {
      pageTitle = "User";
    } else if (path === "/product_service") {
      pageTitle = "Product/ Service";
    } else if (path === "/project") {
      pageTitle = "Project";
    } else if (path === "/client") {
      pageTitle = "Client";
    } else if (path === "/market") {
      pageTitle = "Market";
    } else if (path === "/currency") {
      pageTitle = "Currency";
    } else if (path === "/bank_account") {
      pageTitle = "Bank Account";
    } else if (path === "/department") {
      pageTitle = "Department";
    } else if (path === "/role") {
      pageTitle = "Role";
    }

    // Cập nhật tiêu đề trang
    document.title = pageTitle;
  }, [location]);

  return null;
}

function Main() {
  const dispatch = useDispatch();
  // const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const [lsPermissions, setLsPermissions] = useState([]);
  const [lsPermissionsType, setLsPermissionsType] = useState([]);
  // const history = useHistory();

  const token = cookies.get("token_qldt");
  if (token) {
    dispatch(authSuccess(token));
  }
  const auth = useSelector((state) => state.auth.token) !== null;
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  useEffect(() => {
    if (auth && token) {
      dispatch(getUserInfo());
    }
  }, [auth, dispatch, token]);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role !== "SuperAdmin") {
        const arrPermission = userInfo.permission.map((item, index) => {
          return item.name;
        });

        const arrPermissionType = userInfo.permission.map((item, index) => {
          return item.type;
        });

        if (arrPermission !== undefined) {
          setLsPermissions([...arrPermission]);
          setLsPermissionsType([...arrPermissionType]);
        }
      } else {
        const arrPermission = userInfo.role;
        setLsPermissions([arrPermission]);
      }
    }
  }, [userInfo]);

  return (
    <Router>
      <PageTitleUpdater />
      <Switch>
        {/* <Route path="/HomePage" component={HomePage} /> */}
        <Route path="/change_password" component={ChangePW} />
        <ProtectLoginRoute
          exact
          path="/dang-nhap"
          protect={auth}
          user_info={userInfo}
          lsPermissionsType={lsPermissionsType}
          lsPermissions={lsPermissions}
        >
          <UserLayout>
            <Login />
          </UserLayout>
        </ProtectLoginRoute>
        <RouteWithLayout
          component={HomePage}
          exact
          layout={CustomLayout}
          path="/"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Xem Pivot Operation"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={PivotSale}
          exact
          layout={CustomLayout}
          path="/pivot-sales"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Xem Pivot Sales"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={ProjectDetail}
          exact
          layout={CustomLayout}
          path="/pj-detail"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Xem project detail Operation"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={ProjectDetailSale}
          exact
          layout={CustomLayout}
          path="/pj-detail-sale"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Xem project detail Sales"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Input}
          exact
          layout={CustomLayout}
          path="/input"
          isPrivate={true}
          lsPermissions={[""]}
          permission={[""]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={SaoKe}
          exact
          layout={CustomLayout}
          path="/saoke"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Upload file sao kê Operation"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={LastCheck}
          exact
          layout={CustomLayout}
          path="/lastcheck"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["LC phiếu tính tiền Operation"]}
          isLogged={auth}
        />

        <RouteWithLayout
          component={Revenue}
          exact
          layout={CustomLayout}
          path="/revenue"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Xem doanh thu dự kiến Operation"]}
          isLogged={auth}
        />

        <RouteWithLayout
          component={Entry}
          exact
          layout={CustomLayout}
          path="/entry"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Tạo phiếu tính tiền Operation"]}
          isLogged={auth}
        />

        <RouteWithLayout
          component={Check}
          exact
          layout={CustomLayout}
          path="/check"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Check phiếu tính tiền Operation"]}
          isLogged={auth}
        />

        <RouteWithLayout
          component={Logs}
          exact
          layout={CustomLayout}
          path="/logs"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["View log Operation"]}
          isLogged={auth}
        />

        <RouteWithLayout
          component={DTTT}
          exact
          layout={CustomLayout}
          path="/dttt"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Xuất Output DTTT Operation"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={DTDDTT}
          exact
          layout={CustomLayout}
          path="/dtddtt"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Xuất Output DT DD&TT Operation"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Bar}
          exact
          layout={CustomLayout}
          path="/bar"
          isPrivate={true}
          lsPermissions={[""]}
          permission={[""]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Pie}
          exact
          layout={CustomLayout}
          path="/pie"
          isPrivate={true}
          lsPermissions={[""]}
          permission={[""]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={User}
          exact
          layout={CustomLayoutAdmin}
          path="/user"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin Operation", "SuperAdmin"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Product_Service}
          exact
          layout={CustomLayoutAdmin}
          path="/product_service"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin Operation", "SuperAdmin"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Project}
          exact
          layout={CustomLayoutAdmin}
          path="/project"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin Operation", "SuperAdmin"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Client}
          exact
          layout={CustomLayoutAdmin}
          path="/client"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin Operation", "SuperAdmin"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Market}
          exact
          layout={CustomLayoutAdmin}
          path="/market"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin Operation", "SuperAdmin"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Currency}
          exact
          layout={CustomLayoutAdmin}
          path="/currency"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin Operation", "SuperAdmin"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Bank_Account}
          exact
          layout={CustomLayoutAdmin}
          path="/bank_account"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin Operation", "SuperAdmin"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Department}
          exact
          layout={CustomLayoutAdmin}
          path="/department"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin Operation", "SuperAdmin"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={Roles}
          exact
          layout={CustomLayoutAdmin}
          path="/role"
          isPrivate={true}
          lsPermissions={lsPermissions}
          permission={["Admin Operation", "SuperAdmin"]}
          isLogged={auth}
        />
        <RouteWithLayout
          component={NotFoundLayout}
          layout={CustomLayout}
          path="/"
          lsPermissions={lsPermissions}
          isPrivate={true}
          isLogged={auth}
          permission={"404"}
        />
        <RouteWithLayout
          component={NotFoundLayout}
          layout={CustomLayoutAdmin}
          path="/"
          lsPermissions={lsPermissions}
          isPrivate={true}
          isLogged={auth}
          permission={"404"}
        />
      </Switch>
    </Router>
  );
}

const RouteWithLayout = (props) => {
  const {
    layout: Layout,
    // eslint-disable-next-line no-useless-rename
    isLogged: isLogged,
    component: Component,
    // eslint-disable-next-line no-useless-rename
    isPrivate: isPrivate,
    // eslint-disable-next-line no-useless-rename
    lsPermissions: lsPermissions,
    // eslint-disable-next-line no-useless-rename
    permission: permission,
    // eslint-disable-next-line no-useless-rename
    path: path,
    // eslint-disable-next-line no-useless-rename
    isSuperA: isSuperA,
    ...rest
  } = props;

  const getRejectRoute = (type) => {
    if (type !== "404" && path !== "/") {
      type = "403";
    }

    switch (type) {
      case "403":
        return <NotPermission />;
      case "404":
        return <NotFoundLayout />;
      default:
        return <NotPermission />;
    }
  };

  return (
    <Route
      {...rest}
      render={() =>
        isPrivate ? (
          isLogged ? (
            lsPermissions && lsPermissions.length > 0 ? (
              lsPermissions.some((r) => permission.includes(r)) ? (
                <Layout isLogged={isLogged}>
                  <Component {...props} />
                </Layout>
              ) : (
                getRejectRoute(permission)
              )
            ) : (
              <span></span>
            )
          ) : (
            <Redirect
              to={{
                pathname: "/dang-nhap",
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Layout isLogged={isLogged}>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

const ProtectLoginRoute = ({
  protect,
  lsPermissionsType,
  lsPermissions,
  user_info,
  children,
  ...rest
}) => {
  return (
    <>
      <Route
        {...rest}
        render={() =>
          !protect ? (
            children
          ) : (
            <>
              {lsPermissions.includes("Xem Pivot Operation") === true ||
                lsPermissions.includes("Xem project detail Operation") ||
                lsPermissions.includes("Upload file sao kê Operation") === true ||
                lsPermissions.includes("LC phiếu tính tiền Operation") === true ||
                lsPermissions.includes("Xem doanh thu hàng loạt Operation") === true ||
                lsPermissions.includes("Check phiếu tính tiền Operation") ===
                true ? (
                <Redirect to="/pj-detail"></Redirect>
              ) : (
                <>
                  {lsPermissions.includes("Xem Pivot Sales") === true ||
                    lsPermissions.includes("Xem project detail Sales") ===
                    true ? (
                    <Redirect to="/pj-detail-sale"></Redirect>
                  ) : (
                    ""
                  )}
                  {lsPermissions.includes("Admin Operation") === true &&
                    lsPermissionsType.length === 1 && (
                      <Redirect to="/user"></Redirect>
                    )}
                  {lsPermissions.includes("Upload file sao kê Operation") ===
                    true &&
                    lsPermissionsType.length === 1 && (
                      <Redirect to="/saoke"></Redirect>
                    )}
                  {(lsPermissions.includes("Tạo doanh thu hàng loạt Operation") === 
                    true || lsPermissions.includes("Xem doanh thu dự kiến Operation") ===
                    true) &&
                    lsPermissionsType.length === 1 && (
                      <Redirect to="/revenue"></Redirect>
                    )}
                  {lsPermissions.includes("Tạo phiếu tính tiền Operation") ===
                    true &&
                    lsPermissionsType.length === 1 && (
                      <Redirect to="/entry"></Redirect>
                    )}
                  {lsPermissions.includes("LC phiếu tính tiền Operation") ===
                    true &&
                    lsPermissionsType.length === 1 && (
                      <Redirect to="/lastcheck"></Redirect>
                    )}
                  {lsPermissions.includes("Check phiếu tính tiền Operation") ===
                    true &&
                    lsPermissionsType.length === 1 && (
                      <Redirect to="/check"></Redirect>
                    )}
                  {lsPermissions.includes("Xuất Output DTTT Operation") === true &&
                    lsPermissionsType.length === 1 && (
                      <Redirect to="/dttt"></Redirect>
                    )}
                  {lsPermissions.includes("Xuất Output DT DD&TT Operation") === true &&
                    lsPermissionsType.length === 1 && (
                      <Redirect to="/dtddtt"></Redirect>
                    )}

                  {lsPermissions.includes("SuperAdmin") === true && (
                    <Redirect to="/user"></Redirect>
                  )}
                </>
              )}
            </>
          )
        }
      />
    </>
  );
};

export default Main;
