import { localhost } from "./server";
const apiURL = "/api";

export const endpoint = `${localhost}${apiURL}`;
export const loginLockURL = `${localhost}/login`;
export const loginURL = `${endpoint}/token/`;
export const token_refresh_URL = `${endpoint}/token/refresh/`;
export const logoutURL = `${localhost}/logout/`;
export const userInfoURL = `${endpoint}/infor-user`;
export const listUser = `${endpoint}/user`;
export const listDepartment = `${endpoint}/department`;
export const listProject = `${endpoint}/project`;
export const listRole = `${endpoint}/role`;
export const listMarket = `${endpoint}/market`;
export const listClient = `${endpoint}/client`;
export const listBankAC = `${endpoint}/bankac`;
export const listCurrency = `${endpoint}/currency`;
export const listService = `${endpoint}/service`;
export const listPermission = `${endpoint}/permission`;
export const listStatement = `${endpoint}/statement`;
export const listPivot = `${endpoint}/pivot`;
export const listCategory = `${endpoint}/category`;
export const detailProject = `${endpoint}/detailproject`;
export const detailProjectSale = `${endpoint}/detailprojectsale`;
export const currencyofmonth = `${endpoint}/currencyofmonth`;
export const createBill = `${endpoint}/detailentry`;
export const createBillSale = `${endpoint}/revenuesale`;
export const detailCheck = `${endpoint}/detailcheck`;
export const detailEditExpRevenue = `${endpoint}/detailedit`;
export const detailLastCheck = `${endpoint}/detailcc`;
export const detailLastCheckSuccess = `${endpoint}/detaillcsuccess`;
export const updateRevenue = `${endpoint}/update_revenue`;
export const updateRevenueSale = `${endpoint}/update_revenue_sale`;
export const logDetail = `${endpoint}/log_detail`;
export const logDetailSale = `${endpoint}/log_detail_sale`;

export const dtttProduct = `${endpoint}/dttt`;
export const dtttMarket = `${endpoint}/dttt_market`;
export const dtttBankAC = `${endpoint}/dttt_bankac`;
export const dtttCurrency = `${endpoint}/dttt_currency`;
export const dtttDepartment = `${endpoint}/dttt_department`;
export const dtttClientCode = `${endpoint}/dttt_client`;

export const dtddttProduct = `${endpoint}/dtddtt`;
export const dtddttTeam = `${endpoint}/dtddtt_team`;
export const export_Pivot = `${endpoint}/export_pivot`;

export const importFileClient = `${endpoint}/import_file_samplebill`;
export const exportFileBill = `${endpoint}/export_file_samplebill`;

export const multipleBill = `${endpoint}/mutiple_bill`;
export const getDataProject = `${endpoint}/get_project`;

export const multipleRevenue = `${endpoint}/mutiple_revenue`;
// export const getProjectRevenue = `${endpoint}/get_project_revenue`;
export const getProjectRevenue = `${endpoint}/get_project_revenue_ver`;
export const postIp_Muiti_user = `${endpoint}/ip_multi_user`;
export const postIp_Muiti_client = `${endpoint}/ip_multi_client`;
export const postIp_Muiti_project = `${endpoint}/ip_multi_project`;

export const exportUser = `${endpoint}/export_user`;
export const exportClient = `${endpoint}/export_client`;
export const exportProject = `${endpoint}/export_project`;

export const getProjectCheckNotEntry = `${endpoint}/check_project_not_entry`;

export const getRevenueVer = `${endpoint}/get_revenue_ver`;

export const getLogRevenue = `${endpoint}/get_log_revenue`

export const downloadRevenueVer = `${endpoint}/down_revenue_ver`

export const getVer = `${endpoint}/get_ver`
export const returnRevenue = `${endpoint}/return_reveneu`

export const getLogDetail = `${endpoint}/log_detail_center`

