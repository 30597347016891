import { Col, Form, Input, Modal, Row, Button, Select } from "antd";
import React from "react";
import { PostListService } from "../../api/listService";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const { Option } = Select;

const ModalAddProductService = ({
  isModalOpenAddProduct,
  handleOkAddProduct,
  setIsModalOpenAddProduct,
  fetchListDataService,
  dataOptionCategory,
}) => {

  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  const handleCancelAddProduct = () => {
    setIsModalOpenAddProduct(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);
    data.append("message", "create");
    data.append("categoryId", values.categoryId);
    data.append("servicename", values.servicename);

    // currentDuAn
    PostListService(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Thêm mới Product/ Service thành công",
        });
        setLoadings(false);
        fetchListDataService();
        handleCancelAddProduct();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="ADD PRODUCT SERVICE"
      open={isModalOpenAddProduct}
      onOk={handleOkAddProduct}
      onCancel={handleCancelAddProduct}
      footer={false}
      width={800}
      className="headerTitleModal"
      forceRender={true}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Category"
              name="categoryId"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Category!",
                },
              ]}
            >
              <Select size="large" placeholder="Category">
                {dataOptionCategory.map((e) => (
                  <Option key={e.id} value={e.id}>
                    {e.category}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Product/ Service"
              name="servicename"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Product/ Service!",
                },
              ]}
            >
              <Input size="large" placeholder="Product/ Service" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelAddProduct}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalAddProductService;
