import { authAxios } from "../api/axiosClient";
import { downloadRevenueVer, getLogRevenue, getRevenueVer } from "../constants";


export const GetRevenueVer = (params = {}) => {
    const url = getRevenueVer;
    return authAxios().get(url, { params });
};

export const GetLogRevenue = (params = {}) => {
    const url = getLogRevenue;
    return authAxios().get(url, { params });
};
export const DownloadRevenueVer = (params = {}) => {
    const url = downloadRevenueVer;
    return authAxios().get(url, { params });
};

export const EditRevenueVer = (params = {}) => {
    const url = getRevenueVer;
    return authAxios().post(url, params );
};