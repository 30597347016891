/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import {
  Button,
  DatePicker,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import "./Check.css";
import { GetDataCheck } from "../../api/lastCheckapi";

import dayjs from "dayjs";
import ModalCheck from "./ModalCheck";
import { Loading3QuartersOutlined } from "@ant-design/icons";

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.12.313/build/pdf.worker.js';

const pdfjsLib = window.pdfjsLib;
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";

const Check = () => {
  const pageSize = 10;
  const [isOpenModalCheck, setIsOpenModalCheck] = React.useState(false);
  const [dataCheck, setDataCheck] = React.useState([]);
  const [detailDataLc, setDetailDataLc] = React.useState();
  const [dateDataCheck, setDateDataCheck] = React.useState();
  const [loadingTable, setLoadingTable] = React.useState(true);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [valueFilter, setValueFilter] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    defaultPageSize: 20,
    pageSize: 20,
    showSizeChanger: true,
  });
  const getColumnFilters = (dataFilter) => {
    if (dataFilter !== undefined) {
      const filters = dataFilter.reduce((acc, item) => {
        if (!acc.find((filter) => filter.value === item.clientname)) {
          acc.push({
            text: item.clientname,
            value: item.clientname,
          });
        }
        return acc;
      }, []);
      setValueFilter(filters)
      // return filters;
    }
  };

  const clearFilters = () => {
    setFilteredInfo([]);
  };

  const columns = [
    {
      title: <p className="text-header-check">STT</p>,
      dataIndex: "stt",
      width: "4%",
      align: "center",
      render: (value, record, index) => (
        <span>
          {dataCheck.length > 0
            ? index + 1 + (pagination.current - 1) * pagination.pageSize
            : null}
        </span>
      ),
    },
    {
      title: <p className="text-header-check">Khách hàng</p>,

      dataIndex: "clientname",
      // filters: getColumnFilters(),
      filters: valueFilter.map((item) => ({ text: item.text, value: item.value })), // Sử dụng giá trị từ filterValues

      // filterMode: "tree",
      filterSearch: true,
      filteredValue: filteredInfo.clientname || [],
      onFilter: (value, record) => record.clientname.startsWith(value),
      render: (value, record, index) => <span>{value}</span>,
      width: 100,
      align: "center",
    },
    {
      title: <p className="text-header-check">Dự án</p>,
      dataIndex: "projectname",
      width: 100,
      align: "center",
      render: (value, record, index) => <span>{value}</span>,
    },
    {
      title: <p className="text-header-check">Người gửi</p>,
      dataIndex: "user_upname",
      align: "center",
      width: 100,
      render: (value, record, index) => <span>{value}</span>,
    },
    {
      title: <p className="text-header-check">Ngày gửi</p>,
      dataIndex: "date_up",
      align: "center",
      width: 100,
    },
    {
      title: (
        <p className="text-header-check" style={{ textAlign: "center" }}>
          Thành tiền
        </p>
      ),
      dataIndex: "total_money",
      align: "right",
      width: 100,
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: <p className="text-header-check">Mã số</p>,
      dataIndex: "code",
      align: "center",
      width: 100,
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      width: "7%",
      align: "center",
      render: (value, record, index) => (
        <Button
          onClick={() => showModalCheck(record)}
          style={{
            color: "#337AEE",
            border: "1px solid #337AEE",
            padding: "4px 10px",
          }}
        >
          View
        </Button>
      ),
    },
  ];

  const showModalCheck = (value) => {
    setIsOpenModalCheck(true);
    setDetailDataLc(value);
  };

  const handleChange = (value) => {
    setLoadingSubmit(true);
    fetchDataCheck(value, "change_time")

  };

  const fetchDataCheck = (value, type) => {
    console.log(type)
    if (type === "first_time") {
      if (value.$D <= 10) {
        if (value.$M === 0) {

          GetDataCheck({
            // date: dayjs(value.$d).format("YYYY-MM-DD"),
            date: (value.$y - 1) + "-" + 12 + "-" + value.$D,
          })
            .then((res) => {
              clearFilters();
              setDataCheck(res.data.message);
              setDateDataCheck(value);
              setLoadingSubmit(false);
              getColumnFilters(res.data.message)
            })
            .catch((err) => {
              setLoadingSubmit(false);
            });
        } else {
          GetDataCheck({
            // date: dayjs(value.$d).format("YYYY-MM-DD"),
            date: value.$y + "-" + (value.$M) + "-" + value.$D,
          })
            .then((res) => {
              clearFilters();
              setDataCheck(res.data.message);
              setDateDataCheck(value);
              setLoadingSubmit(false);
              getColumnFilters(res.data.message)
            })
            .catch((err) => {
              setLoadingSubmit(false);
            });
        }
      } else {
        GetDataCheck({
          // date: dayjs(value.$d).format("YYYY-MM-DD"),
          date: value.$y + "-" + (value.$M + 1) + "-" + value.$D,
        })
          .then((res) => {
            clearFilters();
            setDataCheck(res.data.message);
            setDateDataCheck(value);
            setLoadingSubmit(false);
            getColumnFilters(res.data.message)
          })
          .catch((err) => {
            setLoadingSubmit(false);
          });
      }
    } else {
      GetDataCheck({
        // date: dayjs(value.$d).format("YYYY-MM-DD"),
        date: value.$y + "-" + (value.$M + 1) + "-" + value.$D,
      })
        .then((res) => {
          clearFilters();
          setDataCheck(res.data.message);
          setDateDataCheck(value);
          setLoadingSubmit(false);
          getColumnFilters(res.data.message)
        })
        .catch((err) => {
          setLoadingSubmit(false);
        });
    }
  }

  useEffect(() => {
    fetchDataCheck(dayjs(), "first_time")
    setTimeout(() => {
      setLoadingTable(false);
    }, 1000);
  }, []);

  const antIcon = <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />;

  const changePagination = (pagi, filters, sorter, extra) => {
    setPagination(pagi)
    setFilteredInfo(filters);
  };

  const checkDate = () => {
    if (dayjs().date() <= 10) {
      var d = new Date(dayjs().year(), dayjs().month() - 1, dayjs().date())
      setDateDataCheck(dayjs(d))
      return dayjs(d)
    } else {
      return dayjs()
    }
  }

  return (
    <>
      <div style={{ width: "98%", boxSizing: "border-box" }}>
        {/* Khi có dữ liệu sẽ set số lượng row cho table */}

        {loadingTable === true ? (
          <>
            <div className="loading-detail-table">
              <Spin
                indicator={antIcon}
                spinning={loadingTable}
                size="large"
              ></Spin>
            </div>
          </>
        ) : (
          <>
            <div className="bg-params-check">
              <div className="test-check">
                <label
                  className="typo-month-lc"
                  style={{ marginLeft: "2%", marginTop: "0.5%" }}
                >
                  Tháng dự án
                </label>
                <DatePicker
                  placeholder="Chọn tháng"
                  style={{
                    marginLeft: "2%",
                    marginTop: "0.2%",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  onChange={handleChange}
                  picker="month"
                  format={"MM-YYYY"}
                  allowClear={false}
                  defaultValue={checkDate}
                ></DatePicker>
              </div>
            </div>
            <Table
              columns={columns}
              rowClassName="text-body-check"
              className="table-lc"
              dataSource={dataCheck}
              style={{ marginLeft: "1%", marginTop: "1%", width: "100%" }}
              onChange={changePagination}
              pagination={{ defaultPageSize: 20, showSizeChanger: true }}

              scroll={{
                y: window.screen.availHeight > 850 ? "60vh" : "53vh",
              }}
              loading={loadingSubmit}
            />
          </>
        )}

        <ModalCheck
          isOpenModalCheck={isOpenModalCheck}
          setIsOpenModalCheck={setIsOpenModalCheck}
          detailDataLc={detailDataLc}
          handleChange={handleChange}
          dateDataCheck={dateDataCheck}
        />
      </div>
    </>
  );
};

export default Check;
