import React from "react";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { authLogin } from "../../app/Actions/auth";
import imgTong from "../../images/background.svg";
import imgLogin from "../../images/imgLogin.svg";
import imgLoginLogo from "../../images/LOGO IMAGE XANH.svg";
import Swal from "sweetalert2";

class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
    loadings: false,
    modalVisibleRestPass: false,
    isFocusedUser: false,
    isFocusedPass: false,
    checked: "",
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  handleModalForgotPass = () => {
    this.setState({
      modalVisibleRestPass: true,
    });
  };

  handleModalCancel = () => {
    this.setState({ modalVisibleRestPass: false });
  };

  handleFocus = () => {
    this.setState({ isFocusedUser: true });
  };

  handleBlur = () => {
    this.setState({ isFocusedUser: false });
  };

  handleFocus1 = () => {
    this.setState({ isFocusedPass: true });
  };

  handleBlur1 = () => {
    this.setState({ isFocusedPass: false });
  };

  onChangeRemember = (e) => {
    this.setState({ checked: e.target.checked });
  };

  constructor(props) {
    super(props);
    this.state = {
      username: localStorage.getItem("username") || "", // Lấy giá trị từ localStorage
      password: localStorage.getItem("password") || "",
      // Các trạng thái khác của component
    };
  }

  render() {
    const { loading, token } = this.props;
    const {
      loadings,
      isFocusedUser,
      isFocusedPass,
      checked,
      username,
      password,
    } = this.state;
    if (token) {
      // localStorage.setItem("currentSelectedKeys", JSON.stringify(['2']))
      return <Redirect to="/home" />;
    }

    const ToastWarning = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
      customClass: {
        popup: "my-custom-popup",
        timerProgressBar: "my-custom-progress-bar-warning", // Thêm class tùy chỉnh
      },
    });

    const onFinish = (values) => {
      this.props.login(values.username, values.password);

      if (checked === true) {
        localStorage.setItem("username", JSON.stringify(values.username));
        localStorage.setItem("password", JSON.stringify(values.password));
      }
    };

    const handleClickForgot = () => {
      ToastWarning.fire({
        icon: "warning",
        title: "Tính năng đang phát triển!",
      });
    };

    const svgFillColor = isFocusedUser ? "#6266EF" : "#9B9B9B";
    const svgFillColor1 = isFocusedPass ? "#6266EF" : "#9B9B9B";

    const screenWindown = window.screen.width <= 1550;
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ height: "100vh", background: "white", position: "relative" }}
      >
        <Col
          className="FormLogin"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={imgTong}
            alt=""
            width={screenWindown === true ? "65%" : "60%"}
            style={{ position: "absolute" }}
          />
          <Row
            style={screenWindown === true ? { width: "65%" } : { width: "60%" }}
          >
            <Col
              span={12}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <p
                  style={{
                    color: "#5465EF",
                    fontWeight: 700,
                    fontSize: "25px",
                  }}
                >
                  Let's build something great
                </p>
                <img src={imgLogin} alt="" style={{ marginTop: 40 }} />
              </div>
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <div
                style={{
                  width: "90%",
                  background: "#fff",
                  padding: "50px 40px",
                  borderRadius: "15px",
                  boxShadow: "-1px 2px 21px 0px #00000026",
                }}
              >
                <img src={imgLoginLogo} alt="" />
                <p
                  style={{
                    fontSize: "32px",
                    fontWeight: 700,
                    color: "#5C6485",
                    marginTop: 10,
                  }}
                >
                  Welcome!!
                </p>
                <div>
                  <Form
                    name="normal_login"
                    initialValues={{
                      remember: true,
                      username: username.replace(/['"]/g, "") || "",
                      password: password.replace(/['"]/g, "") || "",
                    }}
                    onFinish={onFinish}
                  >
                    <Form.Item className="FormItem">
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                          float: "left",
                        }}
                      >
                        User Name
                      </p>
                    </Form.Item>
                    <Form.Item
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập UserName",
                        },
                      ]}
                      className="FormItem"
                    >
                      <Input
                        size="large"
                        className="inputPageLogin"
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        suffix={
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                              stroke={svgFillColor}
                              strokeWidth="2"
                            />
                            <path
                              d="M15 10C15 11.6569 13.6568 13 12 13C10.3432 13 9 11.6569 9 10C9 8.34314 10.3432 7 12 7C13.6568 7 15 8.34314 15 10Z"
                              stroke={svgFillColor}
                              strokeWidth="1.5"
                            />
                            <path
                              d="M18 20C18 17.7909 15.3137 16 12 16C8.68629 16 6 17.7909 6 20"
                              stroke={svgFillColor}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                            />
                          </svg>
                        }
                        placeholder="User name"
                      />
                    </Form.Item>
                    <Form.Item className="FormItem">
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                          float: "left",
                          marginTop: "2%",
                        }}
                      >
                        Password
                      </p>
                    </Form.Item>

                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập Password!",
                        },
                      ]}
                      className="FormItem"
                    >
                      <Input.Password
                        size="large"
                        className="inputPageLogin"
                        placeholder="Password"
                        onFocus={this.handleFocus1}
                        onBlur={this.handleBlur1}
                        iconRender={(visible) =>
                          visible ? (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21 19V9C21 7.89543 20.1046 7 19 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19Z"
                                stroke={svgFillColor1}
                                strokeWidth="1.5"
                              />
                              <path
                                d="M13.5 13C13.5 12.1716 12.8284 11.5 12 11.5C11.1716 11.5 10.5 12.1716 10.5 13C10.5 13.8284 11.1716 14.5 12 14.5C12.8284 14.5 13.5 13.8284 13.5 13Z"
                                stroke={svgFillColor1}
                                strokeWidth="1.5"
                              />
                              <path
                                d="M12 15L12 17"
                                stroke={svgFillColor1}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.5 6.625C7.5 4.07068 9.44568 2 12 2C13.3984 2 14.6519 2.62066 15.5 3.60154"
                                stroke={svgFillColor1}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.5 7L7.5 6.5C7.5 4.01472 9.51472 2 12 2V2C14.4853 2 16.5 4.01472 16.5 6.5L16.5 7"
                                stroke={svgFillColor1}
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                              <path
                                d="M21 19V9C21 7.89543 20.1046 7 19 7H5C3.89543 7 3 7.89543 3 9V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19Z"
                                stroke={svgFillColor1}
                                strokeWidth="2"
                              />
                              <path
                                d="M13.5 13C13.5 12.1716 12.8284 11.5 12 11.5C11.1716 11.5 10.5 12.1716 10.5 13C10.5 13.8284 11.1716 14.5 12 14.5C12.8284 14.5 13.5 13.8284 13.5 13Z"
                                stroke={svgFillColor1}
                                strokeWidth="2"
                              />
                              <path
                                d="M12 15L12 17"
                                stroke={svgFillColor1}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )
                        }
                      />
                    </Form.Item>
                    <Row style={{ marginBottom: 10 }}>
                      <Col span={12}>
                        <Form.Item className="FormItem">
                          <Checkbox
                            style={{
                              fontSize: "16px",
                              color: "#9B9B9B",
                              float: "left",
                              fontWeight: 400,
                            }}
                            className="checkboxRemember"
                            onChange={this.onChangeRemember}
                          >
                            Remember me
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item className="FormItem">
                          <Link
                            // to={"/forgot"}
                            style={{ fontWeight: 400, color: "#9B9B9B" }}
                            className="login-form-forgot"
                            onClick={handleClickForgot}
                          >
                            Forgot My Password?
                          </Link>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item className="FormItem">
                      <Button
                        htmlType="submit"
                        loading={loading}
                        onClick={() => loadings}
                        size="large"
                        style={{
                          width: "100%",
                          background: "#5465EF",
                          color: "#fff",
                          fontWeight: 700,
                          display: "flex",
                          textAlign: "center",
                          margin: "auto",
                          fontSize: "16px",
                        }}
                        className="button-with-icon"
                      >
                        SIGN UP
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
