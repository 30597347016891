import * as React from "react";
import {
  Button,
  Col,
  DatePicker,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import "./ProjectDetailSale.css";
import dayjs from "dayjs";
import NoData from "../../images/noData.svg";
import EditIcon from "../../images/Pen.svg";
import DownloadIcon from "../../images/IconDownload.svg";
import RevenueIcon from "../../images/IconRevenue.svg";
import CreateBillIcon from "../../images/CreateBill.svg";

import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import {
  CreateBillSale,
  CreateRevenueSale,
  GetDataDetailProjectSale,
  GetFileExport,
  GetLogDetailSale,
  UpdateRevenueSale,
} from "../../api/detailProject";
import { openNotificationWithIcon } from "../../Function";
import { IconButton } from "@mui/material";
import { exportFileBill } from "../../constants";
import FormConFirmSale from "./FormConFirmSale";
import FormCreateRevenueSale from "./form_normal/FormCreateRevenueSale";
import FormEditRevenueSale from "./form_normal/FormEditRevenueSale";
import FormCreateRevenueResponSale from "./form_responsive/FormCreateRevenueResponSale";
import FormEditRevenueResponSale from "./form_responsive/FormEditRevenueResponSale";

const { Option } = Select;

const validateMessages = {
  required: "Không được để trống!",
  types: {
    email: "${label} is not a valid email!",
    // eslint-disable-next-line no-template-curly-in-string
    number: "${label} is not a valid number!",
  },
  pattern: {
    mismatch: "Chỉ được nhập số nguyên",
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    range: "${label} must be between ${min} and ${max}",
  },
};

export default function ProjectDetailSale() {
  const [loading, setLoading] = React.useState(true);
  const [loadingBtnDtdk, setLoadingBtnDtdk] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const [isOpenModalCreateBill, setIsOpenModalCreateBill] =
    React.useState(false);
  const [isOpenModalLogDetail, setIsOpenModalLogDetail] = React.useState(false);
  const [isOpenExpectedRevenue, setIsOpenExpectedRevenue] =
    React.useState(false);
  const [isOpenEditExpectedRevenue, setIsOpenEditExpectedRevenue] =
    React.useState(false);
  const [isOpenBtnEditExpectRevenue, setIsOpenBtnEditExpectRevenue] =
    useState(false);

  const [checkExpectedRevenue, setCheckExpectedRevenue] = React.useState(false);
  const [listDataDep, setListDataDep] = React.useState([]);

  const [listDataProjectOfDep, setListDataProjectOfDep] = React.useState([]);
  const [dataPerMonth, setDataPerMonth] = useState();
  const [dataLogDetail, setDataLogDetail] = useState();
  const [userRole, setUserRole] = React.useState("");

  const [dataDetailProject, setDataDetailProject] = useState([]);
  const [dataBasicProject, setDataBasicProject] = useState();
  const [valuesDetail, setValuesDetail] = useState();
  const [indexRow, setIndexRow] = useState(0);

  const [formGetDataDetail] = Form.useForm();
  const [formExpectedRevenue] = Form.useForm();
  const [formEditExpectedRevenue] = Form.useForm();
  const [formCreateBill] = Form.useForm();

  const [idProjectDownload, setIdProjectDownload] = useState();
  const [loadingBtnDownloadFile, setLoadingBtnDownloadFile] = useState(false);

  const [listClientOfUser, setListClientOfUser] = useState([]);

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const columns = [
    {
      title: "",
      width: 50,
      dataIndex: "month",
      key: "month",
      render: (value, item, index) => (
        <div className="typo-month">
          <span>{value}</span>
        </div>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Expected Revenue</p>,
      width: 80,
      dataIndex: "expected_revenue",
      key: "expected_revenue",
      align: "right",
      render: (value, item, index) => (
        <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "1",
      align: "center",
      width: 80,
      render: (value, item, index) => (
        <>
          {isOpenModalCreateBill === true && index === indexRow ? (
            <Form.Item
              name="start_date"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker format={"DD-MM-YYYY"}></DatePicker>
            </Form.Item>
          ) : (
            <p>{value}</p>
          )}
        </>
      ),
    },
    {
      title: "End Date",
      dataIndex: "close_date",
      key: "1",
      align: "center",
      width: 80,
      render: (value, item, index) => (
        <>
          {isOpenModalCreateBill === true && index === indexRow ? (
            <Form.Item
              name="end_date"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker
                disabledDate={disabledDate}
                format={"DD-MM-YYYY"}
              ></DatePicker>
            </Form.Item>
          ) : (
            <p>{value}</p>
          )}
        </>
      ),
    },
    {
      title: (
        <p style={{ textAlign: "center" }}>
          {"Unit price " +
            (dataBasicProject ? dataBasicProject.currency.code : null)}
        </p>
      ),
      dataIndex: "unit_price",
      key: "2",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isOpenModalCreateBill === true && index === indexRow ? (
            <Form.Item
              name="unit_price"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  }
                }}
                min={0}
                controls={false}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "3",
      align: "center",
      width: 80,
      render: (value, item, index) => (
        <>
          {isOpenModalCreateBill === true && index === indexRow ? (
            <Form.Item
              name="quantity"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                // onChange={changeMoneyVND}
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 && charCode !== 46) || charCode > 57) {
                    e.preventDefault();
                  }
                }}
                min={0}
                controls={false}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Exchange Rate</p>,
      dataIndex: "exchange_rate",
      key: "4",
      width: 60,
      align: "right",
      render: (value, item, index) => (
        <>
          <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Price Excluding VND</p>,
      dataIndex: "price_exchange",
      key: "5",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isOpenModalCreateBill === true && index === indexRow ? (
            <Form.Item
              name="price_excluding"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={changeProjectAmount}
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    e.preventDefault();
                  }
                }}
                min={0}
                controls={false}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Discount</p>,
      dataIndex: "discount",
      key: "11",
      width: 60,
      align: "right",
      render: (value, item, index) => (
        <>
          {isOpenModalCreateBill === true && index === indexRow ? (
            <Form.Item
              name="discount"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={changeProjectAmount}
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    e.preventDefault();
                  }
                }}
                min={0}
                controls={false}
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: <p style={{ textAlign: "center" }}>Project Amount</p>,

      dataIndex: "project_amount",
      key: "12",
      width: 80,
      align: "right",
      render: (value, item, index) => (
        <>
          {isOpenModalCreateBill === true && index === indexRow ? (
            <Form.Item
              name="project_amount"
              style={{ height: 7 }}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber
                // value={valueRevenueExcluding}
                className="right-to-left-input"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onChange={changeProjectAmount}
                style={{ width: "100%" }}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    e.preventDefault();
                  }
                }}
                disabled
              />
            </Form.Item>
          ) : (
            <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
          )}
        </>
      ),
    },
    {
      title: "Action",
      key: "operation",
      align: "center",
      width: 60,
      render: (value, item, index) => (
        <>
          {isOpenModalCreateBill === true && index === indexRow ? (
            <Form.Item style={{ height: 7, width: "100%" }}>
              <IconButton type="submit">
                <CheckOutlined style={{ color: "green" }} />
              </IconButton>
              <IconButton type="button" onClick={handleCancelCreateBill}>
                <CloseOutlined style={{ color: "red" }} />
              </IconButton>
            </Form.Item>
          ) : value.dealstage === 2 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip placement="top" title={"Tạo phiếu tính tiền"}>
                <img
                  src={CreateBillIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() => showModalCreateBill(value, index)}
                  alt=""
                ></img>
              </Tooltip>
              <Tooltip placement="topLeft" title={"Không có doanh thu"}>
                <Popconfirm
                  title="Thông báo"
                  description="Xác nhận tháng này không có doanh thu?"
                  onConfirm={() => confirmNoRevenue(value)}
                  // onCancel={cancelNoRevenue}
                  placement="topLeft"
                  okText="Có"
                  cancelText="Không"
                >
                  <CloseCircleOutlined
                    style={{
                      color: "red",
                      fontSize: 20,
                      cursor: "pointer",
                      marginLeft: 16,
                    }}
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          ) : value.dealstage === 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tooltip placement="topLeft" title={"Chỉnh sửa phiếu tính tiền"}>
                <img
                  src={EditIcon}
                  alt=""
                  onClick={() => showEditCreateBill(value, index)}
                  style={{ cursor: "pointer" }}
                ></img>
              </Tooltip>
              <Tooltip placement="top" title={"Lịch sử thao tác"}>
                <HistoryOutlined
                  onClick={() => showModalLogDetail(value)}
                  style={{
                    color: "green",
                    fontSize: 20,
                    cursor: "pointer",
                    marginLeft: 16,
                  }}
                />
              </Tooltip>
            </div>
          ) : null}
        </>
      ),
    },
  ];

  const showExpectedRevenue = () => {
    setIsOpenExpectedRevenue(true);
  };

  const showEditExpectedRevenue = () => {
    setIsOpenEditExpectedRevenue(true);

    const listExpectRevenue = dataDetailProject.map(
      (item) => item.expected_revenue
    );
    formEditExpectedRevenue.setFieldValue("thang_1", listExpectRevenue[0]);
    formEditExpectedRevenue.setFieldValue("thang_2", listExpectRevenue[1]);
    formEditExpectedRevenue.setFieldValue("thang_3", listExpectRevenue[2]);
    formEditExpectedRevenue.setFieldValue("thang_4", listExpectRevenue[3]);
    formEditExpectedRevenue.setFieldValue("thang_5", listExpectRevenue[4]);
    formEditExpectedRevenue.setFieldValue("thang_6", listExpectRevenue[5]);
    formEditExpectedRevenue.setFieldValue("thang_7", listExpectRevenue[6]);
    formEditExpectedRevenue.setFieldValue("thang_8", listExpectRevenue[7]);
    formEditExpectedRevenue.setFieldValue("thang_9", listExpectRevenue[8]);
    formEditExpectedRevenue.setFieldValue("thang_10", listExpectRevenue[9]);
    formEditExpectedRevenue.setFieldValue("thang_11", listExpectRevenue[10]);
    formEditExpectedRevenue.setFieldValue("thang_12", listExpectRevenue[11]);
  };

  const confirmNoRevenue = (value) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", value.detailid);
    UpdateRevenueSale(data)
      .then((res) => {
        callDataDetailProject();
        openNotificationWithIcon("success", "Khai báo thành công");
      })
      .catch((err) => {
        openNotificationWithIcon("error", "Khai báo thất bại");
      });
  };

  const changeProjectAmount = (event) => {
    if (formCreateBill.getFieldValue("price_excluding") === undefined) {
      formCreateBill.setFieldValue(
        "project_amount",
        Math.round(
          event.target.value - formCreateBill.getFieldValue("discount")
        )
      );
    } else if (formCreateBill.getFieldValue("discount") === undefined) {
      formCreateBill.setFieldValue(
        "project_amount",
        Math.round(formCreateBill.getFieldValue("price_excluding") - 0)
      );
    } else {
      formCreateBill.setFieldValue(
        "project_amount",
        Math.round(
          formCreateBill.getFieldValue("price_excluding") -
          formCreateBill.getFieldValue("discount")
        )
      );
    }
  };

  const showModalCreateBill = (value, index) => {
    setIndexRow(index);
    setDataPerMonth(value);
    setIsOpenModalCreateBill(true);
  };

  const showEditCreateBill = (value, index) => {
    formCreateBill.setFieldsValue({
      start_date:
        value.start_date !== null
          ? dayjs(value.start_date, "DD-MM-YYYY")
          : dayjs(),
      end_date:
        value.close_date !== null
          ? dayjs(value.close_date, "DD-MM-YYYY")
          : dayjs(),
      unit_price: value.unit_price,
      quantity: value.quantity,
      discount: value.discount,
      project_amount: value.project_amount,
      price_excluding: value.price_exchange,
    });
    setIndexRow(index);
    setDataPerMonth(value);
    setIsOpenModalCreateBill(true);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return (
      formCreateBill.getFieldValue("start_date") &&
      formCreateBill.getFieldValue("start_date") > current
    );
  };

  const showModalLogDetail = (value) => {
    setDataLogDetail(value);
    setIsOpenModalLogDetail(true);
  };

  const handleCancelCreateBill = () => {
    setIsOpenModalCreateBill(false);
    formCreateBill.resetFields();
  };

  const onFinishImport = (values) => {
    setLoadingConfirm(true);
    setIsOpenExpectedRevenue(false);
    const yearDetail = values.date.$y;
    GetDataDetailProjectSale({
      id_project: values.project,
      year: yearDetail,
    })
      .then((res) => {
        setValuesDetail(values);
        setDataBasicProject(res.data.project);
        setIdProjectDownload(values.project);
        if (res.data.detail_project.length > 1) {
          // setDataDetailProject([...res.data.detail_project, {
          //     month: "Total",
          //     expected_revenue: res.data.detail_project[12].sum_expected_revenue,
          //     money: res.data.detail_project[12].sum_money,
          //     revenue_excluding_tax: res.data.detail_project[12].sum_revenue_excluding_tax,
          //     tax: res.data.detail_project[12].sum_tax,
          //     total_revenue: res.data.detail_project[12].sum_total_revenue,
          //     expected_revenue_vnd: res.data.detail_project[12].sum_expected_revenue_vnd,
          //     revenue_excluding_tax_vnd: res.data.detail_project[12].sum_revenue_excluding_tax_vnd,
          //     total_revenue_vnd: res.data.detail_project[12].sum_total_revenue_vnd,
          //     tax_vnd: res.data.detail_project[12].sum_tax_vnd,
          // }])
          setDataDetailProject(res.data.detail_project);
          const checkDealStage = res.data.detail_project.filter(
            (item) => item.dealstage === 2
          );

          // if (checkDealStage.length < 1) {
          //   setIsOpenBtnEditExpectRevenue(false);
          // } else {
          setIsOpenBtnEditExpectRevenue(true);
          // }
        } else {
          setDataDetailProject(res.data.detail_project);
        }
        setIsOpenEditExpectedRevenue(false);
        setLoadingConfirm(false);
        setIsOpenModalCreateBill(false);
      })
      .catch((err) => {
        setLoadingConfirm(false);
      });
  };

  useEffect(() => {
    if (dataDetailProject.length === 1) {
      setCheckExpectedRevenue(true);
    } else {
      setCheckExpectedRevenue(false);
    }
  }, [dataDetailProject]);

  const onFinishExpectedRevenue = (values) => {
    setLoadingBtnDtdk(true);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("thang_1", values.thang_1);
    data.append("thang_2", values.thang_2);
    data.append("thang_3", values.thang_3);
    data.append("thang_4", values.thang_4);
    data.append("thang_5", values.thang_5);
    data.append("thang_6", values.thang_6);
    data.append("thang_7", values.thang_7);
    data.append("thang_8", values.thang_8);
    data.append("thang_9", values.thang_9);
    data.append("thang_10", values.thang_10);
    data.append("thang_11", values.thang_11);
    data.append("thang_12", values.thang_12);
    data.append("id_project", valuesDetail.project);
    data.append("message", "create");
    data.append("year", valuesDetail.date.$y);

    let call = document.getElementById("btn-view");

    CreateRevenueSale(data)
      .then((res) => {
        call.addEventListener("click", onFinishImport(valuesDetail));
        formExpectedRevenue.resetFields();
        setIsOpenExpectedRevenue(false);
        setLoadingBtnDtdk(false);
        openNotificationWithIcon("success", "Tạo doanh thu dự kiến thành công");
      })
      .catch((err) => {
        setLoadingBtnDtdk(false);
        openNotificationWithIcon("error", "Tạo doanh thu dự kiến thất bại");
      });
  };

  const onFinishEditExpectedRevenue = (values) => {
    setLoadingBtnDtdk(true);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("thang_1", values.thang_1);
    data.append("thang_2", values.thang_2);
    data.append("thang_3", values.thang_3);
    data.append("thang_4", values.thang_4);
    data.append("thang_5", values.thang_5);
    data.append("thang_6", values.thang_6);
    data.append("thang_7", values.thang_7);
    data.append("thang_8", values.thang_8);
    data.append("thang_9", values.thang_9);
    data.append("thang_10", values.thang_10);
    data.append("thang_11", values.thang_11);
    data.append("thang_12", values.thang_12);
    data.append("id_project", valuesDetail.project);
    data.append("message", "update");
    data.append("year", valuesDetail.date.$y);

    let call = document.getElementById("btn-view");

    CreateRevenueSale(data)
      .then((res) => {
        call.addEventListener("click", onFinishImport(valuesDetail));
        formEditExpectedRevenue.resetFields();
        setIsOpenEditExpectedRevenue(false);
        setLoadingBtnDtdk(false);
        openNotificationWithIcon(
          "success",
          "Chỉnh sửa doanh thu dự kiến thành công"
        );
      })
      .catch((err) => {
        setLoadingBtnDtdk(false);
        openNotificationWithIcon(
          "error",
          "Chỉnh sửa doanh thu dự kiến thất bại"
        );
      });
  };

  const onFinishCreateBill = (values) => {
    if (values.project_amount >= 0) {
      formCreateBill.resetFields();
      // setLoadingModal(true)
      var FormData = require("form-data");
      var data = new FormData();

      data.append("start_date", dayjs(values.start_date).format("DD-MM-YYYY"));
      data.append("end_date", dayjs(values.end_date).format("DD-MM-YYYY"));
      data.append("unit_price", values.unit_price);
      data.append("quantity", values.quantity);
      data.append("price_excluding", values.price_excluding);
      data.append("discount", values.discount);
      data.append("project_amount", values.project_amount);
      data.append("id_detail", dataPerMonth.detailid);
      CreateBillSale(data)
        .then((res) => {
          setIsOpenModalCreateBill(false);
          callDataDetailProject();
          formCreateBill.resetFields();
          openNotificationWithIcon("success", "Đã tạo phiếu thành công");
          // setLoadingModal(false)
        })
        .catch((err) => {
          // setLoadingModal(false)
          openNotificationWithIcon("error", "Tạo phiếu thất bại");
        });
    } else {
      openNotificationWithIcon(
        "error",
        "Price Excluding VND phải lớn hơn Discount"
      );
    }
  };

  const callDataDetailProject = () => {
    let call = document.getElementById("btn-view");
    call.addEventListener("click", onFinishImport(valuesDetail));
  };

  const ChooseDepartment = (e) => {
    formGetDataDetail.setFieldValue("client_code", undefined);
    const listFilter = userInfo.listClientSale.filter(
      (item) => item.depart_id === e
    );
    console.log(e)
    ChooseClient(
      userInfo.listClientSale.length > 0
        ? userInfo.listClientSale[0].client_id
        : null
    );
    if (listFilter.length > 0) {
      formGetDataDetail.setFieldValue(
        "client_code",
        userInfo.listClientSale[0].client_id
      );
      setListClientOfUser(listFilter);
    } else {
      setListClientOfUser(listClientOfUser);
    }
  };

  const ChooseClient = (e) => {
    formGetDataDetail.setFieldValue("project", undefined);
    const listFilter = userInfo.listProject_sale.filter(
      (item) => item.client_id === e
    );
    if (listFilter.length > 0) {
      formGetDataDetail.setFieldValue("project", listFilter[0].id);
      setListDataProjectOfDep(listFilter);
    } else {
      setListDataProjectOfDep([]);
    }
  };

  React.useEffect(() => {
    if (userInfo !== undefined && userInfo.listDepOfProject.length > 0) {
      setLoading(false);

      // Array of department id
      let arrDepart = [];
      userInfo.listProject_sale.forEach((item) =>
        arrDepart.push(item.depart_id)
      );

      // Array of department unique id
      var unique = arrDepart.filter(
        (value, index, array) => array.indexOf(value) === index
      );

      // Department list have project
      const listDepart = userInfo.listDepOfProject.filter((item) =>
        unique.includes(item.depart_id)
      );
      setListDataDep(listDepart);
      // setListDataProjectOfDep(userInfo.listProject_sale);
      ChooseDepartment(
        userInfo.listProject_sale.length > 0
          ? listDepart[0].depart_id
          : null
      );
      if (listDepart.length > 1) {
        formGetDataDetail.setFieldValue("department", listDepart[0].depart_id);
        setUserRole("Admin");
      } else if (listDepart.length === 1) {
        formGetDataDetail.setFieldValue(
          "department",
          listDepart[0].depart_name
        );
        setUserRole("Member");
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    formExpectedRevenue.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formGetDataDetail.setFieldValue("date", dayjs());
  }, [formGetDataDetail]);

  const closeEditExpectedRevenue = () => {
    setIsOpenEditExpectedRevenue(false);
  };

  const [listDisable, setListDisable] = useState([]);

  useEffect(() => {
    const listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const monthNow = dayjs().format("M");
    const listInputDisable = [];
    if (isOpenEditExpectedRevenue === true) {
      for (let i = 0; i < listMonth.length; i++) {
        if (listMonth[i] < monthNow) {
          ;
          listInputDisable.push(listMonth[i]);
        }
      }
    }
    setListDisable(listInputDisable);
  }, [isOpenEditExpectedRevenue]);

  const closeCreateRevenue = () => {
    setIsOpenExpectedRevenue(false);
    formExpectedRevenue.resetFields();
  };

  const GetFileBill = () => {
    setLoadingBtnDownloadFile(true);
    GetFileExport({
      id: idProjectDownload,
    })
      .then((res) => {
        let file_path = `${exportFileBill}?id=${idProjectDownload}`;
        let a = document.createElement("a");
        a.href = file_path;
        a.download = file_path;
        // document.body.appendChild(a);
        a.click();
        setLoadingBtnDownloadFile(false);
        // document.body.removeChild(a);
      })
      .catch((err) => {
        setLoadingBtnDownloadFile(false);
        openNotificationWithIcon("error", err.data.message);
      });
  };

  return (
    <>
      {loading === true ? (
        <div className="loading-detail-pj">
          <Spin spinning={loading} size="large"></Spin>
        </div>
      ) : (
        <div style={{ position: "relative", width: "100%" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "12vh",
            }}
          >
            <div className="get-detail-pj">
              <FormConFirmSale
                formGetDataDetail={formGetDataDetail}
                onFinishImport={onFinishImport}
                validateMessages={validateMessages}
                userInfo={userInfo}
                userRole={userRole}
                ChooseDepartment={ChooseDepartment}
                listDataDep={listDataDep}
                ChooseClient={ChooseClient}
                listClientOfUser={listClientOfUser}
                listDataProjectOfDep={listDataProjectOfDep}
                loadingConfirm={loadingConfirm}
              />

              <Row className="btn-revenue-row">
                <Col span={8} style={{ display: "flex" }}>
                  {dataBasicProject ? (
                    <Button
                      htmlType="button"
                      loading={loadingBtnDownloadFile}
                      className="btn-downloadFile"
                      onClick={GetFileBill}
                    >
                      DOWNLOAD FILE{" "}
                      <img
                        style={{ marginLeft: 8 }}
                        src={DownloadIcon}
                        alt=""
                      ></img>
                    </Button>
                  ) : null}

                  {dataBasicProject && dataDetailProject.length === 1 ? (
                    <Button
                      className="btn-revenue"
                      onClick={showExpectedRevenue}
                    >
                      CREATE REVENUE{" "}
                      <img
                        style={{ marginLeft: 8 }}
                        src={RevenueIcon}
                        alt=""
                      ></img>
                    </Button>
                  ) : null}

                  {dataDetailProject &&
                    isOpenBtnEditExpectRevenue === true &&
                    dataDetailProject.length > 1 ? (
                    <Button
                      className="btn-revenue"
                      onClick={showEditExpectedRevenue}
                    >
                      EDIT REVENUE{" "}
                      <img
                        style={{ marginLeft: 8 }}
                        src={RevenueIcon}
                        alt=""
                      ></img>
                    </Button>
                  ) : null}
                </Col>
                <Col span={15}></Col>
              </Row>

            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "12vh",
              marginTop: "20px",
            }}
          >
            <div className="information">
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.3%",
                }}
              >
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Currency:{" "}
                      {dataBasicProject ? dataBasicProject.currency.code : null}
                    </p>
                  </div>
                </Col>
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Market:{" "}
                      {dataBasicProject ? dataBasicProject.market.name : null}
                    </p>
                  </div>
                </Col>
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Client code:{" "}
                      {dataBasicProject ? dataBasicProject.client.name : null}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0.5%",
                }}
              >
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Product/Service:{" "}
                      {dataBasicProject
                        ? dataBasicProject.pdservice.code
                        : null}
                    </p>
                  </div>
                </Col>
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Bank account:{" "}
                      {dataBasicProject ? dataBasicProject.bank.name : null}
                    </p>
                  </div>
                </Col>
                <Col span={8} className="center-col">
                  <div className="center-div">
                    <p className="text-basic">
                      Deal owner:{" "}
                      {dataBasicProject ? dataBasicProject.dealown : null}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              width: "100%",
              marginTop: "2%",
            }}
          >
            {dataDetailProject.length > 1 &&
              isOpenEditExpectedRevenue === false ? (
              <Form
                form={formCreateBill}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinishCreateBill}
                // onFinishFailed={onFinishFailed}
                validateMessages={validateMessages}
                initialValues={{
                  remember: true,
                }}
                className="custom-form"
                key="formImport"
                style={{ width: "99%", marginLeft: "1%" }}
              >
                <Table
                  columns={columns}
                  dataSource={dataDetailProject}
                  style={{ width: "99%" }}
                  scroll={{
                    y: window.screen.availHeight > 850 ? "56vh" : "52vh",
                    x: 1500,
                  }}
                  pagination={false}
                  className="custom-table table-striped-rows"
                  bordered
                  rowClassName={(record, index) =>
                    index % 2 === 0 ? "table-row-light" : "table-row-dark"
                  }
                />
              </Form>
            ) : null}

            {isOpenExpectedRevenue === false &&
              checkExpectedRevenue === true ? (
              <img src={NoData} alt="" style={{ marginTop: "10%" }}></img>
            ) : null}
          </div>

          {/* screen width > 1600px */}

          {window.screen.width > 1600 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {isOpenExpectedRevenue === true &&
                dataDetailProject.length === 1 ? (
                <FormCreateRevenueSale
                  valuesDetail={valuesDetail}
                  formExpectedRevenue={formExpectedRevenue}
                  onFinishExpectedRevenue={onFinishExpectedRevenue}
                  validateMessages={validateMessages}
                  loadingBtnDtdk={loadingBtnDtdk}
                  closeCreateRevenue={closeCreateRevenue}
                />
              ) : null}

              {isOpenEditExpectedRevenue === true ? (
                <FormEditRevenueSale
                  valuesDetail={valuesDetail}
                  formEditExpectedRevenue={formEditExpectedRevenue}
                  onFinishEditExpectedRevenue={onFinishEditExpectedRevenue}
                  validateMessages={validateMessages}
                  listDisable={listDisable}
                  loadingBtnDtdk={loadingBtnDtdk}
                  closeEditExpectedRevenue={closeEditExpectedRevenue}
                />
              ) : null}
            </div>
          ) : (
            <>
              {/* screen width < 1600 */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "14px",
                }}
              >
                {isOpenExpectedRevenue === true &&
                  dataDetailProject.length === 1 ? (
                  <FormCreateRevenueResponSale
                    valuesDetail={valuesDetail}
                    formExpectedRevenue={formExpectedRevenue}
                    onFinishExpectedRevenue={onFinishExpectedRevenue}
                    validateMessages={validateMessages}
                    loadingBtnDtdk={loadingBtnDtdk}
                    closeCreateRevenue={closeCreateRevenue}
                  />
                ) : null}
                {isOpenEditExpectedRevenue === true ? (
                  <FormEditRevenueResponSale
                    valuesDetail={valuesDetail}
                    formEditExpectedRevenue={formEditExpectedRevenue}
                    onFinishEditExpectedRevenue={onFinishEditExpectedRevenue}
                    validateMessages={validateMessages}
                    listDisable={listDisable}
                    loadingBtnDtdk={loadingBtnDtdk}
                    closeEditExpectedRevenue={closeEditExpectedRevenue}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      )}
      <ModalLogDetail
        isOpenModalLogDetail={isOpenModalLogDetail}
        setIsOpenModalLogDetail={setIsOpenModalLogDetail}
        dataLogDetail={dataLogDetail}
      />
    </>
  );
}

const ModalLogDetail = ({
  isOpenModalLogDetail,
  setIsOpenModalLogDetail,
  dataLogDetail,
}) => {
  const [dataLog, setDataLog] = useState([]);

  const columns = [
    {
      title: "Thời gian",
      width: 50,
      dataIndex: "time",
      key: "time",
      align: "center",
    },
    {
      title: "Người thao tác",
      dataIndex: "user",
      key: "user",
      width: 50,
      align: "center",
    },
    {
      title: "Chi tiết",
      dataIndex: "detail",
      key: "detail",
      width: 200,
      align: "center",
    },
  ];
  const handleCancel = () => {
    setIsOpenModalLogDetail(false);
    if (dataLog.length > 0) {
      var elem = document.getElementById("elem_id");
      elem.scrollIntoView(true);
    }
  };

  const getDataLogDetail = () => {
    GetLogDetailSale({
      id: dataLogDetail.detailid,
    })
      .then((res) => {
        setDataLog(res.data.log);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (isOpenModalLogDetail === true) {
      getDataLogDetail();
    }
  }, [isOpenModalLogDetail]);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="title-bill-log">
              <span className="title-field-bill" style={{ fontSize: 22 }}>
                View Log
              </span>
            </div>
          </div>
        }
        open={isOpenModalLogDetail}
        onCancel={handleCancel}
        width={1000}
        closable={false}
        footer={false}
        style={{ marginTop: 40 }}
      >
        {dataLog && dataLog.length > 0 ? (
          <Table
            columns={columns}
            dataSource={dataLog}
            style={{
              width: "100%",
              marginTop: 20,
              overflow: "auto",
              height: 500,
            }}
            pagination={false}
            className="custom-table table-striped-rows"
            rowClassName="text-body-log"
            bordered
            id="elem_id"
          />
        ) : (
          <div>
            <Empty style={{ display: "initial" }} />
          </div>
        )}
      </Modal>
    </>
  );
};