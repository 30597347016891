/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Spin,
    Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import "./Check.css";
import { GetDataCheck, PostDataCheck } from "../../api/lastCheckapi";
import { localhost } from "../../server";
import {
    CheckOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    Loading3QuartersOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { openNotificationWithIcon } from "../../Function";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const { createCanvas } = require("canvas");

// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

// pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.12.313/build/pdf.worker.js';

const pdfjsLib = window.pdfjsLib;
pdfjsLib.GlobalWorkerOptions.workerSrc =
    "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";

const { TextArea } = Input;

const validateMessages = {
    required: "Không được để trống!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    pattern: {
        mismatch: "Chỉ được nhập số nguyên",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const ModalCheck = ({
    isOpenModalCheck,
    setIsOpenModalCheck,
    detailDataLc,
    handleChange,
    dateDataCheck,
}) => {
    const [urlPDF, setUrlPDF] = React.useState();
    const [imagesURL, setImagesURL] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [loadingImg, setLoadingImg] = React.useState(false);
    const [numPagesPDF, setNumPagesPDF] = React.useState();
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [isOpenUpload, setIsOpenUpload] = React.useState(false);
    const [idDetailEntry, setIdDetailEntry] = React.useState(0);

    const [formCheck] = Form.useForm();

    const handleCancel = () => {
        setIsOpenModalCheck(false);
        setIsOpenUpload(false);
        setCurrentPage(1);
    };

    const GetDetailDataLC = () => {
        GetDataCheck({
            id_detailproject: detailDataLc.id_detailproject_entry,
        })
            .then((res) => {
                setIdDetailEntry(res.data.message[0].id_detailproject_entry);
                formCheck.setFieldsValue({
                    invoice_code: res.data.message[0].code,
                    exchange_rate: res.data.message[0].exchange_rate,
                    revenue_excluding_tax_changed:
                        res.data.message[0].revenue_excluding_tax_changed,
                    revenue_excluding_tax: res.data.message[0].revenue_excluding_tax,
                    tax: res.data.message[0].tax,
                    tax_changed: res.data.message[0].tax_changed,
                    total_revenue: res.data.message[0].total_revenue,
                    total_revenue_changed: res.data.message[0].total_revenue_changed,
                    date_up: dayjs(res.data.message[0].date_up, "DD-MM-YYYY"),
                    date_pay: dayjs(res.data.message[0].date_pay, "DD-MM-YYYY"),
                    user_up: res.data.message[0].user_up.name,
                    currency: res.data.message[0].currency.name,
                    project_detail: res.data.message[0].project_detail,
                });
                setUrlPDF(res.data.message[0].src);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (isOpenModalCheck === true) {
            GetDetailDataLC();
        }
    }, [isOpenModalCheck]);

    function dataURLtoFile(dataURL, fileName) {
        var arr = dataURL.split(",");
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    }

    useEffect(() => {
        if (urlPDF !== undefined) {
            const convertToImage = async () => {
                try {
                    // Tạo đối tượng PDF từ dữ liệu PDF
                    setLoadingImg(true);
                    const pdf = await pdfjsLib.getDocument(localhost + "/" + urlPDF)
                        .promise;

                    // Lấy số trang của PDF
                    const numPages = pdf.numPages;

                    // Mảng chứa hình ảnh
                    const images = [];
                    const pageImagesURL = [];
                    // Duyệt qua từng trang PDF
                    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
                        // Lấy trang PDF
                        const page = await pdf.getPage(pageNumber);

                        // Lấy kích thước của trang
                        const viewport = page.getViewport({ scale: 1 });

                        // Tạo canvas để vẽ trang PDF
                        const canvas = createCanvas(viewport.width, viewport.height);
                        const context = canvas.getContext("2d");

                        // Render trang PDF lên canvas
                        await page.render({
                            canvasContext: context,
                            viewport: viewport,
                        }).promise;

                        // Convert canvas thành hình ảnh dưới dạng URL
                        const imageDataURL = canvas.toDataURL("image/jpeg", 90);
                        const convert_base64tofileimg = dataURLtoFile(
                            imageDataURL,
                            `page_${pageNumber}__.png`
                        );
                        // Thêm hình ảnh vào mảng
                        images.push(convert_base64tofileimg);
                        pageImagesURL.push(URL.createObjectURL(convert_base64tofileimg));
                    }
                    setImagesURL(pageImagesURL);
                    setLoadingImg(false);
                    // Trả về mảng hình ảnh
                    setNumPagesPDF(numPages);
                    return images;
                } catch (error) {
                    console.error("Lỗi chuyển đổi PDF thành hình ảnh:", error);
                    return [];
                }
            };
            convertToImage();
        }
    }, [urlPDF]);

    const handleNextPage = () => {
        if (currentPage < numPagesPDF) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const changeMoneyVND = (event, value) => {
        if (typeof event !== "number" && event !== null) {
            value = value * 1000;
            formCheck.setFieldValue(event.target.id, value);
        }
        formCheck.setFieldValue('revenue_excluding_tax_changed', Math.round(value * formCheck.getFieldValue('exchange_rate')));
        formCheck.setFieldValue(
            "tax",
            Math.round(value * 10 / 100)
        );

        formCheck.setFieldValue(
            "tax_changed",
            Math.round(formCheck.getFieldValue("tax") * formCheck.getFieldValue("exchange_rate"))
        );

        formCheck.setFieldValue(
            "total_revenue",
            Math.round(value + formCheck.getFieldValue("tax"))
        );

        formCheck.setFieldValue(
            "total_revenue_changed",
            Math.round(formCheck.getFieldValue("total_revenue") * formCheck.getFieldValue("exchange_rate"))
        );
    };

    const changeTaxVND = (event, value) => {
        if (typeof event !== "number" && event !== null) {
            value = value * 1000;
            formCheck.setFieldValue(event.target.id, value);
        }
        formCheck.setFieldValue('tax_changed', Math.round(value * formCheck.getFieldValue('exchange_rate')));
        formCheck.setFieldValue(
            "tax_changed",
            Math.round(value * formCheck.getFieldValue("exchange_rate"))
        );

        formCheck.setFieldValue(
            "total_revenue",
            Math.round(value + formCheck.getFieldValue("revenue_excluding_tax"))
        );

        formCheck.setFieldValue(
            "total_revenue_changed",
            Math.round(formCheck.getFieldValue("total_revenue") * formCheck.getFieldValue("exchange_rate"))
        );
    };

    const changeTotalVND = (event, value) => {
        if (typeof event !== "number" && event !== null) {
            value = value * 1000;
            formCheck.setFieldValue(event.target.id, value);
        }

        formCheck.setFieldValue(
            "total_revenue_changed",
            Math.round(value * formCheck.getFieldValue("exchange_rate"))
        );

    };

    const onFinishImport = (values) => {
        setLoadingModal(true);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("date_pay", dayjs(values.date_pay).format("DD-MM-YYYY"));
        data.append("exchange_rate", values.exchange_rate);
        data.append("invoice_code", values.invoice_code);
        data.append("revenue_excluding_tax", values.revenue_excluding_tax);
        data.append(
            "revenue_excluding_tax_changed",
            values.revenue_excluding_tax_changed
        );
        data.append("tax", values.tax);
        data.append("tax_changed", values.tax_changed);
        data.append("total_revenue", values.total_revenue);
        data.append("total_revenue_changed", values.total_revenue_changed);
        data.append("id_detail", idDetailEntry);
        data.append(
            "file",
            values.file !== undefined ? values.file.fileList[0].originFileObj : ""
        );

        PostDataCheck(data)
            .then((res) => {
                setIsOpenModalCheck(false);
                formCheck.resetFields();
                openNotificationWithIcon("success", "Yêu cầu thành công");
                setLoadingModal(false);
                handleChange(dateDataCheck);
                setIsOpenUpload(false);
            })
            .catch((err) => {
                setLoadingModal(false);
                openNotificationWithIcon("error", "Yêu cầu thất bại");
            });
    };

    const antIcon = <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />;

    const confirmUploadFile = () => {
        setIsOpenUpload(true);
    };

    return (
        <>
            <Modal
                open={isOpenModalCheck}
                // onOk={handleOk}
                onCancel={handleCancel}
                width={"80%"}
                className="custom-modal"
                // closable={false}
                footer={false}
                centered
            >
                <Row>
                    <Col
                        span={11}
                        style={{
                            height: "96%",
                            width: "96%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {loadingImg === true ? (
                            <>
                                <div className="loading-detail-check">
                                    <Spin
                                        indicator={antIcon}
                                        spinning={loadingImg}
                                        size="large"
                                    ></Spin>
                                </div>
                            </>
                        ) : (
                            <>
                                {imagesURL.length > 0 && (
                                    <div style={{ cursor: "zoom-in", position: "relative" }}>
                                        <TransformWrapper>
                                            {({ zoomIn, zoomOut, resetTransform }) => (
                                                <>
                                                    <TransformComponent contentStyle={{ width: "100%", display: "flow", padding: "1% 1% 3%" }}>
                                                        {/* Your content to be zoomed goes here */}
                                                        <img
                                                            src={imagesURL[currentPage - 1]}
                                                            style={{
                                                                width: "100%",
                                                                height: "750px",
                                                                // objectFit: "none",
                                                                filter: "drop-shadow(2px 4px 6px black)",
                                                                // imageRendering: "pixelated"
                                                            }}
                                                            alt="Zoomable"
                                                        />
                                                    </TransformComponent>
                                                </>
                                            )}
                                        </TransformWrapper>
                                    </div>
                                )}
                                <Row
                                    style={{
                                        alignItems: "center",
                                        display: "flex",
                                        // justifyContent: "center",
                                    }}
                                >
                                    <Col span={14}>
                                        {numPagesPDF && (
                                            <p style={{ float: "right" }}>
                                                {imagesURL.length > 0 ? (
                                                    <>
                                                        <Button
                                                            type="primary"
                                                            shape="round"
                                                            size="small"
                                                            icon={<DoubleLeftOutlined />}
                                                            onClick={handlePreviousPage}
                                                            disabled={currentPage === 1}
                                                        ></Button>
                                                        &nbsp;&nbsp; {currentPage}/{numPagesPDF} &nbsp;&nbsp;
                                                        <Button
                                                            type="primary"
                                                            shape="round"
                                                            size="small"
                                                            icon={<DoubleRightOutlined />}
                                                            onClick={handleNextPage}
                                                            disabled={currentPage === numPagesPDF}
                                                        >
                                                            {/* <img src={IconNextPage} alt=""></img> */}
                                                        </Button>
                                                    </>

                                                ) : null}
                                            </p>
                                        )}</Col>
                                    <Col span={10}>
                                        <Button style={{ float: "right", background: "#5465ef", color: "#fff" }}>
                                            <a href={urlPDF !== undefined ? localhost + "/" + urlPDF : null} target="_blank" rel="noreferrer">
                                                {urlPDF ? "Xem full ảnh PDF" : null}
                                            </a>
                                        </Button></Col>
                                </Row>
                            </>
                        )}

                    </Col>

                    <Col
                        span={12}
                        offset={1}
                        style={{
                            background: "#fff",
                            boxShadow:
                                "9px 10px 47px 0px rgba(0, 0, 0, 0.15), -18px -13px 53px 0px rgba(0, 0, 0, 0.15)",
                            borderRadius: 6,
                            width: "80%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 16,
                            }}
                        >
                            <div className="title-bill-check">
                                <span className="title-check" style={{ fontSize: 22 }}>
                                    Check phiếu tính tiền
                                </span>
                            </div>
                        </div>
                        <Form
                            form={formCheck}
                            layout="vertical"
                            autoComplete="off"
                            onFinish={onFinishImport}
                            // onFinishFailed={onFinishFailed}
                            validateMessages={validateMessages}
                            initialValues={{
                                remember: true,
                            }}
                            className="custom-form"
                            key="formImport"
                            style={{ marginTop: "20px" }}
                        >
                            <div style={{ marginLeft: "3%", marginRight: "3%" }}>
                                <Row style={{ height: 70 }} className="title-field-bill-check">
                                    <Col span={11} className="col11">
                                        <Form.Item
                                            name="invoice_code"
                                            label={
                                                <span className="title-field-bill-check">Mã số</span>
                                            }
                                            key="invoice_code"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input></Input>
                                        </Form.Item>
                                    </Col>

                                    <Col span={11} offset={2} className="col11">
                                        <Form.Item
                                            name="date_pay"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Ngày phát hành
                                                </span>
                                            }
                                            key="date_pay"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                //   onChange={(value) =>
                                                //     setDateStart(moment(value).format(dateFormat))
                                                //   }
                                                placeholder="Chọn Ngày phát hành"
                                                style={{ width: "100%" }}
                                                format={"DD-MM-YYYY"}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ height: 70 }}>
                                    <Col span={11} className="col11">
                                        <Form.Item
                                            name="currency"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Đơn vị tiền tệ
                                                </span>
                                            }
                                            key="currency"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input disabled={true}></Input>
                                        </Form.Item>
                                    </Col>

                                    <Col span={11} offset={2} className="col11">
                                        <Form.Item
                                            name="exchange_rate"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Tỉ giá quy đổi
                                                </span>
                                            }
                                            key="exchange_rate"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input disabled={true}></Input>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ height: 70 }}>
                                    <Col span={11} className="col11">
                                        <Form.Item
                                            name="revenue_excluding_tax"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Thành tiền
                                                </span>
                                            }
                                            key="revenue_excluding_tax"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                // type="number"
                                                // value={valueRevenueExcluding}
                                                className="right-to-left-input"
                                                placeholder="Nhập tiền"
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                onChange={(e) =>
                                                    changeMoneyVND(
                                                        e,
                                                        formCheck.getFieldValue("revenue_excluding_tax")
                                                    )
                                                }
                                                style={{ width: "100%" }}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                    if (
                                                        charCode === 32 &&
                                                        formCheck.getFieldValue(e.target.id) !== undefined
                                                    ) {
                                                        changeMoneyVND(
                                                            e,
                                                            formCheck.getFieldValue(e.target.id)
                                                        );
                                                    }
                                                }}
                                                min={0}
                                                controls={false}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={11} offset={2} className="col11">
                                        <Form.Item
                                            name="revenue_excluding_tax_changed"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Thành tiền theo VND
                                                </span>
                                            }
                                            key="revenue_excluding_tax_changed"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                // type="number"
                                                // value={valueRevenueExcluding}
                                                min={0}
                                                controls={false}
                                                className="right-to-left-input"
                                                placeholder="Nhập tiền"
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                // onChange={changeMoneyVND}
                                                style={{ width: "100%" }}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ height: 70 }}>
                                    <Col span={11} className="col11">
                                        <Form.Item
                                            name="tax"
                                            label={
                                                <span className="title-field-bill-check">Thuế</span>
                                            }
                                            key="tax"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                // type="number"
                                                // value={valueRevenueExcluding}
                                                min={0}
                                                controls={false}
                                                className="right-to-left-input"
                                                placeholder="Nhập thuế"
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                onChange={(e) =>
                                                    changeTaxVND(e, formCheck.getFieldValue("tax"))
                                                }
                                                style={{ width: "100%" }}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                    if (
                                                        charCode === 32 &&
                                                        formCheck.getFieldValue(e.target.id) !== undefined
                                                    ) {
                                                        changeTaxVND(
                                                            e,
                                                            formCheck.getFieldValue(e.target.id)
                                                        );
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} offset={2} className="col11">
                                        <Form.Item
                                            name="tax_changed"
                                            label={
                                                <span className="title-field-bill-check">Thuế VND</span>
                                            }
                                            key="tax_changed"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                // type="number"
                                                // value={valueRevenueExcluding}
                                                min={0}
                                                controls={false}
                                                className="right-to-left-input"
                                                placeholder="Nhập thuế"
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                style={{ width: "100%" }}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            // disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ height: 70 }}>
                                    <Col span={11} className="col11">
                                        <Form.Item
                                            name="total_revenue"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Tổng tiền
                                                </span>
                                            }
                                            key="total_revenue"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                // type="number"
                                                // value={valueRevenueExcluding}
                                                min={0}
                                                controls={false}
                                                className="right-to-left-input"
                                                placeholder="Nhập tổng tiền"
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                onChange={(e) =>
                                                    changeTotalVND(
                                                        e,
                                                        formCheck.getFieldValue("total_revenue")
                                                    )
                                                }
                                                style={{ width: "100%" }}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                    if (
                                                        charCode === 32 &&
                                                        formCheck.getFieldValue(e.target.id) !== undefined
                                                    ) {
                                                        changeTotalVND(
                                                            e,
                                                            formCheck.getFieldValue(e.target.id)
                                                        );
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} offset={2} className="col11">
                                        <Form.Item
                                            name="total_revenue_changed"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Tổng tiền theo VND
                                                </span>
                                            }
                                            key="total_revenue_changed"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                // type="number"
                                                // value={valueRevenueExcluding}
                                                min={0}
                                                controls={false}
                                                className="right-to-left-input"
                                                placeholder="Nhập tổng tiền"
                                                formatter={(value) =>
                                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                }
                                                style={{ width: "100%" }}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which ? e.which : e.keyCode;
                                                    if (charCode < 48 || charCode > 57) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            // disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ height: 70 }}>
                                    <Col span={11} className="col11">
                                        <Form.Item
                                            name="user_up"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Tên người gửi
                                                </span>
                                            }
                                            key="user_up"
                                        >
                                            <Input disabled={true}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} offset={2} className="col11">
                                        <Form.Item
                                            name="date_up"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Ngày gửi{" "}
                                                </span>
                                            }
                                            key="date_up"
                                        >
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                format={"DD-MM-YYYY"}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ height: 90 }}>
                                    <Col span={24} className="col11">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Form.Item
                                                name="file"
                                                label={
                                                    <span className="title-field-bill-check">
                                                        Đính kèm PDF
                                                    </span>
                                                }
                                                key="file"
                                            >
                                                <Upload
                                                    maxCount={1}
                                                    beforeUpload={() => false}
                                                    accept=".pdf"
                                                >
                                                    <Button
                                                        htmlType="button"
                                                        icon={<UploadOutlined />}
                                                        disabled={!isOpenUpload}
                                                    >
                                                        Click to Upload
                                                    </Button>
                                                </Upload>
                                            </Form.Item>
                                            {isOpenUpload === false ? (
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={
                                                        <p className="title-field-bill-check">
                                                            Bạn muốn Upload File ?
                                                        </p>
                                                    }
                                                    onConfirm={confirmUploadFile}
                                                    okText="Có"
                                                    cancelText="Không"
                                                    style={{ width: 200 }}
                                                >
                                                    <CheckOutlined
                                                        style={{
                                                            color: "green",
                                                            marginLeft: "20px",
                                                            fontSize: 20,
                                                            fontWeight: 700,
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </Popconfirm>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ height: 150 }}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="project_detail"
                                            label={
                                                <span className="title-field-bill-check">
                                                    Nội dung nghiệp vụ
                                                </span>
                                            }
                                            key="project_detail"
                                        >
                                            {/* <label for="upload-photo">Browse...</label> */}
                                            <TextArea disabled={true} rows={3}></TextArea>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row
                                    style={{
                                        justifyContent: "center",
                                        height: "62px",
                                        marginTop: "8px",
                                    }}
                                >
                                    <Button
                                        className="btn-cancel-dtdk"
                                        onClick={handleCancel}
                                        style={{ height: "46px", width: 180 }}
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        loading={loadingModal}
                                        htmlType="submit"
                                        className="btn-submit-dtdk"
                                        style={{ height: "46px", width: 180, marginLeft: "30px" }}
                                    >
                                        SUBMIT
                                    </Button>
                                </Row>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default ModalCheck