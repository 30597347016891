import {
  LoadingOutlined,
  SearchOutlined,
  VerticalLeftOutlined,
  VerticalRightOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Button,
  Spin,
  Table,
  DatePicker,
  Select,
  Input,
  Form,
  InputNumber,
  Space,
} from "antd";
import React, { useMemo, useRef, useState } from "react";
import Dragger from "antd/es/upload/Dragger";
import dayjs from "dayjs";
import "./HandleSaoke.css";
import imgImport from "../../images/LogoImport.svg";
import { GetListBankAC } from "../../api/listBankAC";
import { useEffect } from "react";
import { IconButton } from "@mui/material";
import { GetListStatement, PostListStatement } from "../../api/listStatement";
import { localhost } from "../../server";
import {
  CheckCircleOutlineOutlined,
  HighlightOffOutlined,
} from "@mui/icons-material";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";
import { debounce } from "lodash";
import { useCallback } from "react";

const lsDataSelectSaoKe = [
  {
    id: 1,
    value: "Bank Statement",
  },
  {
    id: 2,
    value: "List sao kê",
  },
];

const { createCanvas } = require("canvas");
const pdfjsLib = window.pdfjsLib;
// pdfjsLib.GlobalWorkerOptions.workerSrc =
//   "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";
const { Option } = Select;
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();


const SaoKe = () => {
  const [fileList, setFileList] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesURL, setImagesURL] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isListBankACCode, setIsListBankACCode] = useState([]);
  const [isListBankACCodeId, setIsListBankACCodeId] = useState();

  const [lsDataSaoKe, setLsDataSaoKe] = useState();
  const [lsDataSaoKeMonth, setLsDataSaoKeMonth] = useState(null);
  const [lsDataSaoKeTKNH, setLsDataSaoKeTKNH] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const [form] = Form.useForm();
  const [formSubmitTable] = Form.useForm();
  const [checkValueXLSX, setCheckValueXLSX] = useState(1);
  const [idEditSK, setIdEditSK] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledIcon, setIsDisabledIcon] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState();
  const [sumTienDuAn, setSumTienDuAn] = useState(0);
  const [sumTienTra, setSumTienTra] = useState(0);
  const [filteredInfoClone, setFilteredInfoClone] = useState({});

  const fetchListDataBankACCode = () => {
    GetListBankAC()
      .then((res) => {
        setIsListBankACCode(res.data.message);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (isListBankACCode.length > 0) {
      setIsListBankACCodeId(isListBankACCode[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListBankACCode]);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    fetchListDataBankACCode();
  }, []);

  const handleChangeUpload = ({ fileList }) => {
    setFileList(fileList);
    if (fileList[0].type === "application/pdf") {
      convertPDFToImages(
        URL.createObjectURL(fileList[0].originFileObj),
        fileList
      );
    } else {
      var lsImageUpload = fileList.map((item) => item.originFileObj);
      setImages(lsImageUpload);
      setImagesURL([URL.createObjectURL(fileList[0].originFileObj)]);
    }
    setCurrentPage(1);
  };

  function dataURLtoFile(dataURL, fileName) {
    var arr = dataURL.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  const convertPDFToImages = async (pdfUrl, fileList) => {
    try {
      // Tạo đối tượng PDF từ dữ liệu PDF
      setLoading(true);
      const pdf = await pdfjsLib.getDocument(pdfUrl).promise;

      // Lấy số trang của PDF
      const totalNumPages = pdf.numPages;
      setNumPages(totalNumPages);
      // Mảng chứa hình ảnh
      const images = [];
      const pageImagesURL = [];
      // Duyệt qua từng trang PDF
      for (let pageNumber = 1; pageNumber <= totalNumPages; pageNumber++) {
        // Lấy trang PDF
        const page = await pdf.getPage(pageNumber);

        // Lấy kích thước của trang
        const viewport = page.getViewport({ scale: 1 });

        // Tạo canvas để vẽ trang PDF
        const canvas = createCanvas(viewport.width, viewport.height);
        const context = canvas.getContext("2d");

        // Render trang PDF lên canvas
        await page.render({
          canvasContext: context,
          viewport: viewport,
        }).promise;

        // Convert canvas thành hình ảnh dưới dạng URL
        const imageDataURL = canvas.toDataURL("image/png");
        const convert_base64tofileimg = dataURLtoFile(
          imageDataURL,
          `page_${pageNumber}__.png`
        );
        // Thêm hình ảnh vào mảng
        images.push(convert_base64tofileimg);
        pageImagesURL.push(URL.createObjectURL(convert_base64tofileimg));
      }
      setImages(images);
      setImagesURL(pageImagesURL);
      setLoading(false);
      // Trả về mảng hình ảnh
      return images;
    } catch (error) {
      return [];
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        marginTop: "15%",
        fontSize: 60,
      }}
      spin
    />
  );

  function handlePaste(e) {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("text");
    const specialChars = /[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]/;
    if (specialChars.test(pastedData)) {
      e.preventDefault(); // Chặn nội dung chứa ký tự đặc biệt được paste vào
    }
  }

  const handleFilter = (value, record) => {
    return record.nameCustomer.startsWith(value);
  };

  // Function Test
  const handleChangeTable = (pagination, filters, sorter) => {
    let listFilter = [];
    let filteredInfoClone = filteredInfo;

    for (let i = 0; i < dataTable.length; i++) {
      if (filters.nameCustomer !== null) {
        for (let j = 0; j < filters.nameCustomer.length; j++) {
          if (dataTable[i].nameCustomer === filters.nameCustomer[j]) {
            listFilter.push(dataTable[i]);
            break;
            // listSum.push(dataTable[i])
          }
        }
      }
    }

    // Lấy ra Tiền dự án
    let sumProject = 0;
    if (listFilter.length > 0) {
      for (let i = 0; i < listFilter.length; i++) {
        sumProject += listFilter[i].moneyProject
      }
    } else {
      for (let i = 0; i < dataTable.length; i++) {
        sumProject += dataTable[i].moneyProject
      }
    }

    setSumTienDuAn(sumProject)

    const dataForm = formSubmitTable.getFieldValue("data");

    if (filteredInfo && filteredInfo.length > 0) {
      for (let i = 0; i < dataForm.length; i++) {
        for (let j = 0; j < filteredInfo.length; j++) {
          if (dataForm[i].id_detail === filteredInfo[j].id_detail) {
            formSubmitTable.setFieldValue(["data", i], dataForm[i]);
            break;
          }
        }
      }
    }

    let sumAmount = 0;
    const dataSumMoney = formSubmitTable.getFieldValue('data') || []
    for (let i = 0; i < listFilter.length; i++) {
      for (let j = 0; j < dataSumMoney.length; j++) {
        if (listFilter[i].id_detail === dataSumMoney[j].id_detail) {
          if (dataSumMoney[j].amount === undefined) {
            sumAmount += 0;
          } else {
            sumAmount += dataSumMoney[j].amount
          }
          break;
        }
      }
    }

    setSumTienTra(sumAmount)
    setFilteredInfo(listFilter);
    setFilteredInfoClone(filters)
  };

  const handleKeyPressAmount = (event, index) => {
    var input = document.getElementById('amount' + index);
    // Check if the key pressed is the Tab key (key code 9)
    if (event.keyCode === 9) {
      if (filteredInfo === undefined || filteredInfo.length === 0) {
        if (index < dataTable.length - 1) {
          // Prevent the default Tab key behavior (focus change)
          input = document.getElementById('amount' + (index + 1));
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        } else {
          input = document.getElementById('amount' + index);
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        }
      } else {
        if (index < filteredInfo.length - 1) {
          // Prevent the default Tab key behavior (focus change)
          input = document.getElementById('amount' + (index + 1));
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        } else {
          input = document.getElementById('amount' + index);
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        }
      }
    }
  }
  const handleKeyPressDate = (event, index) => {
    var input = document.getElementById('date' + index);
    // Check if the key pressed is the Tab key (key code 9)
    if (event.keyCode === 9) {
      if (filteredInfo === undefined || filteredInfo.length === 0) {
        if (index < dataTable.length - 1) {
          // Prevent the default Tab key behavior (focus change)
          input = document.getElementById('date' + (index + 1));
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        } else {
          input = document.getElementById('date' + index);
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        }
      } else {
        if (index < filteredInfo.length - 1) {
          // Prevent the default Tab key behavior (focus change)
          input = document.getElementById('date' + (index + 1));
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        } else {
          input = document.getElementById('date' + index);
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        }
      }
    }
  }
  const handleKeyPressComment = (event, index) => {
    var input = document.getElementById('comment' + index);
    // Check if the key pressed is the Tab key (key code 9)
    if (event.keyCode === 9) {
      if (filteredInfo === undefined || filteredInfo.length === 0) {
        if (index < dataTable.length - 1) {
          // Prevent the default Tab key behavior (focus change)
          input = document.getElementById('comment' + (index + 1));
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        } else {
          input = document.getElementById('comment' + index);
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        }
      } else {
        if (index < filteredInfo.length - 1) {
          // Prevent the default Tab key behavior (focus change)
          input = document.getElementById('comment' + (index + 1));
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        } else {
          input = document.getElementById('comment' + index);
          event.preventDefault();

          // Focus on the second input field (input2)
          input.focus();
        }
      }
    }
  }

  const [listUnique, setListUnique] = useState([])
  const [valueFilter, setValueFilter] = useState([])
  const [inputFilter, setInputFilter] = useState([])
  const [checkFilter, setCheckFilter] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const valueFilterRef = useRef(inputFilter); // Sử dụng useRef để lưu trữ giá trị mà không gây re-render

  const getColumnFilters = (dataFilter) => {
    if (dataFilter !== undefined) {
      const filters = dataFilter.reduce((acc, item) => {
        if (!acc.find((filter) => filter.value === item.nameCustomer)) {
          acc.push({
            text: item.nameCustomer,
            value: item.nameCustomer,
            // id: item.id_detail,
          });
        }
        return acc;
      }, []);
      setListUnique(filters)
      setValueFilter(filters)
    }
  };

  // useEffect(() => {
  //   // formSubmitTable.resetFields()
  //   // if (dataTable.length > 0) {
  //   //   setTimeout(() => {
  //   //     setLoadingTable(false)
  //   //   }, 300);
  //   // }
  // }, [dataTable]);

  const columns = [
    {
      title: "Khách hàng",
      dataIndex: "nameCustomer",
      align: "center",
      key: "nameCustomer",
      // filters: getColumnFilters(),
      filters: valueFilter.map((item) => ({ text: item.text, value: item.value })), // Sử dụng giá trị từ filterValues

      // filterMode: "tree",
      // filterSearch: (input, record) => {
      //   handleFilterSearch(input, record)
      // },

      filterSearch: true,
      onFilter: (value, record) =>
        handleFilter(value, record)
      ,
      width: "15%",
    },

    {
      title: "Dự án",
      dataIndex: "project",
      width: "20%",
      key: "project",
      align: "center",
    },
    {
      title: "Tiền dự án",
      dataIndex: "moneyProject",
      key: "moneyProject",
      width: "10%",
      align: "right",
      render: (text) => {
        const formattedText = Number(text).toLocaleString();
        return <span>{formattedText}</span>;
      },
    },
    {
      title: "Tiền trả",
      key: "amount",
      dataIndex: "amount",
      render: (text, record, index) => {
        let newIndex = 0;
        let data = formSubmitTable.getFieldValue('data');
        if (filteredInfo !== undefined && filteredInfo.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (filteredInfo[index].id_detail === data[i].id_detail) {
              newIndex = i;
            }
          }
        }

        return (
          <>
            <Form.Item name={["data", filteredInfo !== undefined && filteredInfo.length > 0 ? newIndex : index, "amount"]} label={""} initialValue={record.moneyProject}>
              <InputNumber
                defaultValue={record.amount}
                id={"amount" + index}
                onChange={(e) => handleSumAmount(
                  e,
                  formSubmitTable.getFieldValue(["data", filteredInfo !== undefined && filteredInfo.length > 0 ? newIndex : index, "amount"]),
                  filteredInfo !== undefined ? newIndex : index
                )}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onPaste={handlePaste}
                onKeyDown={(e) => handleKeyPressAmount(e, index)}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    e.preventDefault();
                  }
                  if (
                    charCode === 32
                  ) {
                    handleSumAmount(
                      e,
                      formSubmitTable.getFieldValue(["data", filteredInfo !== undefined && filteredInfo.length > 0 ? newIndex : index, "amount"]),
                      filteredInfo !== undefined ? newIndex : index
                    );
                  }
                }}
                style={{ width: "100%", fontWeight: 600, color: "red" }}
                min={0}
                key={index} // Vui lòng xem xét cách thay đổi key để tránh cấu trúc lặp
                controls={false}
                className="tienTra-bank"
              />
            </Form.Item>
          </>
        );
      },
    },
    {
      title: "Ngày chuyển",
      dataIndex: "date",
      key: "date",
      render: (_, record, index) => {
        let newIndex = 0;
        let data = formSubmitTable.getFieldValue('data');
        if (filteredInfo !== undefined && filteredInfo.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (filteredInfo[index].id_detail === data[i].id_detail) {
              newIndex = i;
            }
          }
        };
        return (
          <>
            <Form.Item name={["data", filteredInfo !== undefined && filteredInfo.length > 0 ? newIndex : index, "date"]} label={""} >
              <DatePicker
                id={"date" + index}
                onKeyDown={(e) => handleKeyPressDate(e, index)}
                format={"DD-MM-YYYY"}
                allowClear={false}
                key={index}
              />
            </Form.Item>
          </>
        )
      }
    },
    {
      title: "Diễn giải",
      align: "center",
      dataIndex: "comment",
      key: "comment",
      render: (text, record, index) => {
        let newIndex = 0;
        let data = formSubmitTable.getFieldValue('data');
        if (filteredInfo !== undefined && filteredInfo.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (filteredInfo[index].id_detail === data[i].id_detail) {
              newIndex = i;
            }
          }
        };
        return (
          <Form.Item name={["data", filteredInfo !== undefined && filteredInfo.length > 0 ? newIndex : index, "comment"]} label={""} initialValue={"Payment Received"}>
            <Input.TextArea
              onKeyDown={(e) => handleKeyPressComment(e, index)}
              id={"comment" + index}
              style={{ fontWeight: 600, color: "red" }}
              // defaultValue={"Payment Received"}
              key={index}
            />
          </Form.Item>
        )
      }
    },
  ];

  const handleClickEdit = (record) => {
    return (
      <>
        {idEditSK === record.id_detail ? (
          <>
            <Button
              htmlType="submit"
              style={{
                border: "none",
                boxShadow: "none",
                background: "transparent",
              }}
            >
              <CheckCircleOutlineOutlined />
            </Button>
            <Button
              style={{
                border: "none",
                boxShadow: "none",
                background: "transparent",
              }}
              onClick={() => handleClickEditClear(record)}
            >
              <HighlightOffOutlined />
            </Button>
          </>
        ) : (
          <IconButton
            disabled={idEditSK !== record.id_detail ? isDisabledIcon : false}
            onClick={() => handleClickEditSK(record)}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.2"
                d="M18 11.25L12.75 6.00001L15.2197 3.53034C15.3603 3.38969 15.5511 3.31067 15.75 3.31067C15.9489 3.31067 16.1397 3.38969 16.2803 3.53034L20.4697 7.71968C20.6103 7.86033 20.6893 8.0511 20.6893 8.25001C20.6893 8.44892 20.6103 8.63969 20.4697 8.78034L18 11.25Z"
                fill="#3B425F"
              />
              <path
                d="M8.68934 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V15.3107C3.75 15.2122 3.7694 15.1147 3.80709 15.0237C3.84478 14.9327 3.90003 14.85 3.96967 14.7803L15.2197 3.53034C15.3603 3.38969 15.5511 3.31067 15.75 3.31067C15.9489 3.31067 16.1397 3.38969 16.2803 3.53034L20.4697 7.71968C20.6103 7.86033 20.6893 8.0511 20.6893 8.25001C20.6893 8.44892 20.6103 8.63969 20.4697 8.78034L9.21967 20.0303C9.15003 20.1 9.06735 20.1552 8.97635 20.1929C8.88536 20.2306 8.78783 20.25 8.68934 20.25Z"
                stroke="#3B425F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.75 6L18 11.25"
                stroke="#3B425F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 18L18.75 14.25L18 11.25"
                stroke="#3B425F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.79773 15.0477L8.95232 20.2023"
                stroke="#3B425F"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        )}
      </>
    );
  };

  // Xử lí tiền trả
  const handleSumAmount = (event, value, index) => {
    // for (let i = 0; i < dataAmount.length; i++) {
    //   if (filteredInfo[index].id_detail === dataAmount[i].id_detail) {
    //     formSubmitTable.setFieldValue(
    //       ["data", i, "amount"],
    //       value
    //     );
    //   }
    // }

    let newValue = formSubmitTable.getFieldValue(['data', index, 'amount'])
    if (event !== undefined && event !== null) {
      if (event.charCode === 32 && newValue !== undefined) {
        newValue = newValue * 1000;
        formSubmitTable.setFieldValue(
          ["data", index, "amount"],
          newValue
        );
      } else if (newValue === undefined) {
        formSubmitTable.setFieldValue(
          ["data", index, "amount"],
          0
        );
      }
    } else {
      formSubmitTable.setFieldValue(
        ["data", index, "amount"],
        0
      );
    }

    let sum = 0;
    const dataAmount = formSubmitTable.getFieldValue('data')

    if (filteredInfo !== undefined) {
      if (filteredInfo.length > 0) {
        for (let i = 0; i < filteredInfo.length; i++) {
          for (let j = 0; j < dataAmount.length; j++) {
            if (filteredInfo[i].id_detail === dataAmount[j].id_detail) {
              if (dataAmount[j].amount === undefined) {
                sum += 0;
              } else {
                sum += dataAmount[j].amount
              }
              break;
            }
          }
        }
      }
      else {
        for (let j = 0; j < dataAmount.length; j++) {
          if (dataAmount[j].amount === undefined) {
            sum += 0;
          } else {
            sum += dataAmount[j].amount
          }
        }
      }
    }
    else {
      for (let j = 0; j < dataAmount.length; j++) {
        if (dataAmount[j].amount === undefined) {
          sum += 0;
        } else {
          sum += dataAmount[j].amount
        }
      }
    }
    setSumTienTra(sum)
  }

  const columnsLsSK = [
    {
      title: "Khách hàng",
      dataIndex: "nameCustomer",
      align: "center",
      // filters: getColumnFilters(),
      filters: valueFilter.map((item) => ({ text: item.text, value: item.value })), // Sử dụng giá trị từ filterValues

      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => handleFilter(value, record),
      width: "15%",
    },
    {
      title: "Dự án",
      dataIndex: "project",
      width: "10%",
      // filters: getColumnFiltersProject(),
      // filterMode: "tree",
      // filterSearch: true,
      // onFilter: (value, record) => record.project.startsWith(value),
      // filteredValue: filteredInfo.project || null,
    },
    {
      title: "Tiền dự án",
      align: "right",
      dataIndex: "moneyProject",
      // width: "10%",
      render: (text) => {
        const formattedText = Number(text).toLocaleString();
        return <span>{formattedText}</span>;
      },
    },
    {
      title: "Tiền trả",
      dataIndex: "amount",
      // width: "10%",
      align: "center",
      render: (value, record, index) => (
        <>
          {idEditSK === record.id_detail ? (
            <Form.Item
              style={{ marginBottom: 0, textAlign: "right" }}
              name="amount"
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^\d+$/,
                  message: "Tiền trả chỉ được nhập bằng chữ số!",
                },
              ]}
            >
              <InputNumber
                defaultValue={record.amount}
                onChange={(e) => {
                  const newData = [...dataTable];
                  newData[index].amount = e;
                  setDataTable(newData);
                }}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                onPaste={handlePaste}
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if (charCode < 48 || charCode > 57) {
                    e.preventDefault();
                  }
                  // if (
                  //   charCode === 32 &&
                  //   formCreateBill.getFieldValue(e.target.id) !== undefined
                  // ) {
                  //   changeMoneyVND(
                  //     e,
                  //     formCreateBill.getFieldValue(e.target.id)
                  //   );
                  // }
                }}
                style={{ width: "100%" }}
                min={0}
                key={index}
                controls={false}
                className="tienTra"
              />
            </Form.Item>
          ) : (
            <p>{value !== undefined ? numberWithCommas(value) : ""}</p>
          )}
        </>
      ),
    },
    {
      title: "Ngày chuyển",
      dataIndex: "date",
      // width: "10%",
      align: "center",
      render: (value, record, index) => (
        <>
          {idEditSK === record.id_detail ? (
            <Form.Item
              style={{ marginBottom: 0 }}
              name="date"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <DatePicker allowClear={false} format={"DD-MM-YYYY"} />
            </Form.Item>
          ) : (
            <p>{dayjs(value).format("DD-MM-YYYY")}</p>
          )}
        </>
      ),
    },
    {
      title: "Diễn giải",
      // width: "15%",
      dataIndex: "comment",
      align: "center",
      render: (value, record, index) => (
        <>
          {idEditSK === record.id_detail ? (
            <Form.Item
              style={{ marginBottom: 0 }}
              name="comment"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          ) : (
            <p>{value}</p>
          )}
        </>
      ),
    },
    {
      title: "Thao tác",
      dataIndex: "thaoTac",
      align: "center",
      // width: "5%",
      fixed: "right",
      render: (text, record, index) => handleClickEdit(record),
    },
  ];

  const onChangeXLSK = (e) => {
    setCheckValueXLSX(e);
    setDataTable([]);
    setLsDataSaoKe("");
    setFileList([]);
    setImagesURL([]);
    setImages([]);
  };

  const onChangeMonth = (date, dateString) => {
    setLsDataSaoKeMonth(dateString);
  };

  const onChangeTKNH = (e) => {
    setLsDataSaoKeTKNH(e);
  };

  const handleClickTopRight = () => {
    setLoadingTable(true)
    if (checkValueXLSX === 1) {
      GetListStatement({
        date:
          lsDataSaoKeMonth === null
            ? `01-${currentMonth}-${currentYear}`
            : `01-${lsDataSaoKeMonth}`,
        id_bank:
          lsDataSaoKeTKNH === null
            ? isListBankACCode.length > 0 && isListBankACCode[0].id
            : lsDataSaoKeTKNH,
      })
        .then((res) => {
          setLsDataSaoKe(res.data.message);
          setFilteredInfoClone(res.data.message.data);
          setSumTienTra(0)

          let arrData = [];
          res.data.message.data.map((item) => {
            let dictData = {};
            dictData["id_detail"] = item.id;
            dictData["nameCustomer"] = item.clientname;
            dictData["project"] = item.projectname;
            dictData["moneyProject"] = item.money_detail;
            arrData.push(dictData);
            return arrData;
          });

          setDataTable(arrData);

          const numbers = arrData.map((item) => item.moneyProject);
          const sum = numbers.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
          formSubmitTable.resetFields();
          res.data.message.data.forEach((item, index) => {
            formSubmitTable.setFieldValue(["data", index, "nameCustomer"], item.clientname);
            formSubmitTable.setFieldValue(["data", index, "id_detail"], item.id);
          })

          setTimeout(() => {
            setLoadingTable(false)
            setSumTienDuAn(sum);
          }, 100);
          getColumnFilters(arrData)
        })
        .catch((err) => { });
    }

    if (checkValueXLSX === 2) {
      setDataTable([]);
      setLsDataSaoKe("");
      GetListStatement({
        date:
          lsDataSaoKeMonth === null
            ? `01-${currentMonth}-${currentYear}`
            : `01-${lsDataSaoKeMonth}`,
        id_bank:
          lsDataSaoKeTKNH === null
            ? isListBankACCode.length > 0 && isListBankACCode[0].id
            : lsDataSaoKeTKNH,
        action: checkValueXLSX,
      })
        .then((res) => {
          setLsDataSaoKe(res.data.message);
          // setImagesURL([]);
          // setImages([]);
          let arrData = [];
          res.data.message.data.map((item) => {
            let dictData = {};
            dictData["id_detail"] = item.id;
            dictData["nameCustomer"] = item.clientname;
            dictData["project"] = item.projectname;
            dictData["moneyProject"] = item.total_revenue;
            dictData["linkSK"] = item.src_saoke;
            dictData["comment"] = item.note_saoke;
            dictData["date"] = item.date_cashing;
            dictData["amount"] = item.money_detail;

            arrData.push(dictData);
            return dictData;
          });
          setDataTable(arrData);
          getColumnFilters(arrData)
        })
        .catch((err) => { });
    }
  };

  const handleSubmit = (values) => {
    let listData = formSubmitTable.getFieldValue('data')
    let newList = []
    const FormData = require("form-data");
    let data = new FormData();
    data.append("file", fileList[0].originFileObj);
    data.append(
      "date",
      lsDataSaoKeMonth === null
        ? `01-${currentMonth.toString().padStart(2, "0")}-${currentYear}`
        : `01-${lsDataSaoKeMonth.toString().padStart(2, "0")}`
    );

    for (let i = 0; i < listData.length; i++) {
      if (listData[i].amount === undefined || listData[i].date === undefined || listData[i].comment === undefined) {
        listData[i] = ''
      }
    }

    for (let i = 0; i < listData.length; i++) {
      if (listData[i] !== '') {
        newList.push(listData[i])
      }
    }

    data.append("data", JSON.stringify(newList));
    console.log(listData)
    PostListStatement(data).then((res) => {
      setFileList([]);
      setImages([]);
      setDataTable([]);
      setImagesURL([]);
      setSumTienDuAn(0)
      setSumTienTra(0)
      setValueFilter([])
      setCheckFilter(false)
      ToastSuccess.fire({
        icon: "success",
        title: "Thành công",
      });
      setFilteredInfo([]);
      setFilteredInfoClone({});
      handleClickTopRight()
    }).catch(err => {
      ToastError.fire({
        icon: "error",
        title: "Thất bại",
      });
    });
  };

  const handleClickEditSK = (e) => {
    setIdEditSK(e.id_detail);
    setIsDisabled(!isDisabled);
    setIsDisabledIcon(!isDisabledIcon);

    if (isDisabled) {
      convertPDFToImages(localhost + "/media/" + e.linkSK, fileList);
    }

    form.setFieldsValue({
      amount: e.amount,
      date: dayjs(e.date, "YYYY-MM-DD"),
      comment: e.comment,
    });
  };

  const handleClickEditClear = (e) => {
    setIsDisabled(!isDisabled);
    setIdEditSK();
    setIsDisabledIcon(!isDisabledIcon);
    setFileList([]);
    setImages([]);
    setImagesURL([]);
    form.resetFields();
  };

  const numberWithCommas = (number) => {
    if (number !== null) {
      return number.toLocaleString();
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onFinishEdit = (value) => {
    const FormData = require("form-data");
    let data = new FormData();
    data.append("id_detail", idEditSK);
    data.append("date", dayjs(value.date).format("DD-MM-YYYY"));
    data.append("amount", value.amount);
    data.append("comment", value.comment);
    data.append("message", "update");
    PostListStatement(data).then((res) => {
      ToastSuccess.fire({
        icon: "success",
        title: "Thành công",
      });
      handleClickEditClear();
      GetListStatement({
        date:
          lsDataSaoKeMonth === null
            ? `01-${currentMonth}-${currentYear}`
            : `01-${lsDataSaoKeMonth}`,
        id_bank:
          lsDataSaoKeTKNH === null
            ? isListBankACCode.length > 0 && isListBankACCode[0].id
            : lsDataSaoKeTKNH,
        action: checkValueXLSX,
      })
        .then((res) => {
          setLsDataSaoKe(res.data.message);
          // setImagesURL([]);
          // setImages([]);

          let arrData = [];
          res.data.message.data.map((item) => {
            let dictData = {};
            dictData["id_detail"] = item.id;
            dictData["nameCustomer"] = item.clientname;
            dictData["project"] = item.projectname;
            dictData["moneyProject"] = item.total_revenue;
            dictData["linkSK"] = item.src_saoke;
            dictData["comment"] = item.note_saoke;
            dictData["date"] = item.date_cashing;
            dictData["amount"] = item.money_detail;
            arrData.push(dictData);
            return dictData;
          });
          setDataTable(arrData);
        })
        .catch((err) => { });
    });
    setImagesURL([]);
    setImages([]);
  };

  const [page, setPage] = useState(1);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    // setIsLoading(false);
  }

  return (
    <>
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "35%" }}>
          <Spin
            spinning={loading}
            indicator={antIcon}
            size="large"
            style={{ width: "100%", height: "100%" }}
          >
            {checkValueXLSX === 1 && (
              <Dragger
                className="uploadFileSK"
                maxCount={1}
                fileList={fileList}
                multiple={false}
                onChange={(e) => handleChangeUpload(e)}
                showUploadList={false}
                beforeUpload={() => false}
                accept=".pdf"
                style={{
                  width: "100%",
                  height: "100%",
                  background: "none",
                  border: "unset",
                }}
              >
                {images.length === 0 ? (
                  <div
                    style={{
                      background: "rgba(202, 208, 225, 1)",
                      height: "82vh",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={imgImport} alt="" style={{ width: "50%" }} />
                  </div>
                ) : (
                  <>
                    <div style={{ height: "80vh", width: "100%" }}>
                      {imagesURL.length > 0 && (
                        <div style={{ cursor: "zoom-in" }}>
                          <TransformWrapper>
                            {({ zoomIn, zoomOut, resetTransform }) => (
                              <>
                                <TransformComponent >
                                  {/* Your content to be zoomed goes here */}
                                  <img
                                    src={imagesURL[currentPage - 1]}
                                    style={{ width: "100%", height: "750px" }}
                                    alt="Zoomable"
                                  />
                                </TransformComponent>
                              </>
                            )}
                          </TransformWrapper>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Dragger>
            )}
            {checkValueXLSX === 2 && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  background: "none",
                  border: "unset",
                  padding: "16px 0",
                }}
              >
                {images.length === 0 ? (
                  <div
                    style={{
                      background: "rgba(202, 208, 225, 1)",
                      height: "82vh",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={imgImport} alt="" style={{ width: "50%" }} />
                  </div>
                ) : (
                  <>
                    <div style={{ height: "80vh", width: "100%" }}>
                      {imagesURL.length > 0 && (
                        <div style={{ cursor: "zoom-in" }}>
                          <TransformWrapper>
                            {({ zoomIn, zoomOut, resetTransform }) => (
                              <>
                                <TransformComponent>
                                  {/* Your content to be zoomed goes here */}
                                  <img
                                    src={imagesURL[currentPage - 1]}
                                    style={{ width: "100%", height: "750px" }}
                                    alt="Zoomable"
                                  />
                                </TransformComponent>
                              </>
                            )}
                          </TransformWrapper>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
            {imagesURL.length > 0 ? (
              <div style={{ textAlign: "center", marginTop: 5 }}>
                <Button
                  type="primary"
                  shape="round"
                  icon={<VerticalRightOutlined />}
                  size="small"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                />
                &nbsp;&nbsp; {currentPage}/{numPages} &nbsp;&nbsp;
                <Button
                  type="primary"
                  shape="round"
                  icon={<VerticalLeftOutlined />}
                  size="small"
                  onClick={handleNextPage}
                  disabled={currentPage === numPages}
                />
              </div>
            ) : null}
          </Spin>
        </div>
        <div style={{ width: "1%" }}></div>
        <div style={{ width: "64%" }}>
          <div style={{ width: "100%", padding: "16px 0" }}>
            <div
              style={{
                width: "97%",
                background: "#fff",
                borderRadius: "4px",
              }}
            >
              <div style={{ padding: "20px 0" }}>
                <Row
                  style={{
                    margin: "auto",
                    width: "90%",
                    background: "rgba(229, 247, 254, 1)",
                    textAlign: "center",
                    borderRadius: 6,
                  }}
                >
                  <Col span={12}>
                    <p className="pSaoKeRightTop">
                      TỔNG TIỀN HÓA ĐƠN :{" "}
                      {lsDataSaoKe && lsDataSaoKe !== null
                        ? numberWithCommas(lsDataSaoKe.sum_total)
                        : 0}
                    </p>
                  </Col>
                  <Col span={12}>
                    <p className="pSaoKeRightTop">
                      TỔNG TIỀN THANH TOÁN :{" "}
                      {lsDataSaoKe && lsDataSaoKe !== null
                        ? numberWithCommas(lsDataSaoKe.sum_cashing)
                        : 0}
                    </p>
                  </Col>
                </Row>
              </div>
              <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                // validateMessages={validateMessages}
                // loading={loading}
                initialValues={{
                  remember: true,
                }}
              >
                <Row
                  style={{
                    margin: "auto",
                    width: "90%",
                    paddingBottom: "20px",
                  }}
                >
                  <Col span={8}>
                    <p className="pSaoKeRightBottom">Tháng dự án</p>
                    <DatePicker
                      disabled={isDisabled === true ? false : true}
                      onChange={onChangeMonth}
                      allowClear={false}
                      picker="month"
                      format={"MM-YYYY"}
                      defaultValue={dayjs()}
                      style={{ width: "80%" }}
                      size="large"
                    />
                  </Col>
                  <Col span={8}>
                    <p className="pSaoKeRightBottom">TK Ngân hàng</p>
                    {isListBankACCodeId && (
                      <Select
                        disabled={isDisabled === true ? false : true}
                        size="large"
                        placeholder="Bank Account"
                        style={{
                          width: "80%",
                        }}
                        defaultValue={isListBankACCodeId}
                        onChange={onChangeTKNH}
                      >
                        {isListBankACCode.map((e) => (
                          <Option key={e.id} value={e.id}>
                            {e.codebank}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Col>
                  <Col span={4}>
                    <p className="pSaoKeRightBottom">Action</p>
                    <Select
                      disabled={isDisabled === true ? false : true}
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      onChange={onChangeXLSK}
                      defaultValue={
                        lsDataSelectSaoKe.length > 0 && lsDataSelectSaoKe[0].id
                      }
                    >
                      {lsDataSelectSaoKe.map((e) => (
                        <Option key={e.id} value={e.id}>
                          {e.value}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col span={4}>
                    <p className="pSaoKeRightBottom">
                      {" "}
                      <br />
                    </p>
                    <Button
                      disabled={isDisabled === true ? false : true}
                      size="large"
                      style={{
                        float: "right",
                        background: "rgba(84, 101, 239, 1)",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                      id="clickSubmit"
                      onClick={handleClickTopRight}
                    >
                      Confirm
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <div
              style={{
                width: "97%",
                background: "#fff",
                borderRadius: "4px",
                marginTop: "1%",
              }}
            >
              {checkValueXLSX === 1 && (
                <div>
                  <Form
                    form={formSubmitTable}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={handleSubmit}
                    // onFinishFailed={onFinishFailed}
                    // validateMessages={validateMessages}
                    // loading={loading}
                    initialValues={{
                      remember: true,
                    }}
                  >
                    <Table
                      onChange={handleChangeTable}
                      dataSource={loadingTable ? [] : dataTable}
                      columns={columns}
                      pagination={false}
                      size="small"
                      scroll={{
                        y: 500,
                      }}
                      loading={loadingTable}
                    />
                    <>
                      {sumTienDuAn !== undefined && (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p>Tiền trả:</p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                marginLeft: 6,
                              }}
                            >
                              {Number(sumTienTra).toLocaleString()}
                            </p>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p>Tiền dự án:</p>
                            <p
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                marginLeft: 6,
                              }}
                            >
                              {Number(sumTienDuAn).toLocaleString()}
                            </p>
                          </div>
                        </>
                      )}
                      <Button
                        // onClick={handleSubmit}
                        disabled={imagesURL.length === 0 ? true : false}
                        style={{
                          float: "right",
                          marginTop: 5,
                          background: "rgba(84, 101, 239, 1)",
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </>
                  </Form>
                </div>
              )}
              {checkValueXLSX === 2 && (
                <div>
                  <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinishEdit}
                    // onFinishFailed={onFinishFailed}
                    // validateMessages={validateMessages}
                    initialValues={{
                      remember: true,
                    }}
                    className="custom-form"
                    key="formImport"
                    style={{ width: "100%" }}
                  >
                    <Table
                      dataSource={dataTable}
                      columns={columnsLsSK}
                      pagination={false}
                      size="small"
                      scroll={{
                        y: 500,
                      }}
                    />
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(SaoKe);
