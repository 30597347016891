import { authAxios } from "../api/axiosClient";
import {
  dtttBankAC,
  dtttClientCode,
  dtttCurrency,
  dtttDepartment,
  dtttMarket,
  dtttProduct,
} from "../constants";

export const GetDataProduct = (params = {}) => {
  const url = dtttProduct;
  return authAxios().get(url, { params });
};
export const GetDataMarket = (params = {}) => {
  const url = dtttMarket;
  return authAxios().get(url, { params });
};
export const GetDataBankAC = (params = {}) => {
  const url = dtttBankAC;
  return authAxios().get(url, { params });
};
export const GetDataCurrency = (params = {}) => {
  const url = dtttCurrency;
  return authAxios().get(url, { params });
};
export const GetDataDepartment = (params = {}) => {
  const url = dtttDepartment;
  return authAxios().get(url, { params });
};
export const GetDataClientCode = (params = {}) => {
  const url = dtttClientCode;
  return authAxios().get(url, { params });
};
