import { Col, Form, Input, Modal, Row, Button } from "antd";
import React from "react";
import { useEffect } from "react";
import { PutCurrencyApi } from "../../api/listCurrency";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const ModalEditCurrency = ({
  isModalOpenEditCurrency,
  handleOkEditCurrency,
  setIsModalOpenEditCurrency,
  idEditCurrency,
  dataInforCurrency,
  fetchListDataCurrency,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  const handleCancelEditCurrency = () => {
    setIsModalOpenEditCurrency(false);
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
    if (isModalOpenEditCurrency) {
      form.setFieldsValue({
        name: dataInforCurrency.name,
        code: dataInforCurrency.code,
        convert: dataInforCurrency.convert,
        date: dataInforCurrency.date,
      });
    }
  }, [isModalOpenEditCurrency, dataInforCurrency, form]);

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);

    data.append("id", idEditCurrency);
    data.append("message", "update");
    data.append("name", values.name);
    data.append("code", values.code);

    // currentDuAn
    PutCurrencyApi(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Cập nhật Currency thành công",
        });
        setLoadings(false);
        fetchListDataCurrency();
        handleCancelEditCurrency();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="EDIT CURRENCY"
      open={isModalOpenEditCurrency}
      onOk={handleOkEditCurrency}
      onCancel={handleCancelEditCurrency}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Currency Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Currency Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Currency Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Currency Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Currency Code!",
                },
              ]}
            >
              <Input size="large" placeholder="Currency Code" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelEditCurrency}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalEditCurrency;
