import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { logoutURL } from "../constants";
import { authAxios } from "../api/axiosClient";
import { logout } from "../app/Actions/auth";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const cookies = new Cookies();

const styles = {
  tabIndicator: {
    display: "none", // Ẩn chỉ báo màu
  },
};

const Topbar = ({
  valueTabs,
  setValueTabs,
  checkPathName,
  setIsSidebar,
  isSidebar,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isAnchorEl, setIsAnchorEl] = useState(null);
  const open = Boolean(isAnchorEl);
  const history = useHistory();
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const [lsPermissions, setLsPermissions] = useState([]);
  const [lsPermissionsType, setLsPermissionsType] = useState([]);

  const handleChange = (event, newValue) => {
    setValueTabs(newValue);
    setIsSidebar(!isSidebar);
  };

  const handleClickPerson = (event) => {
    setIsAnchorEl(event.currentTarget);
  };

  const handleClosePerson = () => {
    setIsAnchorEl(null);
  };

  const handleClickChangePW = () => {
    setIsAnchorEl(null);
    history.push("/change_password");
  };

  const onClickAdmin = () => {
    setIsAnchorEl(null);
    history.push("/user");
  };

  const logout_new = (e) => {
    authAxios()
      .get(logoutURL)
      .then((res) => {
        //tắt
        cookies.remove("token_qldt");
        cookies.remove("refresh_qldt");
        cookies.remove("csrftoken");
        cookies.remove("sessionid");
        cookies.remove("token_contract_v2");
        cookies.remove("refresh_contract_v2");
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + "; domain=.rainscales.com.vn; path=/");
        });
        document.cookie = 'token_qldt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
        document.cookie = 'refresh_qldt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

        window.location = "/";
      });
    logout();
  };

  useEffect(() => {
    if (userInfo) {
      const arrPermission = userInfo.permission.map((item, index) => {
        return item.name;
      });

      const arrPermissionType = userInfo.permission.map((item, index) => {
        return item.type;
      });

      if (arrPermission !== undefined) {
        setLsPermissions([...arrPermission]);
      }

      if (arrPermissionType !== undefined) {
        setLsPermissionsType([...arrPermissionType]);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (window.location.pathname === "/pj-detail") {
      setValueTabs(0);
    } else if (
      window.location.pathname === "/pj-detail-sale" ||
      window.location.pathname === "/pivot-sales"
    ) {
      setValueTabs(1);
    }
  }, [valueTabs, setValueTabs]);



  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        backgroundColor: "#ffffff",
        padding: window.screen.availHeight < 800 ? 1 : 2,
      }}
    >
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {!checkPathName && (
          <Tabs
            value={valueTabs}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tabsTopBar"
            TabIndicatorProps={{
              style: styles.tabIndicator,
            }}
          >
            {lsPermissions.includes("Xem Pivot Operation") === true ||
              lsPermissions.includes("Upload file sao kê Operation") === true ||
              lsPermissions.includes("LC phiếu tính tiền Operation") === true ||
              lsPermissions.includes("Tạo phiếu tính tiền Operation") === true ||
              lsPermissions.includes("Check phiếu tính tiền Operation") ===
              true ||
              lsPermissions.includes("Xem project detail Operation") === true ? (
              <Tab
                label="OPERATION"
                className="tabsButton "
                component={Link}
                to="/pj-detail"
                value={0}
              />
            ) : (
              ""
            )}
            {lsPermissionsType.includes(2) === true && (
              <Tab
                label="SALE"
                style={{ marginLeft: 10 }}
                className="tabsButton"
                component={Link}
                to="/pj-detail-sale"
                value={1}
              />
            )}
          </Tabs>
        )}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <Button
          variant="outlined"
          className="btnInfoUser"
          onClick={handleClickPerson}
          startIcon={<PersonOutlinedIcon />}
          endIcon={<ExpandMoreOutlined />}
        >
          {userInfo && userInfo.userName}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={isAnchorEl}
          open={open}
          onClose={handleClosePerson}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {lsPermissions.includes("Admin Operation") === true && (
            <MenuItem onClick={onClickAdmin}>Admin</MenuItem>
          )}
          <MenuItem onClick={handleClickChangePW}>Change Password</MenuItem>
          <MenuItem onClick={() => logout_new()}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Topbar;
