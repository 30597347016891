import { Table } from "antd";
import React from "react";

const TableRealtimeClient = ({ dataTable5 }) => {
  const columns = [
    {
      title: <p className="text-header-output">Client</p>,
      dataIndex: "Client",
      width: 160,
      align: "left",
      sorter: (a, b) => {
        if (a.Client.toLowerCase() !== "grand total") {
          return b.Client.localeCompare(a.Client);
        } else {
          return null;
        }
      },
      sortOrder: "descend",
      showSorterTooltip: false,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background: index === dataTable5.length - 1 ? "#E6E6E6" : null,
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <p
              style={
                index === dataTable5.length - 1
                  ? { textAlign: "center" }
                  : { textAlign: "left" }
              }
            >
              {value}
            </p>
          ),
        };
      },
    },

    {
      title: <p className="text-header-output">Jan</p>,
      dataIndex: "Jan",
      width: 100,
      align: "right",
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Feb</p>,
      dataIndex: "Feb",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Mar</p>,
      dataIndex: "Mar",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Apr</p>,
      dataIndex: "Apr",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },

    {
      title: <p className="text-header-output">May</p>,
      dataIndex: "May",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Jun</p>,
      dataIndex: "Jun",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Jul</p>,
      dataIndex: "Jul",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Aug</p>,
      dataIndex: "Aug",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Sep</p>,
      dataIndex: "Sep",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Oct</p>,
      dataIndex: "Oct",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Nov</p>,
      dataIndex: "Nov",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Dec</p>,
      dataIndex: "Dec",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: index === dataTable5.length - 1 ? "bold" : null,
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
    {
      title: <p className="text-header-output">Grand Total</p>,
      dataIndex: "Grand total",
      align: "right",
      width: 100,
      render: (value, record, index) => {
        return {
          props: {
            style: {
              background:
                value && value[1] === 2 && index !== dataTable5.length - 1
                  ? "#f1de7a"
                  : "#31df3166",
              fontWeight: "bold",
            },
          },
          children: (
            <div>
              {value && value[0] !== null && index !== dataTable5.length - 1
                ? Math.round(value[0]).toLocaleString()
                : value && index === dataTable5.length - 1
                ? Math.round(value).toLocaleString()
                : 0}
            </div>
          ),
        };
      },
    },
  ];

  return (
    <>
      <p className="title-table" style={{ marginLeft: "1%", marginTop: 10 }}>
        DOANH THU DỰ ĐOÁN REALTIME
      </p>
      <Table
        columns={columns}
        pagination={false}
        bordered
        rowClassName="text-body-check"
        className="custom-table table-dttt content-table"
        dataSource={dataTable5}
        style={{
          marginLeft: "1%",
          width: "98%",
          overflowX: "scroll",
          height: "maxContent",
        }}
      />
    </>
  );
};

export default TableRealtimeClient;
