import * as React from "react";
import moment from "moment";
import "moment/locale/vi";
import { useState } from "react";
import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import TopBarAdmin from "./TopBarAdmin";
import SideBarAdmin from "./SideBarAdmin";

moment.locale("vi");

const CustomLayoutAdminFC = ({ children }) => {
  const [isSidebar, setIsSidebar] = useState(true);
  const [theme, colorMode] = useMode();
  const [valueTabs, setValueTabs] = useState(0);

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <SideBarAdmin isSidebar={isSidebar} valueTabs={valueTabs} />
            <main className="content">
              <TopBarAdmin
                setIsSidebar={setIsSidebar}
                valueTabs={valueTabs}
                setValueTabs={setValueTabs}
              />
              <div className="container-fluid" style={{ maxWidth: "100%" }}>
                {children}
              </div>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export const CustomLayoutAdmin = ({ isLogged, children }) => {
  const onUnAuth = () => {
    // localStorage.setItem('loginRedirect',this.props.location.pathname)
    return "Chưa đăng nhập";
  };

  return isLogged ? <CustomLayoutAdminFC children={children} /> : onUnAuth();
};
