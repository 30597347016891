import { authAxios } from "../api/axiosClient";
import { listPermission, listRole } from "../constants";

export const GetListRole = (params = {}) => {
  const url = listRole;
  return authAxios().get(url, { params });
};

export const PostListRole = (params = {}) => {
  const url = listRole;
  return authAxios().post(url, params);
};

export const GetIdRoleEditApi = (params = {}) => {
  const url = listRole;
  return authAxios().get(url, { params });
};

export const GetListPermission = (params = {}) => {
  const url = listPermission;
  return authAxios().get(url, { params });
};
