import { authAxios } from "../api/axiosClient";
import { listProject, postIp_Muiti_project } from "../constants";

export const GetListProject = (params = {}) => {
  const url = listProject;
  return authAxios().get(url, { params });
};

export const PostListProject = (params = {}) => {
  const url = listProject;
  return authAxios().post(url, params);
};

export const GetIdProjectEditApi = (params = {}) => {
  const url = listProject;
  return authAxios().get(url, { params });
};

export const PutProjectApi = (params = {}) => {
  const url = listProject;
  return authAxios().post(url, params);
};

export const PostIpMuitiproject = (params = {}) => {
  const url = postIp_Muiti_project;
  return authAxios().post(url, params);
};
