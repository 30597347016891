import { authAxios } from "../api/axiosClient";
import { listMarket } from "../constants";

export const GetListMarket = (params = {}) => {
  const url = listMarket;
  return authAxios().get(url, { params });
};

export const GetIdMarketEditApi = (params = {}) => {
  const url = listMarket;
  return authAxios().get(url, { params });
};

export const PostListMarket = (params = {}) => {
  const url = listMarket;
  return authAxios().post(url, params);
};

export const PutMarketApi = (params = {}) => {
  const url = listMarket;
  return authAxios().post(url, params);
};
