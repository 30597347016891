/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import {
  Button,
  DatePicker,
  Select,
  Table,
} from 'antd';
import React, { useEffect } from 'react';
import './LastCheck.css';
import { GetDataLastCheck, GetProjectCheckNotEntry } from '../../api/lastCheckapi';
import dayjs from 'dayjs';


import { useState } from 'react';
import ModalLastCheckSuccess from './ModalLastCheckSuccess';
import ModalLastCheck from './ModalLastCheck';
import ModalShowCheck from './ModalShowCheck';

const pdfjsLib = window.pdfjsLib;
pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';

const { Option } = Select;

const LastCheck = () => {
  const [isOpenModalLastCheck, setIsOpenModalLastCheck] = React.useState(false);
  const [isOpenModalLastCheckSuccess, setIsOpenModalLastCheckSuccess] = React.useState(false);
  const [dataLastCheck, setDataLastCheck] = React.useState([]);
  const [detailDataLc, setDetailDataLc] = React.useState();
  const [dateDataLastCheck, setDateDataLastCheck] = React.useState();
  const [process, setProcess] = React.useState('');
  const [pageCurrent, setPageCurrent] = useState(1);
  const [loadingListProcessing, setLoadingListProcessing] = useState(false);
  const [onModalShowCheck, setOnModalShowCheck] = useState(false)
  const [dataProjectNotEntry, setDataProjectNotEntry] = useState([])

  const [valueFilter, setValueFilter] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    defaultPageSize: 20,
    pageSize: 20,
    showSizeChanger: true,
  });
  const pageSize = 10;

  const [filteredInfo, setFilteredInfo] = useState({});

  const getColumnFilters = (dataFilter) => {
    if (dataFilter !== undefined) {
      const filters = dataFilter.reduce((acc, item) => {
        if (!acc.find(filter => filter.value === item.clientname)) {
          acc.push({
            text: item.clientname,
            value: item.clientname
          });
        }
        return acc;
      }, []);
      setValueFilter(filters)
      // return filters;
    }
  };

  const clearFilters = () => {
    setFilteredInfo([]);
  };

  const columns = [
    {
      title: <p className="text-header-lc">STT</p>,
      dataIndex: 'stt',
      width: '4%',
      align: 'center',
      render: (value, record, index) => (
        <span>{dataLastCheck.length > 0 ? index + 1 + (pagination.current - 1) * pagination.pageSize : null}</span>
      )
    },
    {
      title: <p className="text-header-lc">Khách hàng</p>,
      dataIndex: 'clientname',
      // filters: getColumnFilters(),
      filters: valueFilter.map((item) => ({ text: item.text, value: item.value })), // Sử dụng giá trị từ filterValues

      // filterMode: 'tree',
      filterSearch: true,
      filteredValue: filteredInfo.clientname || [],
      onFilter: (value, record) => record.clientname.startsWith(value),
      render: (value, record, index) => <span>{value}</span>,
      width: 100,
      align: 'center'
    },
    {
      title: <p className="text-header-lc">Dự án</p>,
      dataIndex: 'projectname',
      width: 100,
      align: 'center',
      render: (value, record, index) => <span>{value}</span>
    },
    {
      title: <p className="text-header-lc">Người gửi</p>,
      dataIndex: 'user_upname',
      align: 'center',
      width: 100,
      render: (value, record, index) => <span>{value}</span>
    },
    {
      title: <p className="text-header-lc">Ngày gửi</p>,
      dataIndex: 'date_up',
      align: 'center',
      width: 100
    },
    {
      title: (
        <p className="text-header-lc" style={{ textAlign: 'center' }}>
          Thành tiền
        </p>
      ),
      dataIndex: 'total_money',
      align: 'right',
      width: 100,
      render: (value, item, index) => <p>{value && value !== 0 ? value.toLocaleString() : value}</p>
    },
    {
      title: <p className="text-header-lc">Mã số</p>,
      dataIndex: 'code',
      align: 'center',
      width: 100
    },
    {
      title: <p className="text-header-lc">Thao tác</p>,
      dataIndex: 'action',
      width: '7%',
      align: 'center',
      render: (value, record, index) => (
        <Button
          onClick={() => (process === 'Success' ? showModalLastCheckSuccess(record) : showModalLastCheck(record))}
          style={{
            color: '#337AEE',
            border: '1px solid #337AEE',
            padding: '4px 10px'
          }}
        >
          View
        </Button>
      )
    }
  ];

  const showModalLastCheck = value => {
    setIsOpenModalLastCheck(true);
    setDetailDataLc(value);
  };
  const showModalLastCheckSuccess = value => {
    setIsOpenModalLastCheckSuccess(true);
    setDetailDataLc(value);
  };

  const getProjectCheck = (value) => {
    GetProjectCheckNotEntry({
      date: value
    }).then(res => {
      setDataProjectNotEntry(res.data.message)
    }).catch(err => {
      console.log(err)
    })
  }

  const handleChangeProcess = value => {
    setLoadingListProcessing(true)
    console.log(dateDataLastCheck)
    GetDataLastCheck({
      date: dayjs(dateDataLastCheck.$d).format('YYYY-MM-DD'),
      type: value === 'Success' ? 'Success' : undefined
    })
      .then(res => {
        clearFilters();
        setProcess(value);
        setDataLastCheck(res.data.message);
        setLoadingListProcessing(false)
        getColumnFilters(res.data.message)
      })
      .catch(err => {
        setLoadingListProcessing(false)
      });
  };

  const fetchDataLastCheck = (value, type) => {
    console.log(type)

    if (type === "first_time") {
      if (value.$D <= 10) {
        if (value.$M === 0) {
          GetDataLastCheck({
            date: (value.$y - 1) + "-" + 12 + "-" + value.$D,
            type: process === 'Success' ? 'Success' : undefined
          })
            .then(res => {
              clearFilters();
              var d = new Date(dayjs().year(), dayjs().month() - 1, dayjs().date())
              setDataLastCheck(res.data.message);
              setDateDataLastCheck(dayjs(d));
              setLoadingListProcessing(false)
              getColumnFilters(res.data.message)
            })
            .catch(err => {
              setLoadingListProcessing(false)
            });
        } else {
          GetDataLastCheck({
            date: value.$y + "-" + (value.$M) + "-" + value.$D,
            type: process === 'Success' ? 'Success' : undefined
          })
            .then(res => {
              clearFilters();
              var d = new Date(dayjs().year(), dayjs().month() - 1, dayjs().date())
              setDataLastCheck(res.data.message);
              setDateDataLastCheck(dayjs(d));
              setLoadingListProcessing(false)
              getColumnFilters(res.data.message)
            })
            .catch(err => {
              setLoadingListProcessing(false)
            });
        }
      } else {
        GetDataLastCheck({
          date: value.$y + "-" + (value.$M + 1) + "-" + value.$D,
          type: process === 'Success' ? 'Success' : undefined
        })
          .then(res => {
            clearFilters();
            setDataLastCheck(res.data.message);
            setDateDataLastCheck(value);
            setLoadingListProcessing(false)
            getColumnFilters(res.data.message)
          })
          .catch(err => {
            setLoadingListProcessing(false)
          });
      }
    } else {
      GetDataLastCheck({
        date: value.$y + "-" + (value.$M + 1) + "-" + value.$D,
        type: process === 'Success' ? 'Success' : undefined
      })
        .then(res => {
          clearFilters();
          setDataLastCheck(res.data.message);
          setDateDataLastCheck(value);
          setLoadingListProcessing(false)
          getColumnFilters(res.data.message)
        })
        .catch(err => {
          setLoadingListProcessing(false)
        });
    }
  }

  const handleChangeDate = value => {
    setLoadingListProcessing(true)

    getProjectCheck(dayjs(value.$d).format('YYYY-MM-DD'))
    fetchDataLastCheck(value, "change_time")
  };

  useEffect(() => {
    fetchDataLastCheck(dayjs(), "first_time")
  }, []);

  const changePagination = (pagi, filters, sorter, extra) => {
    setPagination(pagi)
    setFilteredInfo(filters);
  };

  const openModalShowCheck = () => {
    setOnModalShowCheck(true)
  }

  const checkDate = () => {
    if (dayjs().date() <= 10) {
      var d = new Date(dayjs().year(), dayjs().month() - 1, dayjs().date())
      setDateDataLastCheck(dayjs(d))
      return dayjs(d)
    } else {
      return dayjs()
    }
  }


  const listHandle = ['Processing', 'Success'];
  return (
    <>
      <div style={{ width: '98%', boxSizing: 'border-box' }}>
        {/* Khi có dữ liệu sẽ set số lượng row cho table */}

        <div className="bg-params-lc">
          <div style={{ width: '14%' }}>
            <div className="test">
              <label className="typo-month-lc" style={{ marginTop: '0.5%', width: '100%' }}>
                Tháng dự án
              </label>
              <DatePicker
                placeholder="Chọn tháng"
                style={{ marginTop: '0.2%', cursor: 'pointer', width: '100%' }}
                onChange={handleChangeDate}
                picker="month"
                format={'MM-YYYY'}
                allowClear={false}
                defaultValue={checkDate}
              ></DatePicker>
            </div>
          </div>
          <div style={{ width: '14%', marginLeft: '40px' }}>
            <div className="test">
              <label className="typo-month-lc" style={{ marginLeft: '4%', marginTop: '0.5%', width: '100%' }}>
                Tiến trình
              </label>
              <Select
                key="department"
                maxTagCount="responsive"
                optionFilterProp="children"
                onChange={handleChangeProcess}
                style={{
                  marginLeft: '2%',
                  marginTop: '0.2%',
                  cursor: 'pointer',
                  width: '100%'
                }}
                defaultValue={listHandle[0]}
              >
                {listHandle.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ width: '14%', marginLeft: '40px' }}>
            <div className="test">
              <label className="typo-month-lc" style={{ marginLeft: '4%', marginTop: '0.5%', width: '100%' }}>
                &nbsp;
              </label>
              <Button onClick={openModalShowCheck}>Kiểm tra dự án</Button>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          rowClassName="text-body-lc"
          className="table-lc"
          dataSource={dataLastCheck}
          style={{ marginLeft: '1%', marginTop: '1%', width: '100%' }}
          onChange={changePagination}
          pagination={{ defaultPageSize: 20, showSizeChanger: true }}
          scroll={{
            y: window.screen.availHeight > 850 ? "60vh" : "53vh",
          }}
          loading={loadingListProcessing}
        />
        <ModalLastCheck
          isOpenModalLastCheck={isOpenModalLastCheck}
          setIsOpenModalLastCheck={setIsOpenModalLastCheck}
          detailDataLc={detailDataLc}
          handleChangeDate={handleChangeDate}
          dateDataLastCheck={dateDataLastCheck}
          process={process}
        />
        <ModalLastCheckSuccess
          isOpenModalLastCheckSuccess={isOpenModalLastCheckSuccess}
          setIsOpenModalLastCheckSuccess={setIsOpenModalLastCheckSuccess}
          detailDataLc={detailDataLc}
          handleChangeDate={handleChangeDate}
          dateDataLastCheck={dateDataLastCheck}
          process={process}
        />

        <ModalShowCheck onModalShowCheck={onModalShowCheck} setOnModalShowCheck={setOnModalShowCheck} dataProjectNotEntry={dataProjectNotEntry} />

      </div>
    </>
  );
};

export default LastCheck;
