import React, { useState, useEffect } from "react";

import { Button, Result } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";

const BlankLayout = () => {
  const [lsPermissions, setLsPermissions] = useState([]);
  const [lsPermissionsType, setLsPermissionsType] = useState([]);
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role !== "SuperAdmin") {
        // setIsSuperA(userInfo.is_superuser);
        const arrPermission = userInfo.permission.map((item, index) => {
          return item.name;
        });

        const arrPermissionType = userInfo.permission.map((item, index) => {
          return item.type;
        });

        if (arrPermission !== undefined) {
          setLsPermissions([...arrPermission]);
          setLsPermissionsType([...arrPermissionType]);
        }
      } else {
        const arrPermission = userInfo.role;
        setLsPermissions([arrPermission]);
      }
    }
  }, [userInfo]);
  return (
    <Result
      status="403"
      title="403"
      className={"not-found-page"}
      subTitle="You can not access this page."
      extra={
        <>
          {lsPermissions.includes("Xem project detail Operation") === true &&
            lsPermissions.includes("Xem project detail Sales") === false && (
              <Link to={"/pj-detail"}>
                <Button type="primary">Back to the home page</Button>
              </Link>
            )}
          {lsPermissions.includes("Xem project detail Operation") === true &&
            lsPermissions.includes("Xem project detail Sales") === true && (
              <Link to={"/pj-detail"}>
                <Button type="primary">Back to the home page</Button>
              </Link>
            )}
          {lsPermissions.includes("Xem project detail Sales") === true &&
            lsPermissions.includes("Xem project detail Operation") ===
            false && (
              <Link to={"/pj-detail-sale"}>
                <Button type="primary">Back to the home page</Button>
              </Link>
            )}
          {lsPermissions.includes("Admin Operation") === true &&
            lsPermissionsType.length === 1 && (
              <Link to={"/user"}>
                <Button type="primary">Back to the home page</Button>
              </Link>
            )}
          {lsPermissions.includes("Upload file sao kê Operation") === true &&
            lsPermissionsType.length === 1 && (
              <Link to={"/saoke"}>
                <Button type="primary">Back to the home page</Button>
              </Link>
            )}
          {lsPermissions.includes("LC phiếu tính tiền Operation") === true &&
            lsPermissionsType.length === 1 && (
              <Link to={"/lastcheck"}>
                <Button type="primary">Back to the home page</Button>
              </Link>
            )}
          {lsPermissions.includes("Check phiếu tính tiền Operation") === true &&
            lsPermissionsType.length === 1 && (
              <Link to={"/check"}>
                <Button type="primary">Back to the home page</Button>
              </Link>
            )}
          {lsPermissions.includes("Xuất Output DTTT Operation") === true &&
            lsPermissionsType.length === 1 && (
              <Link to={"/dttt"}>
                <Button type="primary">Back to the home page</Button>
              </Link>
            )}

          {lsPermissions.includes("Xuất Output DT DD&TT Operation") === true &&
            lsPermissionsType.length === 1 && (
              <Link to={"/dtddtt"}>
                <Button type="primary">Back to the home page</Button>
              </Link>
            )}
          {lsPermissions.includes("SuperAdmin") === true && (
            <Link to={"/user"}>
              <Button type="primary">Back to the home page</Button>
            </Link>
          )}
        </>
      }
    />
  );
};

export default withRouter(connect()(BlankLayout));
