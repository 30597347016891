import { useDispatch } from "react-redux";
import "./App.css";
import { authSuccess } from "./app/Actions/auth";
import Cookies from "universal-cookie";
import Main from "./Router/router";

const cookies = new Cookies();

function App() {
  const dispatch = useDispatch();
  const token = cookies.get("token_qldt");
  // console.log("token: ", token)
  if (token) {
    const payloadBase64 = token.split(".")[1];
    const payload = JSON.parse(atob(payloadBase64));
    const currentTime = Math.floor(Date.now() / 1000);
    // console.log("currentTime: ", currentTime);
    // console.log("payload.exp: ", payload.exp);
    // Kiểm tra thời hạn của token
    if (payload.exp > currentTime) {
      dispatch(authSuccess(token));
    }
    else {
      // Token đã hết hạn
      console.log("Token đã hết hạn");
      cookies.remove("token_qldt");
    }
  }
  return (
    <>
      <Main />
    </>
  );
}

export default App;
