import * as React from "react";
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Upload,
} from "antd";
import {
    UploadOutlined,
    WarningOutlined,
} from "@ant-design/icons";
// import "./Detail.css";
import dayjs from "dayjs";
import { openNotificationWithIcon } from "../../Function";
import { CreateBill } from "../../api/detailProject";
import { GetCurrencyOfMonth } from "../../api/listCurrency";

const validateMessages = {
    required: "Không được để trống!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    pattern: {
        mismatch: "Chỉ được nhập số nguyên",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const ModalEntryCreateBill = ({
    isOpenModalCreateBill,
    setIsOpenModalCreateBill,
    dataPerMonth,
    dataBasicProject,
    dateValueDetail,
    callDataDetailProject,
    handleChange
}) => {
    const [formCreateBill] = Form.useForm();
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [checkExchangeRate, setCheckExchangRate] = React.useState(true);
    const onFinishImport = (values) => {
        setLoadingModal(true);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("date_pay", dayjs(values.date_pay).format("DD-MM-YYYY"));
        data.append("exchange_rate", values.exchange_rate);
        data.append("invoice_code", values.invoice_code);
        data.append("revenue_excluding_tax", values.revenue_excluding_tax);
        data.append(
            "revenue_excluding_tax_changed",
            values.revenue_excluding_tax_changed
        );
        data.append("tax", values.tax);
        data.append("tax_changed", values.tax_changed);
        data.append("total_revenue", values.total_revenue);
        data.append("total_revenue_changed", values.total_revenue_changed);
        data.append("id_detail", dataPerMonth.id_detailproject);
        data.append("file_entry", values.file_entry.fileList[0].originFileObj);
        CreateBill(data)
            .then((res) => {
                setIsOpenModalCreateBill(false);
                // callDataDetailProject();
                formCreateBill.resetFields();
                openNotificationWithIcon("success", "Đã tạo phiếu thành công");
                setLoadingModal(false);
                handleChange(dateValueDetail)
            })
            .catch((err) => {
                setLoadingModal(false);
                console.log(err)
                openNotificationWithIcon("error", "Tạo phiếu thất bại");
            });
    };
    const handleOk = () => {
        setIsOpenModalCreateBill(false);
    };

    const handleCancel = () => {
        setIsOpenModalCreateBill(false);
        setCheckExchangRate(true);
        formCreateBill.resetFields();
    };

    const getDataCurrency = () => {
        console.log(dateValueDetail)
        GetCurrencyOfMonth({
            id: dataBasicProject.currency_id,
            date: "01-" + (dateValueDetail.$M + 1) + "-" + dateValueDetail.$y,
        })
            .then((res) => {

                formCreateBill.setFieldValue("exchange_rate", res.data.message.money);
            })
            .catch((err) => {
                setCheckExchangRate(false);
            });
    };

    React.useEffect(() => {
        if (isOpenModalCreateBill === true) {
            // formCreateBill.setFieldValue("currency", "JPY");
            formCreateBill.setFieldValue("currency", dataBasicProject.currency_code);
            getDataCurrency();
        }
    }, [isOpenModalCreateBill]);

    const changeMoneyVND = (event, value) => {
        if (typeof event !== "number" && event !== null) {
            value = value * 1000;
            formCreateBill.setFieldValue(event.target.id, value);
        }
        formCreateBill.setFieldValue(
            "revenue_excluding_tax_changed",
            Math.round(value * formCreateBill.getFieldValue("exchange_rate"))
        );

        formCreateBill.setFieldValue(
            "tax",
            Math.round(value * 10 / 100)
        );

        formCreateBill.setFieldValue(
            "tax_changed",
            Math.round(formCreateBill.getFieldValue("tax") * formCreateBill.getFieldValue("exchange_rate"))
        );

        formCreateBill.setFieldValue(
            "total_revenue",
            Math.round(value + formCreateBill.getFieldValue("tax"))
        );

        formCreateBill.setFieldValue(
            "total_revenue_changed",
            Math.round(formCreateBill.getFieldValue("total_revenue") * formCreateBill.getFieldValue("exchange_rate"))
        );
    };

    const changeTaxVND = (event, value) => {
        if (typeof event !== "number" && event !== null) {
            value = value * 1000;
            formCreateBill.setFieldValue(event.target.id, value);
        }
        formCreateBill.setFieldValue(
            "tax_changed",
            Math.round(value * formCreateBill.getFieldValue("exchange_rate"))
        );

        if (formCreateBill.getFieldValue("revenue_excluding_tax") === undefined) {
            formCreateBill.setFieldValue(
                "total_revenue",
                Math.round(value + 0)
            );
        } else {
            formCreateBill.setFieldValue(
                "total_revenue",
                Math.round(value + formCreateBill.getFieldValue("revenue_excluding_tax"))
            );
        }


        formCreateBill.setFieldValue(
            "total_revenue_changed",
            Math.round(formCreateBill.getFieldValue("total_revenue") * formCreateBill.getFieldValue("exchange_rate"))
        );

    };
    const changeTotalVND = (event, value) => {
        if (typeof event !== "number" && event !== null) {
            value = value * 1000;
            formCreateBill.setFieldValue(event.target.id, value);
        }

        formCreateBill.setFieldValue(
            "total_revenue_changed",
            Math.round(value * formCreateBill.getFieldValue("exchange_rate"))
        );

    };

    return (
        <>
            <Modal
                title={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div className="title-bill">
                            <span className="title-field-bill" style={{ fontSize: 22 }}>
                                Tạo phiếu tính tiền tháng{" "}
                                {dataPerMonth ? dataPerMonth.month_number : null}
                            </span>
                        </div>
                    </div>
                }
                open={isOpenModalCreateBill}
                onOk={handleOk}
                // onCancel={handleCancel}
                width={700}
                closable={false}
                footer={false}
                style={{ marginTop: 40 }}
                centered
            >
                <Form
                    form={formCreateBill}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinishImport}
                    // onFinishFailed={onFinishFailed}
                    validateMessages={validateMessages}
                    initialValues={{
                        remember: true,
                    }}
                    className="custom-form"
                    key="formImport"
                >
                    <Row style={{ height: 80 }}>
                        <Col span={11} className="col11">
                            <Form.Item
                                name="invoice_code"
                                label={<span className="title-field-bill">Mã số</span>}
                                key="invoice_code"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Nhập mã số"></Input>
                            </Form.Item>
                        </Col>

                        <Col span={11} offset={2} className="col11">
                            <Form.Item
                                name="date_pay"
                                label={<span className="title-field-bill">Ngày phát hành</span>}
                                key="date_pay"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker
                                    //   onChange={(value) =>
                                    //     setDateStart(moment(value).format(dateFormat))
                                    //   }
                                    placeholder="Chọn ngày dự kiến"
                                    style={{ width: "100%" }}
                                    format={"DD-MM-YYYY"}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ height: 80 }}>
                        <Col span={11} className="col11">
                            <Form.Item
                                name="currency"
                                label={<span className="title-field-bill">Đơn vị tiền tệ</span>}
                                key="currency"
                            >
                                <Input disabled={true}></Input>
                            </Form.Item>
                        </Col>

                        <Col span={11} offset={2} className="col11">
                            <Form.Item
                                name="exchange_rate"
                                label={<span className="title-field-bill">Tỉ giá quy đổi</span>}
                                key="exchange_rate"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input disabled={true}></Input>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ height: 80 }}>
                        <Col span={11} className="col11">
                            <Form.Item
                                name="revenue_excluding_tax"
                                label={<span className="title-field-bill">Thành tiền</span>}
                                key="revenue_excluding_tax"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    // type="number"
                                    // value={valueRevenueExcluding}
                                    min={0}
                                    controls={false}
                                    placeholder="Nhập tiền"
                                    className="right-to-left-input"
                                    formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    onChange={(e) =>
                                        changeMoneyVND(
                                            e,
                                            formCreateBill.getFieldValue("revenue_excluding_tax")
                                        )
                                    }
                                    style={{ width: "100%" }}
                                    onKeyPress={(e) => {
                                        const charCode = e.which ? e.which : e.keyCode;
                                        if (charCode < 48 || charCode > 57) {
                                            e.preventDefault();
                                        }
                                        if (
                                            charCode === 32 &&
                                            formCreateBill.getFieldValue(e.target.id) !== undefined
                                        ) {
                                            changeMoneyVND(
                                                e,
                                                formCreateBill.getFieldValue(e.target.id)
                                            );
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={11} offset={2} className="col11">
                            <Form.Item
                                name="revenue_excluding_tax_changed"
                                label={
                                    <span className="title-field-bill">Thành tiền theo VND</span>
                                }
                                key="revenue_excluding_tax_changed"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    // type="number"
                                    // value={valueRevenueExcluding}
                                    min={0}
                                    controls={false}
                                    formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    // onChange={changeMoneyVND}
                                    style={{ width: "100%" }}
                                    // onKeyPress={(e) => {
                                    //     const charCode = e.which ? e.which : e.keyCode;
                                    //     if (charCode < 48 || charCode > 57) {
                                    //         e.preventDefault();
                                    //     }
                                    // }}
                                    className="right-to-left-input"
                                    disabled={true}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ height: 80 }}>
                        <Col span={11} className="col11">
                            <Form.Item
                                name="tax"
                                label={<span className="title-field-bill">Thuế</span>}
                                key="tax"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    // type="number"
                                    // value={valueRevenueExcluding}
                                    min={0}
                                    controls={false}
                                    className="right-to-left-input"
                                    placeholder="Nhập tiền thuế"
                                    formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    onChange={(e) =>
                                        changeTaxVND(e, formCreateBill.getFieldValue("tax"))
                                    }
                                    style={{ width: "100%" }}
                                    onKeyPress={(e) => {
                                        const charCode = e.which ? e.which : e.keyCode;
                                        if (charCode < 48 || charCode > 57) {
                                            e.preventDefault();
                                        }
                                        if (
                                            charCode === 32 &&
                                            formCreateBill.getFieldValue(e.target.id) !== undefined
                                        ) {
                                            changeTaxVND(
                                                e,
                                                formCreateBill.getFieldValue(e.target.id)
                                            );
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2} className="col11">
                            <Form.Item
                                name="tax_changed"
                                label={<span className="title-field-bill">Thuế theo VND</span>}
                                key="tax_changed"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    // type="number"
                                    // value={valueRevenueExcluding}
                                    min={0}
                                    controls={false}
                                    formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    // onChange={changeMoneyVND}
                                    style={{ width: "100%" }}
                                    // onKeyPress={(e) => {
                                    //     const charCode = e.which ? e.which : e.keyCode;
                                    //     if (charCode < 48 || charCode > 57) {
                                    //         e.preventDefault();
                                    //     }
                                    // }}
                                    className="right-to-left-input"
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ height: 80 }}>
                        <Col span={11} className="col11">
                            <Form.Item
                                name="total_revenue"
                                label={<span className="title-field-bill">Tổng tiền</span>}
                                key="total_revenue"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    // type="number"
                                    // value={valueRevenueExcluding}
                                    min={0}
                                    controls={false}
                                    className="right-to-left-input"
                                    placeholder="Nhập tổng tiền"
                                    formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    onChange={(e) =>
                                        changeTotalVND(
                                            e,
                                            formCreateBill.getFieldValue("total_revenue")
                                        )
                                    }
                                    style={{ width: "100%" }}
                                    onKeyPress={(e) => {
                                        const charCode = e.which ? e.which : e.keyCode;
                                        if (charCode < 48 || charCode > 57) {
                                            e.preventDefault();
                                        }
                                        if (
                                            charCode === 32 &&
                                            formCreateBill.getFieldValue(e.target.id) !== undefined
                                        ) {
                                            changeTotalVND(
                                                e,
                                                formCreateBill.getFieldValue(e.target.id)
                                            );
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11} offset={2} className="col11">
                            <Form.Item
                                name="total_revenue_changed"
                                label={
                                    <span className="title-field-bill">Tổng tiền theo VND</span>
                                }
                                key="total_revenue_changed"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber
                                    // type="number"
                                    // value={valueRevenueExcluding}
                                    min={0}
                                    controls={false}
                                    formatter={(value) =>
                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    // onChange={changeMoneyVND}
                                    className="right-to-left-input"
                                    style={{ width: "100%" }}
                                    // onKeyPress={(e) => {
                                    //     const charCode = e.which ? e.which : e.keyCode;
                                    //     if (charCode < 48 || charCode > 57) {
                                    //         e.preventDefault();
                                    //     }
                                    // }}
                                    defaultValue={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ height: 100 }}>
                        <Col span={11} className="col11">
                            <Form.Item
                                name="file_entry"
                                label={<span className="title-field-bill">Đính kèm PDF</span>}
                                key="file_entry"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Upload maxCount={1} beforeUpload={() => false} accept=".pdf">
                                    <Button htmlType="button" icon={<UploadOutlined />}>
                                        Click to Upload
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    {checkExchangeRate === false ? (
                        <div
                            style={{
                                backgroundColor: "rgb(251 53 55 / 79%)",
                                height: 40,
                                display: "flex",
                                alignItems: "center",
                                borderRadius: 6,
                            }}
                        >
                            <WarningOutlined
                                style={{ color: "#fff", fontSize: 30, marginLeft: 10 }}
                            />
                            <p style={{ color: "#fff", fontSize: 16, marginLeft: 30 }}>
                                Liên hệ Admin để tạo tỉ giá quy đổi
                            </p>
                        </div>
                    ) : null}
                    <Row
                        style={{
                            justifyContent: "center",
                            height: "62px",
                            marginTop: "8px",
                        }}
                    >
                        <Button
                            loading={loadingModal}
                            disabled={!checkExchangeRate}
                            htmlType="submit"
                            className="btn-submit-dtdk"
                            style={{ height: "46px", width: 180 }}
                        >
                            SUBMIT
                        </Button>
                        <Button
                            className="btn-cancel-dtdk"
                            onClick={handleCancel}
                            style={{ marginLeft: "30px", height: "46px", width: 180 }}
                        >
                            CANCEL
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ModalEntryCreateBill