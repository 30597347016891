import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { authAxios } from "../api/axiosClient";
import { logoutURL } from "../constants";
import Cookies from "universal-cookie";
import { logout } from "../app/Actions/auth";
import { useSelector } from "react-redux";
import { ExpandMoreOutlined } from "@mui/icons-material";

const cookies = new Cookies();

const TopBarAdmin = ({ valueTabs, setValueTabs, checkPathName }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isAnchorEl, setIsAnchorEl] = useState(null);
  const open = Boolean(isAnchorEl);
  const history = useHistory();
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const handleClickPerson = (event) => {
    setIsAnchorEl(event.currentTarget);
  };

  const handleClosePerson = () => {
    setIsAnchorEl(null);
  };

  const handleClickChangePW = () => {
    setIsAnchorEl(null);
    history.push("/change_password");
  };

  const onClickAdmin = () => {
    setIsAnchorEl(null);
    history.push("/user");
  };

  const logout_new = (e) => {
    authAxios()
      .get(logoutURL)
      .then((res) => {
        //tắt
        cookies.remove("token_qldt");
        cookies.remove("refresh_qldt");
        window.location = "/dang-nhap";
      });
    logout();
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        backgroundColor: "#fff",
        borderBottom: "1px solid #f2f0f0",
        padding: 2,
      }}
    >
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <Tabs
          value={valueTabs}
          // onChange={handleChange}
          aria-label="basic tabs example"
          className="tabsTopBar"
        ></Tabs>
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <Button
          variant="outlined"
          className="btnInfoUser"
          onClick={handleClickPerson}
          startIcon={<PersonOutlinedIcon />}
          endIcon={<ExpandMoreOutlined />}
        >
          {userInfo && userInfo.userName}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={isAnchorEl}
          open={open}
          onClose={handleClosePerson}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={onClickAdmin}>Admin</MenuItem>
          <MenuItem onClick={handleClickChangePW}>Change Password</MenuItem>
          <MenuItem onClick={() => logout_new()}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default TopBarAdmin;
