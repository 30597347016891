import { Button, Modal, Result } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import "./showStatus.css"
function ShowStatusCreateRevenue({ openModalStatus, setOpenModalStatus, detailStatus, resetAll }) {

    const closeModal = () => {
        resetAll()
        setOpenModalStatus(false)
    }
    return (
        <>
            <Modal
                // title={
                //     <div
                //         style={{
                //             display: "flex",
                //             justifyContent: "center",
                //             alignItems: "center",
                //         }}
                //     >
                //         <div className="title-bill" style={{ width: "100%" }}>
                //             <span className="title-field-bill" style={{ fontSize: 22 }}>
                //                 Tạo nhiều doanh thu
                //             </span>
                //         </div>
                //     </div>
                // }
                open={openModalStatus}
                // onCancel={handleCancel}
                width={"40%"}
                closable={false}
                footer={true}
                centered
            >
                <Result
                    status="success"
                    title=<h3>Kết quả</h3>
                    subTitle={
                        <>
                            <div style={{ rowGap: "5ch" }}>
                                <p style={{fontWeight: "bold", fontSize: 16}}>
                                    Số kết quả đúng:  {detailStatus !== undefined ? detailStatus["sucsess"].length : null}
                                </p>

                                {/* <ol> */}
                                <div className='container-result'>
                                    {detailStatus["sucsess"].map((item, index) => (
                                        <span key={index} className='result-success'>{item}</span>
                                    ))}
                                </div>
                                {/* </ol> */}

                                <p style={{fontWeight: "bold", fontSize: 16}}>
                                    Số kết quả sai: {detailStatus !== undefined ? detailStatus["error"].length : null}
                                </p>
                                <div className='container-result'>
                                    {detailStatus["error"].map((item, index) => (
                                        <span key={index} className='result-error'>{item}</span>
                                    ))}
                                </div>
                            </div>
                        </>
                    }


                    extra={[
                        <Button type="primary" key="console" style={{ fontSize: 24, height: 50, width: 100 }} onClick={closeModal}>
                            OK
                        </Button>,
                    ]}
                />
            </Modal>
        </>
    )
}

export default ShowStatusCreateRevenue