import * as React from "react";
import moment from "moment";
import "moment/locale/vi";
import Sidebar from "./Sidebar";
import { useState } from "react";
// import Header from "./Header";
import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./Topbar";
import { useSelector } from "react-redux";
import { useEffect } from "react";

moment.locale("vi");

const CustomLayoutFC = ({ children }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [theme, colorMode] = useMode();
  const [valueTabs, setValueTabs] = useState(null);

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  useEffect(() => {
    if (userInfo) {
      // setIsSuperA(userInfo.is_superuser);
      const arrPermission = userInfo.permission.map((item, index) => {
        return item.type;
      });

      setValueTabs(0);
      if (arrPermission !== undefined) {
        if (
          arrPermission.includes(2) === true &&
          arrPermission.includes(1) === false
        ) {
          setValueTabs(1);
        }
      }
    }
  }, [userInfo]);

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <Sidebar
              isSidebar={isSidebar}
              valueTabs={valueTabs}
              setIsSidebar={setIsSidebar}
            />
            <main className="content1">
              <Topbar
                setIsSidebar={setIsSidebar}
                isSidebar={isSidebar}
                valueTabs={valueTabs}
                setValueTabs={setValueTabs}
              />
              <div className="container-fluid" style={{ maxWidth: "100%" }}>
                {children}
              </div>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
};

export const CustomLayout = ({ isLogged, children }) => {
  const onUnAuth = () => {
    // localStorage.setItem('loginRedirect',this.props.location.pathname)
    return "Chưa đăng nhập";
  };

  return isLogged ? <CustomLayoutFC children={children} /> : onUnAuth();
};
