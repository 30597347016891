import { Col, Form, Input, Modal, Row, Button } from "antd";
import React from "react";
import { PostListMarket } from "../../api/listMarket";
import { useState } from "react";
import { ToastError, ToastSuccess } from "../../components/ToastSwal";

const ModalAddMarket = ({
  isModalOpenAddMarket,
  handleOkAddMarket,
  setIsModalOpenAddMarket,
  fetchListDataMarket,
}) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  const handleCancelAddMarket = () => {
    setIsModalOpenAddMarket(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    setLoadings(!loadings);
    data.append("message", "create");
    data.append("namemarket", values.namemarket);
    data.append("codemarket", values.codemarket);
    // currentDuAn
    PostListMarket(data)
      .then((res) => {
        ToastSuccess.fire({
          icon: "success",
          title: "Thêm mới Market thành công",
        });
        setLoadings(false);
        fetchListDataMarket();
        handleCancelAddMarket();
      })
      .catch((error) => {
        ToastError.fire({
          icon: "error",
          title: error.data.message,
        });
      });
  };

  return (
    <Modal
      title="ADD MARKET"
      open={isModalOpenAddMarket}
      onOk={handleOkAddMarket}
      onCancel={handleCancelAddMarket}
      footer={false}
      width={800}
      forceRender={true}
      className="headerTitleModal"
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        initialValues={{
          remember: true,
        }}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label="Market Name"
              name="namemarket"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Market Name" />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label="Market Code"
              name="codemarket"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập Market Code!",
                },
              ]}
            >
              <Input size="large" placeholder="Market Code" />
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              className="btnSaveModel"
              type="success"
              htmlType="submit"
              variant="contained"
              loading={loadings}
            >
              Save
            </Button>
            <Button
              className="btnCancelModel"
              variant="outlined"
              onClick={handleCancelAddMarket}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalAddMarket;
