/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../../App.css";
import "react-pivottable/pivottable.css";
import "./homecss.css";
import PivotGrid, { FieldChooser } from "devextreme-react/pivot-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.dark.compact.css";
import { Button, DatePicker } from "antd";
import { Box, Fade } from "@mui/material";
import { GetListPivot } from "../../api/listPivot";
import { useEffect } from "react";
import dayjs from "dayjs";
import { GridLoader } from "react-spinners";
import { localhost } from "../../server";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastError } from "../../components/ToastSwal";

const { RangePicker } = DatePicker;

const HomePage = () => {
  const [isListValuePivot, setIsListValuePivot] = useState([]);
  const startOfMonth = dayjs().startOf("year");
  const endOfMonth = dayjs().endOf("year");
  const [loading, setLoading] = useState(true);
  const [valueDateFrom, setValueDateFrom] = useState();
  const [valueDateTo, setValueDateTo] = useState();
  const [loadings, setLoadings] = useState(false);
  const [lsPermissions, setLsPermissions] = useState([]);
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const fetchListDataPivot = () => {
    GetListPivot({
      date_from: startOfMonth.format("DD-MM-YYYY"),
      date_to: endOfMonth.format("DD-MM-YYYY"),
      type: "1",
    })
      .then((res) => {
        setIsListValuePivot(res.data.message);
        setLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setLoading(true);
    fetchListDataPivot();
  }, []);

  const convertToMonth = (dateString) => {
    const dateParts = dateString !== null && dateString.split("/");
    const month = parseInt(dateParts[1], 10);
    return month;
  };

  const convertToYear = (dateString) => {
    const dateParts = dateString !== null && dateString.split("/");
    const year = parseInt(dateParts[2], 10);
    return year;
  };

  const convertedData = isListValuePivot
    .filter((item) => item["Create Date"] !== null)
    .map((item) => ({
      ...item,
      "Create Date": convertToMonth(item["Create Date"]),
      Year: convertToYear(item["Create Date"]),
    }));

  const dataSource = {
    fields: [
      { dataField: "Project Name", area: "row", width: 100 },
      { dataField: "Year", area: "column" },
      { dataField: "Create Date", area: "column" },
      {
        dataField: "Total Revenue",
        summaryType: "sum",
        customizeText: (cellInfo) => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined
            ? cellInfo.value.toLocaleString()
            : "0";
        },
      },
      {
        dataField: "Total Revenue Changed",
        summaryType: "sum",
        customizeText: (cellInfo) => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined
            ? cellInfo.value.toLocaleString()
            : "0";
        },
      },
      {
        dataField: "Expected Revenue",
        summaryType: "sum",
        customizeText: (cellInfo) => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined
            ? cellInfo.value.toLocaleString()
            : "0";
        },
      },
      {
        dataField: "Revenue Excluding Tax",
        // area: "data",
        summaryType: "sum",
        customizeText: (cellInfo) => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined
            ? cellInfo.value.toLocaleString()
            : "0";
        },
      },
      {
        dataField: "Revenue Excluding Tax Changed",
        area: "data",
        summaryType: "sum",
        customizeText: (cellInfo) => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined
            ? cellInfo.value.toLocaleString()
            : "0";
        },
      },
      {
        dataField: "Tax",
        summaryType: "sum",
        customizeText: (cellInfo) => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined
            ? cellInfo.value.toLocaleString()
            : "0";
        },
      },
      {
        dataField: "Tax Changed",
        summaryType: "sum",
        customizeText: (cellInfo) => {
          return cellInfo.value !== 0 && cellInfo.value !== undefined
            ? cellInfo.value.toLocaleString()
            : "0";
        },
      },
    ],
    store: convertedData, // Thay data bằng dữ liệu thực tế của bạn
  };

  const onChangeRangePicker = (month, dateString) => {
    setLoading(true);
    setValueDateFrom(dateString[0]);
    setValueDateTo(dateString[1]);
    GetListPivot({
      date_from: dateString[0],
      date_to: dateString[1],
      type: "1",
    })
      .then((res) => {
        setIsListValuePivot(res.data.message);
        setLoading(false);
      })
      .catch((err) => {});
  };

  const downloadFileWithToken = async (token, filename) => {
    try {
      setLoadings(!loadings);
      const response = await axios.get(
        `${localhost}/api/export_pivot?date_from=${
          valueDateFrom === undefined
            ? startOfMonth.format("DD-MM-YYYY")
            : valueDateFrom
        }&date_to=${
          valueDateTo === undefined
            ? endOfMonth.format("DD-MM-YYYY")
            : valueDateTo
        }`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
      setLoadings(false);
    } catch (error) {
      setLoadings(false);
      ToastError.fire({
        icon: "error",
        title: "Download không thành công!",
      });
    }
  };

  const handleDownload = () => {
    function get_cookie(cookie_name) {
      const value = "; " + document.cookie;
      const parts = value.split("; " + cookie_name + "=");
      if (parts.length > 1) return parts.pop().split(";").shift();
    }

    var uuid_value = get_cookie("token_qldt");
    const authToken = uuid_value;
    const filename = `pivot_${
      valueDateFrom === undefined
        ? startOfMonth.format("DD-MM-YYYY")
        : valueDateFrom
    }_${
      valueDateTo === undefined ? endOfMonth.format("DD-MM-YYYY") : valueDateTo
    }.xlsx`;

    downloadFileWithToken(authToken, filename);
  };

  useEffect(() => {
    if (userInfo) {
      // setIsSuperA(userInfo.is_superuser);
      const arrPermission = userInfo.permission.map((item, index) => {
        return item.name;
      });

      if (arrPermission !== undefined) {
        setLsPermissions([...arrPermission]);
      }
    }
  }, [userInfo]);

  return (
    <>
      <Box
        sx={{
          width: "96%",
          margin: "2% auto",
          display: "grid",
        }}
      >
        <p className="pSaoKeRightBottom">Chọn từ ngày - đến ngày</p>

        <div style={{ marginBottom: 20 }}>
          <RangePicker
            picker="month"
            onChange={onChangeRangePicker}
            format={"DD-MM-YYYY"}
            allowClear={false}
            defaultValue={[startOfMonth, endOfMonth]}
            disabled={loading === true ? true : false}
          />
          {lsPermissions.includes("Download Data Pivot Operation") === true &&
            isListValuePivot.length > 0 && (
              <Button
                onClick={handleDownload}
                style={{
                  // fontSize: "14px",
                  color: "#fff",
                  background: "rgba(84, 101, 239, 1)",
                  borderColor: "rgba(84, 101, 239, 1)",
                  marginLeft: 30,
                }}
                loading={loadings}
              >
                Download
              </Button>
            )}
        </div>
        {loading === false ? (
          <PivotGrid
            dataSource={dataSource}
            id="sales"
            allowFiltering={true}
            allowSorting={true}
            allowSortingBySummary={true}
            height={"78vh"}
            showBorders={true}
            onCellClick={(e) => {
              // const cellData = e.cellElement.textContent;
            }}
          >
            <FieldChooser dataSource={dataSource} enabled={true} height={400} />
          </PivotGrid>
        ) : null}
        {loading === true ? (
          <Fade in={loading} unmountOnExit>
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                background: "#f5f5f5",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <GridLoader color="#020f59" loading={true} size={10} />
              </div>
              <p style={{ fontSize: 20 }}>Loading...</p>
            </div>
          </Fade>
        ) : null}
      </Box>
    </>
  );
};

export default HomePage;
