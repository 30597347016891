import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoPage from "../images/LOGO IMAGE TRẮNG.svg";

import { ExitToAppOutlined } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { UserInfoUrlApi } from "../api/usersApi";

const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: "#fff",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const SideBarAdmin = ({ valueTabs }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const history = useHistory();

  const [selected, setSelected] = useState(
    (window.location.pathname === "/user" && "User") ||
    (window.location.pathname === "/product_service" && "Product/ Service") ||
    (window.location.pathname === "/project" && "Project") ||
    (window.location.pathname === "/client" && "Client") ||
    (window.location.pathname === "/market" && "Market") ||
    (window.location.pathname === "/currency" && "Currency") ||
    (window.location.pathname === "/bank_account" && "Bank Account") ||
    (window.location.pathname === "/department" && "Department") ||
    (window.location.pathname === "/role" && "Role")
  );
  const [lsPermissionsType, setLsPermissionsType] = useState([]);
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const onClickButtonChuyenTrang = () => {
    if (lsPermissionsType.includes(1) === true) {
      setTimeout(function () {
        window.location.reload();
      }, 100);
      history.push("/pj-detail");
    }
    if (
      lsPermissionsType.includes(2) === true &&
      lsPermissionsType.includes(1) === false
    ) {
      setTimeout(function () {
        window.location.reload();
      }, 100);

      history.push("/pj-detail-sale");
    }
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role !== "SuperAdmin") {
        const arrPermission = userInfo.permission.map((item, index) => {
          return item.type;
        });

        if (arrPermission !== undefined) {
          setLsPermissionsType([...arrPermission]);
        }
      } else {
        const arrPermission = userInfo.role;
        setLsPermissionsType([arrPermission]);
      }
    }
  }, [userInfo]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `rgba(2, 15, 89, 1) !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "rgba(2, 15, 89, 1) !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed} className="ProSidebar">
        <Menu iconShape="square" className="MenuUl">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              color: "#fff",
            }}
            className="MenuItemLogo"
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography
                  variant="h3"
                  color={colors.grey[100]}
                  sx={{ margin: "auto" }}
                >
                  <img src={LogoPage} alt="" />
                </Typography>
              </Box>
            )}
          </MenuItem>

          <Box className="BoxMenuItem">
            <Box className="BoxMenuItemTop">
              <Item
                title="User"
                to="/user"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15Z"
                      fill="#5465EF"
                    />
                    <path
                      d="M16.5 5.25H21"
                      stroke={
                        selected === "User" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.75 3V7.5"
                      stroke={
                        selected === "User" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.90527 20.2491C3.82736 18.6531 5.15322 17.3278 6.74966 16.4064C8.34611 15.485 10.1569 15 12.0002 15C13.8434 15 15.6542 15.4851 17.2506 16.4065C18.8471 17.3279 20.1729 18.6533 21.0949 20.2493"
                      stroke={
                        selected === "User" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.6302 11.0796C17.2291 12.1625 16.5239 13.1066 15.5993 13.7984C14.6747 14.4902 13.5701 14.9004 12.418 14.9796C11.266 15.0588 10.1155 14.8037 9.10495 14.245C8.09437 13.6862 7.26659 12.8476 6.72108 11.8298C6.17557 10.812 5.93553 9.65831 6.02978 8.5074C6.12403 7.35648 6.54857 6.25726 7.2524 5.34177C7.95623 4.42629 8.90942 3.73348 9.99745 3.34659C11.0855 2.9597 12.2621 2.89518 13.3859 3.16077"
                      stroke={
                        selected === "User" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Product/ Service"
                to="/product_service"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M3.10386 6.99512C3.03584 7.11049 2.99998 7.24198 3 7.37591V16.6236C3 16.7567 3.03541 16.8874 3.10259 17.0023C3.16978 17.1171 3.26632 17.2121 3.3823 17.2773L11.6323 21.9179C11.7446 21.9811 11.8712 22.0143 12 22.0143L12.001 22.0143L12.089 11.9998L3.10388 6.99513L3.10386 6.99512Z"
                      fill={
                        selected === "Product/ Service"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                    />
                    <path
                      d="M21 16.6237V7.37598C21 7.2429 20.9646 7.11222 20.8974 6.99734C20.8302 6.88247 20.7337 6.78754 20.6177 6.72229L12.3677 2.08167C12.2554 2.01852 12.1288 1.98535 12 1.98535C11.8712 1.98535 11.7446 2.01852 11.6323 2.08167L3.3823 6.72229C3.26632 6.78754 3.16978 6.88247 3.10259 6.99734C3.03541 7.11222 3 7.2429 3 7.37598V16.6237C3 16.7568 3.03541 16.8875 3.10259 17.0023C3.16978 17.1172 3.26632 17.2121 3.3823 17.2774L11.6323 21.918C11.7446 21.9812 11.8712 22.0143 12 22.0143C12.1288 22.0143 12.2554 21.9812 12.3677 21.918L20.6177 17.2774C20.7337 17.2121 20.8302 17.1172 20.8974 17.0023C20.9646 16.8875 21 16.7568 21 16.6237Z"
                      stroke={
                        selected === "Product/ Service"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.5958 14.2979V9.42291L7.5 4.40625"
                      stroke={
                        selected === "Product/ Service"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.8962 6.99605L12.0886 11.9998L3.10352 6.99512"
                      stroke={
                        selected === "Product/ Service"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.089 12L12.001 22.0145"
                      stroke={
                        selected === "Product/ Service"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Project"
                to="/project"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M6.92627 10.4431L6.92542 10.4399L3.98633 13.9669C3.91291 14.055 3.86068 14.1587 3.83364 14.2702C3.80661 14.3816 3.80548 14.4978 3.83037 14.6097L4.98938 19.8253C5.017 19.9496 5.07584 20.0649 5.16033 20.1602C5.24482 20.2555 5.3522 20.3277 5.47232 20.37C5.59245 20.4123 5.72138 20.4233 5.84695 20.402C5.97252 20.3807 6.09059 20.3278 6.19005 20.2483L9.00042 18C7.34716 15.1358 6.82004 12.6071 6.92627 10.4431Z"
                      fill={
                        selected === "Project" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                    />
                    <path
                      opacity="0.2"
                      d="M17.0175 10.3752L17.0184 10.3721L19.9575 13.899C20.0309 13.9871 20.0831 14.0909 20.1101 14.2023C20.1372 14.3137 20.1383 14.4299 20.1134 14.5418L18.9544 19.7574C18.9268 19.8817 18.8679 19.997 18.7834 20.0923C18.699 20.1876 18.5916 20.2598 18.4715 20.3021C18.3513 20.3444 18.2224 20.3555 18.0968 20.3342C17.9713 20.3129 17.8532 20.26 17.7537 20.1804L14.9434 17.9321C16.5966 15.0679 17.1237 12.5393 17.0175 10.3752Z"
                      fill={
                        selected === "Project" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                    />
                    <path
                      d="M13.5 21H10.5"
                      stroke={
                        selected === "Project" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.5288 1.85266C9.74827 3.27898 3.79291 8.97851 9.00032 17.9999H15.0003C20.0995 8.9849 14.2312 3.28705 12.4693 1.85569C12.3365 1.74773 12.1708 1.68854 11.9996 1.68799C11.8285 1.68744 11.6623 1.74556 11.5288 1.85266V1.85266Z"
                      stroke={
                        selected === "Project" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.92541 10.4399L3.98633 13.9668C3.91292 14.0549 3.86069 14.1587 3.83365 14.2701C3.80661 14.3816 3.80548 14.4977 3.83036 14.6097L4.98938 19.8253C5.01701 19.9496 5.07585 20.0649 5.16034 20.1602C5.24484 20.2554 5.35221 20.3277 5.47233 20.37C5.59246 20.4123 5.72138 20.4233 5.84695 20.402C5.97252 20.3807 6.09059 20.3278 6.19004 20.2482L9.00042 17.9999"
                      stroke={
                        selected === "Project" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.0186 10.3721L20.0141 13.9667C20.0875 14.0548 20.1397 14.1585 20.1668 14.27C20.1938 14.3814 20.1949 14.4976 20.1701 14.6095L19.011 19.8251C18.9834 19.9495 18.9246 20.0647 18.8401 20.16C18.7556 20.2553 18.6482 20.3275 18.5281 20.3698C18.408 20.4121 18.279 20.4232 18.1535 20.4019C18.0279 20.3806 17.9098 20.3276 17.8104 20.2481L15 17.9998"
                      stroke={
                        selected === "Project" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 10.125C12.6213 10.125 13.125 9.62132 13.125 9C13.125 8.37868 12.6213 7.875 12 7.875C11.3787 7.875 10.875 8.37868 10.875 9C10.875 9.62132 11.3787 10.125 12 10.125Z"
                      fill={
                        selected === "Project" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                    />
                  </svg>
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Client"
                to="/client"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M7.875 14.75C10.5674 14.75 12.75 12.5674 12.75 9.875C12.75 7.18261 10.5674 5 7.875 5C5.18261 5 3 7.18261 3 9.875C3 12.5674 5.18261 14.75 7.875 14.75Z"
                      fill={
                        selected === "Client" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                    />
                    <path
                      d="M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z"
                      stroke={
                        selected === "Client" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M14.5703 5.43173C15.2408 5.24281 15.9441 5.19978 16.6326 5.30552C17.3212 5.41126 17.9791 5.66333 18.562 6.04475C19.1449 6.42616 19.6393 6.92807 20.012 7.51666C20.3846 8.10525 20.6268 8.76685 20.7221 9.45692C20.8175 10.147 20.764 10.8495 20.565 11.5171C20.366 12.1847 20.0263 12.8019 19.5687 13.3272C19.1111 13.8524 18.5463 14.2735 17.9123 14.5621C17.2782 14.8507 16.5897 15.0001 15.8931 15.0001"
                      stroke={
                        selected === "Client" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.5 18.5059C2.26138 17.4229 3.27215 16.539 4.44698 15.9288C5.62182 15.3186 6.92623 15.0001 8.25008 15C9.57393 14.9999 10.8784 15.3184 12.0532 15.9285C13.2281 16.5386 14.239 17.4225 15.0004 18.5054"
                      stroke={
                        selected === "Client" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.8926 15C17.2166 14.999 18.5213 15.3171 19.6962 15.9273C20.8712 16.5375 21.8819 17.4218 22.6426 18.5054"
                      stroke={
                        selected === "Client" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Market"
                to="/market"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.5 13.0864V19.5C4.5 19.6989 4.57902 19.8897 4.71967 20.0304C4.86032 20.171 5.05109 20.25 5.25 20.25H18.75C18.9489 20.25 19.1397 20.171 19.2803 20.0304C19.421 19.8897 19.5 19.6989 19.5 19.5V13.0866"
                      stroke={
                        selected === "Market" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.06573 3.75H18.9343C19.0973 3.75 19.2558 3.80309 19.3859 3.90124C19.516 3.99939 19.6106 4.13725 19.6554 4.29396L21 9H3L4.34458 4.29396C4.38936 4.13725 4.48396 3.99939 4.61408 3.90124C4.7442 3.80309 4.90274 3.75 5.06573 3.75Z"
                      stroke={
                        selected === "Market" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 9V10.5C9 11.2956 8.68393 12.0587 8.12132 12.6213C7.55871 13.1839 6.79565 13.5 6 13.5C5.20435 13.5 4.44129 13.1839 3.87868 12.6213C3.31607 12.0587 3 11.2956 3 10.5V9"
                      fill="#62687F"
                    />
                    <path
                      d="M9 9V10.5C9 11.2956 8.68393 12.0587 8.12132 12.6213C7.55871 13.1839 6.79565 13.5 6 13.5C5.20435 13.5 4.44129 13.1839 3.87868 12.6213C3.31607 12.0587 3 11.2956 3 10.5V9"
                      stroke={
                        selected === "Market" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15 9V10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5V9"
                      fill="#62687F"
                    />
                    <path
                      d="M15 9V10.5C15 11.2956 14.6839 12.0587 14.1213 12.6213C13.5587 13.1839 12.7956 13.5 12 13.5C11.2044 13.5 10.4413 13.1839 9.87868 12.6213C9.31607 12.0587 9 11.2956 9 10.5V9"
                      stroke={
                        selected === "Market" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21 9V10.5C21 11.2956 20.6839 12.0587 20.1213 12.6213C19.5587 13.1839 18.7956 13.5 18 13.5C17.2044 13.5 16.4413 13.1839 15.8787 12.6213C15.3161 12.0587 15 11.2956 15 10.5V9"
                      fill="#62687F"
                    />
                    <path
                      d="M21 9V10.5C21 11.2956 20.6839 12.0587 20.1213 12.6213C19.5587 13.1839 18.7956 13.5 18 13.5C17.2044 13.5 16.4413 13.1839 15.8787 12.6213C15.3161 12.0587 15 11.2956 15 10.5V9"
                      stroke={
                        selected === "Market" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 20C9 20.5625 8.84249 21.095 8.56499 21.545C8.42249 21.785 8.24999 22.0025 8.04749 22.175C8.02499 22.205 8.0025 22.2275 7.9725 22.25C7.4475 22.7225 6.7575 23 6 23C5.085 23 4.26748 22.5875 3.72748 21.9425C3.71248 21.92 3.69002 21.905 3.67502 21.8825C3.58502 21.7775 3.50251 21.665 3.43501 21.545C3.15751 21.095 3 20.5625 3 20C3 19.055 3.435 18.2075 4.125 17.66C4.2525 17.555 4.38748 17.465 4.52998 17.39C4.96498 17.1425 5.4675 17 6 17C6.75 17 7.42498 17.27 7.94998 17.7275C8.03998 17.795 8.12249 17.8775 8.19749 17.96C8.69249 18.5 9 19.2125 9 20Z"
                      fill="#62687F"
                      stroke={
                        selected === "Market" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.01151 19.9912H4.98828"
                      stroke={
                        selected === "Market" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 19V21.03"
                      stroke={
                        selected === "Market" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Currency"
                to="/currency"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                      fill={
                        selected === "Currency" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                    />
                    <path
                      d="M12 6.75V8.25"
                      stroke={
                        selected === "Currency" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 15.75V17.25"
                      stroke={
                        selected === "Currency" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                      stroke={
                        selected === "Currency" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.75 15.75H13.125C13.6223 15.75 14.0992 15.5525 14.4508 15.2008C14.8025 14.8492 15 14.3723 15 13.875C15 13.3777 14.8025 12.9008 14.4508 12.5492C14.0992 12.1975 13.6223 12 13.125 12H10.875C10.3777 12 9.90081 11.8025 9.54917 11.4508C9.19754 11.0992 9 10.6223 9 10.125C9 9.62772 9.19754 9.15081 9.54917 8.79917C9.90081 8.44754 10.3777 8.25 10.875 8.25H14.25"
                      stroke={
                        selected === "Currency" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Bank Account"
                to="/bank_account"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M2.25 9H21.75L12 3L2.25 9Z"
                      fill={
                        selected === "Bank Account"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                    />
                    <path
                      d="M2.25 9H21.75L12 3L2.25 9Z"
                      stroke={
                        selected === "Bank Account"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.25 9V16.5"
                      stroke={
                        selected === "Bank Account"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.75 9V16.5"
                      stroke={
                        selected === "Bank Account"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.25 9V16.5"
                      stroke={
                        selected === "Bank Account"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.75 9V16.5"
                      stroke={
                        selected === "Bank Account"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 16.5H21"
                      stroke={
                        selected === "Bank Account"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.5 19.5H22.5"
                      stroke={
                        selected === "Bank Account"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Department"
                to="/department"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 12H18.75"
                      stroke={
                        selected === "Department"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      opacity="0.2"
                      d="M18 6.75V17.25H14.25V6.75H18Z"
                      fill="#3B425F"
                      stroke={
                        selected === "Department"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                    />
                    <path
                      opacity="0.2"
                      d="M6 4.5H9.75V19.5H6V4.5Z"
                      fill="#3B425F"
                      stroke={
                        selected === "Department"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                    />
                    <path
                      d="M5.25 12H3"
                      stroke={
                        selected === "Department"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.5 12H10.5"
                      stroke={
                        selected === "Department"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.75 17.25V6.75C18.75 6.33579 18.4142 6 18 6H14.25C13.8358 6 13.5 6.33579 13.5 6.75V17.25C13.5 17.6642 13.8358 18 14.25 18H18C18.4142 18 18.75 17.6642 18.75 17.25Z"
                      stroke={
                        selected === "Department"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.75 3.75H6C5.58579 3.75 5.25 4.08579 5.25 4.5V19.5C5.25 19.9142 5.58579 20.25 6 20.25H9.75C10.1642 20.25 10.5 19.9142 10.5 19.5V4.5C10.5 4.08579 10.1642 3.75 9.75 3.75Z"
                      stroke={
                        selected === "Department"
                          ? "rgba(2, 15, 89, 1)"
                          : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Role"
                to="/role"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M20.2441 6.9095L12.3691 2.45842C12.2565 2.39477 12.1293 2.36133 12 2.36133C11.8707 2.36133 11.7435 2.39477 11.631 2.45842L3.75595 6.9095C3.64033 6.97485 3.54414 7.06974 3.47721 7.18445C3.41027 7.29917 3.375 7.4296 3.375 7.56242V16.4372C3.375 16.57 3.41027 16.7005 3.47721 16.8152C3.54414 16.9299 3.64033 17.0248 3.75595 17.0901L11.631 21.5412C11.7435 21.6049 11.8707 21.6383 12 21.6383C12.1293 21.6383 12.2565 21.6049 12.3691 21.5412L20.244 17.0901C20.3597 17.0248 20.4559 16.9299 20.5228 16.8152C20.5897 16.7005 20.625 16.57 20.625 16.4372V7.56242C20.625 7.4296 20.5897 7.29917 20.5228 7.18445C20.4559 7.06974 20.3597 6.97485 20.2441 6.9095ZM12 15.3748C11.3325 15.3748 10.68 15.1769 10.125 14.806C9.56993 14.4352 9.13735 13.9081 8.88191 13.2914C8.62646 12.6747 8.55962 11.9961 8.68985 11.3414C8.82007 10.6867 9.14151 10.0853 9.61351 9.61333C10.0855 9.14133 10.6869 8.81989 11.3416 8.68967C11.9963 8.55944 12.6749 8.62628 13.2916 8.88172C13.9083 9.13717 14.4354 9.56975 14.8062 10.1248C15.1771 10.6798 15.375 11.3323 15.375 11.9998C15.375 12.443 15.2877 12.8819 15.1181 13.2914C14.9485 13.7008 14.6999 14.0729 14.3865 14.3863C14.0731 14.6997 13.701 14.9483 13.2916 15.1179C12.8821 15.2875 12.4432 15.3748 12 15.3748Z"
                      fill={
                        selected === "Role" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                    />
                    <path
                      d="M20.625 16.4372V7.56241C20.625 7.4296 20.5897 7.29917 20.5228 7.18445C20.4559 7.06973 20.3597 6.97485 20.244 6.90949L12.369 2.45841C12.2565 2.39477 12.1293 2.36133 12 2.36133C11.8707 2.36133 11.7435 2.39477 11.631 2.45841L3.75596 6.90949C3.64033 6.97485 3.54414 7.06973 3.4772 7.18445C3.41027 7.29917 3.375 7.4296 3.375 7.56241V16.4372C3.375 16.57 3.41027 16.7005 3.4772 16.8152C3.54414 16.9299 3.64033 17.0248 3.75596 17.0901L11.631 21.5412C11.7435 21.6049 11.8707 21.6383 12 21.6383C12.1293 21.6383 12.2565 21.6049 12.369 21.5412L20.244 17.0901C20.3597 17.0248 20.4559 16.9299 20.5228 16.8152C20.5897 16.7005 20.625 16.57 20.625 16.4372V16.4372Z"
                      stroke={
                        selected === "Role" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 15.375C13.864 15.375 15.375 13.864 15.375 12C15.375 10.136 13.864 8.625 12 8.625C10.136 8.625 8.625 10.136 8.625 12C8.625 13.864 10.136 15.375 12 15.375Z"
                      stroke={
                        selected === "Role" ? "rgba(2, 15, 89, 1)" : "white"
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
            <Box className="BoxMenuItemFooter">
              {lsPermissionsType.length > 1 && (
                <Tooltip title="Chuyển về trang chủ">
                  <IconButton onClick={onClickButtonChuyenTrang}>
                    <ExitToAppOutlined
                      style={{ color: "#fff", fontSize: "26px" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default SideBarAdmin;
