import {
    Button,
    Col,
    DatePicker,
    Empty,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Table,
    Upload,
} from "antd";
import {
    UploadOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import "./Detail.css";
import dayjs from "dayjs";

import { useState, useEffect } from "react";
import {
    CreateMultipleBill,
    GetDataDetailProject,
    GetDataProject,
} from "../../../api/detailProject";
import { GetCurrencyOfMonth } from "../../../api/listCurrency";
import { openNotificationWithIcon } from "../../../Function";

const { Option } = Select;

const validateMessages = {
    required: "Không được để trống!",
    types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
    },
    pattern: {
        mismatch: "Chỉ được nhập số nguyên",
    },
    number: {
        range: "${label} must be between ${min} and ${max}",
    },
};

const ModalCreateMultipleBill = ({
    isOpenModalCreateMultipleBill,
    setIsOpenModalCreateMultipleBill,
    dataBasicProject,
    valuesDetail,
}) => {
    const [formCreateMultiBill] = Form.useForm();
    const [loadingModal, setLoadingModal] = useState(false);
    const [checkExchangeRate, setCheckExchangRate] = useState(true);
    const [listProject, setListProject] = useState([]);
    const [listProjectFilter, setListProjectFilter] = useState([]);
    const [listClient, setListClient] = useState([]);
    const [projectChose, setProjectChose] = useState([]);
    const [sumValueProject, setSumValueProject] = useState([
        {
            revenue_excluding_tax: 0,
            revenue_excluding_tax_changed: 0,
            tax: 0,
            tax_changed: 0,
            total_revenue: 0,
            total_revenue_changed: 0,
        },
    ]);
    const [yearCreateBill, setYearCreateBill] = useState();
    const [dataOfRemove, setDataOfRemove] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [messageCurrency, setMessageCurrency] = useState();
    const [selectedItems, setSelectedItems] = useState([]);

    const resetGrandTotal = () => {
        setSumValueProject([
            {
                revenue_excluding_tax: 0,
                revenue_excluding_tax_changed: 0,
                tax: 0,
                tax_changed: 0,
                total_revenue: 0,
                total_revenue_changed: 0,
            },
        ]);
    };

    const resetAll = () => {
        setIsOpenModalCreateMultipleBill(false);
        formCreateMultiBill.resetFields();
        setProjectChose([]);
        setListProject([]);
        setCheckExchangRate(true);
        resetGrandTotal();
        setSelectedItems([]);
        setListProjectFilter([])
        setListClient([])
    };

    const handleCancel = () => {
        resetAll();
    };

    const onFinish = (values) => {
        // setLoadingModal(true);
        let checkTotal = true;
        var FormData = require("form-data");
        var data = new FormData();
        data.append("date_create", dayjs(values.date_create).format("DD-MM-YYYY"));
        data.append("date_pay", dayjs(values.date_pay).format("DD-MM-YYYY"));
        data.append("invoice_code", values.invoice_code);
        data.append("data", JSON.stringify(values.data));
        data.append("file_entry", values.file_entry.fileList[0].originFileObj);
        for (let i = 0; i < values.data.length; i++) {
            if (values.data[i].total_revenue <= 0) {
                checkTotal = true;
                break;
            } else {
                checkTotal = false;
            }
        }

        if (checkTotal === true) {
            // setLoadingModal(false);
            openNotificationWithIcon("error", "Không thể tạo phiếu tính tiền cho dự án không có doanh thu");
        } else {
            // openNotificationWithIcon("success", "Được submit");
            CreateMultipleBill(data)
                .then((res) => {
                    setLoadingModal(false);
                    resetAll();
                    openNotificationWithIcon("success", "Đã tạo phiếu thành công");
                })
                .catch((err) => {
                    setLoadingModal(false);
                    openNotificationWithIcon("error", "Tạo phiếu thất bại");
                });
        }
    };

    const changeMoneyVND = (event, value, index) => {
        if (typeof event !== "number" && event !== null) {
            value = value * 1000;
            formCreateMultiBill.setFieldValue(
                ["data", index, "revenue_excluding_tax"],
                value
            );
        }

        formCreateMultiBill.setFieldValue(
            ["data", index, "revenue_excluding_tax_changed"],
            Math.round(value * formCreateMultiBill.getFieldValue("exchange_rate"))
        );

        formCreateMultiBill.setFieldValue(
            ["data", index, "tax"],
            Math.round(value * 10 / 100)
        );

        formCreateMultiBill.setFieldValue(
            ["data", index, "tax_changed"],
            Math.round(formCreateMultiBill.getFieldValue(["data", index, "tax"]) * formCreateMultiBill.getFieldValue("exchange_rate"))
        );

        formCreateMultiBill.setFieldValue(
            ["data", index, "total_revenue"],
            Math.round(value + formCreateMultiBill.getFieldValue(["data", index, "tax"]))
        );

        formCreateMultiBill.setFieldValue(
            ["data", index, "total_revenue_changed"],
            Math.round(formCreateMultiBill.getFieldValue(["data", index, "total_revenue"]) * formCreateMultiBill.getFieldValue("exchange_rate"))
        );

        let totalExcluding = 0;
        let totalExcludingChanged = 0;
        let totalTax = 0;
        let totalTaxChanged = 0;
        let totalRevenue = 0;
        let totalRevenueChanged = 0;
        const listValueData = formCreateMultiBill.getFieldValue("data");

        // Thành tiền
        for (let i = 0; i < listValueData.length; i++) {
            totalExcluding +=
                listValueData[i].revenue_excluding_tax !== undefined
                    ? listValueData[i].revenue_excluding_tax
                    : 0;
        }

        // Thành tiền theo VND
        for (let i = 0; i < listValueData.length; i++) {
            totalExcludingChanged +=
                listValueData[i].revenue_excluding_tax_changed !== undefined
                    ? listValueData[i].revenue_excluding_tax_changed
                    : 0;
        }

        // Thuế
        for (let i = 0; i < listValueData.length; i++) {
            totalTax +=
                listValueData[i].tax !== undefined
                    ? listValueData[i].tax
                    : 0;
        }

        // Thuế theo VND
        for (let i = 0; i < listValueData.length; i++) {
            totalTaxChanged +=
                listValueData[i].tax_changed !== undefined
                    ? listValueData[i].tax_changed
                    : 0;
        }

        // Tổng tiền
        for (let i = 0; i < listValueData.length; i++) {
            totalRevenue +=
                listValueData[i].total_revenue !== undefined
                    ? listValueData[i].total_revenue
                    : 0;
        }

        // Tổng tiền theo VND
        for (let i = 0; i < listValueData.length; i++) {
            totalRevenueChanged +=
                listValueData[i].total_revenue_changed !== undefined
                    ? listValueData[i].total_revenue_changed
                    : 0;
        }

        setSumValueProject([
            {
                revenue_excluding_tax: totalExcluding.toLocaleString(),
                revenue_excluding_tax_changed: totalExcludingChanged.toLocaleString(),
                tax: totalTax.toLocaleString(),
                tax_changed: totalTaxChanged.toLocaleString(),
                total_revenue: totalRevenue.toLocaleString(),
                total_revenue_changed: totalRevenueChanged.toLocaleString(),
            },
        ]);
    };

    const changeTaxVND = (event, value, index) => {
        if (typeof event !== "number" && event !== null) {
            value = value * 1000;
            formCreateMultiBill.setFieldValue(["data", index, "tax"], value);
        }

        formCreateMultiBill.setFieldValue(
            ["data", index, "tax_changed"],
            Math.round(value * formCreateMultiBill.getFieldValue("exchange_rate"))
        );

        if (formCreateMultiBill.getFieldValue(["data", index, "revenue_excluding_tax"]) === undefined) {
            formCreateMultiBill.setFieldValue(
                ["data", index, "total_revenue"],
                Math.round(value + 0)
            );
        } else {
            formCreateMultiBill.setFieldValue(
                ["data", index, "total_revenue"],
                Math.round(value + formCreateMultiBill.getFieldValue(["data", index, "revenue_excluding_tax"]))
            );
        }

        formCreateMultiBill.setFieldValue(
            ["data", index, "total_revenue_changed"],
            Math.round(formCreateMultiBill.getFieldValue(["data", index, "total_revenue"]) * formCreateMultiBill.getFieldValue("exchange_rate"))
        );

        let totalTax = 0;
        let totalTaxChange = 0;
        let totalRevenue = 0;
        let totalRevenueChanged = 0;
        const listValueData = formCreateMultiBill.getFieldValue("data");

        for (let i = 0; i < listValueData.length; i++) {
            totalTax += listValueData[i].tax !== undefined ? listValueData[i].tax : 0;
        }

        for (let i = 0; i < listValueData.length; i++) {
            totalTaxChange +=
                listValueData[i].tax_changed !== undefined
                    ? listValueData[i].tax_changed
                    : 0;
        }

        for (let i = 0; i < listValueData.length; i++) {
            totalRevenue += listValueData[i].total_revenue !== undefined ? listValueData[i].total_revenue : 0;
        }

        for (let i = 0; i < listValueData.length; i++) {
            totalRevenueChanged +=
                listValueData[i].total_revenue_changed !== undefined
                    ? listValueData[i].total_revenue_changed
                    : 0;
        }

        setSumValueProject([
            {
                revenue_excluding_tax: sumValueProject[0].revenue_excluding_tax,
                revenue_excluding_tax_changed:
                    sumValueProject[0].revenue_excluding_tax_changed,
                tax: totalTax.toLocaleString(),
                tax_changed: totalTaxChange.toLocaleString(),
                total_revenue: totalRevenue.toLocaleString(),
                total_revenue_changed: totalRevenueChanged.toLocaleString(),
            },
        ]);
    };

    const changeTotalVND = (event, value, index) => {
        if (typeof event !== "number" && event !== null) {
            value = value * 1000;
            formCreateMultiBill.setFieldValue(
                ["data", index, "total_revenue"],
                value
            );
        }

        formCreateMultiBill.setFieldValue(
            ["data", index, "total_revenue_changed"],
            Math.round(value * formCreateMultiBill.getFieldValue("exchange_rate"))
        );

        let totalRevenue = 0;
        let totalRevenueChanged = 0;
        const listValueData = formCreateMultiBill.getFieldValue("data");
        for (let i = 0; i < listValueData.length; i++) {
            totalRevenue +=
                listValueData[i].total_revenue !== undefined
                    ? listValueData[i].total_revenue
                    : 0;
        }

        for (let i = 0; i < listValueData.length; i++) {
            totalRevenueChanged +=
                listValueData[i].total_revenue_changed !== undefined
                    ? listValueData[i].total_revenue_changed
                    : 0;
        }

        setSumValueProject([
            {
                revenue_excluding_tax: sumValueProject[0].revenue_excluding_tax,
                revenue_excluding_tax_changed:
                    sumValueProject[0].revenue_excluding_tax_changed,
                tax: sumValueProject[0].tax,
                tax_changed: sumValueProject[0].tax_changed,
                total_revenue: totalRevenue.toLocaleString(),
                total_revenue_changed: totalRevenueChanged.toLocaleString(),
            },
        ]);
    };

    const handleTaxChanged = () => {
        let totalTaxChanged = 0;
        const listValueData = formCreateMultiBill.getFieldValue("data");

        for (let i = 0; i < listValueData.length; i++) {
            totalTaxChanged +=
                listValueData[i].tax_changed !== undefined
                    ? listValueData[i].tax_changed
                    : 0;
        }

        setSumValueProject([
            {
                revenue_excluding_tax: sumValueProject[0].revenue_excluding_tax,
                revenue_excluding_tax_changed:
                    sumValueProject[0].revenue_excluding_tax_changed,
                tax: sumValueProject[0].tax,
                tax_changed: totalTaxChanged.toLocaleString(),
                total_revenue: sumValueProject[0].total_revenue,
                total_revenue_changed: sumValueProject[0].total_revenue_changed,
            },
        ]);
    }

    const handleTotalRevenueChanged = () => {
        let totalRevenueChanged = 0;
        const listValueData = formCreateMultiBill.getFieldValue("data");

        for (let i = 0; i < listValueData.length; i++) {
            totalRevenueChanged +=
                listValueData[i].total_revenue_changed !== undefined
                    ? listValueData[i].total_revenue_changed
                    : 0;
        }

        setSumValueProject([
            {
                revenue_excluding_tax: sumValueProject[0].revenue_excluding_tax,
                revenue_excluding_tax_changed:
                    sumValueProject[0].revenue_excluding_tax_changed,
                tax: sumValueProject[0].tax,
                tax_changed: sumValueProject[0].tax_changed,
                total_revenue: sumValueProject[0].total_revenue,
                total_revenue_changed: totalRevenueChanged.toLocaleString(),
            },
        ]);
    }

    const getDataCurrency = (idCurrency, time) => {
        GetCurrencyOfMonth({
            id: idCurrency,
            date: "01-" + (dayjs(time).month() + 1) + "-" + dayjs(time).year(),
        })
            .then((res) => {
                setCheckExchangRate(true);
                formCreateMultiBill.setFieldValue(
                    "exchange_rate",
                    res.data.message.money
                );
            })
            .catch((err) => {
                setCheckExchangRate(false);
                setMessageCurrency(err.data.message);
                formCreateMultiBill.setFieldValue("exchange_rate", undefined);
            });
    };

    useEffect(() => {
        if (isOpenModalCreateMultipleBill === true) {
            // formCreateMultiBill.setFieldValue("currency", dataBasicProject.currency.code);
            // getDataCurrency();
        }
    }, [isOpenModalCreateMultipleBill]);

    const chooseMonth = (value) => {
        formCreateMultiBill.setFieldsValue({
            client: undefined,
            project: undefined,
            exchange_rate: undefined,
            currency: undefined,
            invoice_code: undefined,
            date_pay: undefined,
            file_entry: undefined,
            data: undefined,
        });
        formCreateMultiBill.getFieldValue("data");
        setFileList([]);
        setListProjectFilter([]);
        setProjectChose([]);
        setCheckExchangRate(true);
        resetGrandTotal();
        if (value !== null) {
            GetDataProject({
                date_create: dayjs(value).format("DD-MM-YYYY"),
            })
                .then((res) => {
                    setYearCreateBill(value);
                    setListProject(res.data.message.listProject);
                    setListClient(res.data.message.listClient);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    useEffect(() => {
        if (isOpenModalCreateMultipleBill === true) {
        }
    }, [isOpenModalCreateMultipleBill]);

    const columns = [
        {
            title: "Dự án",
            width: "14%",
            dataIndex: "project",
            key: "project",
            render: (value, item, index) => (
                <>
                    <Form.Item
                        name={["data", index, "project_id"]}
                        label={""}
                        key="project_id"
                        className="custom-form-item"
                    >
                        <div className="typo-month">
                            <p>{projectChose[index].project}</p>
                        </div>
                    </Form.Item>
                </>
            ),
        },
        {
            title: <p style={{ textAlign: "center" }}>Thành tiền</p>,
            width: "14%",
            dataIndex: "revenue_excluding_tax",
            key: "revenue_excluding_tax",
            align: "right",
            render: (value, item, index) => (
                <>
                    <Form.Item
                        name={["data", index, "revenue_excluding_tax"]}
                        label={""}
                        key="revenue_excluding_tax"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        className="custom-form-item"
                    >
                        <InputNumber
                            // type="number"
                            // value={valueRevenueExcluding}
                            min={0}
                            controls={false}
                            placeholder="Nhập tiền"
                            className="right-to-left-input"
                            formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            // onClick={(record) => handleInputClick(record, index)}
                            // onChange={newValue => changeMoneyVND(newValue, index)}
                            // onChange={value => changeMoneyVND(value, index)}
                            onChange={(e) =>
                                changeMoneyVND(
                                    e,
                                    formCreateMultiBill.getFieldValue([
                                        "data",
                                        index,
                                        "revenue_excluding_tax",
                                    ]),
                                    index
                                )
                            }
                            style={{ width: "100%" }}
                            onKeyPress={(e) => {
                                const charCode = e.which ? e.which : e.keyCode;
                                if (charCode < 48 || charCode > 57) {
                                    e.preventDefault();
                                }
                                if (
                                    charCode === 32 &&
                                    formCreateMultiBill.getFieldValue([
                                        "data",
                                        index,
                                        "revenue_excluding_tax",
                                    ]) !== undefined
                                ) {
                                    changeMoneyVND(
                                        e,
                                        formCreateMultiBill.getFieldValue([
                                            "data",
                                            index,
                                            "revenue_excluding_tax",
                                        ]),
                                        index
                                    );
                                }
                            }}
                        />
                    </Form.Item>
                </>
            ),
        },
        {
            title: <p style={{ textAlign: "center" }}>Thành tiền theo VND</p>,
            width: "14%",
            dataIndex: "revenue_excluding_tax_changed",
            key: "revenue_excluding_tax_changed",
            align: "right",
            render: (value, item, index) => (
                <Form.Item
                    name={["data", index, "revenue_excluding_tax_changed"]}
                    label={""}
                    key="revenue_excluding_tax_changed"
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                    className="custom-form-item"
                >
                    <InputNumber
                        // type="number"
                        // value={valueRevenueExcluding}
                        min={0}
                        controls={false}
                        placeholder="Nhập tiền"
                        className="right-to-left-input"
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        style={{ width: "100%" }}
                        onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode < 48 || charCode > 57) {
                                e.preventDefault();
                            }
                        }}
                        disabled={true}
                    />
                </Form.Item>
            ),
        },
        {
            title: <p style={{ textAlign: "center" }}>Thuế</p>,
            width: "14%",
            dataIndex: "tax",
            key: "tax",
            align: "right",
            render: (value, item, index) => (
                <Form.Item
                    name={["data", index, "tax"]}
                    label={""}
                    key="tax"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    className="custom-form-item"
                >
                    <InputNumber
                        // type="number"
                        // value={valueRevenueExcluding}
                        min={0}
                        controls={false}
                        placeholder="Nhập tiền"
                        className="right-to-left-input"
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        // onChange={newValue => changeTaxVND(newValue, index)}
                        onChange={(e) =>
                            changeTaxVND(
                                e,
                                formCreateMultiBill.getFieldValue(["data", index, "tax"]),
                                index
                            )
                        }
                        style={{ width: "100%" }}
                        onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode < 48 || charCode > 57) {
                                e.preventDefault();
                            }
                            if (
                                charCode === 32 &&
                                formCreateMultiBill.getFieldValue(["data", index, "tax"]) !==
                                undefined
                            ) {
                                changeTaxVND(
                                    e,
                                    formCreateMultiBill.getFieldValue(["data", index, "tax"]),
                                    index
                                );
                            }
                        }}
                    />
                </Form.Item>
            ),
        },
        {
            title: <p style={{ textAlign: "center" }}>Thuế theo VND</p>,
            width: "14%",
            dataIndex: "tax_changed",
            key: "tax_changed",
            align: "right",
            render: (value, item, index) => (
                <Form.Item
                    name={["data", index, "tax_changed"]}
                    label={""}
                    key="tax_changed"
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                    className="custom-form-item"
                >
                    <InputNumber
                        // type="number"
                        // value={valueRevenueExcluding}
                        onChange={(e) =>
                            handleTaxChanged(
                                e,
                                formCreateMultiBill.getFieldValue(["data", index, "tax_changed"]),
                                index
                            )
                        }
                        min={0}
                        controls={false}
                        placeholder="Nhập tiền"
                        className="right-to-left-input"
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        style={{ width: "100%" }}
                        onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode < 48 || charCode > 57) {
                                e.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            ),
        },
        {
            title: <p style={{ textAlign: "center" }}>Tổng tiền</p>,
            width: "14%",
            dataIndex: "total_revenue",
            key: "total_revenue",
            align: "right",
            render: (value, item, index) => (
                <Form.Item
                    name={["data", index, "total_revenue"]}
                    label={""}
                    key="total_revenue"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    className="custom-form-item"
                >
                    <InputNumber
                        // type="number"
                        // value={valueRevenueExcluding}
                        min={0}
                        controls={false}
                        placeholder="Nhập tiền"
                        className="right-to-left-input"
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        // onChange={newValue => changeTotalVND(newValue, index)}
                        onChange={(e) =>
                            changeTotalVND(
                                e,
                                formCreateMultiBill.getFieldValue([
                                    "data",
                                    index,
                                    "total_revenue",
                                ]),
                                index
                            )
                        }
                        style={{ width: "100%" }}
                        onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode < 48 || charCode > 57) {
                                e.preventDefault();
                            }
                            if (
                                charCode === 32 &&
                                formCreateMultiBill.getFieldValue([
                                    "data",
                                    index,
                                    "total_revenue",
                                ]) !== undefined
                            ) {
                                changeTotalVND(
                                    e,
                                    formCreateMultiBill.getFieldValue([
                                        "data",
                                        index,
                                        "total_revenue",
                                    ]),
                                    index
                                );
                            }
                        }}
                    />
                </Form.Item>
            ),
        },
        {
            title: <p style={{ textAlign: "center" }}>Tổng tiền theo VND</p>,
            width: "14%",
            dataIndex: "total_revenue_changed",
            key: "total_revenue_changed",
            // align: "right",
            render: (value, item, index) => (
                <Form.Item
                    name={["data", index, "total_revenue_changed"]}
                    label={""}
                    key="total_revenue_changed"
                    // rules={[
                    //   {
                    //     required: true,
                    //   },
                    // ]}
                    className="custom-form-item"
                >
                    <InputNumber
                        // type="number"
                        // value={valueRevenueExcluding}

                        onChange={(e) =>
                            handleTotalRevenueChanged(
                                e,
                                formCreateMultiBill.getFieldValue(["data", index, "total_revenue_changed"]),
                                index
                            )
                        }
                        min={0}
                        controls={false}
                        placeholder="Nhập tiền"
                        className="right-to-left-input"
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        style={{ width: "100%" }}
                        onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (charCode < 48 || charCode > 57) {
                                e.preventDefault();
                            }
                        }}
                    />
                </Form.Item>
            ),
        },
    ];

    const columnSum = [
        {
            // title: "Dự án",
            width: "14%",
            dataIndex: "project",
            key: "project",
            render: (value, item, index) => (
                <div className="typo-month">
                    <p>Grand Total</p>
                </div>
            ),
        },
        {
            // title: <p style={{ textAlign: "center" }}>Thành tiền</p>,
            width: "14%",
            dataIndex: "revenue_excluding_tax",
            key: "revenue_excluding_tax",
            align: "right",
            render: (value, item, index) => (
                <p>{sumValueProject[0].revenue_excluding_tax}</p>
            ),
        },
        {
            // title: <p style={{ textAlign: "center" }}>Thành tiền theo VND</p>,
            width: "14%",
            dataIndex: "revenue_excluding_tax_changed",
            key: "revenue_excluding_tax_changed",
            align: "right",
            render: (value, item, index) => (
                <p>{sumValueProject[0].revenue_excluding_tax_changed}</p>
            ),
        },
        {
            // title: <p style={{ textAlign: "center" }}>Thuế</p>,
            width: "14%",
            dataIndex: "tax",
            key: "tax",
            align: "right",
            render: (value, item, index) => <p>{sumValueProject[0].tax}</p>,
        },
        {
            // title: <p style={{ textAlign: "center" }}>Thuế theo VND</p>,
            width: "14%",
            dataIndex: "tax_changed",
            key: "tax_changed",
            align: "right",
            render: (value, item, index) => <p>{sumValueProject[0].tax_changed}</p>,
        },
        {
            // title: <p style={{ textAlign: "center" }}>Tổng tiền</p>,
            width: "14%",
            dataIndex: "total_revenue",
            key: "total_revenue",
            align: "right",
            render: (value, item, index) => <p>{sumValueProject[0].total_revenue}</p>,
        },
        {
            // title: <p style={{ textAlign: "center" }}>Tổng tiền theo VND</p>,
            width: "14%",
            dataIndex: "total_revenue_changed",
            key: "total_revenue_changed",
            align: "right",
            render: (value, item, index) => (
                <p>{sumValueProject[0].total_revenue_changed}</p>
            ),
        },
    ];

    const ChooseClient = (value) => {
        formCreateMultiBill.setFieldsValue({
            project: undefined,
            exchange_rate: undefined,
            currency: undefined,
            file_entry: undefined,
            data: undefined,
        });
        setFileList([]);
        setCheckExchangRate(true);
        resetGrandTotal();
        const newListClient = listProject.filter(
            (item) => item.client_id === value
        );
        setProjectChose([]);
        setListProjectFilter(newListClient);
    };

    const handleDeselect = (value) => {
        let totalExcluding = 0;
        let totalExcludingChanged = 0;
        let totalTax = 0;
        let totalTaxChanged = 0;
        let totalRevenue = 0;
        let totalRevenueChanged = 0;

        const newList = formCreateMultiBill.getFieldValue("data");
        // Get index of value want remove
        const index = dataOfRemove.indexOf(value);
        if (selectedItems.length > 1) {
            newList.splice(index, 1);
            formCreateMultiBill.setFieldValue(["data"], newList);
            for (let i = 0; i < newList.length; i++) {
                totalExcluding +=
                    newList[i].revenue_excluding_tax !== undefined
                        ? newList[i].revenue_excluding_tax
                        : 0;
                totalExcludingChanged +=
                    newList[i].revenue_excluding_tax_changed !== undefined
                        ? newList[i].revenue_excluding_tax_changed
                        : 0;
                totalTax += newList[i].tax !== undefined ? newList[i].tax : 0;
                totalTaxChanged +=
                    newList[i].tax_changed !== undefined ? newList[i].tax_changed : 0;
                totalRevenue +=
                    newList[i].total_revenue !== undefined ? newList[i].total_revenue : 0;
                totalRevenueChanged +=
                    newList[i].total_revenue_changed !== undefined
                        ? newList[i].total_revenue_changed
                        : 0;
            }

            // set Value Grand Total
            setSumValueProject([
                {
                    revenue_excluding_tax: totalExcluding.toLocaleString(),
                    revenue_excluding_tax_changed: totalExcludingChanged.toLocaleString(),
                    tax: totalTax.toLocaleString(),
                    tax_changed: totalTaxChanged.toLocaleString(),
                    total_revenue: totalRevenue.toLocaleString(),
                    total_revenue_changed: totalRevenueChanged.toLocaleString(),
                },
            ]);
        } else {
            formCreateMultiBill.setFieldValue("exchange_rate", undefined);
            formCreateMultiBill.setFieldValue("currency", undefined);
            formCreateMultiBill.setFieldValue(["data"], undefined);
            resetGrandTotal();
        }
    };

    const ChooseProject = (value, option) => {
        const parsedData = value.map((jsonString) => JSON.parse(jsonString));
        // get List id of parsedData
        let newArrId = parsedData.map((item) => {
            return item.id;
        });

        const newListProject = parsedData.filter((item) =>
            newArrId.includes(item.id)
        );

        // Get value currency based on project
        if (newListProject.length > 0) {
            GetDataDetailProject({
                id_project: newListProject[0].id,
                year: dayjs(yearCreateBill).year(),
            })
                .then((res) => {
                    getDataCurrency(res.data.project.currency.id, yearCreateBill);
                    formCreateMultiBill.setFieldValue(
                        "currency",
                        res.data.project.currency.code
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            formCreateMultiBill.setFieldValue("currency", undefined);
        }

        const deselectedIndexes = selectedItems
            .map((item, index) => (value.indexOf(item) === -1 ? index : -1))
            .filter((index) => index !== -1);

        // Xử lý các mục bị bỏ chọn
        if (deselectedIndexes.length > 0) {
            // Thực hiện hành động bạn muốn với các mục bị bỏ chọn ở đây
            setDataOfRemove(value);
            setProjectChose(newListProject);
            if (newListProject.length === 0) {
                formCreateMultiBill.setFieldValue("data", undefined);
            }
        } else {
            // set value of field project_id
            newListProject.forEach((item, index) => {
                formCreateMultiBill.setFieldValue(
                    ["data", index, "project_id"],
                    item.id
                );
            });

            setDataOfRemove(value);
            setProjectChose(newListProject);
            if (newListProject.length === 0) {
                formCreateMultiBill.setFieldValue("data", undefined);
            }
        }
        setSelectedItems(value);
    };

    const handleChangeFile = (value) => {
        setFileList(value.fileList);
    };

    return (
        <>
            <Modal
                title={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div className="title-bill" style={{ width: "100%" }}>
                            <span className="title-field-bill" style={{ fontSize: 22 }}>
                                Tạo phiếu tính tiền
                            </span>
                        </div>
                    </div>
                }
                open={isOpenModalCreateMultipleBill}
                // onOk={handleOk}
                // onCancel={handleCancel}
                width={window.screen.availHeight < 800 ? "80%" : "70%"}
                closable={false}
                footer={false}
                // style={{ padding: "10px 24px" }}

                centered
            >
                <Form
                    form={formCreateMultiBill}
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    validateMessages={validateMessages}
                    initialValues={{
                        remember: true,
                    }}
                    className="custom-form"
                    key="formImport"
                >
                    <Row style={{ height: 80 }}>
                        <Col span={7} offset={1} className="col11">
                            <Form.Item
                                name="date_create"
                                label={
                                    <span className="title-field-bill">Tháng tạo phiếu</span>
                                }
                                key="date_create"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker
                                    onChange={(value) => chooseMonth(value)}
                                    placeholder="Chọn tháng tạo phiếu"
                                    style={{ width: "100%" }}
                                    format={"MM-YYYY"}
                                    picker="month"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7} offset={1} className="col11">
                            <Form.Item
                                name="invoice_code"
                                label={<span className="title-field-bill">Mã số</span>}
                                key="invoice_code"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="Nhập mã số"></Input>
                            </Form.Item>
                        </Col>
                        <Col span={7} offset={1} className="col11">
                            <Form.Item
                                name="date_pay"
                                label={<span className="title-field-bill">Ngày phát hành</span>}
                                key="date_pay"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker
                                    //   onChange={(value) =>
                                    //     setDateStart(moment(value).format(dateFormat))
                                    //   }
                                    placeholder="Chọn ngày dự kiến"
                                    style={{ width: "100%" }}
                                    format={"DD-MM-YYYY"}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ height: 80 }}>
                        <Col span={7} offset={1} className="col11">
                            <Form.Item
                                name="client"
                                label={<p className="title-field-bill">Khách hàng</p>}
                                key="client"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Chọn Khách hàng"
                                    key="client"
                                    allowClear
                                    maxTagCount="responsive"
                                    optionFilterProp="children"
                                    // className="custom-select"
                                    showSearch
                                    onChange={ChooseClient}
                                // optionLabelProp="data-label"
                                // getPopupContainer={getPopupContainer}
                                >
                                    {listClient.length > 0
                                        ? listClient.map((item, index) => (
                                            <Option key={index} value={item.id}>
                                                {item.client_code}
                                            </Option>
                                        ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={7} offset={1} className="col11">
                            <Form.Item
                                name="project"
                                label={<p className="title-field-bill">Dự án</p>}
                                key="project"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Chọn Dự án"
                                    key="department"
                                    allowClear
                                    maxTagCount="responsive"
                                    optionFilterProp="children"
                                    // className="custom-select"
                                    showSearch
                                    // optionLabelProp="data-label"
                                    mode="multiple"
                                    onDeselect={handleDeselect}
                                    onChange={ChooseProject}
                                // getPopupContainer={getPopupContainer}
                                >
                                    {listProject.length > 0
                                        ? listProjectFilter.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={JSON.stringify(item)}
                                            // value={item.id}
                                            >
                                                {item.project}
                                            </Option>
                                        ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            span={7}
                            offset={1}
                            className="col11"
                            style={{ display: "flex" }}
                        >
                            <Col span={11}>
                                {" "}
                                <Form.Item
                                    name="currency"
                                    label={
                                        <span className="title-field-bill">Đơn vị tiền tệ</span>
                                    }
                                    key="currency"
                                >
                                    <Input disabled={true}></Input>
                                </Form.Item>
                            </Col>

                            <Col span={11} offset={2}>
                                <Form.Item
                                    name="exchange_rate"
                                    label={
                                        <span className="title-field-bill">Tỉ giá quy đổi</span>
                                    }
                                    key="exchange_rate"
                                // rules={[
                                //   {
                                //     required: true,
                                //   },
                                // ]}
                                >
                                    <Input disabled={true}></Input>
                                </Form.Item>
                            </Col>
                        </Col>
                    </Row>

                    <Table
                        columns={columns}
                        dataSource={projectChose}
                        style={{ width: "100%" }}
                        // scroll={{
                        //   y: window.screen.availHeight > 850 ? "56vh" : "52vh",
                        //   x: 1500,
                        // }}
                        pagination={false}
                        className="custom-table table-striped-rows table-all-revenue"
                        bordered
                        rowClassName={(record, index) =>
                            index % 2 === 0 ? "table-row-light" : "table-row-dark"
                        }
                        locale={{
                            emptyText: (
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Chưa có dữ liệu"
                                />
                            ),
                        }}
                    />

                    {projectChose.length > 0 ? (
                        <Table
                            columns={columnSum}
                            dataSource={sumValueProject}
                            style={{ width: "100%" }}
                            // scroll={{
                            //   y: window.screen.availHeight > 850 ? "56vh" : "52vh",
                            //   x: 1500,
                            // }}
                            pagination={false}
                            className="custom-table table-striped-rows"
                            bordered
                            rowClassName={(record, index) =>
                                index % 2 === 0 ? "table-row-light" : "table-row-dark"
                            }
                            locale={{
                                emptyText: null,
                            }}
                            showHeader={false}
                        />
                    ) : null}

                    <Col span={11} style={{ marginTop: "1%" }} className="col11">
                        <Form.Item
                            name="file_entry"
                            label={<span className="title-field-bill">Đính kèm PDF</span>}
                            key="file_entry"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Upload
                                maxCount={1}
                                beforeUpload={() => false}
                                accept=".pdf"
                                fileList={fileList}
                                onChange={handleChangeFile}
                            >
                                <Button htmlType="button" icon={<UploadOutlined />}>
                                    Bấm để tải lên
                                </Button>
                            </Upload>
                        </Form.Item>
                    </Col>

                    {checkExchangeRate === false ? (
                        <div
                            style={{
                                backgroundColor: "rgb(251 53 55 / 79%)",
                                height: 40,
                                display: "flex",
                                alignItems: "center",
                                borderRadius: 6,
                            }}
                        >
                            <WarningOutlined
                                style={{ color: "#fff", fontSize: 30, marginLeft: 10 }}
                            />
                            <p style={{ color: "#fff", fontSize: 16, marginLeft: 30 }}>
                                {messageCurrency}
                            </p>
                        </div>
                    ) : null}

                    <Row
                        style={{
                            justifyContent: "center",
                            height: "62px",
                            marginTop: "1.5%",
                        }}
                    >
                        <Button
                            loading={loadingModal}
                            disabled={!checkExchangeRate}
                            htmlType="submit"
                            className="btn-submit-dtdk"
                            style={{ height: "46px", width: 180 }}
                        >
                            SUBMIT
                        </Button>
                        <Button
                            className="btn-cancel-dtdk"
                            onClick={handleCancel}
                            style={{ marginLeft: "30px", height: "46px", width: 180 }}
                        >
                            CANCEL
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default ModalCreateMultipleBill;
