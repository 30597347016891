import React from 'react'
import "../ProjectDetailSale.css"
import { Button, Col, Form, InputNumber, Row } from 'antd';
function FormCreateRevenueSale({
    valuesDetail,
    formExpectedRevenue,
    onFinishExpectedRevenue,
    validateMessages,
    loadingBtnDtdk,
    closeCreateRevenue
}) {
    return (
        <>
            <div className="bg-dtdk">
                <div style={{ marginTop: "14px" }}>
                    <Row style={{ marginBottom: 8 }}>
                        <Col className="title-dtdk" span={20} offset={2}>
                            Doanh thu dự kiến {valuesDetail.date.$y}
                        </Col>
                    </Row>
                    <Form
                        form={formExpectedRevenue}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={onFinishExpectedRevenue}
                        // onFinishFailed={onFinishFailed}
                        validateMessages={validateMessages}
                        initialValues={{
                            remember: true,
                        }}
                        className="custom-form"
                        key="formImport"
                    >
                        <div>
                            <Row>
                                <Col span={8} offset={2}>
                                    <Form.Item
                                        name="thang_1"
                                        label={
                                            <span className="custom-field-revenue">
                                                Tháng1
                                            </span>
                                        }
                                        // label='Tháng 1'
                                        key="thang_1"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 1"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} offset={4}>
                                    <Form.Item
                                        name="thang_7"
                                        label=<span className="custom-field-revenue">
                                            Tháng 7
                                        </span>
                                        key="thang_7"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 7"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8} offset={2}>
                                    <Form.Item
                                        name="thang_2"
                                        label={
                                            <p className="custom-field-revenue">Tháng 2</p>
                                        }
                                        key="thang_2"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 2"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} offset={4}>
                                    <Form.Item
                                        name="thang_8"
                                        label=<span className="custom-field-revenue">
                                            Tháng 8
                                        </span>
                                        key="thang_8"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 8"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8} offset={2}>
                                    <Form.Item
                                        name="thang_3"
                                        label=<span className="custom-field-revenue">
                                            Tháng 3
                                        </span>
                                        key="thang_3"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 3"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} offset={4}>
                                    <Form.Item
                                        name="thang_9"
                                        label=<span className="custom-field-revenue">
                                            Tháng 9
                                        </span>
                                        key="thang_9"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 9"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8} offset={2}>
                                    <Form.Item
                                        name="thang_4"
                                        label=<span className="custom-field-revenue">
                                            Tháng 4
                                        </span>
                                        key="thang_4"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 4"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} offset={4}>
                                    <Form.Item
                                        name="thang_10"
                                        label=<span className="custom-field-revenue">
                                            Tháng 10
                                        </span>
                                        key="thang_10"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 10"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8} offset={2}>
                                    <Form.Item
                                        name="thang_5"
                                        label=<span className="custom-field-revenue">
                                            Tháng 5
                                        </span>
                                        key="thang_5"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 5"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} offset={4}>
                                    <Form.Item
                                        name="thang_11"
                                        label=<span className="custom-field-revenue">
                                            Tháng 11
                                        </span>
                                        key="thang_11"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 11"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8} offset={2}>
                                    <Form.Item
                                        name="thang_6"
                                        label=<span className="custom-field-revenue">
                                            Tháng 6
                                        </span>
                                        key="thang_6"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 6"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} offset={4}>
                                    <Form.Item
                                        name="thang_12"
                                        label=<span className="custom-field-revenue">
                                            Tháng 12
                                        </span>
                                        key="thang_12"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            // type="number"
                                            // value={valueRevenueExcluding}
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                )
                                            }
                                            // onChange={changeMoneyVND}
                                            style={{ width: "100%" }}
                                            onKeyPress={(e) => {
                                                const charCode = e.which
                                                    ? e.which
                                                    : e.keyCode;
                                                if (charCode < 48 || charCode > 57) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            min={0}
                                            controls={false}
                                            placeholder="Nhập doanh thu dự kiến tháng 12"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row
                                style={{ justifyContent: "center", height: "62px" }}
                            >
                                <Button
                                    htmlType="submit"
                                    loading={loadingBtnDtdk}
                                    className="btn-submit-dtdk"
                                    style={{ height: "46px", width: 180 }}
                                >
                                    SUBMIT
                                </Button>
                                <Button
                                    className="btn-cancel-dtdk"
                                    onClick={closeCreateRevenue}
                                    style={{
                                        marginLeft: "30px",
                                        height: "46px",
                                        width: 180,
                                    }}
                                >
                                    CANCEL
                                </Button>
                            </Row>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default FormCreateRevenueSale